import { useApp } from '../context';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';

const Errors = () => {
  const { error, setError } = useApp();

  const handleDismiss = () => {
    setError('')
  }

  return(
    <CenterModal open={error?.length > 0} handleClose={handleDismiss} blurStyle={{ zIndex: 19999 }} style={{ zIndex: 20000, width: 400, maxWidth: '90%' }} dontExpand={true}>
      <h1>Uh Oh!</h1>
      <div>{error}</div>
      <BrellyBtn className='mt-4' onClick={handleDismiss}>OK</BrellyBtn>
    </CenterModal>
  )
}

export default Errors;

// <IonAlert
//   isOpen={error?.length > 0}
//   onDidDismiss={handleDismiss}
//   header='Uh-oh!'
//   subHeader=''
//   message={error}
//   buttons={['OK']}
// />
