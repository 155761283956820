import { useApp } from 'context';
import { titleCase } from 'utils';
import { roles } from 'constants';
import { useProject } from './context';
import ProPic from 'components/ProPic';
import { useState, useRef } from 'react';
import { groupContactsByType } from 'utils';
import { PersonIcon } from 'components/Icons';
import { BsChevronDown } from 'react-icons/bs';
import { InlineModal } from 'components/Modals';
import { AddContactModal, EditContactModal, ImportContactToClaimModal } from 'modals';

const ContactsBox = () => {
  const { projectId } = useApp();
  const roleFilterBtn = useRef(null);
  const [addModal, setAddModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [roleFilter, setRoleFilter] = useState(false);
  const { contacts, handleClaimContacts } = useProject();
  const [filters, setFilters] = useState({ role: "all roles", searchVal: "" });
  const filteredContacts = getFiltered(filters, contacts);
  const groupedContacts = groupContactsByType(filteredContacts);
  const groupedForFilters = groupContactsByType(contacts);
  const existingRoles = groupedForFilters?.map((gc) => gc?.type?.toLowerCase());
  const roleFilters = roles?.filter((r) => existingRoles?.includes(r?.label?.toLowerCase()) || r?.label === 'All Roles')

  const updateFilters = (changes = {}) => {
    setFilters({ ...filters, ...changes })
  }

  const handleRoleFilter = (newFilter) => {
    updateFilters({ role: newFilter })
    setRoleFilter(false);
  }

  // <SearchBar
  //   style={{ width: 300 }}
  //   value={filters?.searchVal}
  //   className='d-none d-lg-flex'
  //   onChange={(v) => updateFilters({ searchVal: v })}
  // />

  return(
    <div className='dash-box'>
      <div className='jb-ac'>
        <div className='jb-ac'>
          <div className='dash-box-title me-4'>
            <PersonIcon style={{ marginRight: 8 }} stroke='#0D72C5' />
            Contacts
          </div>
        </div>
        <div className='je-ac'>
          <div ref={roleFilterBtn} onClick={() => setRoleFilter(true)} className='js-ac me-3 d-none d-lg-flex' style={{ cursor: 'pointer', color: 'rgb(100, 102, 107)', fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
            Role
            <BsChevronDown style={{ marginLeft: 4 }} />
          </div>
          <div className='brelly-text-btn me-3' onClick={() => setImportModal(true)} style={{ color: '#0D72C5' }}>Import</div>
          <div className='brelly-text-btn' onClick={() => setAddModal(true)} style={{ color: '#0D72C5' }}>Add New</div>
        </div>
      </div>
      <TableHeader />

      {groupedContacts?.map((group) => (
        <div key={group?.type}>
          <div className='dash-table-header'>{group?.type}</div>
          {group?.contacts?.map((c) => (
            <ContactItem ph={c} key={c?.id} />
          ))}
        </div>
      ))}

      <ImportContactToClaimModal open={importModal} handleClose={() => setImportModal(false)} handleUpdate={handleClaimContacts} />
      <AddContactModal open={addModal} handleClose={() => setAddModal(false)} handleUpdate={handleClaimContacts} inClaim={projectId} />
      <InlineModal btnRef={roleFilterBtn} open={roleFilter} handleClose={() => setRoleFilter(false)}>
        {roleFilters?.map((p, idx) => (
          <div key={idx} className={`dash-table-item ${idx === 0 ? '' : 'mt-3'}`} style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleRoleFilter(p?.label?.toLowerCase())}>{p?.label}</div>
        ))}
      </InlineModal>
    </div>
  )
}

export default ContactsBox;

const TableHeader = () => {
  return(
    <div className='row mt-3 d-none d-lg-flex'>
      <div className='dash-table-header col-1'></div>
      <div className='dash-table-header col-3'>Name</div>
      <div className='dash-table-header col-2'>Email</div>
      <div className='dash-table-header col-2'>Phone Number</div>
      <div className='dash-table-header col-2'>Company</div>
      <div className='dash-table-header col-2'>Role</div>
    </div>
  )
}

const ContactItem = ({ ph }) => {
  const [editModal, setEditModal] = useState(false);
  // const claimStr = ph?.claims?.map((c) => c.address)?.join(', ');
  const { handleClaimContacts } = useProject();

  return(
    <>
      <div onClick={() => setEditModal(true)} className='row mb-2 brelly-content' style={{ borderRadius: 10, height: 50, cursor: 'pointer' }}>
        <div className='col-1 dash-table-item jc-ac d-none d-lg-flex'>
          <ProPic user={ph} />
        </div>
        <div className='col-12 col-lg-3 dash-table-item'>{ph?.firstName} {ph?.lastName}</div>
        <div className='col-2 dash-table-item d-none d-lg-flex'>
          <a href={`mailto:${ph?.email}`} style={{ textTransform: 'lowercase' }}>{ph?.primaryEmail}</a>
        </div>
        <div className='col-2 dash-table-item d-none d-lg-flex'>
          <a href={`tel:${ph?.primaryPhoneNumber}`}>{ph?.primaryPhoneNumber}</a>
        </div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>{ph?.company}</div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>{titleCase(ph?.role)}</div>
      </div>
      <EditContactModal open={editModal} handleClose={() => setEditModal(false)} contact={ph} handleUpdate={handleClaimContacts} />
    </>
  )
}

const createSearchStr = (contact) => {
  let fName = contact?.firstName
  let lName = contact?.lastName
  let str = `${fName} ${lName}`
  return str?.toLowerCase()
}

const getFiltered = (filters, contacts) => {
  let _contacts = contacts

  if (filters?.role !== 'all roles') {
    _contacts = _contacts?.filter((t) => t.role?.toLowerCase() === filters?.role)
  }

  if (filters?.searchVal?.length > 0) {
    let formatted = _contacts?.map((c) => ({ ...c, searchVal: createSearchStr(c) }))
    _contacts = formatted?.filter((t) => t.searchVal?.includes(filters?.searchVal?.toLowerCase()))
  }

  return _contacts
}
