import { DropdownSearchBar } from "components/SearchBar";
import { SingleSelect } from 'components/Select';
import { useApp } from "context";

const getLabel = (project) => {
  let property = project?.property;
  let address = property?.address;

  if (address?.fullAddress) {
    return address?.fullAddress;
  } else {
    let ph = project?.policyholder;

    return `No address - ${ph?.firstName} ${ph?.lastName}`
  }
}

const ClaimSelector = ({ value = [], onChange = () => {} }) => {
  const { projects } = useApp();
  const projectOptions = projects?.map((p, idx) => ({ label: getLabel(p), value: p?.id }))?.filter((p) => p?.label);

  return(
    <DropdownSearchBar
      value={value}
      single={true}
      onChange={onChange}
      options={projectOptions}
      label='Claim Name or Number'
      style={{ width: '100%' }}
    />
  )
}

export default ClaimSelector;

export const ClaimSelectorSingle = ({ value = [], onChange = () => {} }) => {
  const { projects } = useApp();
  const projectOptions = projects?.map((p, idx) => ({ label: getLabel(p), value: p?.id }))?.filter((p) => p?.label);

  return(
    <SingleSelect
      value={value}
      onChange={onChange}
      options={projectOptions}
      label='Claim Name or Number'
      style={{ width: '100%' }}
    />
  )
}
