export const roles = [
  { label: 'All Roles', value: 20, all: true },
  { label: 'Policyholder', value: 1 },
  { label: 'Public Adjuster', value: 2 },
  { label: 'Insurance Adjuster', value: 3 },
  { label: 'Insurance Agent', value: 4 },
  { label: 'Contractor, General', value: 5 },
  { label: 'Contractor, Roofer', value: 6 },
  { label: 'Contractor, Restoration', value: 7 },
  { label: 'Contractor, Mechanical', value: 8 },
  { label: 'Contractor, Other', value: 9 },
  { label: 'Mortgagee/Lender', value: 10 },
  { label: 'Inspector', value: 11 },
  { label: 'Attorney', value: 12 },
  { label: 'Expert, Engineering', value: 13 },
  { label: 'Expert, Hygienist', value: 14 },
  { label: 'Expert, Accountant', value: 15 },
  { label: 'Expert, Other', value: 16 },
  { label: 'Appraiser/Umpire', value: 17 },
  { label: 'Engineer', value: 18 },
  { label: 'Other', value: 19 }
]

export const lossTypes = [
  { label: 'Wind', value: 1 },
  { label: 'Structural', value: 2 },
  { label: 'Debris Impact', value: 3 },
  { label: 'Other Impact', value: 4 },
  { label: 'Water', value: 5 },
  { label: 'Fire', value: 6 },
  { label: 'Smoke', value: 7 },
  { label: 'Lighting', value: 8 },
  { label: 'Hail', value: 9 },
  { label: 'Freezing', value: 10 },
  { label: 'Snow/Ice', value: 11 },
  { label: 'Theft', value: 12 },
  { label: 'Vandalism', value: 13 },
  { label: 'Other', value: 14 },
]

export const inventoryCats = [
  { label: "Appliances", value: 1 },
  { label: "Art", value: 2 },
  { label: "Books", value: 3 },
  { label: "Cash", value: 4 },
  { label: "Clothing", value: 5 },
  { label: "Collectables", value: 6 },
  { label: "Computers & Accessories", value: 7 },
  { label: "Electronics", value: 8 },
  { label: "Equipment", value: 9 },
  { label: "Food", value: 10 },
  { label: "Furniture", value: 11 },
  { label: "Healthcare Items & Medications", value: 12 },
  { label: "Housewares", value: 13 },
  { label: "Jewelery", value: 14 },
  { label: "Pet Supplies", value: 15 },
  { label: "School Supplies", value: 16 },
  { label: "Stereos", value: 17 },
  { label: "Televisions", value: 18 },
  { label: "Tools", value: 19 }
]

export const paymentTypes = [
  { label: 'Cash', value: 1},
  { label: 'Check', value: 2},
  { label: 'Card', value: 3},
  { label: 'Wire', value: 4},
]

export const payees = [
  { label: 'Home Depot', value: 1},
  { label: 'State Farm', value: 2},
  { label: 'Harry Fox', value: 3},
  { label: 'Holiday Inn', value: 4},
]

export const contactRoles = roles?.filter((r) => !r?.all);
