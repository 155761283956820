import { useApp } from "context";
import brelly from "@brelly/sdk";
import { DashPage, ProfilePictureUpload } from "./components";
import { OrgStagesSection, OrgDetailsSection, OrgTeamSection } from 'sections';

const OrgPage = () => {
  const { tab, selectedOrg, orgId, orgTab, setOrgTab, setLoading, setError, handleUser } = useApp();
  const scrollArea = window.innerHeight - 262;
  const isSelected = tab === "org";

  const handleLogo = async (ev) => {
    let file = ev.target.files[0];

    try {
      setLoading(true);
      await brelly.organizations.uploadLogo(orgId, file);
      handleUser()
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue uploading your logo, please try again!");
    }
  };

  return (
    <DashPage tab={tab} value="org" padding={false}>
      <div
        style={{
          width: "100%",
          height: 180,
          background: "linear-gradient(90deg, #0D72C5 0%, #239CFF 100%)",
          borderRadius: "12px 0px 0px 0px",
          paddingLeft: 64,
        }}
        className="js-ac"
      >
        <h1 style={{ color: "#ffffff" }}>{selectedOrg?.name}</h1>
      </div>
      <div style={{ paddingLeft: 64, paddingRight: 42 }}>
        <div
          style={{
            width: "100%",
            padding: "22px 0px",
            background: "#ffffff",
            marginTop: -40,
            borderRadius: 18,
            boxShadow: "3px 3px 8px rgba(0,0,0,.03)",
            cursor: "pointer",
            height: scrollArea,
          }}
          className="js-ac"
        >
          <div className="container" style={{ height: "100%" }}>
            <div className="row" style={{ height: "100%" }}>
              <div
                className="col-3 js-ac flex-col"
                style={{
                  height: "100%",
                  borderRight: "1px solid rgba(0,0,0,.06)",
                  paddingTop: 22,
                }}
              >
                <ProfilePictureUpload
                  img={selectedOrg?.logo?.downloadUrl}
                  style={{ width: 140, height: 140, borderRadius: 10 }}
                  handleChange={handleLogo}
                  userOverride={{ firstName: selectedOrg?.name }}
                />
                <h2 style={{ marginTop: 16 }}>{selectedOrg?.name}</h2>
                <div style={{ width: 200, maxWidth: '80%' }}>
                  <AccountTabBtn
                    value="1"
                    label="Company Info"
                    orgTab={orgTab}
                    setOrgTab={setOrgTab}
                  />
                  <AccountTabBtn
                    value="3"
                    label="Team"
                    orgTab={orgTab}
                    setOrgTab={setOrgTab}
                  />
                  <AccountTabBtn
                    value="4"
                    label="Claim Phases & Workflows"
                    orgTab={orgTab}
                    setOrgTab={setOrgTab}
                  />
                </div>
              </div>
              <div
                className="col-9"
                style={{ height: "100%", paddingLeft: 32, paddingRight: 32 }}
              >
                {isSelected && <OrgDetailsSection orgTab={orgTab} />}
                {isSelected && <OrgStagesSection orgTab={orgTab} />}
                {isSelected && <OrgTeamSection orgTab={orgTab} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashPage>
  );
};

export default OrgPage;

const AccountTabBtn = ({ value, label, orgTab, setOrgTab }) => {
  let isSelected = value === orgTab;

  return (
    <h3
      onClick={() => setOrgTab(value)}
      style={{
        marginTop: 24,
        fontSize: 15,
        color: isSelected ? "#0D72C5" : "#64666B",
        fontWeight: 700,
      }}
    >
      {label}
    </h3>
  );
};
