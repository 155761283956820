import brelly from "@brelly/sdk";
import { useApp } from "context";
import Input from "components/Input";
import TextArea from "components/TextArea";
import { useState, useEffect } from "react";
import { format, isBefore } from "date-fns";
import { BrellyBtn } from 'components/Button';
import { CenterModal } from "components/Modals";
import { AddFileModal } from 'modals';
import { AttachmentItem } from 'items';
import ClaimSelector from 'components/ClaimSelector';
import { DateSelect, TimeSelect } from "components/Select";
import TeammemberSelector from 'components/TeammemberSelector';

export const AddEventModal = ({ open, handleClose, handleUpdate = () => {} }) => {
  const { user, setLoading, membershipId, setError, projects, projectId } = useApp();
  const [desc, setDesc] = useState("");
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [name, setName] = useState("");
  const [due, setDue] = useState(null);
  const [start, setStart] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (open) {
      setSelectedUsers([{ label: `${user?.firstName} ${user?.lastName}`, value: membershipId }])
      setDesc("")
      setName("")
      setDue(null)
      setStart(null)
      setFiles([])
    }
    //eslint-disable-next-line
  }, [open])

  const handleFiles = (_files) => {
    setFiles([...files, ..._files])
  }

  const getProject = () => {
    if (projectId) {
      return projectId
    } else {
      return selectedClaim[0]?.value
    }
  }

  const handleAdd = async () => {
    const projectId = getProject();

    if (!name) {
      setError("Please name this event")
    } else if (!due) {
      setError("Please add an end date or time")
    } else if (!projectId && selectedUsers?.length === 0) {
      setError("Please select a claim or a user")
    } else if (isBefore(new Date(due), new Date(start))) {
      setError("End cannot be before start")
    } else {
      try {
        setLoading(true);
        let { data } = await brelly.events.create({
          claimId: projectToClaim(projectId, projects),
          projectId: projectId,
          description: desc,
          startDate: start,
          endDate: due,
          name: name,
        });
        await brelly.events.setParticipants(data?.id, selectedUsers?.map((ass) => ass?.value))
        if (files?.length > 0) {
          let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
          let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id)

          if (localFiles?.length > 0) {
            await brelly.events.uploadFiles(data?.id, localFiles)
          }

          if (remoteFiles?.length > 0) {
            await brelly.events.addFiles(data?.id, remoteFiles)
          }
        }
        handleUpdate()
        setLoading(false);
        handleClose();
      } catch (err) {
        setError(err.message)
        setLoading(false);
      }
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add Event</div>
      <div className="js-as">
        <div style={{ width: 300, marginRight: 8 }}>
          <Input
            value={name}
            onChange={setName}
            placeholder=""
            label="Event Name"
          />
          <div className='jb-ac'>
            <DateSelect
              value={start}
              onChange={setStart}
              placeholder=""
              label="Start Date"
              contStyle={{ width: '55%' }}
            />
            <TimeSelect value={start} onChange={setStart} label="Start Time" contStyle={{ width: '40%' }} />
          </div>
          <div className='jb-ac'>
            <DateSelect
              value={due}
              onChange={setDue}
              placeholder=""
              label="End Date"
              contStyle={{ width: '55%' }}
            />
            <TimeSelect value={due} onChange={setDue} label="End Time" contStyle={{ width: '40%' }} />
          </div>
          {!projectId && <ClaimSelector value={selectedClaim} onChange={setSelectedClaim} />}
          <TeammemberSelector value={selectedUsers} onChange={setSelectedUsers} />
        </div>

        <div style={{ width: 300, marginLeft: 8 }}>
          <TextArea label="Description" value={desc} onChange={setDesc} />

          <div className="je-ac mt-2">
            <div className="me-3">
              <div
                className="dash-table-item"
                style={{ color: "#64666B", fontSize: 12 }}
              >
                Task Creator
              </div>
              <div className="dash-table-item" style={{ fontWeight: 600 }}>
                {user?.firstName} {user?.lastName}
              </div>
            </div>
            <div>
              <div
                className="dash-table-item"
                style={{ color: "#64666B", fontSize: 12 }}
              >
                Task Created
              </div>
              <div className="dash-table-item" style={{ fontWeight: 600 }}>
                {format(new Date(), "MMM dd, yyyy")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        {files?.map((m) => (
          <AttachmentItem key={m?.id} file={m} />
        ))}
      </div>
      <BrellyBtn
        onClick={() => setAddFilesModal(true)}
        className="mt-3"
        style={{ background: "#2D3C57" }}
      >
        Add Attachments
      </BrellyBtn>
      <BrellyBtn className="mt-2" onClick={handleAdd}>
        Save
      </BrellyBtn>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .csv, .xls, xlsx, .mov, .mp4, .ppt, .pptx, .txt, .svg"
      />
    </CenterModal>
  );
};

const projectToClaim = (pId, projects) => {
  let project = projects?.find((p) => p?.id === pId) || {};
  return project?.claim?.id;
}
