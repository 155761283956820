// External Imports
import { useState, useEffect, useRef } from "react";
import { Portal } from "react-portal";
import { BsChevronDown } from "react-icons/bs";

// Internal Imports
import { BrellyBtn } from "components/Button";
import CheckBox from "components/CheckBox";

interface SingleSelectProps {
  value?: string;
  onChange: (v: string) => void;
  options: any[];
  style?: any;
  chevronStyle?: any;
  placeholder?: string;
  label?: string;
}

export const SingleSelect = ({
  value,
  onChange,
  options = [],
  style = {},
  placeholder = "",
  label = "",
}: SingleSelectProps) => {
  const [boundingRect, setBoundingRect] = useState<any>({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;

  const isSelected = (option: string) => option === value;

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect((inputRef.current as any)?.getBoundingClientRect());
    }
  }, [inputRef, focus, value]);

  const handleSelect = (val: string) => {
    setFocus(false);
    if (val === value) {
      onChange("");
    } else {
      onChange(val);
    }
  };

  return (
    <div className="" style={{ position: "relative" }}>
      {label && (
        <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className="mt-2">
          {label}
        </div>
      )}
      <div style={{ position: "absolute", right: 10, top: 5 }} onClick={() => setFocus(true)}>
        <BsChevronDown style={{ color: "#0D72C5" }} />
      </div>
      <div
        ref={inputRef}
        onClick={() => setFocus(true)}
        className="dash-input js-ac"
        placeholder={placeholder}
        style={{ cursor: "pointer", outline: focus ? "1px solid #0D72C5" : "", ...style }}
      >
        {value}
      </div>

      {focus && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={() => setFocus(false)}
          />
          <div
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 10,
              paddingLeft: 12,
              paddingRight: 12,
              zIndex: 1010,
              fontFamily: "Lato",
              left: boundingRect.x,
              top: boundingRect.bottom + 6,
              width: boundingRect.width,
            }}
            className="shadow"
          >
            <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
              {options?.length > 0 &&
                options.map((option, idx) => (
                  <div
                    key={idx}
                    onClick={() => handleSelect(option)}
                    style={{
                      width: "100%",
                      height: 38,
                      borderRadius: 10,
                      background: "white",
                      fontSize: 14,
                      cursor: "pointer",
                      marginTop: idx === 0 ? 12 : 6,
                      marginBottom: idx === options?.length - 1 ? 12 : 0,
                    }}
                    className="js-ac ps-2"
                  >
                    <CheckBox title={option} value={isSelected(option)} setValue={() => {}} />
                  </div>
                ))}
              {options?.length === 0 && <div className="jc-ac mb-2">No Options</div>}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

interface MultiSelectProps {
  value?: string[];
  onChange: (v: string[]) => void;
  options: any[];
  style?: any;
  chevronStyle?: any;
  placeholder?: string;
  label?: string;
  wrapperClass?: string;
}

export const MultiSelect = ({
  value,
  onChange,
  options,
  style = {},
  chevronStyle = {},
  placeholder = "",
  label = "",
  wrapperClass = "",
}: MultiSelectProps) => {
  const [boundingRect, setBoundingRect] = useState<any>({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueLength = value?.length || 0;
  const valueStr = value?.join(", ") || "";
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect((inputRef.current as any)?.getBoundingClientRect());
    }
  }, [inputRef, focus, valueLength]);

  const isSelected = (option: string) => (Array.isArray(value) ? !!value.includes(option) : false);

  const handleSelect = (option: string) => {
    if (isSelected(option)) {
      onChange(value!.filter((v) => v !== option));
    } else {
      return Array.isArray(value) ? onChange([...value, option]) : onChange([option]);
    }
  };

  return (
    <div className={wrapperClass} style={{ position: "relative" }}>
      {label && (
        <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className="mt-2">
          {label}
        </div>
      )}
      <div
        style={{
          position: "absolute",
          right: 10,
          top: 5,
          ...chevronStyle,
        }}
        onClick={() => setFocus(true)}
      >
        <BsChevronDown style={{ color: "#0D72C5" }} />
      </div>
      <div
        ref={inputRef}
        onClick={() => setFocus(true)}
        className="dash-input js-ac"
        placeholder={placeholder}
        style={{
          cursor: "pointer",
          outline: focus ? "1px solid #0D72C5" : "",
          overflowX: "scroll",
          whiteSpace: "nowrap",
          minWidth: 208,
          width: "100%",
          ...style,
        }}
      >
        {valueStr}
      </div>
      {focus && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={() => setFocus(false)}
          />
          <div
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 10,
              padding: 12,
              zIndex: 1010,
              fontFamily: "Lato",
              left: boundingRect.x,
              top: boundingRect.bottom + 6,
              width: boundingRect.width,
            }}
            className="shadow"
          >
            <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
              {options?.length > 0 &&
                options.map((option, key) => (
                  <div
                    key={key}
                    onClick={() => handleSelect(option)}
                    style={{
                      width: "100%",
                      height: 38,
                      borderRadius: 10,
                      background: "white",
                      fontSize: 14,
                      cursor: "pointer",
                    }}
                    className="mb-2 js-ac ps-2"
                  >
                    <CheckBox title={option} value={isSelected(option)} setValue={() => {}} />
                  </div>
                ))}
              {options?.length === 0 && <div className="jc-ac mb-2">No Options</div>}
            </div>

            <BrellyBtn style={{ marginTop: 12 }} onClick={() => setFocus(false)}>
              Done
            </BrellyBtn>
          </div>
        </Portal>
      )}
    </div>
  );
};
