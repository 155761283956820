// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface OptionsInterface {
  getLossTypes: (query?: Query) => Promise<any>;
}

export const options = (client: Axios): OptionsInterface => ({
  getLossTypes: async (query?: Query) => {
    const url = addUrlQuery("/loss-types", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },
});
