import { EmptyState, DetailItem } from 'pages/Viewer/Details/components';
import { formatCurrency } from "utils";
import { useState } from "react";

export const Coverages = ({ coverages }) => {
  const sortedCoverages = coverages?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  return (
    <>
      <div
        className="mt-3 brelly-content ps-3 pe-3 pt-2 pb-3"
        style={{ borderRadius: 8 }}
      >
        <div className="jb-ac" style={{ width: "100%", marginBottom: 14 }}>
          <div className="dash-box-title">Coverages</div>
        </div>
        {sortedCoverages?.map((c, key) => (
          <DetailItem
            key={key}
            title={c?.name}
            value={formatCurrency(c?.premium)}
          />
        ))}
        <EmptyState type="Coverages" arr={coverages} />
      </div>
    </>
  );
};
