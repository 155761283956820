import { useState } from 'react';
import Input from 'components/Input';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';
import { currentToFloat } from 'utils';
import brelly from "@brelly/sdk";
import { useApp } from 'context';
import { useProject } from 'pages/Dash/Claims/Claim/context';

export const EditCovergesModal = ({ open, handleClose }) => {
  const { setLoading } = useApp();
  const { coverages, handleCoverages } = useProject();
  const [newCoverages, setNewCoverages] = useState([]);
  const sortedCovs = coverages?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

  const handleChange = (update) => {
    let remaining = newCoverages?.filter((c) => c?.id !== update?.id)
    setNewCoverages([...remaining, update])
  }

  const handleEdit = async () => {
    try {
      setLoading(true);
      await Promise.all(newCoverages?.map((c) => brelly.coverages.update(c?.id, { premium: currentToFloat(c?.premium) })));
      await handleCoverages();
      setLoading(false);
      handleClose()
    } catch(err) {
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Coverages</div>
      {sortedCovs?.map((cov, key) => <CovItem cov={cov} key={key} handleChange={handleChange} />)}
      <BrellyBtn onClick={handleEdit} className='mt-4' style={{ width: 400, maxWidth: '100%' }}>Save</BrellyBtn>
    </CenterModal>
  )
}

const CovItem = ({ cov, handleChange }) => {
  const [value, setValue] = useState(cov?.premium);

  const onChange = (v) => {
    setValue(v);
    handleChange({ ...cov, id: cov?.id, premium: v })
  }

  return(
    <Input value={value} onChange={onChange} label={cov?.name} placeholder='$100,000' type='number' />
  )
}
