import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { useState } from 'react';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';

export const ClaimOnboardingModal = ({ project, open, handleClose }) => {
  const [confirmModal, setConfirmModal] = useState(false);

  return(
    <>
      <CenterModal open={open} handleClose={handleClose}>
        <div style={{ fontWeight: 600, fontSize: 20, textAlign: 'center' }}>Almost there...</div>
        <div className='mt-3' style={{ textAlign: 'center' }}>The policyholder is onboarding information about this claim.  As soon as the process is complete, you will receive an email notification and can begin working on the claim.</div>
        <BrellyBtn className='mt-4' onClick={handleClose}>Got It</BrellyBtn>
        <div className='jc-ac mt-3'>
          <div className='brelly-text-btn' onClick={() => setConfirmModal(true)}>Cancel Policyholder Onboarding and Onboard Manually</div>
        </div>
      </CenterModal>
      <ConfirmModal project={project} open={confirmModal} handleClose={() => setConfirmModal(false)} handleMainClose={handleClose} />
    </>
  )
}

const ConfirmModal = ({ open, project, handleClose, handleMainClose }) => {
  const { setError, setLoading, handleProjects } = useApp();

  const handleCancel = async () => {
    if (project?.invite?.id) {
      try {
        setLoading(true);
        await brelly.projectInvites.cancel(project?.invite?.id, { createSubresources: true });
        handleProjects()
        handleClose()
        handleMainClose()
        setLoading(false);
      } catch(err) {
        setLoading(false);
        setError(err?.message)
      }
    } else {
      setError("No claim selected")
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose} style={{ maxWidth: 500 }}>
      <div style={{ fontWeight: 600, fontSize: 20, textAlign: 'center' }}>Cancel</div>
      <div className='mt-3' style={{ textAlign: 'center' }}>If you cancel policyholder onboarding, then you can begin working on this claim immediately, but you’ll have to do the work of populating information about the claim manually. Are you sure you want to cancel?</div>
      <div className='jb-ac mt-3'>
        <BrellyBtn style={{ width: "49%", background: 'white', color: 'rgb(13, 114, 197)', border: '1px solid rgb(13, 114, 197)' }} onClick={handleClose}>No, Go Back</BrellyBtn>
        <BrellyBtn style={{ width: "49%" }} onClick={handleCancel}>Yes, Confirm Cancel</BrellyBtn>
      </div>
    </CenterModal>
  )
}
