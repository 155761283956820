import { useState, useEffect } from "react";
import { useApp } from "context";
import ProPic from "components/ProPic";
import CheckBox from "components/CheckBox";
import SearchBar from "components/SearchBar";
import { FilterIcon } from "components/Icons";
import { BrellyBtn } from "components/Button";
import { BsChevronDown } from "react-icons/bs";
import { CenterModal } from 'components/Modals';
import { AddClaimModal, FilterModal, ClaimOnboardingModal } from 'modals';
import { getFilteredClaims, getFilterCount, getInitialFilters } from './Filter';
import { useWindowSize } from 'react-use';
import { subDays, isAfter } from 'date-fns';

const ClaimsPage = () => {
  const { setProject, projects, user, orgId } = useApp();
  const [newClaimModal, setNewClaimModal] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [filters, setFilters] = useState(getInitialFilters());
  const pending = projects?.filter((p) => !p?.claim?.id);
  const notPending = projects?.filter((p) => p?.claim?.id);
  const claims = getFilteredClaims(user, notPending, filters, searchVal);
  const filterCount = getFilterCount(filters);

  useEffect(() => {
    if (projects?.length > 0) {
      let existingPending = JSON.parse(localStorage.getItem(`${orgId}-pending`)) || [];
      let existingRecent = JSON.parse(localStorage.getItem(`${orgId}-recentlycompleted`)) || []
      let pendingIds = pending?.map((p) => p?.id);
      let _recentlyCompleted = existingPending?.filter((p) => !pendingIds?.includes(p));
      let recentlyCompleted = _recentlyCompleted?.map((rc) => ({ projectId: rc, added: new Date() }))
      let freshRecent = existingRecent?.filter((erc) => isAfter(new Date(erc?.added), subDays(new Date(), 7)))
      localStorage.setItem(`${orgId}-pending`, JSON.stringify(pendingIds));
      localStorage.setItem(`${orgId}-recentlycompleted`, JSON.stringify([...recentlyCompleted, ...freshRecent]));
    }
    //eslint-disable-next-line
  }, [projects])

  const updateFilter = (changes) => {
    setFilters({...filters, ...changes})
  }

  return (
    <div className="" style={{ paddingTop: 24 }}>
      <div className="jb-ac">
        <h1 style={{ marginBottom: 0 }}>Claim Dashboard</h1>
        <div className="je-ac d-none d-lg-flex">
          <div
            style={{ position: 'relative' }}
            className="brelly-text-btn me-4"
            onClick={() => setFilterModal(true)}
          >
            <FilterIcon size={18} style={{ marginRight: 4, marginBottom: 2 }} />
            Filter
            <FilterAlert filterCount={filterCount} />
          </div>
          <SearchBar
            style={{ background: "white" }}
            placeholder="Search Claims"
            value={searchVal}
            onChange={setSearchVal}
          />
          <BrellyBtn className="ms-4" onClick={() => setNewClaimModal(true)}>
            New Claim
          </BrellyBtn>
        </div>
      </div>
      {pending?.length > 0 && <OnboardingClaims pending={pending} showOnboarding={showOnboarding} setShowOnboarding={setShowOnboarding} />}
      <div className="col-12 mt-3">
        <div className="dash-box">
          <div className="jb-ac">
            <div className="js-ac">
              <div
                className="brelly-text-btn me-4"
                style={{ color: filters?.claimsType === "myclaims" ? "" : "#9398A0" }}
                onClick={() => updateFilter({ claimsType: "myclaims"})}
              >
                My Claims
              </div>
              <div
                className="brelly-text-btn"
                style={{ color: filters?.claimsType === "allclaims" ? "" : "#9398A0" }}
                onClick={() => updateFilter({ claimsType: "allclaims"})}
              >
                All Claims
              </div>
            </div>
            <CheckBox
              value={filters?.showArchived}
              setValue={(v) => updateFilter({ showArchived: v })}
              title="Show Archived Claims"
              className="d-none d-lg-flex"
            />
          </div>
          <div className='container' style={{ maxHeight: 'calc(100vh - 270px)', overflow: 'auto', width: 'calc(100% + 24px)', marginLeft: -12 }}>
            <div className="row mt-4 d-none d-lg-flex">
              <div className="dash-table-header col-2">
                Primary PH
                <BsChevronDown style={{ color: "#0D72C5", marginLeft: 2 }} />
              </div>
              <div className="dash-table-header col-3">
                Address
                <BsChevronDown style={{ color: "#0D72C5", marginLeft: 2 }} />
              </div>
              <div className="dash-table-header col-2">
                Claim Number
                <BsChevronDown style={{ color: "#0D72C5", marginLeft: 2 }} />
              </div>
              <div className="dash-table-header col-1">
                Owners
              </div>
              <div className="dash-table-header col-2">
                Loss Type
                <BsChevronDown style={{ color: "#0D72C5", marginLeft: 2 }} />
              </div>
              <div className="dash-table-header col-2">
                Status
                <BsChevronDown style={{ color: "#0D72C5", marginLeft: 2 }} />
              </div>
            </div>
            {claims?.map((project, key) => (
              <ClaimItem key={key} project={project} setProject={setProject} />
            ))}
          </div>
        </div>
      </div>
      <FilterModal
        filters={filters}
        open={filterModal}
        setFilters={setFilters}
        handleClose={() => setFilterModal(false)}
      />
      <AddClaimModal
        open={newClaimModal}
        setShowOnboarding={setShowOnboarding}
        handleClose={() => setNewClaimModal(false)}
      />
    </div>
  );
};

export default ClaimsPage;

const OnboardingClaims = ({ pending, showOnboarding, setShowOnboarding }) => {
  const { width } = useWindowSize();
  const sizeStyles = width > 991 ? { maxHeight: '210px', overflow: 'auto' } : {};

  return(
    <div className="dash-box mt-3">
      <div onClick={() => setShowOnboarding(!showOnboarding)} className="brelly-text-btn jb-ac">
        Onboarding Claims
        <BsChevronDown style={{ transform: showOnboarding ? '' : 'rotate(-180deg)' }} />
      </div>
      {showOnboarding && (
        <div>
          <div className="row mt-2 d-none d-lg-flex">
            <div className="dash-table-header col-12 col-lg-8">
              Primary PH
            </div>
            <div className="dash-table-header col-12 col-lg-4 je-ac">
              Status
            </div>
          </div>
          <div className='container' style={{ ...sizeStyles, width: 'calc(100% + 24px)', marginLeft: -12 }}>
            {pending?.map((project, key) => (
              <PendingItem key={key} project={project} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const ClaimItem = ({ project, setProject }) => {
  const { phases, orgId } = useApp();
  const currentPhase = phases?.find((p) => p?.id === project?.currentPhaseId) || {};
  const currentPhaseName = currentPhase?.name || "Unknown";
  const { width } = useWindowSize();
  const sizeClasses = width > 991 ? 'pt-2 pb-2 brelly-item-hover' : 'pt-3 pb-3 brelly-content mt-2';
  const [recentClaimModal, setRecentClaimModal] = useState(false);
  const lossTypesArr = project?.claim?.lossTypes?.map((lt) => lt?.type) || []
  const lossTypes = lossTypesArr.join(", ");

  let getName = () => {
    if (project?.policyholder) {
      return `${project?.policyholder?.firstName} ${project?.policyholder?.lastName}`;
    } else {
      return "Unknown";
    }
  };

  const handleClick = () => {
    let existingRecent = JSON.parse(localStorage.getItem(`${orgId}-recentlycompleted`)) || []
    let existingRecentIds = existingRecent?.map((p) => p?.projectId);

    if (existingRecentIds?.includes(project?.id)) {
      setRecentClaimModal(true)
      let remainingRecent = existingRecent?.filter((p) => p?.projectId !== project?.id)
      localStorage.setItem(`${orgId}-recentlycompleted`, JSON.stringify(remainingRecent));
    } else {
      setRecentClaimModal(false)
      setProject(project?.id)
    }
  }

  return (
    <div
      className={`row ${sizeClasses}`}
      style={{ cursor: "pointer", borderRadius: 10 }}
      onClick={handleClick}
    >
      <div className="dash-table-item col-2 d-none d-lg-flex">{getName()}</div>


      <div className="dash-table-item col-12 col-lg-3 fw-mobile">{project?.property?.address?.fullAddress || "Address Unknown"}</div>

      <div className="dash-table-item col-12 mt-3 d-lg-none">
        <div className='me-1'>Policyholder: </div>
        {getName()}
      </div>

      <div className="dash-table-item col-12 col-lg-2 mt-2 mt-lg-0">
        <div className='me-1 d-lg-none'>Claim Number: </div>
        {project?.claim?.claimNumber || "Unknown"}
      </div>

      <div className="dash-table-item col-lg-1 d-none d-lg-flex">
        {project?.contributors?.map((owner, key) => (
          <ProPic
            user={owner?.user}
            key={key}
            size={18}
            className="me-1"
            style={{ fontSize: 9 }}
          />
        ))}
      </div>

      <div className="dash-table-item col-2 d-none d-lg-flex">{lossTypes}</div>

      <div className="dash-table-item col-2 d-none d-lg-flex">{currentPhaseName}</div>

      <CenterModal open={recentClaimModal} handleClose={() => setRecentClaimModal(false)}>
        <div style={{ fontWeight: 600, fontSize: 20 }}>You can start working on this claim!</div>
        <div className='mt-2'>The policyholder has onboarded info about this property and claim.  Before starting, please note:</div>
        <div style={{ color: '#0D72C5' }} className='js-as mt-2'>
          <div style={{ width: 5, height: 5, borderRadius: '50%', marginRight: 5, marginTop: 10, flexShrink: 0, background: '#0D72C5' }} />
          <div>
            <b>You should review the onboarded information carefully for accuracy and completeness.</b>
            It’s possible the policyholder or the insurer provided incorrect information.
          </div>
        </div>
        <div style={{ color: '#0D72C5' }} className='js-as mt-2'>
          <div style={{ width: 5, height: 5, borderRadius: '50%', marginRight: 5, marginTop: 10, flexShrink: 0, background: '#0D72C5' }} />
          <div>
            <b>The policyholder may not be through uploading photos, videos, and documents.</b>
            You will see them in the files tab as soon as they’re uploaded.
          </div>
        </div>
        <BrellyBtn className='mt-3' onClick={handleClick}>Got It</BrellyBtn>
      </CenterModal>
    </div>
  );
};

// <div className="dash-table-item col-2">
//   {project?.policy?.insuranceCompany?.name}
// </div>

const PendingItem = ({ project }) => {
  const { width } = useWindowSize();
  const [modal, setModal] = useState(false);
  const sizeClasses = width > 991 ? 'pt-2 pb-2 brelly-item-hover' : 'pt-3 pb-3 brelly-content mt-2';

  let getName = () => {
    if (project?.policyholder) {
      return `${project?.policyholder?.firstName} ${project?.policyholder?.lastName}`;
    } else {
      return "Unknown";
    }
  };

  return (
    <>
      <div onClick={() => setModal(true)} className={`row pt-2 pb-2 dash-table-item ${sizeClasses}`} style={{ borderRadius: 10 }}>
        <div className="col-12 col-lg-8 fw-mobile mb-2 mb-lg-1">{getName()}</div>
        <div className="col-12 col-lg-4 je-ac d-block d-lg-flex">Onboarding</div>
      </div>
      <ClaimOnboardingModal open={modal} project={project} handleClose={() => setModal(false)} />
    </>
  );
};

const FilterAlert = ({ filterCount }) => {
  if (filterCount > 0) {
    return(
      <div style={{ position: 'absolute', top: -5, left: -12, width: 20, height: 20, borderRadius: '50%', color: 'white', fontSize: 12, fontWeight: 600, background: '#0D72C5' }} className='jc-ac shadow-sm'>
        {filterCount}
      </div>
    )
  } else {
    return null
  }
}
