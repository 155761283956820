import { IoOpenOutline } from "react-icons/io5";
import { TrashIcon } from "components/Icons";
import { useMeasure } from "react-use";
import Input from "components/Input";
import { StaticGoogleMap, Marker } from "react-static-google-map";

export const DetailItem = ({ title, value, style = {} }) => {
  return (
    <div className="jb-ac mt-3 mb-1">
      <div
        className="dash-table-item"
        style={{ color: "#64666B", paddingRight: 0 }}
      >
        {title}
      </div>
      <div
        className="dash-table-item"
        style={{ fontWeight: 600, paddingRight: 0, ...style }}
      >
        {value || "Unknown"}
      </div>
    </div>
  );
};

export const EmailDetailItem = ({ title, value, style = {} }) => {
  return (
    <div className="jb-ac mt-3 mb-1">
      <div
        className="dash-table-item"
        style={{ color: "#64666B", paddingRight: 0 }}
      >
        {title}
      </div>
      {value && (
        <a
          href={`mailto:${value}`}
          className="dash-table-item"
          style={{
            fontWeight: 600,
            paddingRight: 0,
            ...style,
            textTransform: "lowercase",
          }}
        >
          {value}
        </a>
      )}
      {!value && (
        <div
          className="dash-table-item"
          style={{
            fontWeight: 600,
            paddingRight: 0,
            ...style,
          }}
        >
          Unknown
        </div>
      )}
    </div>
  );
};

export const PhoneDetailItem = ({ title, value, style = {} }) => {
  return (
    <div className="jb-ac mt-3 mb-1">
      <div
        className="dash-table-item"
        style={{ color: "#64666B", paddingRight: 0 }}
      >
        {title}
      </div>
      {value && (
        <a
          href={`tel:${value}`}
          className="dash-table-item"
          style={{ fontWeight: 600, paddingRight: 0, ...style }}
        >
          {value}
        </a>
      )}
      {!value && (
        <div
          className="dash-table-item"
          style={{ fontWeight: 600, paddingRight: 0, ...style }}
        >
          Unknown
        </div>
      )}
    </div>
  );
};

export const MapDetailItem = ({ title, value = {}, style = {} }) => {
  const [ref, { width }] = useMeasure();
  const coords = value?.coords || {};
  const fullAddress = value?.fullAddress;
  const coordinates = coords?.coordinates || [];
  const lat = coordinates[1];
  const lng = coordinates[0];
  const showMap = fullAddress && width;

  const showInMapClicked = () => {
    window.open("https://maps.google.com?q=" + lat + "," + lng);
  };

  return (
    <div className="mt-3 mb-1" ref={ref}>
      <div className="jb-ac">
        <div className="dash-table-item" style={{ color: "#64666B" }}>
          {title}
        </div>
        <div
          className="dash-table-item"
          style={{ fontWeight: 600, paddingRight: 0, ...style }}
        >
          {fullAddress || "Unknown"}
        </div>
      </div>
      {showMap && (
        <div style={{ position: "relative" }} className="mt-2">
          <StaticGoogleMap
            size={`${Math.round(width)}x150`}
            className="brelly-static-map"
            apiKey="AIzaSyB0qZRRUt8uHwvt28io-NgWIB5xTWQMfxI"
          >
            <Marker location={fullAddress} color="0x0066cc" label="" />
          </StaticGoogleMap>
          <div
            onClick={showInMapClicked}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              width: 32,
              height: 32,
              background: "white",
              border: "1px solid #0d72c5",
              color: "#0d72c5",
              borderRadius: 4,
              zIndex: 1000,
              fontSize: 18,
            }}
            className="jc-ac shadow-sm"
          >
            <IoOpenOutline />
          </div>
        </div>
      )}
    </div>
  );
};

export const EmptyState = ({ type, cb, arr = [] }) => {
  if (arr?.length === 0) {
    return (
      <div className="jc-ac p-2 empty-state-warning" onClick={() => cb(true)}>
        <div style={{ cursor: "pointer" }}>
          <div className="jc-ac">No {type} Found</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const CustomField = ({ entry, additional, setAdditional }) => {
  let entries = additional?.entries || [];
  const { key, value, id } = entry;

  const handleChange = (type, newValue) => {
    let others = entries?.filter((c) => c?.id !== id);
    setAdditional({
      entries: [
        ...others,
        { key: key, value: value, [type]: newValue, id: id },
      ],
    });
  };

  const deleteEntry = () => {
    let remaining = entries?.filter((c) => c?.id !== id);
    setAdditional({ entries: remaining });
  };

  return (
    <div className="js-ac" style={{ width: "100%" }}>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-5">
          <Input
            value={key}
            onChange={(nv) => handleChange("key", nv)}
            label="Name"
          />
        </div>
        <div className="col-7" style={{ paddingLeft: 0 }}>
          <Input
            value={value}
            onChange={(nv) => handleChange("value", nv)}
            label="Value"
          />
        </div>
      </div>
      <div
        style={{
          width: 28,
          flexShrink: 0,
          height: 35,
          marginTop: 17,
          marginRight: -6,
        }}
        className="je-ac"
      >
        <TrashIcon size={16} onClick={deleteEntry} />
      </div>
    </div>
  );
};
