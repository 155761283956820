import { MapDetailItem, EmailDetailItem, PhoneDetailItem } from 'pages/Dash/Claims/Claim/Details/components';
import { useProject } from "pages/Dash/Claims/Claim/context";
import { EditContactModal } from 'modals';
import { AddPHModal } from 'modals';
import { HugIcon } from "components/Icons";
import { useState } from "react";

export const Policyholder = () => {
  const { contacts, project } = useProject();
  const [addPHModal, setAddPHModal] = useState(false);
  const policyholders = contacts?.filter((c) => c?.role === 'POLICYHOLDER')

  return (
    <>
      <div className="dash-box mt-2 dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 14 }}>
          <div className="dash-box-title">
            <HugIcon style={{ marginRight: 8 }} stroke="#0D72C5" />
            Policyholders
          </div>
          <div
            className="brelly-text-btn"
            onClick={() => setAddPHModal(true)}
            style={{ color: "#0D72C5" }}
          >
            Add
          </div>
        </div>
        {policyholders?.map((p, idx) => (
          <PolicyholderItem policyholder={p} key={p?.id} idx={idx} />
        ))}
      </div>
      <AddPHModal open={addPHModal} handleClose={() => setAddPHModal(false)} />
    </>
  );
};

const PolicyholderItem = ({ policyholder, idx }) => {
  const { handleClaimContacts } = useProject();
  const [editModal, setEditModal] = useState(false);
  const notes = policyholder?.notes || [];
  const mt = idx !== 0 ? 'mt-2' : ''

  return(
    <div className={`brelly-content ps-3 pe-3 pt-1 pb-3 ${mt}`} style={{ borderRadius: 8 }}>
      <div className='jb-ac mt-3'>
        <div className="dash-table-item" style={{ fontWeight: 600, fontSize: 16 }}>{policyholder?.firstName} {policyholder?.lastName}</div>
        <div
          className="brelly-text-btn"
          onClick={() => setEditModal(true)}
          style={{ color: "#0D72C5" }}
        >
          Edit
        </div>
      </div>
      <EmailDetailItem
        style={{ textTransform: "lowercase", cursor: "pointer" }}
        title="Email"
        value={policyholder?.primaryEmail}
      />
      <PhoneDetailItem title="Phone" value={policyholder?.primaryPhoneNumber} />
      <MapDetailItem
        title="Mailing Address"
        value={policyholder?.homeAddress}
      />

      <EditContactModal open={editModal} handleClose={() => setEditModal(false)} contact={policyholder} handleUpdate={handleClaimContacts} />
    </div>
  )
}

// {notes?.length > 0 && <NotesSection notes={notes} />}
// const NotesSection = ({ notes }) => {
//   return(
//     <>
//       <div className="dash-table-item mt-3 mb-2" style={{ color: "#64666B" }}>
//         Notes
//       </div>
//       {notes?.map((note, key) => (
//         <div key={key} className="dash-table-item mb-1">
//           {note?.body}
//         </div>
//       ))}
//     </>
//   )
// }
