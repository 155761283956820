// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface ContactsInterface {
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  batchCreate: (records: any[], sharedAttrs: { [key: string]: any }, query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  makePublic: (id: string, query?: Query) => Promise<any>;
  makePrivate: (id: string, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  uploadProfilePic: (id: string, file: any, query?: Query) => Promise<any>;
  getProjects: (id: string, query?: Query) => Promise<any>;
  setProjects: (id: string, projectIds: string[], query?: Query) => Promise<any>;
  addProjects: (id: string, projectIds: string[], query?: Query) => Promise<any>;
  removeProjects: (id: string, projectIds: string[], query?: Query) => Promise<any>;
}

export const contacts = (client: Axios): ContactsInterface => ({
  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/contacts", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  batchCreate: async (records: any[], sharedAttrs: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/contacts/batch", query);
    const cleanedRecords = cleanValues(records);
    const cleanedSharedAttrs = cleanValues(sharedAttrs);
    const res: AxiosResponse = await client.post(url, {
      records: cleanedRecords,
      sharedAttrs: cleanedSharedAttrs,
    });
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  makePublic: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}/public`, query);
    const res: AxiosResponse = await client.put(url);
    return {
      data: res.data,
    };
  },

  makePrivate: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}/private`, query);
    const res: AxiosResponse = await client.put(url);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  uploadProfilePic: async (id: string = "current", file: any, query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}/profile-picture`, query);
    const formData = new FormData();
    formData.append("file", file);

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  // *** Relationship Queries ***

  getProjects: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}/projects`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  setProjects: async (id: string, projectIds: string[], query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}/projects/set`, query);
    const res: AxiosResponse = await client.post(url, { ids: projectIds });
    return {
      data: res.data,
    };
  },

  addProjects: async (id: string, projectIds: string[], query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}/projects/add`, query);
    const res: AxiosResponse = await client.post(url, { ids: projectIds });
    return {
      data: res.data,
    };
  },

  removeProjects: async (id: string, projectIds: string[], query?: Query) => {
    const url = addUrlQuery(`/contacts/${id}/projects/remove`, query);
    const res: AxiosResponse = await client.post(url, { ids: projectIds });
    return {
      data: res.data,
    };
  },
});
