const Toggle = ({ value, onChange }) => {
  return (
    <div
      onClick={onChange}
      style={{ width: 50, height: 30, padding: 2, background: "#F5F5F5", borderRadius: 20 }}
    >
      <div
        style={{
          height: 26,
          width: 26,
          borderRadius: "50%",
          background: value ? "#0D72C5" : "#fff",
          marginLeft: value ? 20 : 0,
          transition: "all 200ms linear",
        }}
        className="shadow-sm"
      />
    </div>
  );
};

export default Toggle;
