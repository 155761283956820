import { useApp } from 'context';

export const useSelectStyles = (isSelected: any) => {
  const { isDarkMode } = useApp();

  const getBorder = (state: any) => {
    if (state.isFocused || state.isActive || isSelected) {
      return '1px solid #46C695'
    } else {
      return '1px solid black'
    }
  }

  const selectStyles = {
    indicatorSeparator: (provided: any) => ({
      ...provided,
      background: 'rgba(0,0,0,0)',
      color: 'rgba(0,0,0,0)',
      opacity: 0
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color:'rgba(0,0,0,0)',
      opacity: 0
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 100000001,
      minHeight: 50,
      background: isDarkMode ? '#1a1a1a' : 'white',
      width: 'calc(100% + 6px)',
      marginLeft: -3
    }),
    // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
    container: (provided, state) => ({
      ...provided,
      width: '100%'
    }),
    // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
    control: (provided, state) => ({
      ...provided,
      height: 40,
      flexShrink: 0,
      width: '100%',
      background:'transparent',
      border: getBorder(state),
      borderRadius: 6,
      boxShadow: (state.isFocused || state.isActive || state.hover) ? 0 : 0,
      paddingLeft: 9,
      color: isDarkMode ? 'rgb(255,255,255)' : 'rgb(17, 24, 39)',
      fontSize: 15,
      fontWeight: 400,
      paddingTop: 2
    }),
    input: (provided: any) => ({
      ...provided,
      color: isDarkMode ? 'rgb(255,255,255)' : 'rgb(17, 24, 39)',
      fontSize: 15,
      fontWeight: 400,
      fontFamily: 'Lato'
    }),
    // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
    option: (provided, state) => ({
      ...provided,
      color: (state.isSelected || isDarkMode) ? 'white' : 'rgb(17, 24, 39)',
      fontSize: 15,
      fontWeight: 400,
      backgroundColor: state.isSelected ? "#46C695" : ""
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: isDarkMode ? 'rgb(255,255,255)' :  'rgb(17, 24, 39)',
      fontSize: 15,
      fontWeight: 400
    }),
  }

  return {selectStyles}
}

export const useSelectStyles2 = (isSelected: any) => {
  const { isDarkMode } = useApp();
  const selectStyles = {
    indicatorSeparator: (provided: any) => ({
      ...provided,
      background: 'rgba(0,0,0,0)',
      color: 'rgba(0,0,0,0)',
      opacity: 0
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color:'rgba(0,0,0,0)',
      opacity: 0
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 100000001,
      minHeight: 50,
      background: isDarkMode ? '#1a1a1a' : 'white',
      width: 'calc(100% + 6px)',
      marginLeft: -3
    }),
    // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
    container: (provided, state) => ({
      ...provided,
      width: '100%'
    }),
    // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
    control: (provided, state) => ({
      ...provided,
      height: 38,
      flexShrink: 0,
      width: '100%',
      background:'#F5F5F5',
      border: 'none',
      borderRadius: 8,
      boxShadow: 'none',
      paddingLeft: 5,
      color: isDarkMode ? 'rgb(255,255,255)' : 'rgb(17, 24, 39)',
      fontSize: 16,
      fontWeight: 400,
      paddingTop: 2,
      fontFamily: 'Lato'
    }),
    input: (provided: any) => ({
      ...provided,
      color: isDarkMode ? 'rgb(255,255,255)' : 'rgb(17, 24, 39)',
      fontSize: 16,
      fontFamily: 'Lato'
    }),
    // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
    option: (provided, state) => ({
      ...provided,
      color: (state.isSelected || isDarkMode) ? 'white' : 'rgb(17, 24, 39)',
      fontSize: 16,
      fontWeight: 400,
      backgroundColor: state.isSelected ? "#46C695" : ""
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: isDarkMode ? 'rgb(255,255,255)' :  'rgb(17, 24, 39)',
      fontSize: 16,
      fontWeight: 400
    }),
  }

  return {selectStyles}
}
