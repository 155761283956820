// External Imports
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
// import { Intercom } from "@capacitor-community/intercom";

// Internal Imports
import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import {BrellyBtn} from "components/Button";
import AuthPage from 'components/AuthImage';

const PasswordReset = () => {
  const history = useHistory();
  // @ts-expect-error TS(2339): Property 'setUser' does not exist on type 'unknown... Remove this comment to see the full error message
  const { setUser, setLoading, setError } = useApp();
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  // const [toastMessage, setToastMessage] = useState("Check your email for a reset code");
  // const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    // setShowToast(true);
  }, []);

  const handleReset = async () => {
    if (!resetCode) {
      setError("Please enter the reset code we sent your email address");
    } else if (!newPassword) {
      setError("Please create a new password");
    } else if (!newPasswordConfirm) {
      setError("Please confirm your new password");
    } else if (newPassword !== newPasswordConfirm) {
      setError("Passwords do not match");
    } else {
      setLoading(true);
      try {
        const user = await brelly.auth.resetPassword(resetCode, newPassword);
        if (user && user.id) {
          // set user in context
          setUser(user);

          // save user to localStorage as backup
          localStorage.removeItem("reset-password-email");

          // register user session with intercom
          // Intercom.registerIdentifiedUser({
          //   userId: user.id,
          //   email: user.email,
          // });
          //
          // // verify web hmac
          // Intercom.setUserHash({ hmac: user.webHmac });

          // send to login
          setLoading(false);
          history.push("/");
        } else {
          setError("Something went wrong, please try again!");
        }
      } catch (err) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleNewCode = async () => {
    const email = localStorage.getItem("reset-password-email");
    if (email) {
      await brelly.auth.sendPasswordResetCode(email);
      // setToastMessage("A new code was sent to your email");
      // setShowToast(true);
    } else {
      // setToastMessage("Please reenter your email");
      // setShowToast(true);
    }
  };

  return (
    <AuthPage title='Reset Password'>
      <Input
        id="password-reset-code"
        label="Reset Code"
        value={resetCode}
        onChange={setResetCode}
        placeholder="i.e. '0123456'"
        enterKeyHint="next"
      />
      <Input
        id="new-password"
        type="password"
        label="New Password"
        value={newPassword}
        onChange={setNewPassword}
        placeholder="Create your new password"
        enterKeyHint="next"
        autoComplete="new-password"
      />
      <Input
        id="confirm-new-password"
        type="password"
        label="Confirm New Password"
        value={newPasswordConfirm}
        onChange={setNewPasswordConfirm}
        placeholder="Confirm your new password"
        enterKeyHint="next"
        autoComplete="new-password"
      />
      <BrellyBtn className='mt-3' onClick={handleReset}>Reset</BrellyBtn>
      <div className='jb-ac mt-3'>
        <div className="brelly-text-btn" onClick={() => history.push("/login")}>
          Back to login
        </div>
        <div className="brelly-text-btn" onClick={handleNewCode}>
          Resend code
        </div>
      </div>
    </AuthPage>
  );
};

export default PasswordReset;
