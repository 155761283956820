import { useApp } from "context";
import brelly from "@brelly/sdk";
import { useState, useRef } from "react";
import { ConfirmDeleteModal } from 'modals';
import { InlineModal } from "components/Modals";
import { IoEllipsisVerticalOutline } from "react-icons/io5";

export const WorkflowItem = ({ canEdit, workflow, setSelectedWorkflow }) => {
  const { setLoading, handleUser, setError } = useApp();
  const etModalBtnRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [optionsModal, setOptionsModal] = useState(false);

  const handleDeleteModal = (e) => {
    e.stopPropagation()
    setOptionsModal(false);
    setDeleteModal(true)
  }

  const handleDeleteModalClose = (e) => {
    e.stopPropagation()
    setDeleteModal(false)
  }

  const handleEditModal = (e) => {
    e.stopPropagation()
    setOptionsModal(false);
    setSelectedWorkflow(workflow)
  }

  const handleOptions = (e) => {
    e.stopPropagation();
    if (canEdit) {
      setOptionsModal(true)
    } else {
      setSelectedWorkflow(workflow)
    }
  }

  const handleDelete = async (e) => {
    e.stopPropagation()
    try {
      setLoading(true);
      await brelly.workflows.delete(workflow?.id);
      handleUser(false);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err?.message)
    }
  }

  return(
    <div
      onClick={() => setSelectedWorkflow(workflow)}
      style={{ width: "100%", height: 44, background: "#F6F5F5", borderRadius: 10  }}
      className="jb-ac ps-3 pe-3 mb-1"
    >
      <div style={{ color: "#0D72C5", fontWeight: 600, userSelect: "none" }}>{workflow?.name}</div>

      <div>
        <div className='jc-ac' ref={etModalBtnRef} onClick={handleOptions}>
          <IoEllipsisVerticalOutline />
        </div>
        {canEdit && (
          <>
            <InlineModal btnRef={etModalBtnRef} open={optionsModal} handleClose={() => setOptionsModal(false)}>
              <div className='dash-table-item mb-2' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={handleEditModal}>Edit</div>
              <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer', color: '#ff0000' }} onClick={handleDeleteModal}>Delete</div>
            </InlineModal>
            <ConfirmDeleteModal open={deleteModal} handleClose={handleDeleteModalClose} onDelete={handleDelete} />
          </>
        )}
      </div>
    </div>
  )
}
