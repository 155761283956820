import { format } from 'date-fns';
import Calendar from './Calender';
import { Portal } from 'react-portal';
import CheckBox from 'components/CheckBox';
import { BrellyBtn } from 'components/Button';
import { useState, useEffect, useRef } from 'react';
import { BsChevronDown, BsCalendar2Week, BsClock } from 'react-icons/bs';
import { useApp } from 'context';
import { SearchIcon } from 'components/Icons';
import { ClaimOnboardingModal, CompletedOnboardingModal } from 'modals';

export const ClaimSearch = () => {
  const { projects, setProject } = useApp();
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;
  const [options, setOptions] = useState([])
  const [searchVal, setSearchVal] = useState("");

  const [selected, setSelected] = useState({})
  const [obModal, setObModal] = useState(false);
  const [newModal, setNewModal] = useState(false);

  const handleOb = (project) => {
    setObModal(true);
    setFocus(false);
    setSelected(selected)
  }

  const handleNew = (project) => {
    setNewModal(true);
    setFocus(false);
    setSelected(selected)
  }

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect())
    }

    if (projects?.length > 0) {
      setOptions(projects)
    }
    setSelected({})
  }, [inputRef, focus])

  const createSearchStr = (p) => {
    let ph1 = p?.policyholder?.firstName
    let ph2 = p?.policyholder?.lastName
    let num = p?.claim?.claimNumber
    let address = p?.property?.address?.fullAddress
    let str = `${ph1} ${ph2} ${num} ${address}`
    return str?.toLowerCase()
  }

  const filteredOptions = () => {
    if (searchVal?.length === 0) {
      return options;
    } else {
      let formatted = projects?.map((o) => ({ ...o, searchStr: createSearchStr(o) }))
      return formatted?.filter((option) => option.searchStr?.includes(searchVal?.toLowerCase()))
    }
  }

  return(
    <div className='' style={{ position: 'relative', width: 400, maxWidth: '85%' }}>
      <div style={{ position: 'absolute', left: 10, bottom: 8 }} onClick={() => setFocus(true)}>
        <SearchIcon style={{ color: '#0D72C5' }} />
      </div>
      <input value={searchVal} onChange={(e) => setSearchVal(e.target.value)} ref={inputRef} onClick={() => setFocus(true)} className='dash-input js-ac' placeholder="Search Claims" style={{ cursor: 'pointer', outline: focus ? '1px solid #0D72C5' : '', width: '100%', paddingLeft: 42 }} />
      {focus && (
        <Portal>
          <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)', zIndex: 1009 }} onClick={() => setFocus(false)} />
          <div style={{ position: 'absolute', background: '#F5F5F5', borderRadius: 10, paddingLeft: 12, paddingRight: 12, zIndex: 1010, fontFamily: 'Lato', left: boundingRect.x, top: boundingRect.bottom + 6, width: boundingRect.width }} className='shadow'>
            <div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
              {filteredOptions()?.length > 0 && (
                filteredOptions().map((project, idx) => <ProjectItem project={project} key={project?.id} idx={idx} handleOb={handleOb} handleNew={handleNew} setFocus={setFocus} />)
              )}
              {filteredOptions()?.length === 0 && (
                <div className='jc-ac mb-2'>No Claims Found</div>
              )}
            </div>
          </div>
        </Portal>
      )}
      <ClaimOnboardingModal open={obModal} project={selected} handleClose={() => setObModal(false)} />
      <CompletedOnboardingModal open={newModal} handleClick={() => setProject(selected?.id)} handleClose={() => setNewModal(false)} />
    </div>
  )
}

const ProjectItem = ({ handleOb, setFocus, handleNew, project, idx }) => {
  const { orgId, setProject } = useApp()

  const handleSelect = () => {
    if (project?.claim?.id) {
      let existingRecent = JSON.parse(localStorage.getItem(`${orgId}-recentlycompleted`)) || []
      let existingRecentIds = existingRecent?.map((p) => p?.projectId);

      if (existingRecentIds?.includes(project?.id)) {
        handleNew(project)
        let remainingRecent = existingRecent?.filter((p) => p?.projectId !== project?.id)
        localStorage.setItem(`${orgId}-recentlycompleted`, JSON.stringify(remainingRecent));
      } else {
        setFocus(false)
        setProject(project?.id)
      }
    } else {
      handleOb(project)
    }
  }

  return(
    <>
      <div onClick={() => handleSelect()} style={{ width: '100%', height: 44, borderRadius: 10, background: 'white', fontSize: 14, cursor: 'pointer', marginTop: idx === 0 ? 12 : 6, marginBottom : 12, lineHeight: 1 }} className='jc-as flex-col ps-2'>
        {project?.policyholder?.lastName}, {project?.policyholder?.firstName}
        <div style={{ fontSize: 12, marginTop: 2 }}>{project?.property?.address?.fullAddress || "Unknown Address"}</div>
      </div>
    </>
  )
}
