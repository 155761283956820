import { MapDetailItem, EmailDetailItem, PhoneDetailItem } from 'pages/Dash/Claims/Claim/Details/components';
import { HugIcon } from "components/Icons";
import { useState } from "react";

export const Policyholder = ({ contacts, project }) => {
  const policyholders = contacts?.filter((c) => c?.role === 'POLICYHOLDER')

  return (
    <>
      <div className="dash-box mt-2 dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 14 }}>
          <div className="dash-box-title">
            <HugIcon style={{ marginRight: 8 }} stroke="#0D72C5" />
            Policyholders
          </div>
        </div>
        {policyholders?.map((p, idx) => (
          <PolicyholderItem policyholder={p} key={p?.id} idx={idx} />
        ))}
      </div>
    </>
  );
};

const PolicyholderItem = ({ policyholder, idx }) => {
  const notes = policyholder?.notes || [];
  const mt = idx !== 0 ? 'mt-2' : ''

  return(
    <div className={`brelly-content ps-3 pe-3 pt-1 pb-3 ${mt}`} style={{ borderRadius: 8 }}>
      <div className='jb-ac mt-3'>
        <div className="dash-table-item" style={{ fontWeight: 600, fontSize: 16 }}>{policyholder?.firstName} {policyholder?.lastName}</div>
      </div>
      <EmailDetailItem
        style={{ textTransform: "lowercase", cursor: "pointer" }}
        title="Email"
        value={policyholder?.primaryEmail}
      />
      <PhoneDetailItem title="Phone" value={policyholder?.primaryPhoneNumber} />
      <MapDetailItem
        title="Mailing Address"
        value={policyholder?.homeAddress}
      />
    </div>
  )
}
