import brelly from "@brelly/sdk";
import { useApp } from 'context';
import Input from 'components/Input';
import { AddFileModal } from 'modals';
import { AttachmentItem } from 'items';
import { getClaimDisplay } from 'utils';
import TextArea from 'components/TextArea';
import { useState, useEffect } from 'react';
import { ConfirmDeleteModal } from 'modals';
import { BrellyBtn } from 'components/Button';
import { DateSelect } from 'components/Select';
import { CenterModal } from 'components/Modals';
import { ClaimSelectorSingle } from 'components/ClaimSelector';
import TeammemberSelector from 'components/TeammemberSelector';
import { SingleSelect } from "components/Select";

export const EditTaskModal = ({ task, open, handleClose, handleUpdate }) => {
  const { setLoading, setError, projectId, projects, workflows } = useApp();
  const [desc, setDesc] = useState('');
  const [confirmfirmDeleteModal, setConfirmfirmDeleteModal] = useState(false);
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [due, setDue] = useState(null);
  const [name, setName] = useState('');
  const [files, setFiles] = useState([]);
  const [phase, setPhase] = useState(null);
  const existingFiles = task?.files || []
  const allFiles = [...existingFiles, ...files];
  const inClaim = !!projectId

  let project = projects?.find((p) => p?.id === selectedClaim?.value);
  let workflow = workflows?.find((w) => w?.id === project?.workflowId);
  let phaseOptions = workflow?.phases?.map((wp) => ({ ...wp, label: wp?.name, value: wp?.id }));
  let poLength = phaseOptions?.length;

  const handleFiles = (_files) => {
    setFiles([...files, ..._files])
  }

  useEffect(() => {
    if (open) {
      setDesc(task?.description)
      if (task?.endDate) {
          setDue(new Date(task?.endDate))
      }
      setName(task?.name)
      let formattedUsers = task?.assignees?.map((ass) => ({ label: `${ass?.user?.firstName} ${ass?.user?.lastName}`, value: ass?.id }));
      setSelectedUsers(formattedUsers);
      setSelectedClaim({ label: getClaimDisplay(task?.projectId, projects), value: task?.projectId })
    }
    //eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (phaseOptions?.length > 0) {
      if (task?.phaseId) {
        let originalPhase = phaseOptions?.find((p) => p?.id === task?.phaseId);
        setPhase(originalPhase);
      }
    }

  }, [poLength])

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.tasks.update(task?.id, { name: name, description: desc, endDate: due, projectId: selectedClaim[0]?.value, phaseId: phase?.id })
      await brelly.tasks.setAssignees(task?.id, selectedUsers?.map((ass) => ass?.value))
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id);
        let remoteNoDuplicates = checkFileDupes(remoteFiles, existingFiles)

        if (localFiles?.length > 0) {
          await brelly.tasks.uploadFiles(task?.id, localFiles)
        }

        if (remoteNoDuplicates?.length > 0) {
          await brelly.tasks.addFiles(task?.id, remoteNoDuplicates)
        }
      }
      await handleUpdate()
      setLoading(false);
      handleClose()
    } catch(err) {
      setError(err?.message)
      setLoading(false);
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true);
      setConfirmfirmDeleteModal(false)
      await brelly.tasks.delete(task?.id);
      await handleUpdate()
      handleClose()
      setLoading(false);
    } catch(err) {
      setError(err);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose} style={{ width: 700, maxWidth: '100%' }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Task</div>
      <div className='row' style={{ maxHeight: window.innerHeight - 280, overflowY: 'auto' }}>
        <div className='col-12 col-lg-6'>
          <Input value={name} onChange={setName} placeholder='' label='Task Name' />
          <DateSelect value={due} onChange={setDue} label='Due Date' />
          <ClaimSelectorSingle value={selectedClaim} onChange={setSelectedClaim} />
          {selectedClaim?.value && <SingleSelect label='Claim Phase' value={phase} onChange={setPhase} options={phaseOptions} />}
          <TeammemberSelector value={selectedUsers} onChange={setSelectedUsers} />
        </div>
        <div className='col-12 col-lg-6'>
          <TextArea label='Description' value={desc} onChange={setDesc} style={{ height: 280 }} />
        </div>
      </div>
      {allFiles?.length > 0 && (
        <div className='row mt-1'>
          {allFiles?.map((f, key) => (
            <AttachmentItem key={key} file={f} />
          ))}
        </div>
      )}
      <BrellyBtn onClick={() => setAddFilesModal(true)} className='mt-2' style={{ background: '#2D3C57' }}>Add Attachments</BrellyBtn>
      <div className='row mt-2'>
        <div className='col-6'>
          <BrellyBtn style={{ border: '1px solid red', color: 'red', background: 'transparent' }} onClick={() => setConfirmfirmDeleteModal(true)}>Delete</BrellyBtn>
        </div>
        <div className='col-6'>
          <BrellyBtn  onClick={handleEdit}>Save</BrellyBtn>
        </div>
      </div>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .csv, .xls, xlsx, .mov, .mp4, .ppt, .pptx, .txt, .svg"
      />
      <ConfirmDeleteModal open={confirmfirmDeleteModal} handleClose={() => setConfirmfirmDeleteModal(false)} onDelete={handleDelete} />
    </CenterModal>
  )
}

const checkFileDupes = (toAttach, existing) => {
  let existingIds = existing?.map((eimg) => eimg?.id);
  return toAttach?.filter((nimg) => !existingIds?.includes(nimg))
}
