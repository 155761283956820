import { DetailItem } from 'pages/Dash/Claims/Claim/Details/components';
import { HomeIcon } from "components/Icons";
import { useState } from "react";

export const Mortgage = ({ property }) => {
  const mortgagee = property?.mortgagee;

  return (
    <>
      <div className="dash-box mt-2 dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 14 }}>
          <div className="dash-box-title">
            <HomeIcon
              style={{ marginRight: 8, marginBottom: 2 }}
              stroke="#0D72C5"
            />
            Mortgage
          </div>
        </div>
        <DetailItem title="Loan Number" value={property?.mortgageLoanNumber} />
        <DetailItem title="Lendor" value={mortgagee?.name} />
        <DetailItem title="Address" value={mortgagee?.address?.fullAddress} />
        <DetailItem title="Phone Number" value={mortgagee?.phoneNumber} />
      </div>
    </>
  );
};
