// External Imports
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { IntercomProvider } from "react-use-intercom";

// Sentry Monitoring
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Replay } from "@sentry/replay";

// Brelly SDK
import brelly from "@brelly/sdk";

const INTERCOM_APP_ID = "hpfx6s5z";

// other sentry integrations
// Sentry.captureException("Test Captured Exception");
// Sentry.createReduxEnhancer
// BrowserTracing - routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)

// Sentry monitoring and tracking
if (process.env.REACT_APP_NODE_ENV !== "local") {
  Sentry.init({
    dsn: "https://4ed27902f54c4ca994e61d2d566ea323@o4504607676891136.ingest.sentry.io/4504883945537536",
    release: "brelly.pro.webapp@0.0.1",
    dist: "1",
    // sample rate for error events
    sampleRate: process.env.REACT_APP_NODE_ENV === "production" ? 0.5 : 1.0,
    // sample rate for transaction events
    tracesSampleRate: process.env.REACT_APP_NODE_ENV === "production" ? 0.5 : 1.0,
    // sample rate for session replays
    replaysSessionSampleRate: process.env.REACT_APP_NODE_ENV === "production" ? 0.5 : 1.0,
    // sample rate for error replays
    replaysOnErrorSampleRate: process.env.REACT_APP_NODE_ENV === "production" ? 0.5 : 1.0,
    environment: process.env.REACT_APP_NODE_ENV,
    debug: process.env.REACT_APP_NODE_ENV === "local" ? true : false,
    // @ts-expect-error TS(2345): Argument of type '{ dsn: string; release: string; ... Remove this comment to see the full error message
    includeLocalVariables: true,
    integrations: [
      new BrowserTracing(), // routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      new Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}

const unauthenticatedRoutes = [
  "/login",
  "/register",
  "/forgot-password",
  "/password-reset",
  "/verify",
  "/onboarding"
];

brelly.initialize({
  cognitoUserPoolId: process.env.REACT_APP_COGNITO_PRO_USER_POOL_ID || "",
  cognitoClientId: process.env.REACT_APP_COGNITO_PRO_CLIENT_ID || "",
  cognitoRegion: process.env.REACT_APP_COGNITO_REGION || "",
  apiVersion: process.env.REACT_APP_API_VERSION || "",
  apiBaseUrl: process.env.REACT_APP_API_URL || "",
  userType: "professional",
  handleUnauthenticated: () => {
    let path = window.location.pathname;

    if (!unauthenticatedRoutes.includes(path) && !path?.includes('/access-link/')) {
      window.location.href = "/login";
    }
  },
});

const container = document.getElementById("root");
// @ts-expect-error TS(2345): Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <App />
    </IntercomProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
defineCustomElements(window);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
