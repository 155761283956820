// Internal Imports
import Toggle from "components/Toggle";

interface GlobalInputSwitchProps {
  type: string;
  value: any;
  onChange: (v: any) => void;
}

const GlobalInputSwitch = ({ type, value, onChange }: GlobalInputSwitchProps) => {
  switch (type) {
    case "boolean": {
      return <Toggle value={value} onChange={() => onChange(!value)} />;
    }
  }
};

export default GlobalInputSwitch;
