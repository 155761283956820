import { useState } from "react";
import { LinkItem } from './Item';
import { LinksIcon } from "components/Icons";
import { AddLinkModal } from 'modals';
import { useProject } from "pages/Dash/Claims/Claim/context";
import { EmptyState } from 'pages/Dash/Claims/Claim/Details/components';

export const Links = () => {
  const { claim } = useProject();
  const [addLinkModal, setAddLinkModal] = useState(false);
  const links = claim?.links || [];

  return (
    <>
      <div className="dash-box dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 10 }}>
          <div className="dash-box-title">
            <LinksIcon style={{ marginRight: 8 }} stroke="#0D72C5" />
            Important Links
          </div>
          <div
            className="brelly-text-btn"
            onClick={() => setAddLinkModal(true)}
            style={{ color: "#0D72C5" }}
          >
            Add New
          </div>
        </div>
        {links?.map((link, key) => (
          <LinkItem key={key} link={link} links={links} />
        ))}
        <EmptyState type="Links" cb={setAddLinkModal} arr={links} />
      </div>
      <AddLinkModal
        open={addLinkModal}
        handleClose={() => setAddLinkModal(false)}
      />
    </>
  );
};
