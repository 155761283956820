import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import { checkFileDupes } from 'utils';
import TextArea from 'components/TextArea';
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CenterModal } from 'components/Modals';
import { AddFileModal } from 'modals';
import { AttachmentItem } from 'items';
import TeammemberSelector from 'components/TeammemberSelector';

export const EditWFTaskModal = ({ open, handleClose, task }) => {
  const { setLoading } = useApp();
  const [values, setValues] = useState({});
  const [files, setFiles] = useState([]);
  const [addFilesModal, setAddFilesModal] = useState(false);
  const existingFiles = task?.files || []
  const allFiles = [...existingFiles, ...files];
  const formattedUsers = task?.assignees?.map((ass) => ({ label: `${ass?.user?.firstName} ${ass?.user?.lastName}`, value: ass?.id }));
  const [selectedUsers, setSelectedUsers] = useState(formattedUsers);

  const handleFiles = (_files) => {
    setFiles([...files, ..._files])
  }

  const handleUpdate = (updates = {}) => {
    setValues({ ...values, ...updates })
  }

  useEffect(() => {
    if (open) {
      setValues(task)
    }
    //eslint-disable-next-line
  }, [open])

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.workflowTasks.update(task?.id, {
        name: values?.name,
        updatedAt: new Date(),
        description: values?.description,
      })
      await brelly.workflowTasks.setAssignees(task?.id, selectedUsers?.map((ass) => ass?.value));
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id);
        let remoteNoDuplicates = checkFileDupes(remoteFiles, existingFiles)

        if (localFiles?.length > 0) {
          await brelly.workflowTasks.uploadFiles(task?.id, localFiles)
        }

        if (remoteNoDuplicates?.length > 0) {
          await brelly.workflowTasks.addFiles(task?.id, remoteNoDuplicates)
        }
      }
      setLoading(false);
      handleClose()
    } catch(err) {
      console.log(err);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose} style={{ width: 700, maxWidth: '100%' }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Task {task?.name}</div>
      <div className='row' style={{ maxHeight: window.innerHeight - 280, overflowY: 'auto' }}>
        <div className='col-12 col-lg-6'>
          <Input value={values?.name || ""} onChange={(v) => handleUpdate({ name: v })} label='Name' />
          <TeammemberSelector value={selectedUsers} onChange={setSelectedUsers} />
        </div>
        <div className='col-12 col-lg-6'>
          <TextArea value={values?.description || ""} onChange={(v) => handleUpdate({ description: v })} label='Description' />
        </div>
      </div>
      {allFiles?.length > 0 && (
        <div className='row mt-2'>
          {allFiles?.map((f, key) => (
            <AttachmentItem key={key} file={f} />
          ))}
        </div>
      )}
      <BrellyBtn onClick={() => setAddFilesModal(true)} className='mt-3' style={{ background: '#2D3C57' }}>Add Attachments</BrellyBtn>
      <BrellyBtn onClick={handleEdit} className='mt-2'>Save</BrellyBtn>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
      />
    </CenterModal>
  )
}
