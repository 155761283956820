import ProjectContextWrapper from "./context";
import ClaimDashHeader from './ClaimHeader';
import { useWindowSize } from 'react-use';
import Inventory from "./Inventory";
import Timeline from "./Timeline";
import Expenses from "./Expenses";
import Contacts from "./Contacts";
import Calendar from "./Calendar";
import { useApp } from "context";
import Reports from "./Reports";
import Details from "./Details";
import Files from "./Files";
import Tasks from "./Tasks";
import Docs from "./Docs";

const Claim = () => {
  const { claimTab } = useApp();
  const { width } = useWindowSize();
  const headerHeight = width > 991 ? 264 : 208

  return (
    <div style={{ position: "relative", paddingTop: 24 }}>
      <div style={{ width: "100%", background: "#F5F5F5" }}>
        <div className="dash-box">
          <ClaimDashHeader />
        </div>
        <div className="jb-ac mt-3 ps-2 pe-2 brelly-h4" style={{ paddingBottom: 14, overflowX: 'auto' }}>
          {claimTabBtns.map((btn) => (
            <ClaimTabBtn value={btn.value} key={btn.value} title={btn.title} />
          ))}
        </div>
      </div>
      <div
        style={{
          height: `calc(100vh - ${headerHeight}px)`,
          overflowY: "auto",
          overflowX: "hidden",
          paddingBottom: 50,
        }}
      >
        {claimTab === "inventory" && <Inventory />}
        {claimTab === "timeline" && <Timeline />}
        {claimTab === "expenses" && <Expenses />}
        {claimTab === "contacts" && <Contacts />}
        {claimTab === "calendar" && <Calendar />}
        {claimTab === "reports" && <Reports />}
        {claimTab === "details" && <Details />}
        {claimTab === "files" && <Files />}
        {claimTab === "tasks" && <Tasks />}
        {claimTab === "docs" && <Docs />}
      </div>
    </div>
  );
};

const WrappedClaim = () => {
  return (
    <ProjectContextWrapper>
      <Claim />
    </ProjectContextWrapper>
  );
};

export default WrappedClaim;

const ClaimTabBtn = ({ value, title }) => {
  const { claimTab, setClaimTab } = useApp();
  let isSelected = value === claimTab;

  return (
    <div
      style={{
        cursor: "pointer",
        color: isSelected ? "#0D72C5" : "#64666B",
        fontWeight: isSelected ? 700 : "",
        flexShrink: 0
      }}
      className='me-3 me-lg-0'
      onClick={() => setClaimTab(value)}
    >
      {title}
    </div>
  );
};

const claimTabBtns = [
  { value: "details", title: "Claim Details" },
  { value: "tasks", title: "Tasks" },
  { value: "calendar", title: "Calendar" },
  { value: "timeline", title: "Timeline" },
  { value: "contacts", title: "Contacts" },
  { value: "files", title: "Files" },
  { value: "inventory", title: "Inventory Builder" },
  { value: "expenses", title: "Expense Builder" },
];
