import { useState } from 'react';
import { format } from 'date-fns';
import ProPic from 'components/ProPic';
import { CenterModal } from 'components/Modals';
import { AttachmentItem } from 'items';
import { getFileTypeIcon } from 'components/FileIcons';

export const ViewEventModal = ({ task = {}, open, handleClose }) => {
  let startDate = task?.startDate ? format(new Date(task?.startDate), 'MMM dd, yyyy') : ""
  let endDate = task?.endDate ? format(new Date(task?.endDate), 'MMM dd, yyyy') : ""
  let startTime = task?.startDate ? format(new Date(task?.startDate), 'h:mm aaa') : ""
  let endTime = task?.endDate ? format(new Date(task?.endDate), 'h:mm aaa') : ""

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ width: 450 }}>
        <span style={{ fontSize: 12 }}>Event Details</span>
        <div style={{ fontWeight: 600, fontSize: 20 }} className='jb-ac mb-4'>
          {task?.name}
        </div>
        {startDate && (
          <div className='js-ac mb-2'>
            <div style={{ width: 200 }}>Start</div>
            <div className='js-ac'>
              <div style={{ fontWeight: 500 }} className='me-3'>{startDate}</div>
              <div style={{ fontWeight: 500 }}>{startTime}</div>
            </div>
          </div>
        )}
        {endDate && (
          <div className='js-ac mb-2'>
            <div style={{ width: 200 }}>End</div>
            <div className='js-ac'>
              <div style={{ fontWeight: 500 }} className='me-3'>{endDate}</div>
              <div style={{ fontWeight: 500 }}>{endTime}</div>
            </div>
          </div>
        )}
        {task?.participants?.length > 0 && (
          <div className='js-ac mb-2'>
            <div style={{ width: 200 }}>Participants:</div>
            <div className='js-ac'>
              {task?.participants?.map((user, key) => (
                <ProPic user={user?.user} key={key} size={24} className='me-1' style={{ fontSize: 14 }} />
              ))}
            </div>
          </div>
        )}
        {task?.description && (
          <div className='mb-4'>
            <div style={{ fontWeight: 600 }}>Description:</div>
            <div className='mt-1' style={{ lineHeight: 1.2 }}>
              {task?.description}
            </div>
          </div>
        )}
        <div className='mt-4'>
          {task?.files?.length > 0 && <div style={{ fontWeight: 600 }}>Attachments:</div>}
          <div className='row'>
            {task?.files?.map((tf, key) => (
              <AttachmentItem file={tf} key={key} />
            ))}
          </div>
        </div>
      </div>
    </CenterModal>
  )
}
