import { useEffect, useState } from "react";
import { useApp } from "context";
import brelly from "@brelly/sdk";
import Menu, { MobileMenu } from "./Menu";
import { useWindowSize } from "react-use";
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
import { BsHouseDoor } from "react-icons/bs";
import Details from './Details';
import Timeline from './Timeline';
import Files from './Files';
import Inventory from './Inventory';
import Expenses from './Expenses';

const ShareViewer = () => {
  const [loaded, setLoaded] = useState(false);
  const { setLoading, searchParams, setError } = useApp();
  const { width, height } = useWindowSize();
  const contentWidth = width > 991 ? width - 260 : width;
  const contentHeight = height - 80;
  const [mobileMenu, setMobileMenu] = useState(false);
  const { id } = useParams();
  const token = searchParams.get("token");
  // const [accessLink, setAccessLink] = useState({});
  const [project, setProject] = useState({});
  const [claimTab, setClaimTab] = useState("")
  const headerHeight = width > 991 ? 264 : 208;
  const [allowedTabs, setAllowedTabs] = useState([])
  const [coverages, setCoverages] = useState([]);
  const [deductibles, setDeductibles] = useState([]);
  const [contacts, setContacts] = useState([]);

  const handleAccessLink = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.accessLinks.get(id, { token: token });
      let projectData = await brelly.projects.get(data?.projectId, { token: token });
      setProject(projectData);

      if (data?.allowedTabs[0] === 'All') {
        setAllowedTabs(allClaimTabBtns)
        setClaimTab("details")
      } else {
        let filteredBtns = allClaimTabBtns?.filter((btn) => data?.allowedTabs?.includes(btn.title));
        setAllowedTabs(filteredBtns);
        setClaimTab(filteredBtns[0]?.value)
      }

      let coverageData = await brelly.policies.getCoverages(projectData?.policyId, { token: token });
      setCoverages(coverageData?.filter((c) => !c?.deductible));
      setDeductibles(coverageData?.filter((c) => c?.deductible));

      let contactsData = await brelly.projects.getContacts(projectData?.id, { token: token });
      setContacts(contactsData);

      setLoading(false);
      setLoaded(true)
    } catch(err) {
      setLoading(false);
      setError(err?.message);
    }
  }

  useEffect(() => {
    handleAccessLink()

    //eslint-disable-next-line
  }, [id]);

  if (loaded) {
    return (
      <div
        style={{ width: width, height: height, background: "#ffffff" }}
        className="js-as"
      >
        <Menu />
        <MobileMenu open={mobileMenu} setOpen={setMobileMenu} />
        <div style={{ width: contentWidth, height: height, position: "relative" }}>
          <Header setMobileMenu={setMobileMenu} />

          <div
            style={{
              height: contentHeight,
              width: '100%',
              padding: '0px 32px 0px 32px',
              borderRadius: "12px 0px 0px 0px",
              position: "absolute",
              top: 80,
              left: 0,
              transform: "translate(0%, 0%)",
              transition: `all 300ms linear 250ms`,
              boxShadow: "inset -4px -4px 8px rgba(0,0,0,.038)",
            }}
            className="brelly-content"
          >
            <div style={{ position: "relative", paddingTop: 24 }}>
              <div style={{ width: "100%", background: "#F5F5F5" }}>
                <div className="dash-box">
                  <ClaimDashHeader project={project} />
                </div>
                <div className="jb-ac mt-3 ps-2 pe-2 brelly-h4" style={{ paddingBottom: 14, overflowX: 'auto' }}>
                  {allowedTabs.map((btn) => (
                    <ClaimTabBtn value={btn.value} key={btn.value} title={btn.title} claimTab={claimTab} setClaimTab={setClaimTab} />
                  ))}
                </div>
              </div>
              <div
                style={{
                  height: `calc(100vh - ${headerHeight}px)`,
                  overflowY: "auto",
                  overflowX: "hidden",
                  paddingBottom: 50,
                }}
              >
                {claimTab === 'details' && <Details project={project} coverages={coverages} deductibles={deductibles} contacts={contacts} />}
                {claimTab === 'timeline' && <Timeline project={project} token={token} />}
                {claimTab === 'files' && <Files project={project} token={token} />}
                {claimTab === 'inventory' && <Inventory project={project} token={token} />}
                {claimTab === 'expenses' && <Expenses project={project} token={token} coverages={coverages} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>
  }
};

export default ShareViewer;

const Header = () => {
  return (
    <div
      style={{
        height: 80,
        width: '100%',
        cursor: "pointer",
      }}
      className="jb-ac ps-3 ps-lg-0 pe-3 pe-lg-5"
    />
  );
};

const ClaimDashHeader = ({ project, setTab }) => {
  const claim = project?.claim;
  const property = project?.property;

  const claimNumber = claim?.claimNumber ? claim.claimNumber : "Unknown";
  const dateOfLoss = claim?.dateOfLoss
    ? format(new Date(claim?.dateOfLoss), "MMM dd, yyyy")
    : "Unknown";

  let getName = () => {
    if (project?.policyholder) {
      return `${project?.policyholder?.firstName} ${project?.policyholder?.lastName}`;
    } else {
      return "Unknown";
    }
  };

  return (
    <div className="jb-ac">
      <div className="js-ac" style={{ fontWeight: 600, fontSize: 20 }}>
        <div style={{ position: "relative" }} className='d-none d-lg-block'>
          <Img claim={claim} />
        </div>
        <div>
          <div style={{ maxWidth: '100%' }} className='truncate1'>{property?.address?.fullAddress || "ADDRESS"}</div>
          <div style={{ fontSize: 14, fontWeight: 400 }} className='d-none d-lg-block'>
            <div className="mt-1 truncate1">
              <span>Primary Policyholder:</span>
              <span className="ms-1" style={{ fontWeight: 600 }}>
                {getName()}
              </span>
            </div>
            <div className="truncate1">
              <span>Claim Number:</span>
              <span className="ms-1" style={{ fontWeight: 600 }}>
                {claimNumber}
              </span>
            </div>
            <div className="truncate1">
              <span>Loss Date:</span>
              <span className="ms-1" style={{ fontWeight: 600 }}>
                {dateOfLoss}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='je-as brelly-text-btn' style={{ height: 90 }}>
        {project?.currentPhase?.name}
      </div>
    </div>
  );
};

const ClaimTabBtn = ({ value, title, claimTab, setClaimTab }) => {
  let isSelected = value === claimTab;

  return (
    <div
      style={{
        cursor: "pointer",
        color: isSelected ? "#0D72C5" : "#64666B",
        fontWeight: isSelected ? 700 : "",
        flexShrink: 0
      }}
      className='me-3 me-lg-0'
      onClick={() => setClaimTab(value)}
    >
      {title}
    </div>
  );
};

const Img = ({ claim }) => {
  if (claim?.coverPhoto?.downloadUrl) {
    return (
      <img
        alt=""
        style={{
          width: 95,
          height: 95,
          marginRight: 12,
          borderRadius: 10,
          objectFit: "cover",
          transition: "all 200ms linear",
        }}
        src={claim?.coverPhoto?.downloadUrl}
      />
    );
  } else {
    return (
      <div
        style={{ width: 95, height: 95, marginRight: 12, borderRadius: 10, background: "#F5F5F5" }}
        className="jc-ac"
      >
        <BsHouseDoor />
      </div>
    );
  }
};

const allClaimTabBtns = [
  { value: "details", title: "Claim Details" },
  { value: "timeline", title: "Timeline" },
  { value: "files", title: "Files" },
  { value: "inventory", title: "Contents Inventory" },
  { value: "expenses", title: "Expenses" },
];
