export const getUniqueArrayValues = (arr: any, matchKey: any) => {
  let distinct = [];

  for (var i = 0; i < arr.length; i++)
    //eslint-disable-next-line
    if (distinct?.filter((d) => d[matchKey] === arr[i]?.[matchKey])?.length < 1) {
      distinct.push(arr[i]);
    }
  return distinct;
};

export const boundedNumber = (number: any, upper: any, lower: any) => {
  let upperBound = Math.min(number, upper);
  return Math.max(upperBound, lower);
};

export const createGroupedArrays = (array: any, compareKey: any) => {
  return array.reduce(function (r: any, a: any) {
    r[a[compareKey]] = r[a[compareKey]] || [];
    r[a[compareKey]].push(a);
    return r;
  }, Object.create(null));
};

export const groupArrayItems = (array: any, compareKey: any) => {
  let objectGroups = array.reduce(function (r: any, a: any) {
    r[a[compareKey]] = r[a[compareKey]] || [];
    r[a[compareKey]].push(a);
    return r;
  }, Object.create(null));

  return Object.entries(objectGroups).map((item) => ({ type: item[0], values: item[1] }));
};

export const titleCase = (str: string) => {
   var splitStr = str.toLowerCase().split(' ');
   for (var i = 0; i < splitStr.length; i++) {
       // You do not need to check if i is larger than splitStr length, as your for does that for you
       // Assign it back to the array
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
   }
   // Directly return the joined string
   return splitStr.join(' ');
}
