import brelly from "@brelly/sdk";
import { useApp } from 'context';
import Input from "components/Input";
import CheckBox from "components/CheckBox";
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import PhonePicker from 'components/PhoneNumber';
import { useHistory } from 'react-router-dom';

const AuthModal = ({ open, invite, handleClose, setWelcomeModal }) => {
  const [verify, setVerify] = useState(false);
  const className = verify ? 'jc-ac shadow-sm' : 'jb-as shadow-sm'

  if (open) {
    return(
      <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', zIndex: 1000, background: 'rgba(0, 172, 176,.6)' }} className='jc-ac'>
        <div style={{ width: 1000, background: 'white', padding: 20, borderRadius: 10, height: 559 }} className={className}>
          {!verify && (
            <>
              <LoginUI invite={invite} handleClose={handleClose} />
              <div style={{ width: 1, height: 519, background: '#F5F5F5' }} />
              <RegisterUI setVerify={setVerify} />
            </>
          )}
          {verify && (
            <VerifyUI setVerify={setVerify} handleClose={handleClose} invite={invite} />
          )}
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default AuthModal;

const VerifyUI = ({ setVerify, invite, handleClose }) => {
  const history = useHistory()
  const { setError, setLoading, setUser, searchParams } = useApp();
  const [verificationCode, setVerificationCode] = useState("");

  const handleVerify = async () => {
    if (verificationCode?.length < 1) {
      setError("Please enter the code we emailed you");
    } else {
      setLoading(true);
      try {
        const user = await brelly.auth.verifyConfirmationCode(verificationCode);
        setUser(user);
        let { data } = await brelly.membershipInvites.accept(invite?.id, invite?.code);
        setLoading(false);
        handleClose();
        history.push(`/dash?tab=home&mid=${data?.id}&walkthrough=${invite?.organization?.name}`)
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handleNewCode = async () => {
    await brelly.auth.resendConfirmationCode();
  };

  return(
    <div style={{ width: 450 }} className=''>
      <h3 style={{ color: 'black' }}>Enter the verification code we just sent to your email</h3>
      <Input value={verificationCode} onChange={setVerificationCode} label='Code' className='mt-3' />
      <BrellyBtn onClick={handleVerify} className='mt-3'>Verify</BrellyBtn>
      <div className='jb-ac mt-3'>
        <div onClick={() => setVerify(false)} style={{ fontSize: 14 }} className='brelly-text-btn'>Back</div>
        <div onClick={handleNewCode} style={{ fontSize: 14 }} className='brelly-text-btn'>Send New Code</div>
      </div>
    </div>
  )
}

const RegisterUI = ({ setVerify }) => {
  const { setError, setLoading } = useApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [tncModal, setTncModal] = useState(false);
  const [ppModal, setPPModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sms, setSms] = useState(true);

  const handleRegister = async () => {
    if (!terms) {
      setError("You must agree to the terms & conditions to register");
    } else if (email?.length < 1) {
      setError("Email cannot be blank!");
    } else if (password?.length < 1) {
      setError("Password cannot be blank!");
    } else if (password?.length < 7) {
      setError("Please create a password with at least 7 characters");
    } else if (firstName?.length < 1) {
      setError("Please add your first name");
    } else if (lastName?.length < 1) {
      setError("Please add your last name");
    } else {
      setLoading(true);

      try {
        const data = await brelly.auth.signup(email.toLowerCase(), password, {
          phoneNumber,
          firstName,
          lastName,
          sms,
        });
        setLoading(false);
        setVerify(true);
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    }
  };

  return(
    <div style={{ width: 450 }} className=''>
      <h1 style={{ color: 'black' }}>Register</h1>
      <Input label='First Name' value={firstName} onChange={setFirstName} />
      <Input label='Last Name' value={lastName} onChange={setLastName} />
      <PhonePicker label='Phone Number' value={phoneNumber} onChange={setPhoneNumber} />
      <Input label='Email' value={email} onChange={setEmail} />
      <Input label='Password' value={password} onChange={setPassword} type='password' />
      <Input style={{ marginBottom: 18 }} label='Confirm Password' value={confirmPassword} onChange={setConfirmPassword} type='password' />
      <CheckBox value={terms} setValue={setTerms} title="I agree to the Terms of Service and Privacy Policy" />
      <CheckBox value={sms} setValue={setSms}
        className="mt-2"
        title="I consent to Brelly sending text messages to the phone number list above.  Standard messaging rates may app."
      />
      <BrellyBtn onClick={handleRegister} className="mt-3">Create Account</BrellyBtn>
    </div>
  )
}

const LoginUI = ({ invite, handleClose, setWelcomeModal }) => {
  const { setError, setUser, setLoading, searchParams } = useApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory()

  const handleLogin = async () => {
    if (email?.length < 1) {
      setError("Email cannot be blank!");
    } else if (password?.length < 1) {
      setError("Password cannot be blank!");
    } else {
      setLoading(true);
      try {
        const user = await brelly.auth.signin(email.toLowerCase(), password);
        setUser(user);
        let { data } = await brelly.projectInvites.accept(invite?.id, invite?.code);
        setLoading(false);
        handleClose();
        history.push(`/dash?tab=home&mid=${data?.id}&walkthrough=${invite?.organization?.name}`)
      } catch (err) {
        setLoading(false);
        setError("Something went wrong, please try again!");
      }
    }
  };

  return(
    <div style={{ width: 450, height: 519 }} className=''>
      <h1 style={{ color: 'black' }}>Login</h1>
      <Input label='Email' value={email} onChange={setEmail} />
      <Input label='Password' value={password} onChange={setPassword} type='password' />
      <BrellyBtn onClick={handleLogin} className='mt-3'>Login</BrellyBtn>
    </div>
  )
}
