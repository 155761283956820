import { useState, useEffect } from 'react';
import Input from 'components/Input';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';
import { currentToFloat, createTime } from 'utils';
import brelly from "@brelly/sdk";
import { useApp } from 'context';
import { useProject } from 'pages/Dash/Claims/Claim/context';
import { v4 as uuid } from 'uuid';

export const AddDeductiblesModal = ({ open, policy, handleClose }) => {
  const { handleCoverages } = useProject();
  const { setLoading, setError } = useApp();
  const [covs, setCovs] = useState([{ name: '', value: '', id: uuid(), createdAt: new Date() }]);
  const sortedCovs = covs?.sort((a, b) => createTime(a.createdAt) - createTime(b.createdAt))

  useEffect(() => {
    setCovs([{}])
  }, [open])

  const addLink = () => {
    setCovs([...covs, { name: '', value: '', id: uuid(), createdAt: new Date() }])
  }

  const handleAdd = async () => {
    let filtered = covs?.filter((c) => c?.name && c?.value);

    if (filtered?.length > 0) {
      try {
        setLoading(true);
        await Promise.all(filtered?.map((c) => brelly.coverages.create({
          name: c?.name,
          policyId: policy?.id,
          deductible: currentToFloat(c?.value),
          createdAt: c?.createdAt
        })))
        await handleCoverages();
        setLoading(false);
        handleClose()
      } catch(err) {
        let plural = filtered?.length > 1 ? 'deductibles' : 'deductible'
        setError(`There was an issue adding your ${plural}, please check your connection and try again`)
        setLoading(false);
      }
    } else {
      handleClose()
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add Deductibles</div>
      {sortedCovs?.map((c, key) => <Single key={key} coverage={c} covs={covs} setCovs={setCovs} />)}
      <div className='jc-ac mt-2'>
        <div className='dash-table-btn' onClick={addLink} style={{ color: '#0D72C5' }}>Add Another</div>
      </div>
      <BrellyBtn onClick={handleAdd} className='mt-4' style={{ width: 400, maxWidth: '100%' }}>Save</BrellyBtn>
    </CenterModal>
  )
}

const Single = ({ coverage, covs, setCovs }) => {
  const { name, value } = coverage;

  const handleChange = (type, newValue) => {
    let others = covs?.filter((c) => c?.id !== coverage?.id);
    setCovs([...others, { ...coverage, [type]: newValue }])
  }

  return(
    <div>
      <Input value={name} onChange={(nv) => handleChange('name', nv)} label='Name' />
      <Input value={value} onChange={(nv) => handleChange('value', nv)} label='Amount' type='number' placeholder="$" />
    </div>
  )
}
