import brelly from "@brelly/sdk";
import { useApp } from "context";
import { v4 as uuidV4 } from 'uuid';
import Input from "components/Input";
import { currentToFloat } from "utils";
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CustomField } from '../../components';
import { CenterModal } from "components/Modals";
import LocationPicker from "components/LocationPicker2";
import { useProject } from "pages/Dash/Claims/Claim/context";

export const EditModal = ({ property, open, handleClose }) => {
  const { handleClaim } = useProject();
  const { setError, setLoading } = useApp();
  const [address, setAddress] = useState("");
  const [replacement, setReplacement] = useState("");
  const [built, setBuilt] = useState("");
  const [remodeled, setRemodeled] = useState("");
  const [sqft, setSqft] = useState("");
  const [additional, setAdditional] = useState({ entries: [] });
  const sortedAdditional = additional?.entries?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) || [];
  const hasAdditional = sortedAdditional?.length > 0;
  const colClass = hasAdditional ? 'col-12 col-lg-6' : 'col-12';

  const addAdditional = () => {
    setAdditional({ entries: [...additional?.entries, { key: '', value: '', id: uuidV4(), createdAt: new Date() }] })
  }

  useEffect(() => {
    if (open) {
      setReplacement(property?.replacementCost || "");
      setBuilt(property?.yearBuilt || "");
      setRemodeled(property?.lastRenovationDate || "");
      setSqft(property?.sqft || "");
      if (property?.address) {
        let addy = property?.address;
        setAddress({ value: { placeId: addy?.placeId }, label: addy?.fullAddress, brellyId: 1 });
      }
      if (property?.additionalInfo) {
        setAdditional(property?.additionalInfo)
      }
    }
    //eslint-disable-next-line
  }, [open]);

  const handleEdit = async () => {
    try {
      setLoading(true);
      if (address?.lat) {
        let addressData = await brelly.addresses.create(address);
        await brelly.properties.update(property?.id, {
          sqft: sqft,
          yearBuilt: built,
          addressId: addressData?.id,
          additionalInfo: additional,
          lastRenovationDate: remodeled,
          replacementCost: currentToFloat(replacement),
        });
      } else {
        await brelly.properties.update(property?.id, {
          sqft: sqft,
          yearBuilt: built,
          additionalInfo: additional,
          lastRenovationDate: remodeled,
          replacementCost: currentToFloat(replacement)
        });
      }
      handleClaim(false);
      setLoading(false);
      handleClose();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20, width: hasAdditional ? 800 : 400, maxWidth: '100%' }}>Edit Property</div>
      <div className='row'>
        <div className={colClass}>
          <LocationPicker value={address} setValue={setAddress} label="Address" />
          <Input
            value={replacement}
            onChange={setReplacement}
            label="Replacement Cost"
            type="number"
            placeholder="$400,000"
          />
          <Input value={built} onChange={setBuilt} label="Year Built" />
          <Input value={remodeled} onChange={setRemodeled} label="Year Last Renovated" />
          <Input value={sqft} onChange={setSqft} label="Square Footage" />
        </div>
        {sortedAdditional?.length > 0 && (
          <div className='col-6'>
            <div className='brelly-text-btn' style={{ marginTop: -23 }}>Custom</div>
            {sortedAdditional?.map((entry) => (
              <CustomField key={entry?.id} entry={entry} additional={additional} setAdditional={setAdditional} />
            ))}
            <div className='mt-3 jc-ac'>
              <div onClick={addAdditional} className='brelly-text-btn'>Add Custom</div>
            </div>
          </div>
        )}
        {sortedAdditional?.length === 0 && (
          <div className='mt-3 jc-ac'>
            <div onClick={addAdditional} className='brelly-text-btn'>Add Custom</div>
          </div>
        )}
      </div>
      <BrellyBtn className="mt-3" onClick={handleEdit}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
