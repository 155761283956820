import { useApp } from 'context';
import { getUniqueArrayValues } from 'utils';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';
import { SingleSelect, DateSelect } from 'components/Select';
import { getInitialFilters } from 'pages/Dash/Claims/Filter';

export const FilterModal = ({ open, filters, setFilters, handleClose }) => {
  const { projects, phases } = useApp()
  const phaseOptions = getPhaseOptions(projects, phases)
  const carrierOptions = getCarrierOptions(projects)
  const memberOptions = getMemberOptions(projects)
  const perilOptions = getPerilOptions(projects)

  const updateFilter = (changes) => {
    setFilters({...filters, ...changes})
  }

  const resetFilters = () => {
    setFilters(getInitialFilters())
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Filter</div>
      <SingleSelect value={filters?.carrier} onChange={(v) => updateFilter({ carrier: v })} options={carrierOptions} label="Carrier" />
      <SingleSelect value={filters?.contributor} onChange={(v) => updateFilter({ contributor: v })} options={memberOptions} label="Contributor" />
      <SingleSelect value={filters?.lossType} onChange={(v) => updateFilter({ lossType: v })} options={perilOptions} label="Loss Type" />
      <SingleSelect value={filters?.phase} onChange={(v) => updateFilter({ phase: v })} options={phaseOptions} label="Claim Phase" />
      <div className='jb-ac'>
      <DateSelect value={filters?.startDate} onChange={(v) => updateFilter({ startDate: v })} style={{ width: 180 }} label='Claim Loss Date Range - Start' />
      <DateSelect value={filters?.endDate} onChange={(v) => updateFilter({ endDate: v })} style={{ width: 180 }} label='End' />
      </div>
      <BrellyBtn className='mt-4' onClick={handleClose} style={{ width: 400 }}>Close</BrellyBtn>
      <div className='mt-3 jc-ac brelly-text-btn' style={{ width: 400 }} onClick={resetFilters}>Reset filters</div>
    </CenterModal>
  )
}

const getPhaseOptions = (projects, phases) => {
  let usedPhases = projects?.map((p) => ({
    label: phases?.find((phase) => phase?.id === p?.currentPhaseId)?.name,
    value: p?.currentPhaseId
  }))
  let filtered = usedPhases?.filter((p) => p?.value)
  return [{ label: "All", value: 'all' }, ...getUniqueArrayValues(filtered, 'value')]
}

const getCarrierOptions = (projects) => {
  let filtered = projects?.filter((p) => p?.policy?.insuranceCompany?.name)
  let formatted = filtered?.map((o) => ({
    label: o?.policy?.insuranceCompany?.name,
    value: o?.policy?.insuranceCompany?.name
  }))
  return [{ label: "All", value: 'all' }, ...getUniqueArrayValues(formatted, 'value')]
}

const getMemberOptions = (projects) => {
  let formatted = projects?.map((p) => p?.contributors?.map((pc) => ({ label: `${pc?.user?.firstName} ${pc?.user?.lastName}`, value: pc?.userId })));
  let flattened = formatted?.flat();
  return [{ label: "All", value: 'all' }, ...getUniqueArrayValues(flattened, 'value')]
}

const getPerilOptions = (projects) => {
  let filtered = projects?.filter((p) => p?.claim?.perilType);
  let formatted = filtered?.map((p) => p?.claim?.perilType?.split(","));
  let flattened = formatted?.flat();
  let uniqie = [...new Set(flattened)]
  let trimmed = uniqie?.map((o) => o[0] === " " ? o.substring(1) : o)
  return [{ label: "All", value: 'all' }, ...trimmed?.map((po) => ({ label: po, value: po }))]
}
