// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface AddressesInterface {
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
}

export const addresses = (client: Axios): AddressesInterface => ({
  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/addresses", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },
});
