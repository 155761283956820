import { useState } from "react";
import Input from "components/Input";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { SingleSelect } from "components/Select";

import brelly from "@brelly/sdk";
import { useApp } from "context";

export const InviteTeamMemberModal = ({ open, handleTeam, handleClose }) => {
  const { orgId, setLoading } = useApp();
  const [role, setRole] = useState({ label: "Member", value: "MEMBER" });
  const [email, setEmail] = useState("");

  const handleAdd = async () => {
    try {
      setLoading(true);
      await brelly.membershipInvites.send({
        organizationId: orgId,
        role: role?.value,
        email: email
      });
      handleTeam();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <CenterModal open={open} handleClose={handleClose}>
        <div style={{ fontWeight: 600, fontSize: 20 }}>Invite Team Member</div>
        <div style={{ width: 400 }}>
          <Input
            label="Email"
            value={email}
            onChange={setEmail}
            placeholder="bwalton1@ucla.edu"
          />
          <SingleSelect
            label="Role"
            value={role}
            onChange={setRole}
            options={[{ label: 'Admin', value: 'ADMIN' }, { label: 'Member', value: 'MEMBER' }]}
          />
        </div>
        <BrellyBtn className="mt-4" onClick={handleAdd}>
          Invite
        </BrellyBtn>
      </CenterModal>
    </>
  );
};
