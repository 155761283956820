import { IonButton } from "@ionic/react";

const Button = ({
  children,
  onClick,
  fill,
  shape,
  expand = "block",
  theme = "primary",
  disabled = false,
  strong = true,
  size,
  styles = {},
}: any) => {
  return (
    <IonButton
      onClick={onClick}
      disabled={disabled}
      expand={expand}
      fill={fill}
      shape={shape}
      strong={strong}
      color={theme}
      size={size}
      style={{
        marginLeft: 0,
        marginRight: 0,
        fontSize: 16,
        fontFamily: "Lato",
        "--border-radius": "4px",
        textTransform: "none",
        maxWidth: '100%',
        ...styles,
      }}
    >
      {children}
    </IonButton>
  );
};

export default Button;

export const AltButton = ({ children, callback = () => {}, style = {} }: any) => {
  return (
    <div className="alt-btn" onClick={callback} style={style}>
      {children}
    </div>
  );
};

export const BrellyBtn = ({
  style = {},
  className = "",
  onClick = () => {},
  btnRef = null,
  children = null,
}: any) => {
  return (
    <div
      ref={btnRef}
      onClick={onClick}
      style={{
        padding: "8px 18px",
        minWidth: 150,
        background: "#0D72C5",
        color: "#fff",
        borderRadius: 6,
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Lato',
        textShadow: "1px 1px 1px rgba(0,0,0,.1)",
        boxShadow: "1px 1px 2px rgba(0,0,0,.1)",
        cursor: "pointer",
        maxWidth: '100%',
        height: 40,
        ...style,
      }}
      className={`jc-ac ${className}`}
    >
      {children}
    </div>
  );
};

export const BrellyBorderBtn = ({
  style = {},
  className = "",
  onClick = () => {},
  btnRef = null,
  children = null,
}: any) => {
  return (
    <div
      ref={btnRef}
      onClick={onClick}
      style={{
        padding: "7px 18px",
        minWidth: 150,
        background: "transparent",
        color: "#0D72C5",
        borderRadius: 8,
        border: '1px solid #0D72C5',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Lato',
        textShadow: "1px 1px 1px rgba(0,0,0,.1)",
        boxShadow: "1px 1px 2px rgba(0,0,0,.2)",
        cursor: "pointer",
        ...style,
      }}
      className={`jc-ac ${className}`}
    >
      {children}
    </div>
  );
};

export const BrellyIconBtn = ({
  style = {},
  className = "",
  onClick = () => {},
  btnRef = null,
  children = null,
}: any) => {
  return (
    <div
      ref={btnRef}
      onClick={onClick}
      style={{ height: 36, width: 36, border: "1.5px solid #0D72C5", borderRadius: 8, ...style }}
      className={`jc-ac ${className}`}
    >
      {children}
    </div>
  );
};
