import { useApp } from "context";
import { titleCase } from "utils";
import { roles } from "constants";
import { DashPage } from "./components";
import SearchBar from "components/SearchBar";
import { InlineModal } from "components/Modals";
import { useEffect, useState, useRef } from "react";
import { AddContactModal, ImportContactModal, EditContactModal, ContactFilterModal } from "modals";
import { PiUsersThree, PiUser } from "react-icons/pi";

const initialFilters = {
  searchVal: "",
  role: { label: "All", value: 1 },
  company: { label: "All", value: 1 },
  sharing: { label: "All", value: 1 },
};

const ContactsPage = () => {
  const { tab, handleContacts, membershipId } = useApp();
  const isSelected = tab === "contacts";

  useEffect(() => {
    if (isSelected) {
      handleContacts();
    }
    //eslint-disable-next-line
  }, [membershipId, isSelected]);

  return (
    <DashPage tab={tab} value="contacts">
      <ContactsBox />
    </DashPage>
  );
};

export default ContactsPage;

const ContactsBox = () => {
  const [filterModal, setFilterModal] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [roleFilter, setRoleFilter] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const roleFilterBtn = useRef(null);
  const { contacts } = useApp();
  const filteredContacts = getFiltered(filters, contacts);

  const updateFilters = (changes = {}) => {
    setFilters({ ...filters, ...changes });
  };

  const handleRoleFilter = (newFilter) => {
    updateFilters({ role: newFilter });
    setRoleFilter(false);
  };

  return (
    <div className="" style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)", paddingTop: 24 }}>
      <div className="jb-ac">
        <h1 style={{ marginBottom: 0 }}>Contacts</h1>
        <div className="je-ac">
          <div className="brelly-text-btn me-3" onClick={() => setFilterModal(true)}>
            Filter
          </div>
          <SearchBar
            className="d-none d-lg-flex"
            value={filters?.searchVal}
            style={{ background: "white" }}
            onChange={(v) => updateFilters({ searchVal: v })}
          />
          <div
            style={{ color: "#0D72C5" }}
            className="brelly-text-btn ms-3"
            onClick={() => setImportModal(true)}
          >
            Import
          </div>
          <div
            style={{ color: "#0D72C5" }}
            className="brelly-text-btn ms-3"
            onClick={() => setAddModal(true)}
          >
            Add New
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-3 d-none d-lg-flex">
          <div className="col-1" />
          <div className="dash-table-header col-3">Name</div>
          <div className="dash-table-header col-2">Email</div>
          <div className="dash-table-header col-2">Phone Number</div>
          <div className="dash-table-header col-2">Company</div>
          <div className="dash-table-header col-2">Role</div>
        </div>
        {filteredContacts?.map((ph, key) => (
          <ContactItem ph={ph} key={key} />
        ))}
      </div>
      <ContactFilterModal
        open={filterModal}
        handleClose={() => setFilterModal(false)}
        contacts={contacts}
        filters={filters}
        updateFilters={updateFilters}
      />
      <ImportContactModal open={importModal} handleClose={() => setImportModal(false)} />
      <AddContactModal open={addModal} handleClose={() => setAddModal(false)} />
      <InlineModal btnRef={roleFilterBtn} open={roleFilter} handleClose={() => setRoleFilter(false)}>
        {roles?.map((p, idx) => (
          <div
            key={idx}
            className={`dash-table-item ${idx === 0 ? "" : "mt-3"}`}
            style={{ fontWeight: 600, cursor: "pointer" }}
            onClick={() => handleRoleFilter(p?.label?.toLowerCase())}
          >
            {p?.label}
          </div>
        ))}
      </InlineModal>
    </div>
  );
};

const ContactItem = ({ ph }) => {
  const [editModal, setEditModal] = useState(false);
  // const claimStr = ph?.projects?.map((c) => c.property?.address?.fullAddress)?.join(", ");

  return (
    <>
      <div
        onClick={() => setEditModal(true)}
        className="mt-2 brelly-content row dash-table-item align-items-center"
        style={{
          borderRadius: 10,
          height: 50,
          cursor: "pointer",
          background: "white",
        }}
      >
        <div className="dash-table-item jc-ac col-1 d-none d-lg-flex">
          {ph?.isPrivate && <PiUser style={{ fontSize: 24 }} />}
          {!ph?.isPrivate && <PiUsersThree style={{ fontSize: 24 }} />}
        </div>
        <div className="dash-table-item col-12 col-lg-3">
          {ph?.firstName} {ph?.lastName}
        </div>
        <div className="dash-table-item col-2 d-none d-lg-flex">
          <a href={`mailto:${ph?.primaryEmail}`} style={{ textTransform: "lowercase" }}>
            {ph?.primaryEmail}
          </a>
        </div>
        <div className="dash-table-item col-2 d-none d-lg-flex">
          <a href={`tel:${ph?.primaryPhoneNumber}`}>{ph?.primaryPhoneNumber}</a>
        </div>
        <div className="dash-table-item col-2 d-none d-lg-flex">{ph?.company}</div>
        <div className="dash-table-item col-2 d-none d-lg-flex" style={{ textTransform: "capitalize" }}>
          {titleCase(ph?.role || "")}
        </div>
      </div>
      <EditContactModal open={editModal} handleClose={() => setEditModal(false)} contact={ph} />
    </>
  );
};

const createSearchStr = (contact) => {
  let fName = contact?.firstName;
  let lName = contact?.lastName;
  let str = `${fName} ${lName}`;
  return str?.toLowerCase();
};

const getFiltered = (filters, contacts) => {
  let _contacts = contacts;

  if (filters?.role?.label !== "All") {
    if (filters?.role?.label === "Unknown") {
      _contacts = _contacts?.filter((t) => !t.role);
    } else {
      _contacts = _contacts?.filter((t) => t.role?.toLowerCase() === filters?.role?.label?.toLowerCase());
    }
  }

  if (filters?.sharing?.label !== "All") {
    if (filters?.sharing?.label === "Shared") {
      _contacts = _contacts?.filter((t) => !t.isPrivate);
    } else {
      _contacts = _contacts?.filter((t) => t.isPrivate);
    }
  }

  if (filters?.company?.label !== "All") {
    if (filters?.role?.label === "Unknown") {
      _contacts = _contacts?.filter((t) => !t.company);
    } else {
      _contacts = _contacts?.filter((t) => t.company === filters?.company?.label);
    }
  }

  if (filters?.searchVal?.length > 0) {
    let formatted = _contacts?.map((c) => ({ ...c, searchVal: createSearchStr(c) }));
    _contacts = formatted?.filter((t) => t.searchVal?.includes(filters?.searchVal?.toLowerCase()));
  }

  return _contacts;
};
