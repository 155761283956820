// External Imports
import { useState, useEffect } from "react";
import { AiOutlineLink } from "react-icons/ai";

// Internal Imports
import { inventoryCats } from "constants";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { MultiSelect } from "components/Select";
import { DateSelect } from "components/Select";
import Input from "components/Input";
import { useProject } from "pages/Dash/Claims/Claim/context";
import { useApp } from "context";

// Brelly SDK
import brelly from "@brelly/sdk";

const getFilteredIds = (selectedCats, items) => {
  let _items = items;

  if (selectedCats?.length > 0) {
    let formatted = selectedCats?.map((c) => c?.label);
    _items = _items?.filter((t) => formatted?.includes(t?.category));
  }

  return _items.map((i) => i.id);
};

export const InventoryReportModal = ({ open, handleClose }) => {
  const { setLoading, projectId, claimId, setError, addToast } = useApp();
  const { personalItems, media, setMedia } = useProject();
  const [selectedCats, setSelectedCats] = useState([]);
  const [name, setName] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const catOptions = [{ label: 'All', value: 'all', all: true }, ...inventoryCats];

  useEffect(() => {
    if (open) {
      setSelectedCats([]);
      setName("");
      setFrom(null);
      setTo(null);
    }
  }, [open]);

  const handleCreate = async () => {
    if (projectId && claimId) {
      try {
        setLoading(true);

        // Get filtered personal items
        const inventoryItemIds =
          selectedCats.length > 0 ? getFilteredIds(selectedCats, personalItems) : undefined;

        // Generate expense report
        const report = await brelly.reports.createInventoryDamageReport({
          inventoryItemIds,
          projectId,
          claimId,
          name,
          from,
          to,
        });

        // Add report to file state
        setMedia([report, ...media]);

        // Add toast with link to report and info about saving to claim files
        addToast(
          <div>
            Your{" "}
            <a
              href={report.downloadUrl}
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
              target="_blank"
            >
              report <AiOutlineLink style={{ fontSize: 18 }} />
            </a>{" "}
            was created and saved to your claim files!{" "}
          </div>
        );

        // Update state
        setLoading(false);
        handleClose();
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    } else {
      setError("No claim selected");
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Create Report</div>
      <Input value={name} onChange={setName} placeholder="Inventory Report" label="Report Name" />
      <MultiSelect
        label="Category"
        options={catOptions}
        value={selectedCats}
        onChange={setSelectedCats}
        style={{ width: 400, maxWidth: '100%' }}
      />
      <DateSelect value={from} onChange={setFrom} label="From" />
      <DateSelect value={to} onChange={setTo} label="To" />
      <BrellyBtn className="mt-4" onClick={handleCreate}>
        Create
      </BrellyBtn>
    </CenterModal>
  );
};
