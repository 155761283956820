import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { format } from 'date-fns';
import ProPic from "components/ProPic";
import { useState, useRef } from 'react';
import { ConfirmDeleteModal } from 'modals';
import { Status } from 'components/TaskStatus';
import { InlineModal } from 'components/Modals';
import { BsFlag, BsFlagFill } from 'react-icons/bs';
import { ViewTaskModal, EditTaskModal } from 'modals';
import { IoEllipsisVerticalOutline } from 'react-icons/io5';

export const ClaimTaskItem = ({ task, inClaim, handleUpdate }) => {
  const { setLoading, setError } = useApp();
  const etModalBtnRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewTaskModal, setViewTaskModal] = useState(false);
  const [editTaskModal, setEditTaskModal] = useState(false);
  // const [editTaskModal, setEditTaskModal] = useState(false);
  const [editTaskTypeModal, setEditTaskTypeModal] = useState(false);

  const handleInlineModal = (e) => {
    e.stopPropagation();
    setEditTaskTypeModal(true)
  }

  // const handleEditModal = () => {
  //   setEditTaskTypeModal(false);
  //   setEditTaskModal(true)
  // }

  const handleDeleteModal = () => {
    setEditTaskTypeModal(false);
    setDeleteModal(true)
  }

  const handleEditModal = () => {
    setEditTaskTypeModal(false);
    setEditTaskModal(true)
  }

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.tasks.delete(task?.id)
      await handleUpdate();
      setLoading(false);
      setEditTaskTypeModal(false)
    } catch(err) {
      setLoading(false);
      setError(err.message)
    }
  }

  const handlePriority = async (e) => {
    e.stopPropagation();
    try {
      await brelly.tasks.update(task?.id, { priority: !task?.priority });
      await handleUpdate();
    } catch(err) {
      setError(err.message)
    }
  }

  const getEndDate = () => {
    if (task?.endDate) {
      return format(new Date(task?.endDate), "MM/dd/yyyy")
    } else {
      return null
    }
  }

  return(
    <>
      <div className='mt-2 brelly-content pt-3 pb-3 dash-table-item align-items-center' onClick={() => setViewTaskModal(true)} style={{ borderRadius: 10, background: "#DCEFFF" }}>
        {/*PRIORITY DESKTOP*/}
        <div className='col-1 d-none d-lg-flex jc-ac' onClick={handlePriority}>
          {task?.priority ? <BsFlagFill style={{ fontSize: 20 }} /> : <BsFlag style={{ fontSize: 20 }} />}
        </div>
        {/*TASK NAME*/}
        <div className='col-10 col-lg-6 js-ac fw-mobile'>{task?.name}</div>
        {/*PRIORITY MOBILE*/}
        <div className='col-2 d-lg-none je-ac' onClick={handlePriority}>
          {task?.priority ? <BsFlagFill style={{ fontSize: 16 }} /> : <BsFlag style={{ fontSize: 16 }} />}
        </div>
        {/*STATUS DESKTOP*/}
        <div className='d-none d-lg-block col-2 pe-2'>
          <Status task={task} handleUpdate={handleUpdate} />
        </div>
        {/*DATE MOBILE*/}
        <div className='d-lg-none col-12 mt-3'>
          Due: {getEndDate()}
        </div>
        {/*ASSIGNEES*/}
        <div className='col-12 col-lg-1 mt-2 mt-lg-0 js-ac'>
          <div className='me-2 d-lg-none'>Assignees:</div>
          {task?.assignees?.map((ass, key) => (
            <ProPic key={key} user={ass?.user} img={ass?.user?.profilePic?.downloadUrl} size={18} className="me-1" style={{ fontSize: 9 }} />
          ))}
        </div>
        {/*DATE DESKTOP*/}
        <div className='d-none d-lg-block col-1'>{getEndDate()}</div>
        {/*ACTIONS DESKTOP*/}
        <div className='col-1 jc-ac d-none d-lg-flex' ref={etModalBtnRef} onClick={(e) => handleInlineModal(e)}>
          <IoEllipsisVerticalOutline style={{ fontSize: 16, color: '#000000' }} />
        </div>
        {/*STAUS MOBILE*/}
        <div className='d-lg-none col-12 js-ac mt-3'><Status task={task} handleUpdate={handleUpdate} /></div>
      </div>
      <InlineModal btnRef={etModalBtnRef} open={editTaskTypeModal} handleClose={() => setEditTaskTypeModal(false)}>
        <div className='dash-table-item mb-2' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={handleEditModal}>Edit</div>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer', color: '#ff0000' }} onClick={handleDeleteModal}>Delete</div>
      </InlineModal>
      <ConfirmDeleteModal open={deleteModal} handleClose={() => setDeleteModal(false)} onDelete={handleDelete} />
      <EditTaskModal task={task} open={editTaskModal} handleClose={() => setEditTaskModal(false)} handleUpdate={handleUpdate} />
      <ViewTaskModal task={task} open={viewTaskModal} handleClose={() => setViewTaskModal(false)} handleUpdate={handleUpdate} inClaim={inClaim} />
    </>
  )
}

// <div className='jb-ac'>
//   <TableItem width='5%'>
//     <div className='jc-ac' onClick={handlePriority}>
//       {task?.priority ? <BsFlagFill style={{ fontSize: 20 }} /> : <BsFlag style={{ fontSize: 20 }} />}
//     </div>
//   </TableItem>
//   <TableItem width='56%'>{task?.name}</TableItem>
//   <TableItem width='12%'>
//     <Status task={task} handleUpdate={handleUpdate} />
//   </TableItem>
//   <TableItem width='12%'>
//     {task?.assignees?.map((ass, key) => (
//       <ProPic key={key} user={ass?.user} size={18} className="me-1" style={{ fontSize: 9 }} />
//     ))}
//   </TableItem>
//   <TableItem width='10%'>{format(new Date(task?.endDate), "MM/dd/yyyy")}</TableItem>
//   <TableItem width='5%'>
//     <div ref={etModalBtnRef} onClick={(e) => handleInlineModal(e)} className='je-ac'>
//       <IoEllipsisVerticalOutline style={{ fontSize: 16, color: '#000000' }} />
//     </div>
//   </TableItem>
// </div>

// <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={handleEditModal}>Edit</div>
