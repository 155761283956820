import { useState, useRef } from 'react';
import { format, subDays } from 'date-fns';
import { DocsIcon2 } from 'components/Icons';
import { BsFiletypePdf } from 'react-icons/bs';
import { InlineModal } from 'components/Modals';
import { IoEllipsisVerticalOutline, IoCloudDownloadOutline } from 'react-icons/io5';
import {InventoryReportModal, ExpenseReportModal, ConfirmDeleteModal} from 'modals';

const ReportsBox = () => {
  const [reportTypeModal, setReportTypeModal] = useState(false);
  const [addExpenseReportModal, setAddExpenseReportModal] = useState(false);
  const [addInventoryReportModal, setAddInventoryReportModal] = useState(false);
  const modalBtnRef = useRef(null);

  const handleAddExpense = () => {
    setReportTypeModal(false)
    setAddExpenseReportModal(true)
  }

  const handleAddInventory = () => {
    setReportTypeModal(false)
    setAddInventoryReportModal(true)
  }

  return(
    <div className='dash-box'>
      <div className='jb-ac'>
        <div className='dash-box-title'>
          <DocsIcon2 style={{ marginRight: 8 }} stroke='#0D72C5' />
          Reports
        </div>
        <div ref={modalBtnRef} className='dash-table-btn' onClick={() => setReportTypeModal(true)} style={{ color: '#0D72C5' }}>Create New Report</div>
      </div>

      <div className='jb-ac mt-3'>
        <div className='dash-table-header' style={{ width: "8%" }}></div>
        <div className='dash-table-header' style={{ width: "20%" }}>Document name</div>
        <div className='dash-table-header' style={{ width: "16%" }}>Date created</div>
        <div className='dash-table-header' style={{ width: "16%" }}>Date uploaded</div>
        <div className='dash-table-header' style={{ width: "16%" }}>Uploaded by</div>
        <div className='dash-table-header' style={{ width: "16%" }}>Shared with PH</div>
        <div className='dash-table-header' style={{ width: "8%" }}>Actions</div>
      </div>

      {reports?.map((report, key) => (
        <ReportItem report={report} key={key} />
      ))}

      <InlineModal btnRef={modalBtnRef} open={reportTypeModal} handleClose={() => setReportTypeModal(false)}>
        <div className='dash-table-item' onClick={handleAddExpense} style={{ fontWeight: 600, cursor: 'pointer' }}>Expense Report</div>
        <div className='dash-table-item mt-3' onClick={handleAddInventory} style={{ fontWeight: 600, cursor: 'pointer' }}>Inventory Report</div>
      </InlineModal>

      <InventoryReportModal open={addInventoryReportModal} handleClose={() => setAddInventoryReportModal(false)} />
      <ExpenseReportModal open={addExpenseReportModal} handleClose={() => setAddExpenseReportModal(false)} />
    </div>
  )
}

export default ReportsBox;

const ReportItem = ({ report }) => {
  const [actionsModal, setActionsModal] = useState(false);
  // const [shareModal, setShareModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const modalBtnRef = useRef(null);

  const handleConfirmDelete = () => {
    setDeleteModal(true);
    setActionsModal(false);
  }

  return(
    <>
      <div className='jb-ac mt-2 brelly-content' style={{ borderRadius: 10, height: 50, cursor: 'pointer' }}>
        <div className='dash-table-item jc-ac' style={{ width: "8%" }}>
          <BsFiletypePdf style={{ fontSize: 24 }} />
        </div>
        <div className='dash-table-item' style={{ width: "20%" }}>{report?.name}</div>
        <div className='dash-table-item' style={{ width: "16%" }}>{report?.created}</div>
        <div className='dash-table-item' style={{ width: "16%" }}>{report?.uploaded}</div>
        <div className='dash-table-item' style={{ width: "16%" }}>{report?.uploadedBy}</div>
        <div className='dash-table-item' style={{ width: "16%" }}>{report?.shared ? 'Yes' : "No"}</div>
        <div className='dash-table-item' style={{ width: "8%", color: '#0D72C5' }}>
          <div className='js-ac'>
            <div className='me-3'>
              <IoCloudDownloadOutline />
            </div>
            <div ref={modalBtnRef} onClick={() => setActionsModal(true)}>
              <IoEllipsisVerticalOutline />
            </div>
          </div>
        </div>
      </div>
      <InlineModal btnRef={modalBtnRef} open={actionsModal} handleClose={() => setActionsModal(false)}>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }}>Share with PH</div>
        <div className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer', color: '#ff0000' }} onClick={handleConfirmDelete}>Delete</div>
      </InlineModal>
      <ConfirmDeleteModal open={deleteModal} handleClose={() => setDeleteModal(false)} />
    </>
  )
}

const reports = [
  { name: 'Expense Report 3', created: format(subDays(new Date(), 1), 'MMM dd, yyyy'), uploaded: format(new Date(), 'MMM dd, yyyy'), uploadedBy: 'Trevor Johnson', shared: true, url: '' },
  { name: 'Expense Report 2', created: format(subDays(new Date(), 4), 'MMM dd, yyyy'), uploaded: format(subDays(new Date(), 3), 'MMM dd, yyyy'), uploadedBy: 'Jacquelyn Broussard', shared: true, url: '' },
  { name: 'Inventory Report 1', created: format(subDays(new Date(), 6), 'MMM dd, yyyy'), uploaded: format(subDays(new Date(), 4), 'MMM dd, yyyy'), uploadedBy: 'You', shared: false, url: '' },
  { name: 'Expense Report 1', created: format(subDays(new Date(), 8), 'MMM dd, yyyy'), uploaded: format(subDays(new Date(), 8), 'MMM dd, yyyy'), uploadedBy: 'You', shared: true, url: '' },
]
