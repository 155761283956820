// External Imports
import { useState, useContext, createContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
// Internal Imports
import brelly from "@brelly/sdk";
import { getClaimDisplay } from 'utils';
import { usePayees, useLossTypes } from 'hooks';

const AppContext = createContext();

export default function AppContextWrapper({ children, outletRef }) {
  const { search } = useLocation();
  const history = useHistory();
  const { payees, searchPayees, handlePayees } = usePayees();
  const { lossTypes, handleLossTypes } = useLossTypes();
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const [load, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [toasts, setToasts] = useState([]);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tab = searchParams.get("tab");
  const orgTab = searchParams.get("orgTab");
  const membershipId = searchParams.get("mid");
  const projectId = searchParams.get("project");
  const claimTab = searchParams.get("claimtab");
  const profileTab = searchParams.get("profileTab");

  const memberships = user?.memberships;
  const selectedMembership = memberships?.find((m) => m?.id === membershipId);
  const selectedOrg = selectedMembership?.organization;
  const orgId = selectedOrg?.id;
  const workflows = selectedOrg?.workflows || [];
  const workflow = workflows[0] || {};
  const phases = workflow?.phases || [];

  const [projects, setProjects] = useState([]);
  const selectedProject = projects?.find((p) => p?.id === projectId);
  const claim = selectedProject?.claim;
  const claimId = claim?.id;

  const [tasks, setTasks] = useState([]);
  const [emails, setEmails] = useState([]);
  const [events, setEvents] = useState([]);
  const [reports, setReports] = useState([]);
  const [contacts, setContacts] = useState([]);

  const _claimOptions = projects?.map((c) => ({ ...c, label: getClaimDisplay(c), value: c?.id }));
  const claimOptions = _claimOptions?.filter((o) => o?.label);

  const addToast = (msg) => {
    setToasts([...toasts, { message: msg, id: uuidv4(), time: new Date(), open: true }])
  }

  const handleContacts = async () => {
    if (membershipId) {
      try {
        let { data } = await brelly.memberships.getContacts(membershipId);
        setContacts(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleProjects = async (_orgId = orgId) => {
    if (_orgId) {
      try {
        let { data } = await brelly.organizations.getProjects(_orgId);
        setProjects(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleTasks = async () => {
    if (membershipId) {
      try {
        let { data } = await brelly.memberships.getTasks(membershipId);
        let formatted = data?.map((t) => ({...t, type: 'task' }))
        setTasks(formatted);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleEvents = async () => {
    if (membershipId) {
      try {
        let { data } = await brelly.memberships.getEvents(membershipId);
        let formatted = data?.map((t) => ({...t, type: 'event' }))
        setEvents(formatted);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleUser = async () => {
    try {
      let { data } = await brelly.users.get();
      setUser(data);
      if (!searchParams.get("mid")) {
        if (data?.memberships?.length > 0) {
          let firstMembership = data?.memberships[0];
          setMembership(firstMembership);
        }
      } else {
        let membership = data?.memberships?.find((m) => m?.id === membershipId)
        handleProjects(membership?.organization?.id);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      await brelly.auth.signout();
      setUser({});
      setLoading(false);
      history.push('/login')
    } catch(err) {
      setLoading(false);
      console.log(err);
    }
  };

  const setMembership = async (newMembership) => {
    let membershipId = newMembership?.id;

    if (newMembership) {
      searchParams.delete("project");
      searchParams.delete("claimtab");
      searchParams.set("mid", membershipId);
      history.replace({ search: searchParams.toString() });
      handleProjects(newMembership?.organization?.id);
    }
  };

  const setOrgTab = (orgTab) => {
    searchParams.delete("project");
    searchParams.delete("claimtab");
    searchParams.set("tab", "org");
    searchParams.set("orgTab", orgTab);
    history.replace({ search: searchParams.toString() });
  };

  const setProfileTab = (profileTab) => {
    searchParams.delete("project");
    searchParams.delete("claimtab");
    searchParams.set("tab", "profile");
    searchParams.set("profileTab", profileTab);
    history.replace({ search: searchParams.toString() });
  };

  const setTab = (newTab) => {
    searchParams.set("tab", newTab);
    searchParams.delete("project");
    searchParams.delete("claimtab");
    searchParams.delete("orgTab");
    searchParams.delete("profileTab");
    history.replace({ search: searchParams.toString() });
  };

  const setProject = (projectId) => {
    searchParams.set("tab", "claims");
    searchParams.set("project", projectId);
    searchParams.set("claimtab", "details");
    history.replace({ search: searchParams.toString() });
  };

  const setClaimTab = (claimtab) => {
    searchParams.set("claimtab", claimtab);
    history.replace({ search: searchParams.toString() });
  };

  const appLocalState = {
    // state
    tab,
    user,
    load,
    tasks,
    error,
    orgId,
    toasts,
    phases,
    events,
    loader,
    orgTab,
    payees,
    reports,
    claimId,
    addToast,
    contacts,
    workflow,
    projects,
    claimTab,
    workflows,
    setToasts,
    outletRef,
    projectId,
    lossTypes,
    profileTab,
    selectedOrg,
    memberships,
    searchParams,
    membershipId,
    claimOptions,
    selectedMembership,
    // functions
    setTab,
    setUser,
    setError,
    setOrgTab,
    setLoader,
    setProject,
    setReports,
    handleUser,
    setLoading,
    setClaimTab,
    handleTasks,
    handleEvents,
    handleLogout,
    searchPayees,
    handlePayees,
    setMembership,
    setProfileTab,
    handleContacts,
    handleProjects,
    handleLossTypes,
    //temp
    emails,
    setEmails,
  };

  return (
    <AppContext.Provider value={appLocalState}>{children}</AppContext.Provider>
  );
}

export function useApp() {
  return useContext(AppContext);
}
