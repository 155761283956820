// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface PropertyAttributesInterface {
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  batchCreate: (valueArr: { [key: string]: any }[], query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  batchUpdate: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  getInventoryItems: (id: string, query?: Query) => Promise<any>;
  getLossItems: (id: string, query?: Query) => Promise<any>;
  getFiles: (id: string, query?: Query) => Promise<any>;
  uploadFiles: (id: string, files: any[], query?: Query) => Promise<any>;
  setFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  addFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  removeFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
}

export const propertyAttributes = (client: Axios): PropertyAttributesInterface => ({
  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/property-attributes/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/property-attributes", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  batchCreate: async (valueArr: { [key: string]: any }[], query?: Query) => {
    const url = addUrlQuery("/property-attributes/batch", query);
    const cleanedArr = valueArr.map((values) => cleanValues(values));
    const res: AxiosResponse = await client.post(url, cleanedArr);
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/property-attributes/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  batchUpdate: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/property-attributes/batch", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/property-attributes/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  // *** Relationship Queries ***

  getInventoryItems: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/property-attributes/${id}/inventory-items`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getLossItems: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/property-attributes/${id}/loss-items`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getFiles: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/property-attributes/${id}/files`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  uploadFiles: async (id: string, files: any[], query?: Query) => {
    const url = addUrlQuery(`property-attributes/${id}/files`, query);

    const formData = new FormData();
    if (Array.isArray(files)) {
      files.forEach((file) => formData.append("files", file));
    }

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  setFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/property-attributes/${id}/files/set`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  addFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/property-attributes/${id}/files/add`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  removeFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/property-attributes/${id}/files/remove`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },
});
