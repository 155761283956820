import { ViewContactModal } from 'pages/Viewer/Modals/ViewContact';
import { PhoneIcon } from 'components/Icons';
import { ItemSpan } from './components';
import { useApp } from 'context';
import { useState } from 'react';

const CallItem = ({ ev }) => {
  const { contacts } = useApp();
  const caller = ev?.caller;
  const reciever = ev?.reciever;
  const [rModal, setRModal] = useState(false);
  const [cModal, setCModal] = useState(false);

  return(
    <>
      <div className='js-ac'>
        <div style={{ width: 32, height: 32, borderRadius: '50%', background: '#DBEFFE', flexShrink: 0 }} className='jc-ac me-2'>
          <PhoneIcon style={{ marginTop: 3, marginLeft: 3 }} />
        </div>
        <div className='js-ac' style={{ flexWrap: 'wrap' }}>
          <ItemSpan m='me-1' text={`${caller?.firstName} ${caller?.lastName}`} onClick={() => setRModal(true)} />
          called
          <ItemSpan m='me-1 ms-1' text={`${reciever?.firstName} ${reciever?.lastName}`} onClick={() => setCModal(true)} />
          about
          <ItemSpan m='ms-1' text={ev?.topic} />
        </div>
      </div>
      <ViewContactModal open={rModal} handleClose={() => setRModal(false)} contact={caller} />
      <ViewContactModal open={cModal} handleClose={() => setCModal(false)} contact={reciever} />
    </>
  )
}

export default CallItem;
