// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface ClaimsInterface {
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  uploadCoverPhoto: (id: string, file: any, query?: Query) => Promise<any>;
  getLossItems: (id: string, query?: Query) => Promise<any>;
  getClaimTasks: (id: string, query?: Query) => Promise<any>;
  getExpenses: (id: string, query?: Query) => Promise<any>;
  getPayments: (id: string, query?: Query) => Promise<any>;
  getFiles: (id: string, query?: Query) => Promise<any>;
  uploadFiles: (id: string, files: any[], query?: Query) => Promise<any>;
  setFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  addFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  removeFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  getLossTypes: (id: string, query?: Query) => Promise<any>;
  setLossTypes: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  addLossTypes: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  removeLossTypes: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  compileTimeline: (
    id: string,
    include: { files: boolean; emails: boolean; calls: boolean; events: boolean; tasks: boolean },
    query?: Query
  ) => Promise<any>;
}

export const claims = (client: Axios): ClaimsInterface => ({
  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/claims/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/claims", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/claims/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/claims/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  uploadCoverPhoto: async (id: string, file: any, query?: Query) => {
    const url = addUrlQuery(`claims/${id}/cover-photo`, query);
    const formData = new FormData();
    formData.append("file", file);

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  // *** Relationship Queries ***

  getLossItems: async (id: string, query?: Query) => {
    const url = addUrlQuery(`claims/${id}/loss-items`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getClaimTasks: async (id: string, query?: Query) => {
    const url = addUrlQuery(`claims/${id}/tasks`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getExpenses: async (id: string, query?: Query) => {
    const url = addUrlQuery(`claims/${id}/expenses`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getPayments: async (id: string, query?: Query) => {
    const url = addUrlQuery(`claims/${id}/payments`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getFiles: async (id: string, query?: Query) => {
    const url = addUrlQuery(`claims/${id}/files`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  uploadFiles: async (id: string, files: any[], query?: Query) => {
    const url = addUrlQuery(`claims/${id}/files`, query);
    const formData = new FormData();
    if (Array.isArray(files)) {
      files.forEach((file) => formData.append("files", file));
    }

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  setFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/claims/${id}/files/set`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  addFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/claims/${id}/files/add`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  removeFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/claims/${id}/files/remove`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  getLossTypes: async (id: string, query?: Query) => {
    const url = addUrlQuery(`claims/${id}/loss-types`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  setLossTypes: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/claims/${id}/loss-types/set`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  addLossTypes: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/claims/${id}/loss-types/add`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  removeLossTypes: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/claims/${id}/loss-types/remove`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  // *** Timeline Queries **

  compileTimeline: async (
    id: string,
    include: { files: boolean; emails: boolean; calls: boolean; events: boolean; tasks: boolean },
    query?: Query
  ) => {
    const url = addUrlQuery(`claims/${id}/timeline`, { ...include, ...query });
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },
});
