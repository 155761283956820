import { DetailItem, MapDetailItem } from 'pages/Dash/Claims/Claim/Details/components';
import { formatCurrency, formatNumber } from "utils";
import { HomeIcon } from "components/Icons";

export const Property = ({ property }) => {
  return (
    <>
      <div className="dash-box mt-2 dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 14 }}>
          <div className="dash-box-title">
            <HomeIcon
              style={{ marginRight: 8, marginBottom: 2 }}
              stroke="#0D72C5"
            />
            Property
          </div>
        </div>
        <DetailItem
          title="Replacement Cost (total value)"
          value={formatCurrency(property?.replacementCost)}
        />
        <DetailItem title="Year Built" value={property?.yearBuilt} />
        <DetailItem
          title="Year Last Renovated"
          value={property?.lastRenovationDate}
        />
        <DetailItem title="Square Footage" value={formatNumber(property?.sqft)} />
        <MapDetailItem title="Address" value={property?.address} />
      </div>
    </>
  );
};
