import { DetailItem, EmptyState } from 'pages/Dash/Claims/Claim/Details/components';
import { AddCovergesModal, EditCovergesModal } from 'modals';
import { useProject } from "pages/Dash/Claims/Claim/context";
import { formatCurrency } from "utils";
import { useState } from "react";

export const Coverages = () => {
  const { coverages, policy } = useProject();
  const [addCovergesModal, setAddCovergesModal] = useState(false);
  const [editCoveragesModal, setEditCoveragesModal] = useState(false);
  const sortedCoverages = coverages?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <>
      <div
        className="mt-3 brelly-content ps-3 pe-3 pt-2 pb-3"
        style={{ borderRadius: 8 }}
      >
        <div className="jb-ac" style={{ width: "100%", marginBottom: 14 }}>
          <div className="dash-box-title">Coverages</div>
          <div className="je-ac">
            <div
              className="brelly-text-btn"
              onClick={() => setEditCoveragesModal(true)}
              style={{ color: "#0D72C5" }}
            >
              Edit
            </div>
            <div
              className="brelly-text-btn ms-3"
              onClick={() => setAddCovergesModal(true)}
              style={{ color: "#0D72C5" }}
            >
              Add
            </div>
          </div>
        </div>
        {sortedCoverages?.map((c, key) => (
          <DetailItem
            key={key}
            title={c?.name}
            value={formatCurrency(c?.premium)}
          />
        ))}
        <EmptyState type="Coverages" cb={setAddCovergesModal} arr={coverages} />
      </div>
      <AddCovergesModal
        open={addCovergesModal}
        policy={policy}
        handleClose={() => setAddCovergesModal(false)}
      />
      <EditCovergesModal
        open={editCoveragesModal}
        policy={policy}
        handleClose={() => setEditCoveragesModal(false)}
      />
    </>
  );
};
