// External Imports
import { useState, useEffect } from "react";

// Internal Imports
import { inventoryCats } from 'constants';
import { AddFileModal } from 'modals';
import { SingleSelect } from "components/Select";
import { CenterModal } from "components/Modals";
import { DateSelect } from "components/Select";
import { BrellyBtn } from "components/Button";
import { AttachmentItem } from 'items';
import Input from "components/Input";
import { useProject } from "pages/Dash/Claims/Claim/context";
import { useApp } from "context";
import { currentToFloat } from "utils";

// Brelly SDK
import brelly from "@brelly/sdk";

export const AddInventoryModal = ({ property, open, handleClose }) => {
  const { setLoading, claimId } = useApp();
  const { handleItems } = useProject();
  const [uploadModal, setUploadModal] = useState(false);
  const [name, setName] = useState("");
  const [purchaseDate, setPurchaseDate] = useState(null);
  const [model, setModel] = useState("");
  const [category, setCategory] = useState({});
  const [replacementCost, setReplacementCost] = useState("");
  const [purchaseCost, setPurchaseCost] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [serial, setSerial] = useState("");
  const [location, setLocation] = useState("");
  const [replacementUrl, setReplacementUrl] = useState("");
  const [files, setFiles] = useState([]);

  const handleFiles = (_files) => {
    setFiles([...files, ..._files]);
  };

  const reset = () => {
    setFiles([]);
    setName("");
    setModel("");
    setSerial("");
    setLocation("");
    setCategory({});
    setManufacturer("");
    setPurchaseCost("");
    setReplacementUrl("");
    setReplacementCost("");
    setPurchaseDate(new Date());
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const handleAdd = async () => {
    try {
      setLoading(true);
      // Create personal item
      let personalItem = await brelly.inventoryItems.create({
        propertyId: property?.id,
        name: name,
        purchaseDate: purchaseDate,
        purchasePrice: currentToFloat(purchaseCost),
        model: model,
        replacementCost: currentToFloat(replacementCost),
        manufacturer: manufacturer,
        serialNumber: serial,
        comparableLink: replacementUrl,
        category: category?.label,
        location: location,
      });

      // Create corresponding loss item
      let lossItem = await brelly.lossItems.create({
        inventoryItemId: personalItem?.id,
        claimId,
      });

      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFileIds = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id);
        const uploadedFiles = [];

        // Upload new files to personal item
        if (localFiles?.length > 0) {
          const newFiles = await brelly.inventoryItems.uploadFiles(personalItem?.id, localFiles);
          if (Array.isArray(newFiles) && newFiles?.length > 0) {
            // Attach new files to claim
            const newFilesIds = newFiles.map((file) => file?.id);
            await brelly.claims.addFiles(claimId, newFilesIds);
            uploadedFiles.push(...newFiles.map((file) => file?.id));
          }
        }

        // Attach existing files to personal item
        if (remoteFileIds?.length > 0) {
          await brelly.inventoryItems.addFiles(personalItem?.id, remoteFileIds);
          uploadedFiles.push(...remoteFileIds);
        }

        // Attach all existing files to loss item
        await brelly.lossItems.addFiles(lossItem?.id, uploadedFiles);
      }
      await handleItems();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <CenterModal open={open} handleClose={handleClose} style={{ width: 700, maxWidth: "100%" }}>
        <div style={{ fontWeight: 600, fontSize: 20 }}>New Inventory Item</div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <Input value={name} onChange={setName} placeholder="" label="Name of Item" />
            <DateSelect value={purchaseDate} onChange={setPurchaseDate} label="Purchase Date" />
            <Input value={manufacturer} onChange={setManufacturer} placeholder="" label="Manufacturer" />
            <Input value={model} onChange={setModel} placeholder="" label="Model" />
            <SingleSelect
              value={category}
              onChange={setCategory}
              options={inventoryCats}
              single={true}
              label="Category"
            />
          </div>
          <div className="col-12 col-lg-6">
            <Input
              value={replacementCost}
              onChange={setReplacementCost}
              placeholder="$"
              type="number"
              label="Replacement Cost"
            />
            <Input
              value={purchaseCost}
              onChange={setPurchaseCost}
              placeholder="$"
              type="number"
              label="Purchase Cost"
            />
            <Input value={serial} onChange={setSerial} placeholder="" label="Serial Number" />
            <Input value={location} onChange={setLocation} placeholder="" label="Location" />
            <Input
              value={replacementUrl}
              onChange={setReplacementUrl}
              placeholder=""
              label="Replacement Url"
              type="url"
            />
          </div>
        </div>
        <div className="row mt-2">
          {files?.map((m) => (
            <AttachmentItem key={m?.id} file={m} />
          ))}
        </div>
        <BrellyBtn onClick={() => setUploadModal(true)} className="mt-3" style={{ background: "#2D3C57" }}>
          Add Pictures
        </BrellyBtn>
        <BrellyBtn className="mt-2" onClick={handleAdd}>
          Save
        </BrellyBtn>
      </CenterModal>
      <AddFileModal
        open={uploadModal}
        onSave={handleFiles}
        handleClose={() => setUploadModal(false)}
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .csv, .xls, xlsx, .mov, .mp4, .ppt, .pptx, .txt, .svg"
      />
    </>
  );
};
