import {
  HomeIcon,
  CalendarIcon,
  PhotoIcon,
  BookIcon,
  PersonIcon,
  DocsIcon,
  CircleInfoIcon,
  DoubleCheckIcon,
  LogoutIcon,
  ClaimsIcon,
  MailIcon,
  EstimatesIcon,
} from "components/Icons";
import { useApp } from "context";
import ProPic from "components/ProPic";
import { useState, useRef, useEffect } from "react";
import { CenterModal } from "components/Modals";
import { useIntercom } from 'react-use-intercom';
import ImgTitleSub, { convertTitle } from "components/ImgTitleSub";
import { Transition } from 'react-transition-group';
import { Portal } from 'react-portal';
import { BsX } from 'react-icons/bs';
import { BrellyBtn } from 'components/Button';
import { useHistory } from "react-router-dom";

const defaultStyle = {
  transition: `all 300ms ease-in-out`,
  transform: "translate(110%, 0%)",
  position: 'fixed', right: 0, top: 0, width: '90%', maxWidth: 500, height: '100vh', background: '#0D72C5', zIndex: 100000
}

const transitionStyles = {
  entering: { transform: "translate(0%, 0%)" },
  entered:  { transform: "translate(0%, 0%)" },
  exiting:  { transform: "translate(110%, 0%)" },
  exited:  { transform: "translate(110%, 0%)" },
};

export const MobileMenu = ({ open, setOpen }) => {
  const [estimateModal, setEstimateModal] = useState(false);
  const { selectedMembership, selectedOrg } = useApp();
  const { user, handleLogout, tab, setTab } = useApp();
  const { show } = useIntercom();
  const nodeRef = useRef(null);

  const handleNav = (newTab) => {
    setTab(newTab)
    setOpen(false);
  }

  const handleEstimateModal = () => {
    setEstimateModal(true);
    setOpen(false);
  }

  return(
    <>
      {open && <div onClick={() => setOpen(false)} style={{ width: '100%', height: '100vh', background: 'rgba(0,0,0,.2)', left: 0, top: 0, zIndex: 10000, position: 'fixed' }} />}
      <Transition nodeRef={nodeRef} in={open} timeout={300}>
        {state => (
          <div ref={nodeRef} style={{ ...defaultStyle, ...transitionStyles[state] }} className='ps-3 pe-3 pb-3 pt-5'>
            <ImgTitleSub
              className="mt-2"
              img={selectedOrg?.logo?.downloadUrl}
              title={selectedOrg?.name}
              sub={selectedMembership?.role}
              proPicStyle={{ color: 'white', border: '1px solid #ffffff' }}
              style={{ cursor: "pointer", color: 'white', marginBottom: 32, marginLeft: 20 }}
            />
            <MenuBtn
              title="Home"
              value="home"
              tab={tab}
              setTab={handleNav}
              highlight={tab === "claim"}
            />
            <MenuBtn title="Claims" value="claims" tab={tab} setTab={handleNav} />
            <MenuBtn title="My Tasks" value="tasks" tab={tab} setTab={handleNav} />
            <MenuBtn
              title="My Calendar"
              value="calendar"
              tab={tab}
              setTab={handleNav}
            />
            <MenuBtn
              title="Contacts"
              value="contacts"
              tab={tab}
              setTab={handleNav}
            />
            <a
              onClick={() => setOpen(false)}
              href="https://brelly.com/claim-resources/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn menu-a"
            >
              <BookIcon
                style={{ marginRight: 12, marginTop: 5 }}
                stroke="#ffffff"
              />
              Brelly Resources
            </a>
            <div
              onClick={handleEstimateModal}
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn"
            >
              <EstimatesIcon style={{ marginRight: 12 }} stroke="#ffffff" />
              Brelly Estimates
            </div>
            <div
              style={{
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => setTab("profile")}
              className="js-ac dash-menu-btn"
            >
              <ProPic
                user={user}
                size={24}
                style={{ marginRight: 12, fontSize: 12, color: 'white', border: '1px solid #ffffff' }}
              />
              My Profile
            </div>
            <div
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
              onClick={show}
            >
              <CircleInfoIcon style={{ marginRight: 12 }} stroke="#ffffff" />
              Help
            </div>
            <div
              onClick={handleLogout}
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
            >
              <LogoutIcon style={{ marginRight: 12 }} stroke="#ffffff" />
              Logout
            </div>
          </div>
        )}
      </Transition>
      <EstimatingModal
        open={estimateModal}
        handleClose={() => setEstimateModal(false)}
      />
    </>
  )
}

const Menu = () => {
  const [estimateModal, setEstimateModal] = useState(false);
  const { user, handleLogout, tab, setTab, selectedMembership, searchParams } = useApp();
  const contentHeight = window.innerHeight - 90;
  const { show } = useIntercom();
  const orgSelected = selectedMembership?.id;
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [walkthrough, setWalkthough] = useState({});
  let walkthroughCompany = searchParams.get("walkthrough");

  useEffect(() => {
    if (searchParams.get("ownerwelcome") === 'true') {
      setWelcomeModal(true)
    } else if (walkthroughCompany?.length > 0) {
      setWalkthough({ open: true, section: 1, company: walkthroughCompany })
    }
    //eslint-disable-next-line
  }, [walkthroughCompany])

  return (
    <>
      <div style={{ width: 260, height: "100vh", paddingTop: 24 }} className='d-none d-lg-block'>
        <img
          alt=""
          style={{ height: 56, marginBottom: 24, marginLeft: 26 }}
          src="/assets/logos/logoAB.png"
        />
        <div
          style={{
            height: contentHeight,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            {orgSelected && (
              <>
                <MenuBtn
                  title="Home"
                  value="home"
                  tab={tab}
                  setTab={setTab}
                  highlight={tab === "claim"}
                />
                <MenuBtn title="Claims" value="claims" tab={tab} setTab={setTab} />
                <MenuBtn title="My Tasks" value="tasks" tab={tab} setTab={setTab} />
                <MenuBtn
                  title="My Calendar"
                  value="calendar"
                  tab={tab}
                  setTab={setTab}
                />
                <MenuBtn
                  title="Contacts"
                  value="contacts"
                  tab={tab}
                  setTab={setTab}
                />

                <div
                  style={{
                    marginTop: 12,
                    marginBottom: 12,
                    height: 46,
                    paddingLeft: 26,
                    position: "relative",
                    color: "#64666B",
                    fontFamily: "Lato",
                    fontSize: 18,
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  className="js-ac dash-menu-btn"
                >
                  <MailIcon style={{ marginRight: 12 }} stroke="#64666B" />
                  Inbox
                  <div style={{ position: 'absolute', left: 52, bottom: -4, fontSize: 12, fontWeight: 600, color: '#0D72C5', fontStyle: 'italic' }}>Coming Soon!</div>
                </div>
              </>
            )}

            <a
              href="https://brelly.com/claim-resources/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn menu-a"
            >
              <BookIcon
                style={{ marginRight: 12, marginTop: 5 }}
                stroke="#64666B"
              />
              Brelly Resources
            </a>
            <div
              onClick={() => setEstimateModal(true)}
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn"
            >
              <EstimatesIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Brelly Estimates
            </div>
          </div>
          <div style={{ paddingBottom: 24 }}>
            <div
              style={{
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => setTab("profile")}
              className="js-ac dash-menu-btn"
            >
              <ProPic
                user={user}
                size={24}
                img={user?.profilePic?.downloadUrl}
                style={{ marginRight: 12, fontSize: 12 }}
              />
              My Profile
            </div>
            <div
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                background: walkthrough?.section === 2 ? '#0D72C5' : '',
                color: walkthrough?.section === 2 ? 'white' : '#64666B'
              }}
              className="js-ac dash-menu-btn"
              onClick={show}
            >
              <CircleInfoIcon style={{ marginRight: 12 }} stroke={walkthrough?.section === 2 ? "white" : "#64666B"} />
              Help
            </div>
            <div
              onClick={handleLogout}
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
            >
              <LogoutIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Logout
            </div>
          </div>
        </div>
      </div>
      <EstimatingModal
        open={estimateModal}
        handleClose={() => setEstimateModal(false)}
      />
      <WelcomeModal open={welcomeModal} handleClose={() => setWelcomeModal(false)} />
      <WalkThroughModal walkthrough={walkthrough} setWalkthough={setWalkthough} />
    </>
  );
};

export default Menu;

const EstimatingModal = ({ open, handleClose }) => {
  return (
    <CenterModal open={open} handleClose={handleClose} dontExpand={true}>
      <h1 style={{ width: 300, textAlign: "center" }}>On the Way!</h1>
      <h3 style={{ width: 300, textAlign: "center" }}>
        Brelly is excited to announce that it will soon offer estimating
        services to Brelly Pro customers at a discount. Defensible, timely, and
        insurance-compliant estimates -- all just one click away.
      </h3>
    </CenterModal>
  );
};

const MenuBtn = ({ title, value, tab, setTab, highlight }) => {
  let selected = highlight || value === tab;
  let delay = selected ? 320 : 0;
  const width = window.innerWidth;
  const color = width > 991 ? selected ? "#2D3C57" : "#64666B" : '#ffffff';
  const selectedBackground = width > 991 ? "rgb(233, 245, 255)" : '';

  const icons = {
    home: (
      <HomeIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    inbox: (
      <MailIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    claims: (
      <ClaimsIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    tasks: (
      <DoubleCheckIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    contacts: (
      <PersonIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    docs: (
      <DocsIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    pics: (
      <PhotoIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    estimates: (
      <EstimatesIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    calendar: (
      <CalendarIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
  };

  return (
    <div
      onClick={() => setTab(value)}
      style={{
        marginTop: 12,
        marginBottom: 12,
        height: 46,
        paddingLeft: 26,
        position: "relative",
        color: color,
        fontFamily: "Lato",
        fontSize: 18,
        cursor: "pointer",
        background: selected ? selectedBackground : "",
        fontWeight: 500,
        transition: `all 200ms ease ${delay}ms`,
      }}
      className="js-ac dash-menu-btn"
    >
      {icons[value]} {title}
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          width: 4,
          height: 46,
          borderRadius: "42px 0px 0px 42px",
          background: selected ? "#0D72C5" : "",
          transition: `all 260ms ease ${delay}ms`,
        }}
      />
    </div>
  );
};

const WelcomeModal = ({ open, handleClose }) => {
  const history = useHistory();
  const { searchParams } = useApp();

  const handleDone = () => {
    handleClose()
    searchParams.delete('ownerwelcome')
    history.replace({ search: searchParams.toString() });
  }

  if (open) {
    return(
      <Portal>
        <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)' }} onClick={handleDone} />
        <div style={{ position: 'fixed', background: 'white', padding: 18, zIndex: 1001, borderRadius: 18, width: 400, maxWdith: '90%', right: 20, top: 20 }} className='shadow'>
          <div onClick={handleDone}><BsX style={{ position: 'absolute', top: 12, right: 12, color: 'black', fontSize: 18 }} /></div>
          <Wm2 handleDone={handleDone} />
        </div>
      </Portal>
    )
  } else {
    return null;
  }
}

// const Wm1 = ({ setPage }) => {
//   return(
//     <>
//       <h1 style={{ textAlign: "center" }}>Welcome to Brelly!</h1>
//       <img alt='' src='/assets/imgs/welcome.png' style={{ width: 300 }} className='mt-3 mb-3' />
//       <BrellyBtn style={{ width: '100%' }} onClick={() => setPage(2)}>
//         Continue
//       </BrellyBtn>
//     </>
//   )
// }

const Wm2 = ({ handleDone }) => {
  return(
    <>
      <h1 style={{ textAlign: "center" }}>Welcome to Brelly!</h1>
      <div className='mt-3 mb-3' style={{ textAlign: 'center' }}>To get started, create a new company or join an existing by accepting an invitation <br />(if available)</div>
      <BrellyBtn style={{ width: '100%' }} onClick={handleDone}>
        Continue
      </BrellyBtn>
    </>
  )
}

// const Wm3 = ({ setPage,  handleDone }) => {
//   return(
//     <>
//       <h1 style={{ textAlign: "center" }}>Welcome to Brelly!</h1>
//       <div className='mt-3 mb-3' style={{ textAlign: 'center' }}>If you need help, simply click on the help button here and chat with a Brelly team member for assistance.</div>
//       <BrellyBtn style={{ width: '100%' }} onClick={handleDone}>
//         Done
//       </BrellyBtn>
//     </>
//   )
// }

const WalkThroughModal = ({ walkthrough, setWalkthough }) => {
  const open = walkthrough?.open;
  const section = walkthrough?.section;
  const history = useHistory();
  const { searchParams } = useApp();

  const handleUpdate = (changes = {}) => {
    setWalkthough({ ...walkthrough, ...changes })
  }

  const getPos = () => {
    if (section === 1) {
      return { left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }
    } else if (section === 2) {
      return { left: 200, bottom: 80 }
    }
  }

  const handleDone = () => {
    setWalkthough({})
    searchParams.delete('walkthrough')
    history.replace({ search: searchParams.toString() });
  }

  if (open) {
    return(
      <Portal>
        <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)' }} onClick={handleDone} />
        <div style={{ position: 'fixed', background: 'white', padding: 18, zIndex: 1001, borderRadius: 18, width: 400, ...getPos(), transition: 'all 200ms linear' }} className='shadow'>
          <div onClick={handleDone}><BsX style={{ position: 'absolute', top: 12, right: 12, color: 'black', fontSize: 18 }} /></div>
          {section === 1 && <Wt1 walkthrough={walkthrough} handleUpdate={handleUpdate} />}
          {section === 2 && <Wt2 handleDone={handleDone} />}
        </div>
      </Portal>
    )
  } else {
    return null;
  }
}

const Wt1 = ({ walkthrough, handleUpdate }) => {
  const { selectedOrg } = useApp();

  return(
    <>
      <h1 style={{ textAlign: "center", marginTop: 18 }}>Welcome to <span style={{ textTransform: 'capitalize' }}>{walkthrough?.company}!</span></h1>
      <div className='jc-ac'>
        <ProPic
          size={64}
          style={{ fontSize: 32, marginTop: 12, marginBottom: 24 }}
          img={selectedOrg?.logo?.downloadUrl}
          user={convertTitle(selectedOrg?.name)}
        />
      </div>
      <BrellyBtn style={{ width: '100%' }} onClick={() => handleUpdate({ section: 2 })}>
        Continue
      </BrellyBtn>
    </>
  )
}

const Wt2 = ({ handleDone }) => {
  return(
    <>
      <h1 style={{ textAlign: "center" }}>Need Help?</h1>
      <div style={{ textAlign: "center" }}>If you need help, simply click on the help button here and chat with a Brelly team member for assistance.</div>
      <BrellyBtn style={{ width: '100%' }} className="mt-3" onClick={handleDone}>
        Done
      </BrellyBtn>
    </>
  )
}
