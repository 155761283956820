import { DropdownSearchBar } from "components/SearchBar";
import { MultiSelect } from 'components/Select';
import { useState, useEffect } from "react";
import brelly from "@brelly/sdk";
import { useApp } from "context";

const TeammemberSelector = ({ value = [], onChange = () => {}, label = "Assign Users" }) => {
  const { orgId } = useApp();
  const [team, setTeam] = useState([]);
  const memberOptions = team?.map((tm, idx) => ({ label: `${tm?.user?.firstName} ${tm?.user?.lastName}`, value: tm?.id }));

  const handleTeam = async () => {
    if (orgId) {
      try {
        let { data } = await brelly.organizations.getMemberships(orgId);
        setTeam(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    handleTeam();
    //eslint-disable-next-line
  }, [orgId])

  return(
    <DropdownSearchBar
      label={label}
      value={value}
      single={false}
      onChange={onChange}
      options={memberOptions}
      style={{ width: '100%' }}
    />
  )
}

export default TeammemberSelector;

export const InlineTeammemberSelector = ({ value = [], onChange = () => {}, label = "Assign Users" }) => {
  const { orgId } = useApp();
  const [team, setTeam] = useState([]);
  const memberOptions = team?.map((tm, idx) => ({ label: `${tm?.user?.firstName} ${tm?.user?.lastName}`, value: tm?.id }));

  const handleTeam = async () => {
    if (orgId) {
      try {
        let { data } = await brelly.organizations.getMemberships(orgId);
        setTeam(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    handleTeam()
    //eslint-disable-next-line
  }, [orgId])

  return(
    <MultiSelect
      label={label}
      value={value}
      single={false}
      onChange={onChange}
      options={memberOptions}
      style={{ width: '100%' }}
    />
  )
}
