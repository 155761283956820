// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface OrganizationsInterface {
  search: (name: string, query?: Query) => Promise<any>;
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  uploadLogo: (id: string, file: any, query?: Query) => Promise<any>;
  uploadCoverPhoto: (id: string, file: any, query?: Query) => Promise<any>;
  getMemberships: (id: string, query?: Query) => Promise<any>;
  getMembershipInvites: (id: string, query?: Query) => Promise<any>;
  getPendingMembershipInvites: (id: string, query?: Query) => Promise<any>;
  getProjects: (id: string, query?: Query) => Promise<any>;
  getContacts: (id: string, query?: Query) => Promise<any>;
  getWorkfows: (id: string, query?: Query) => Promise<any>;
}

export const organizations = (client: Axios): OrganizationsInterface => ({
  search: async (name: string, query?: Query) => {
    const url = addUrlQuery(`/organizations?name=${name}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/organizations/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/organizations", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/organizations/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/organizations/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  uploadLogo: async (id: string, file: any, query?: Query) => {
    const url = addUrlQuery(`organizations/${id}/logo`, query);
    const formData = new FormData();
    formData.append("file", file);

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  uploadCoverPhoto: async (id: string, file: any, query?: Query) => {
    const url = addUrlQuery(`organizations/${id}/cover-photo`, query);
    const formData = new FormData();
    formData.append("file", file);

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  // *** Relationship Queries ***

  getMemberships: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/organizations/${id}/memberships`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getMembershipInvites: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/organizations/${id}/membership-invites`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getPendingMembershipInvites: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/organizations/${id}/membership-invites`, {
      ...query,
      filter: { ...(query?.filter || {}), respondedAt: null, canceledAt: null },
    });
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getProjects: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/organizations/${id}/projects`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getContacts: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/organizations/${id}/contacts`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getWorkfows: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/organizations/${id}/workflows`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },
});
