import { useState } from 'react';
import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { format } from 'date-fns';
import ProPic from 'components/ProPic';
import { getClaimDisplay } from 'utils';
import { Status } from 'components/TaskStatus';
import { CenterModal } from 'components/Modals';
import { EditTaskModal} from 'modals';
import { AttachmentItem } from 'items';
import { TaskPriority } from 'components/Priority';
import { ConfirmDeleteModal } from 'modals';

export const ViewTaskModal = ({ task = {}, open, handleClose, handleUpdate }) => {
  if (open) {
    return <Inner task={task} open={open} handleClose={handleClose} handleUpdate={handleUpdate} />
  } else {
    return;
  }
}

const Inner = ({ task = {}, open, handleClose, handleUpdate }) => {
  const [confirmfirmDeleteModal, setConfirmfirmDeleteModal] = useState(false);
  const { phases, projects, setProject, setLoading, setError } = useApp()
  let date = task?.endDate ? format(new Date(task?.endDate), 'MMM dd, yyyy') : ""
  const [editTaskModal, setEditTaskModal] = useState(false);
  const taskPhase = phases?.find((p) => p?.id === task?.phaseId);
  const claimDisplay = getClaimDisplay(task?.projectId, projects)

  const handleProject = () => {
    handleClose()
    setProject(task?.projectId)
  }

  const handleDelete = async () => {
    try {
      setLoading(true);
      setConfirmfirmDeleteModal(false)
      await brelly.tasks.delete(task?.id);
      await handleUpdate()
      handleClose()
      setLoading(false);
    } catch(err) {
      setError(err);
      setLoading(false);
    }
  }

  return(
    <>
      <CenterModal open={open} handleClose={handleClose}>
        <div style={{ width: 500 }}>
          <span style={{ fontSize: 12 }}>Task Details</span>
          <div style={{ fontWeight: 600, fontSize: 20 }} className='jb-ac'>
            <div className='js-ac'>
              <TaskPriority style={{ width: 30 }} iconStyle={{ fontSize: 16 }} className='js-ac' task={task} handleUpdate={handleUpdate} />
              {task?.name}
            </div>
            <div className='je-ac'>
              <div className='brelly-text-btn me-3' onClick={() => setEditTaskModal(true)} style={{ color: '#0D72C5' }}>Edit</div>
              <div className='brelly-text-btn' onClick={() => setConfirmfirmDeleteModal(true)} style={{ color: 'red' }}>Delete</div>
            </div>
          </div>
          <div className='js-ac mt-4'>
            <div style={{ width: 200 }}>Status:</div>
            <div>
              <Status task={task} handleUpdate={handleUpdate} />
            </div>
          </div>

          <div className='js-ac mt-2'>
            <div style={{ width: 200 }}>Associated Claim:</div>
            <div>
              <div onClick={handleProject} className='truncate1 brelly-text-btn' style={{ fontWeight: 500 }}>{claimDisplay}</div>
            </div>
          </div>

          <div className='js-ac mt-2'>
            <div style={{ width: 200 }}>Associated Claim Phase:</div>
            <div>
              <div style={{ fontWeight: 500 }}>{taskPhase?.name || "No Phase"}</div>
            </div>
          </div>

          <div className='js-ac mt-2'>
            <div style={{ width: 200 }}>Due Date:</div>
            <div style={{ fontWeight: 500 }}>{date || "None"}</div>
          </div>

          <Creator task={task} />

          <div className='js-ac mt-2'>
            <div style={{ width: 200 }}>Created At:</div>
            <div>
              <div className='truncate1' style={{ fontWeight: 500 }}>{format(new Date(task?.createdAt), 'MM/dd/yyyy - hh:mm aaa')}</div>
            </div>
          </div>

          {task?.assignees?.length > 0 && (
            <div className='js-ac mt-2'>
              <div style={{ width: 200 }}>Assignees:</div>
              <div className='js-ac'>
                {task?.assignees?.map((user, key) => (
                  <ProPic user={user?.user} key={key} size={24} className='me-1' style={{ fontSize: 14 }} />
                ))}
              </div>
            </div>
          )}
          {task?.description && (
            <div className='mt-4'>
              <div style={{ fontWeight: 600 }}>Description:</div>
              <div className='mt-1' style={{ lineHeight: 1.2 }}>
                {task?.description}
              </div>
            </div>
          )}
          <div className='mt-4'>
            {task?.files?.length > 0 && <div style={{ fontWeight: 600 }}>Attachments:</div>}
            <div className='row'>
              {task?.files?.map((tf, key) => (
                <AttachmentItem file={tf} key={key} />
              ))}
            </div>
          </div>
        </div>
      </CenterModal>
      <EditTaskModal task={task} open={editTaskModal} handleClose={() => setEditTaskModal(false)} handleUpdate={handleUpdate} />
      <ConfirmDeleteModal open={confirmfirmDeleteModal} handleClose={() => setConfirmfirmDeleteModal(false)} onDelete={handleDelete} />
    </>
  )
}

const Creator = ({ task }) => {
  if (task?.creator) {
    return(
      <div className='js-ac mt-2'>
        <div style={{ width: 200 }}>Creator:</div>
        <div>
          <div className='truncate1 js-ac' style={{ fontWeight: 500 }}>
            <ProPic user={task?.creator} size={24} className='me-1' style={{ fontSize: 14 }} />
            {task?.creator?.firstName} {task?.creator?.lastName}
          </div>
        </div>
      </div>
    )
  } else if (task?.phaseId) {
    return (
      <div className='js-ac mt-2'>
        <div style={{ width: 200 }}>Creator:</div>
        <div>
          <div className='truncate1 js-ac' style={{ fontWeight: 500 }}>
            Workflow
          </div>
        </div>
      </div>
    )
  } else {
    return;
  }
}
