import { DetailItem, EmptyState } from 'pages/Dash/Claims/Claim/Details/components';
import { AddDeductiblesModal, EditDeductiblesModal } from 'modals';
import { useProject } from "pages/Dash/Claims/Claim/context";
import { formatCurrency } from "utils";
import { useState } from "react";

export const Deductibles = () => {
  const { deductibles, policy } = useProject();
  const [addDeductiblesModal, setAddDeductiblesModal] = useState(false);
  const [editDeductiblesModal, setEditDeductiblesModal] = useState(false);
  const sortedDeductibles = deductibles?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <>
      <div
        className="mt-3 brelly-content ps-3 pe-3 pt-2 pb-3"
        style={{ borderRadius: 8 }}
      >
        <div className="jb-ac" style={{ width: "100%", marginBottom: 14 }}>
          <div className="dash-box-title">Deductibles</div>
          <div className="je-ac">
            <div
              className="brelly-text-btn"
              onClick={() => setEditDeductiblesModal(true)}
              style={{ color: "#0D72C5" }}
            >
              Edit
            </div>
            <div
              className="brelly-text-btn ms-3"
              onClick={() => setAddDeductiblesModal(true)}
              style={{ color: "#0D72C5" }}
            >
              Add
            </div>
          </div>
        </div>
        {sortedDeductibles?.map((c, key) => (
          <DetailItem
            key={key}
            title={c?.name}
            value={formatCurrency(c?.deductible)}
          />
        ))}
        <EmptyState
          type="Deductibles"
          cb={setAddDeductiblesModal}
          arr={deductibles}
        />
      </div>
      <AddDeductiblesModal
        open={addDeductiblesModal}
        policy={policy}
        handleClose={() => setAddDeductiblesModal(false)}
      />
      <EditDeductiblesModal
        open={editDeductiblesModal}
        policy={policy}
        handleClose={() => setEditDeductiblesModal(false)}
      />
    </>
  );
};
