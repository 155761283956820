import { useState } from "react";
import Input from "components/Input";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { useProject } from "pages/Dash/Claims/Claim/context";
import brelly from "@brelly/sdk";
import { useApp } from "context";
import { v4 as uuidV4 } from 'uuid';
import { currentToFloat } from 'utils';
import { CustomField } from '../../components';
import MortgageCompanySelect from "components/MortgageCompanySelect";

export const AddMortgageCompany = ({ open, property, handleClose }) => {
  const { handleClaim } = useProject()
  const { setLoading, setError } = useApp();
  const [loanNumber, setLoanNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [lender, setLender] = useState({})
  const [additional, setAdditional] = useState({ entries: [] });
  const sortedAdditional = additional?.entries?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) || [];

  const addAdditional = () => {
    setAdditional({ entries: [...additional?.entries, { key: '', value: '', id: uuidV4(), createdAt: new Date() }] })
  }

  const handleAdd = async () => {
    try {
      setLoading(true);
      await brelly.mortgages.create({
        propertyId: property?.id,
        loanNumber,
        amount: currentToFloat(amount),
        lenderId: lender?.id,
        additionalInfo: additional
      });
      handleClaim(false)
      setLoading(false);
      handleClose();
    } catch (err) {
      setError(err?.message);
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add Mortgage</div>
      <MortgageCompanySelect value={lender} onSelect={setLender} />
      <Input label="Loan Number" value={loanNumber} onChange={setLoanNumber} />
      <Input label="Amount" value={amount} onChange={setAmount} type='number' placeholder="$" />
      {sortedAdditional?.length > 0 && (
        <>
          <div className='brelly-text-btn mt-3'>Custom</div>
          {sortedAdditional?.map((entry) => (
            <CustomField key={entry?.id} entry={entry} additional={additional} setAdditional={setAdditional} />
          ))}
        </>
      )}
      <div className='mt-3 jc-ac'>
        <div onClick={addAdditional} className='brelly-text-btn'>Add Custom</div>
      </div>
      <BrellyBtn onClick={handleAdd} className="mt-3" style={{ width: 400, maxWidth: '100%' }}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
