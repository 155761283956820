import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';

export const ConfirmDeleteModal = ({
  title = 'Are You Sure?',
  subTitle = 'This cannot be undone',
  message,
  open,
  handleClose,
  onDelete,
  onCancel
}) => {
  const handleDismiss = (e) => {
    onCancel && onCancel();
    handleClose(e);
  }

  const handleDelete = (e) => {
    onDelete && onDelete();
    handleClose(e);
  }

  return(
    <CenterModal open={open} handleClose={handleDismiss}>
      <div style={{ width: 460 }}>
        <div style={{ fontWeight: 600, fontSize: 20 }}>{title}</div>
        <div className='mt-2'>{subTitle}</div>
        <div className='row mt-3'>
          <div className='col-12 col-md-6'>
            <BrellyBtn style={{ color: '#0D72C5', background: 'transparent', boxShadow: 'none' }} onClick={handleDismiss}>No, Go Back</BrellyBtn>
          </div>
          <div className='col-12 col-md-6'>
            <BrellyBtn style={{ background: 'red' }} onClick={handleDelete}>Yes, Delete It</BrellyBtn>
          </div>
        </div>
      </div>
    </CenterModal>
  )
}
