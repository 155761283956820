// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface InventoryItemsInterface {
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  getLossItems: (id: string, query?: Query) => Promise<any>;
  getFiles: (id: string, query?: Query) => Promise<any>;
  uploadFiles: (id: string, files: any[], query?: Query) => Promise<any>;
  setFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  addFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  removeFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
}

export const inventoryItems = (client: Axios): InventoryItemsInterface => ({
  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/inventory-items/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/inventory-items", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/inventory-items/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/inventory-items/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  // *** Relationship Queries ***

  getLossItems: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/inventory-items/${id}/loss-items`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getFiles: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/inventory-items/${id}/files`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  uploadFiles: async (id: string, files: any[], query?: Query) => {
    const url = addUrlQuery(`inventory-items/${id}/files`, query);
    const formData = new FormData();
    if (Array.isArray(files)) {
      files.forEach((file) => formData.append("files", file));
    }

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  setFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/inventory-items/${id}/files/set`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  addFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/inventory-items/${id}/files/add`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  removeFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/inventory-items/${id}/files/remove`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },
});
