import { useState } from 'react';
import { format } from 'date-fns';
import ProPic from 'components/ProPic';
import { Status } from 'components/TaskStatus';
import { CenterModal } from 'components/Modals';
import { EditTaskModal} from 'modals';
import { AttachmentItem } from 'items';
import { TaskPriority } from 'components/Priority';

export const ViewTaskModal = ({ task = {}, open, handleClose }) => {
  let date = task?.endDate ? format(new Date(task?.endDate), 'MMM dd, yyyy') : "";
  const status = task?.status

  return(
    <>
      <CenterModal open={open} handleClose={handleClose}>
        <div style={{ width: 500 }}>
          <span style={{ fontSize: 12 }}>Task Details</span>
          <div style={{ fontWeight: 600, fontSize: 20 }} className='jb-ac'>
            {task?.name}
          </div>
          <div className='js-ac mt-4'>
            <div style={{ width: 200 }}>Status:</div>
            <div style={{ paddingLeft: 12, paddingRight: 12, height: 24, cursor: 'pointer', borderRadius: 24, background: status === 'To Do' ? 'red' : status === 'Complete' ? 'green' : 'blue', color: 'white', fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }} className='jc-ac'>
              {status?.toLowerCase()}
            </div>
          </div>
          <div className='js-ac mt-2'>
            <div style={{ width: 200 }}>Due Date:</div>
            <div style={{ fontWeight: 500 }}>{date}</div>
          </div>
          {task?.assignees?.length > 0 && (
            <div className='js-ac mt-2'>
              <div style={{ width: 200 }}>Assignees:</div>
              <div className='js-ac'>
                {task?.assignees?.map((user, key) => (
                  <ProPic user={user?.user} key={key} size={24} className='me-1' style={{ fontSize: 14 }} />
                ))}
              </div>
            </div>
          )}
          {task?.description && (
            <div className='mt-4'>
              <div style={{ fontWeight: 600 }}>Description:</div>
              <div className='mt-1' style={{ lineHeight: 1.2 }}>
                {task?.description}
              </div>
            </div>
          )}
          <div className='mt-4'>
            {task?.files?.length > 0 && <div style={{ fontWeight: 600 }}>Attachments:</div>}
            <div className='row'>
              {task?.files?.map((tf, key) => (
                <AttachmentItem file={tf} key={key} />
              ))}
            </div>
          </div>
        </div>
      </CenterModal>
    </>
  )
}
