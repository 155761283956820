import AddTimeline from './Add';
import { format } from 'date-fns';
import TimelineItem from './Items';
import { useProject } from '../context';
import SearchBar from 'components/SearchBar';
import { BrellyBtn } from 'components/Button';
import { useState, useRef, useEffect } from 'react';
import { TypeFilterModal, DateFilterModal, getFiltered } from './Filter';
import { TimelineIcon, SortIcon, CalendarIcon, EventTypeIcon } from 'components/Icons';
import { useApp } from 'context';

const TimelineBox = () => {
  const { claimTab } = useApp();
  const typeFilterBtnRef = useRef(null);
  const dateFilterBtnRef = useRef(null);
  const [addEvent, setAddEvent] = useState(false);
  const { timeline, handleTimeline, claim } = useProject();
  const [dateFilterModal, setDateFilterModal] = useState(false);
  const [typeFilterModal, setTypeFilterModal] = useState(false);
  const [filters, setFilters] = useState({ type: [], searchVal: '', sort: 'latest' });
  const filteredTimeline = getFiltered(filters, timeline)
  const claimId = claim?.id;
  const isOpen = claimTab === "timeline"

  const handeFilterUpdate = (changes = {}) => {
    setFilters({ ...filters, ...changes })
  }

  const handleReverse = () => {
    if (filters?.sort === 'soonest') {
      handeFilterUpdate({ sort: 'latest' })
    } else {
      handeFilterUpdate({ sort: 'soonest' })
    }
  }

  useEffect(() => {
    if (claimId) {
      handleTimeline(claimId)
    }
    //eslint-disable-next-line
  }, [claimId, isOpen])

  const getDaysEvents = (day) => {
    return day?.values
  }

  // <div className='brelly-text-btn me-4'>
  //   <PrintIcon style={{ marginRight: 1 }} />
  //   Print
  // </div>

  return(
    <>
      <div className='row'>
        <div className='col-12 mb-3'>
          <div className='dash-box jb-ac'>
            <div className='dash-box-title'>
              <TimelineIcon style={{ marginRight: 6 }} stroke='#0D72C5' />
              Claim Timeline
            </div>
            <div className='je-ac d-none d-lg-flex'>
              <BrellyBtn onClick={() => setAddEvent(!addEvent)}>Add New Entry</BrellyBtn>
            </div>
            <div className='je-ac d-lg-none'>
              <div className='brelly-text-btn' onClick={() => setAddEvent(!addEvent)}>Add New</div>
            </div>
          </div>
        </div>
        {addEvent && <AddTimeline setAddEvent={setAddEvent} />}
        <div className='col-12'>
          <div className='dash-box'>
            <div className='jb-ac d-none d-lg-flex' style={{ marginBottom: 24 }}>
              <div className='je-ac'>
                <SearchBar className='' style={{ width: 300 }} placeholder='Search Timeline' value={filters?.searchVal} onChange={(v) => handeFilterUpdate({ searchVal: v })} />
                <div className='brelly-text-btn ms-4' onClick={handleReverse}>
                  <SortIcon size={20} style={{ marginRight: 2 }} />
                  Reverse
                </div>
              </div>
              <div className='je-ac'>
                <div className='brelly-text-btn me-4' ref={typeFilterBtnRef} onClick={() => setTypeFilterModal(true)}>
                  <EventTypeIcon style={{ marginRight: 2, marginTop: 1 }} size={20} />
                  Entry Type
                </div>
                <div className='brelly-text-btn' ref={dateFilterBtnRef} onClick={() => setDateFilterModal(true)}>
                  <CalendarIcon style={{ marginRight: 4, marginBottom: 3 }} size={18} />
                  Dates
                </div>
              </div>
            </div>
            {filteredTimeline.map((taskDay, key) => (
              <div key={key} className='mt-1'>
                <div className='row'>
                  <div className='col-2 col-lg-1 jc-ac' style={{ paddingLeft: 0, paddingRight: 0, flexDirection: 'column' }}>
                    <div className='dash-table-item' style={{ color: '#0D72C5', paddingRight: 0 }}>{format(new Date(taskDay?.type), 'MM/dd/yy')}</div>
                    <ItemSpacer />
                  </div>
                </div>
                {getDaysEvents(taskDay)?.map((task, idx) => <TimelineItem key={idx} task={task} last={getDaysEvents(taskDay)?.length === idx + 1} handleTimeline={handleTimeline} />)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <DateFilterModal
        filters={filters}
        dateFilterModal={dateFilterModal}
        dateFilterBtnRef={dateFilterBtnRef}
        handeFilterUpdate={handeFilterUpdate}
        setDateFilterModal={setDateFilterModal}
      />
      <TypeFilterModal
        filters={filters}
        typeFilterModal={typeFilterModal}
        typeFilterBtnRef={typeFilterBtnRef}
        handeFilterUpdate={handeFilterUpdate}
        setTypeFilterModal={setTypeFilterModal}
      />
    </>
  )
}

export default TimelineBox;

export const ItemSpacer = ({ top, task, last }) => {
  let hide = !top && last;
  let width = window.innerWidth;
  let size = width > 991 ? 14 : 34;

  return(
    <div style={{ width: 1, height: top ? 3 : size, marginTop: top ? 1 : 3, marginBottom: top ? 2 : 0, borderLeft: hide ? '' : '1px dotted #0D72C5' }} />
  )
}
