import { groupArrayItems } from 'utils';
import { DateSelect } from 'components/Select';
import { InlineModal } from 'components/Modals';
import { isAfter, isBefore, format } from 'date-fns';

export const DateFilterModal = ({ filters, dateFilterBtnRef, dateFilterModal, setDateFilterModal, handeFilterUpdate }) => {
  return(
    <InlineModal btnRef={dateFilterBtnRef} open={dateFilterModal} handleClose={() => setDateFilterModal(false)}>
      <DateSelect label='Start Date' style={{ minWidth: 240 }} value={filters?.startDate} onChange={(v) => handeFilterUpdate({ startDate: v })} />
      <DateSelect label='End Date' style={{ minWidth: 240 }} value={filters?.endDate} onChange={(v) => handeFilterUpdate({ endDate: v })} />
    </InlineModal>
  )
}

export const TypeFilterModal = ({ filters, typeFilterBtnRef, typeFilterModal, setTypeFilterModal, handeFilterUpdate }) => {
  return(
    <InlineModal btnRef={typeFilterBtnRef} open={typeFilterModal} handleClose={() => setTypeFilterModal(false)}>
      <div className={`dash-table-item js-ac mb-2`} onClick={() => handeFilterUpdate({ type: 'All' })} style={{ fontWeight: 600, cursor: 'pointer', minWidth: 120 }}>
        <div style={{ width: 20, height: 20, borderRadius: 4, border: '1px solid #0D72C5', marginRight: 8, padding: 2 }}>
          {filters?.type === 'All' && <div style={{ width: '100%', height: '100%', background: '#0D72C5', borderRadius: 3 }} />}
        </div>
        All
      </div>
      <div className={`dash-table-item js-ac mb-2`} onClick={() => handeFilterUpdate({ type: 'Event' })} style={{ fontWeight: 600, cursor: 'pointer', minWidth: 120 }}>
        <div style={{ width: 20, height: 20, borderRadius: 4, border: '1px solid #0D72C5', marginRight: 8, padding: 2 }}>
          {filters?.type === 'Event' && <div style={{ width: '100%', height: '100%', background: '#0D72C5', borderRadius: 3 }} />}
        </div>
        Event
      </div>
      <div className={`dash-table-item js-ac mb-2`} onClick={() => handeFilterUpdate({ type: 'Email' })} style={{ fontWeight: 600, cursor: 'pointer', minWidth: 120 }}>
        <div style={{ width: 20, height: 20, borderRadius: 4, border: '1px solid #0D72C5', marginRight: 8, padding: 2 }}>
          {filters?.type === 'Email' && <div style={{ width: '100%', height: '100%', background: '#0D72C5', borderRadius: 3 }} />}
        </div>
        Email
      </div>
      <div className={`dash-table-item js-ac mb-2`} onClick={() => handeFilterUpdate({ type: 'Call' })} style={{ fontWeight: 600, cursor: 'pointer', minWidth: 120 }}>
        <div style={{ width: 20, height: 20, borderRadius: 4, border: '1px solid #0D72C5', marginRight: 8, padding: 2 }}>
          {filters?.type === 'Call' && <div style={{ width: '100%', height: '100%', background: '#0D72C5', borderRadius: 3 }} />}
        </div>
        Call
      </div>
      <div className={`dash-table-item js-ac`} onClick={() => handeFilterUpdate({ type: 'Task' })} style={{ fontWeight: 600, cursor: 'pointer', minWidth: 120 }}>
        <div style={{ width: 20, height: 20, borderRadius: 4, border: '1px solid #0D72C5', marginRight: 8, padding: 2 }}>
          {filters?.type === 'Task' && <div style={{ width: '100%', height: '100%', background: '#0D72C5', borderRadius: 3 }} />}
        </div>
        Task
      </div>
    </InlineModal>
  )
}

const createSearchStr = (task) => {
  let name = task?.name || "";
  let body = task?.body || "";
  let topic = task?.topic || "";
  let subject = task?.subject || "";
  let description = task?.description || "";
  let str = `${name} ${body} ${topic} ${subject} ${description}`
  return str?.toLowerCase()
}

export const getFiltered = (filters, timeline) => {
  let formatted = timeline?.map((t) => ({ ...t, compareDate: t?.completedAt || t?.sentAt || t?.occuredAt || t?.endDate || t?.createdAt }))
  let _timeline = formatted;

  if (filters?.startDate) {
    _timeline = _timeline?.filter((t) => isAfter(new Date(t?.compareDate), new Date(filters?.startDate)))
  }

  if (filters?.endDate) {
    _timeline = _timeline?.filter((t) => isBefore(new Date(t?.compareDate), new Date(filters?.endDate)))
  }

  if (filters?.type === 'Event') {
    _timeline = _timeline?.filter((t) => t?.endDate)
  }

  if (filters?.type === 'Email') {
    _timeline = _timeline?.filter((t) => t?.sentAt)
  }

  if (filters?.type === 'Call') {
    _timeline = _timeline?.filter((t) => t?.occuredAt)
  }

  if (filters?.type === 'Task') {
    _timeline = _timeline?.filter((t) => t?.completedAt)
  }

  if (filters?.searchVal?.length > 0) {
    let formatted = _timeline?.map((t) => ({ ...t, searchStr: createSearchStr(t) }))
    _timeline = formatted?.filter((t) => t?.searchStr?.includes(filters?.searchVal?.toLowerCase()))
  }

  const formattedTimeline = _timeline?.map((t) => ({ ...t, date: format(new Date(t?.createdAt), 'MM/dd/yyyy') }))
  let groupedEvents = groupArrayItems(formattedTimeline, 'date');

  if (filters.sort === 'latest') {
    groupedEvents = groupedEvents?.sort((a, b) => new Date(a.date) - new Date(b.date))
  } else {
    groupedEvents = groupedEvents?.sort((a, b) => new Date(b.date) - new Date(a.date))
  }

  return groupedEvents
}
