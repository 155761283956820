import brelly from "@brelly/sdk";
import { useApp } from "context";
import Input from "components/Input";
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { SingleSelect } from "components/Select";
import LocationPicker from "components/LocationPicker2";
import TeammemberSelector from "components/TeammemberSelector";

export const AddClaimModal = ({ open, handleClose, setShowOnboarding = () => {} }) => {
  const [progress, setProgress] = useState({ screen: 1 });
  const currentScreen = progress?.screen;

  useEffect(() => {
    setProgress({ screen: 1 });
  }, [open]);

  const handleProgress = (changes = {}) => {
    setProgress({ ...progress, ...changes });
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div className="jc-ac ps-5 pe-5 pt-4 pb-4" style={{ width: 600, maxWidth: "100%" }}>
        {currentScreen === 1 && <AddClaimScreen1 handleProgress={handleProgress} />}
        {currentScreen === 2 && <AddClaimScreen2 progress={progress} handleProgress={handleProgress} />}
        {currentScreen === 3 && <AddClaimScreen3 progress={progress} handleProgress={handleProgress} />}
        {currentScreen === 4 && <AddClaimScreen4 progress={progress} handleProgress={handleProgress} />}
        {currentScreen === 5 && (
          <AddClaimScreen5
            progress={progress}
            handleClose={handleClose}
            setShowOnboarding={setShowOnboarding}
          />
        )}
      </div>
    </CenterModal>
  );
};

const AddClaimScreen2 = ({ progress, handleProgress }) => {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const nextScreen = progress?.createSubresources ? 3 : 4;

  const handleNext = () => {
    handleProgress({
      submitValues: {
        policyholder: {
          firstName: fName,
          lastName: lName,
          email: email,
          primaryEmail: email,
        },
      },
      screen: nextScreen,
    });
  };

  return (
    <div>
      <div className="mt-2 mb-4" style={{ textAlign: "center", fontWeight: 600, fontSize: 18 }}>
        What is the primary policyholder’s name and email?
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <Input value={fName} onChange={setFName} label="First Name" />
        </div>
        <div className="col-12 col-lg-6">
          <Input value={lName} onChange={setLName} label="Last Name" />
        </div>
        <div className="col-12">
          <Input value={email} onChange={setEmail} label="Email" />
        </div>
      </div>
      <BrellyBtn className="mt-4" style={{ width: "100%" }} onClick={handleNext}>
        Next
      </BrellyBtn>
    </div>
  );
};

const AddClaimScreen3 = ({ progress, handleProgress }) => {
  const { workflows } = useApp();
  const [propertyAddress, setPropertyAddress] = useState("");


  const handleNext = () => {
      handleProgress({
        address: propertyAddress,
        screen: 4
      });
    }

  return (
    <div style={{ width: "100%" }}>
      <div className="mt-2 mb-4" style={{ textAlign: "center", fontWeight: 600, fontSize: 18 }}>
        At what address is the property located?
      </div>
      <LocationPicker value={propertyAddress} setValue={setPropertyAddress} label="Property Address" />
      <BrellyBtn className="mt-4" style={{ width: "100%" }} onClick={handleNext}>
        Next
      </BrellyBtn>
    </div>
  );
};

const AddClaimScreen4 = ({ progress, handleProgress }) => {
  const { workflows } = useApp();
  const formattedWorkflows = workflows?.map((w) => ({ ...w, label: w?.name, value: w?.id }));
  const [selectedWorkflow, setSelectedWorkflow] = useState(formattedWorkflows[0]);

  const handleNext = () => {
    handleProgress({
      screen: 5,
      workflowId: selectedWorkflow?.id,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="mt-2 mb-4" style={{ textAlign: "center", fontWeight: 600, fontSize: 18 }}>
        Select a Workflow
      </div>
      <SingleSelect
        value={selectedWorkflow}
        onChange={setSelectedWorkflow}
        options={formattedWorkflows}
        label="Workflow"
      />
      <BrellyBtn className="mt-4" style={{ width: "100%" }} onClick={handleNext}>
        Next
      </BrellyBtn>
    </div>
  );
};

const AddClaimScreen5 = ({ progress, handleClose, setShowOnboarding }) => {
  const { user, orgId, setError, setLoading, membershipId, handleProjects } = useApp();
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    setSelectedUsers([{ label: `${user?.firstName} ${user?.lastName}`, value: membershipId }]);
    //eslint-disable-next-line
  }, []);

  const handleAdd = async () => {
    let createSubresources = progress?.createSubresources;
    let submitValues = progress?.submitValues || {};
    let address = progress?.address;

    try {
      setLoading(true);

      if (createSubresources) {
        let addressData = await brelly.addresses.create(address);
        let { data } = await brelly.projects.create({
          property: {
            addressId: addressData?.id,
          },
          organizationId: orgId,
          workflowId: progress?.workflowId,
          createSubresources: true,
          ...submitValues,
        });
        await brelly.projects.setContributors(
          data?.id,
          selectedUsers?.map((u) => u?.value)
        );
      } else {
        let { data } = await brelly.projects.create({
          organizationId: orgId,
          workflowId: progress?.workflowId,
          ...submitValues,
        });
        await brelly.projectInvites.send({
          projectId: data?.id,
          organizationId: orgId,
          email: progress?.submitValues?.policyholder?.email,
          lastName: progress?.submitValues?.policyholder?.lastName,
          firstName: progress?.submitValues?.policyholder?.firstName,
        });
        setShowOnboarding(true);
        await brelly.projects.setContributors(
          data?.id,
          selectedUsers?.map((u) => u?.value)
        );
      }

      await handleProjects();
      handleClose();
      setLoading(false);
    } catch (err) {
      setError(err?.message);
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="mt-2 mb-2" style={{ textAlign: "center", fontWeight: 600, fontSize: 18 }}>
        Who on your team will work on this claim?
      </div>
      <div style={{ textAlign: "center" }} className="mb-3">
        You can modify this selection later if needed.
      </div>
      <TeammemberSelector value={selectedUsers} onChange={setSelectedUsers} label="Assign Team Members" />
      <BrellyBtn className="mt-4" onClick={handleAdd}>
        Done
      </BrellyBtn>
    </div>
  );
};

const AddClaimScreen1 = ({ handleProgress }) => {
  const [learnMoreModal, setLearnMoreModal] = useState(false);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="mt-2 mb-2" style={{ textAlign: "center", fontWeight: 600, fontSize: 18 }}>
          Do you want the policyholder’s help in <br />
          onboarding this claim?
        </div>
        <div className="jc-ac">
          <div className="brelly-text-btn mb-4" onClick={() => setLearnMoreModal(true)}>
            Learn More
          </div>
        </div>
        <BrellyBtn onClick={() => handleProgress({ createSubresources: false, screen: 2 })}>Yes</BrellyBtn>
        <BrellyBtn
          style={{ background: "white", border: "1px solid rgb(13, 114, 197)", color: "rgb(13, 114, 197)" }}
          className="mt-3"
          onClick={() => handleProgress({ createSubresources: true, screen: 2 })}
        >
          No
        </BrellyBtn>
      </div>
      <LearnMoreModal open={learnMoreModal} handleClose={() => setLearnMoreModal(false)} />
    </>
  );
};

const LearnMoreModal = ({ open, handleClose }) => {
  return (
    <CenterModal open={open} handleClose={handleClose} style={{ maxWidth: 500 }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>About Claim Onboarding</div>
      <div className="mt-3">
        If you click <b>yes</b>, the policyholder does the work of onboarding claim, insurance, property, and
        damage info for you. With this option, you cannot work on the claim until the policyholder completes
        this onboarding process.
      </div>
      <div className="mt-3">
        If you click <b>no</b>, the policyholder does not onboard info about their property and claim,
        although they do still receive a link to access their claim portal. This option allows you to begin
        working on this claim immediately.
      </div>
    </CenterModal>
  );
};
