import { useState } from 'react';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { BrellyBtn } from 'components/Button';
import { paymentTypes } from 'constants';
import { DateSelect } from 'components/Select';
import { CenterModal } from 'components/Modals';
import { AddFileModal } from 'modals';
import { SingleSelect } from 'components/Select';
import PayeeSelect from 'components/PayeeSelect';
import { AttachmentItem } from 'items';
import brelly from "@brelly/sdk";
import { useApp } from 'context';
import { useProject } from 'pages/Dash/Claims/Claim/context';
import { currentToFloat } from 'utils';

export const AddReimbursementModal = ({ open, handleClose }) => {
  const { coverages, claim, handlePayments, handleCoverages } = useProject();
  const coverageOptions = coverages?.map((c, idx) => ({ ...c, label: c?.name, value: idx + 1 }))
  const { setLoading, setError } = useApp();
  const [desc, setDesc] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState(null);
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [selectedPayee, setSelectedPayee] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [selectedCoverage, setSelectedCoverage] = useState([]);
  const [files, setFiles] = useState([]);

  const handleFiles = (_files) => {
    setFiles(_files)
    setAddFilesModal(false)
  }

  const handleAdd = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.claimPayments.create({
        paidAt: date,
        amount: currentToFloat(amount),
        description: desc,
        claimId: claim?.id,
        // paymentMethod: selectedPayment?.label,
        coverageId: selectedCoverage?.id,
        // payeeId: selectedPayee?.id
      })
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id)

        if (localFiles?.length > 0) {
          await brelly.claimPayments.uploadFiles(data?.id, localFiles)
        }

        if (remoteFiles?.length > 0) {
          await brelly.claimPayments.addFiles(data?.id, remoteFiles)
        }
      }
      await Promise.all([handlePayments(), handleCoverages()])
      setLoading(false);
      handleClose()
    } catch(err) {
      setLoading(false);
      setError("There was an issue creating your expense! Please check your connection and try again")
    }
  }

  // <SingleSelect value={selectedPayment} onChange={setSelectedPayment} options={paymentTypes} single={true} label='Payment Type' />
  // <PayeeSelect onSelect={setSelectedPayee} label="Paid By" />

  return(
    <>
      <CenterModal open={open} handleClose={handleClose}>
        <div style={{ fontWeight: 600, fontSize: 20, width: 400, maxWidth: '100%' }}>Add Reimbursement</div>
        <Input value={amount} onChange={setAmount} label='Amount' placeholder="$" type='number' />
        <TextArea label='Description' value={desc} onChange={setDesc} style={{ height: 80 }} />
        <DateSelect value={date} onChange={setDate} label='Date' />
        <SingleSelect value={selectedCoverage} onChange={setSelectedCoverage} options={coverageOptions} label='Select Coverage' />
        <div className='row mt-2'>
          {files?.map((m) => (
            <AttachmentItem key={m?.id} file={m} />
          ))}
        </div>
        <BrellyBtn
          onClick={() => setAddFilesModal(true)}
          className="mt-3"
          style={{ background: "#2D3C57" }}
        >
          Add Proof of Payment
        </BrellyBtn>
        <BrellyBtn onClick={handleAdd} className='mt-2'>Save</BrellyBtn>
        <AddFileModal
          open={addFilesModal}
          onSave={handleFiles}
          handleClose={() => setAddFilesModal(false)}
        />
      </CenterModal>
    </>
  )
}
