import { useApp } from 'context';
import brelly from "@brelly/sdk";
import AuthModal from './AuthModal';
import ProPic from 'components/ProPic';
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';

let header = 'Welcome to Brelly!';
let subheader = 'You have been invited to join a new business';
let boxHeader = 'Please confirm that the information below is correct';

const ConfirmShare = () => {
  const history = useHistory();
  const { user, claims, setLoading, searchParams, setError, handleUser } = useApp();
  const [authModal, setAuthModal] = useState(false);
  const [invite, setInvite] = useState({})
  const [welcomeModal, setWelcomeModal] = useState();
  const code = searchParams.get("code");
  const organization = invite?.organization;

  const handleInvitation = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.membershipInvites.getByCode(code);
      setInvite(data)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err?.message);
      if (err?.message === 'This invite has already been completed') {
        history.push('/dash?tab=home');
      }
    }
  }

  const handleNext = async () => {
    if (user?.id) {
      try {
        setLoading(true);
        let { data } = await brelly.membershipInvites.accept(invite?.id, code);
        history.push(`/dash?tab=home&mid=${data?.id}&walkthrough=${invite?.organization?.name}`)
        setLoading(false);
      } catch(err) {
        console.log(err);
      }
    } else {
      setAuthModal(true)
    }
  }

  useEffect(() => {
    if (code) {
      handleInvitation()
    } else {
      setWelcomeModal(true)
      // history.push('/dash?tab=home');
    }

    handleUser();
  }, [code])

  return(
    <div className="jc-ac brelly-content" style={{ height: "100vh", width: '100vw' }}>
      <div className='jc-ac flex-col' style={{ width: '100vw' }}>
        <h1 style={{ maxWidth: 600, textAlign: 'center' }}>{header}</h1>
        <h2 style={{ maxWidth: 600, textAlign: 'center' }}>{subheader}</h2>
        <div style={{ padding: '29px 32px', background: 'white', borderRadius: 12, marginTop: 18, width: 800 }} className='shadow jc-ac flex-col'>
          <h2 style={{ textAlign: 'center', maxWidth: 500 }}>{boxHeader}</h2>
          <ProPic img={organization?.logo?.downloadUrl} user={{ firstName: organization?.name }} size={70} className='mb-1 mt-3' style={{ fontSize: 38, paddingTop: 3, borderRadius: 10 }} />
          <h2 style={{ fontWeight: 600 }}>{organization?.name}</h2>
          <div className='ja-ac mt-3'>
            <BrellyBtn style={{ marginRight: 12, width: 280, background: 'red' }}>
              Decline
            </BrellyBtn>
            <BrellyBtn style={{ marginLeft: 12, width: 280 }} onClick={handleNext}>
              Correct, let’s proceed!
            </BrellyBtn>
          </div>
        </div>
      </div>
      <AuthModal open={authModal} invite={invite} handleClose={() => setAuthModal(false)} setWelcomeModal={setWelcomeModal} />
      <WelcomeModal open={welcomeModal} organization={organization} invite={invite} handleClose={() => setWelcomeModal(false)} />
    </div>
  )
}

export default ConfirmShare;

const WelcomeModal = ({ open, invite, organization, handleClose }) => {
  const { searchParams } = useApp();
  const history = useHistory();

  const handleDone = () => {
    searchParams.delete("code");
    history.replace({ search: searchParams.toString() });
    history.push('/dash?tab=home');
    handleClose()
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <h1 style={{ width: 300, textAlign: "center" }}>Welcome to {organization?.name}!</h1>
      <img alt='' src='/assets/imgs/welcome.png' style={{ width: 300 }} className='mt-3 mb-3' />
      <BrellyBtn style={{ width: '100%' }} onClick={handleDone}>
        Get Started
      </BrellyBtn>
    </CenterModal>
  )
}
