import brelly from "@brelly/sdk";
import { useApp } from "context";
import { titleCase } from 'utils';
import { useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { useProject } from 'pages/Dash/Claims/Claim/context';

export const ImportContactToClaimModal = ({ open, handleClose, handleUpdate = () => {} }) => {
  if (open) {
    return <Inner open={open} handleClose={handleClose} handleUpdate={handleUpdate} />
  } else {
    return null
  }
};

const Inner = ({ open, handleClose, handleUpdate = () => {} }) => {
  const { setLoading, contacts, handleContacts, setError, projectId } = useApp();
  const { contacts: claimContacts, handleClaimContacts } = useProject()

  const isSelected = (c) => {
    let claimContactIds = claimContacts?.map((c) => c?.id);
    return !!claimContactIds?.includes(c?.id);
  }

  useEffect(() => {
    if (open) {
      handleContacts();
    }
    //eslint-disable-next-line
  }, [open])

  const handleAdd = async (contact) => {
    if (!isSelected(contact)) {
      try {
        setLoading(true);
        await brelly.contacts.addProjects(contact?.id, [projectId]);
        handleClaimContacts()
        setLoading(false);
      } catch(err) {
        setLoading(false);
        setError(err?.message);
      }
    }
  }

  return (
    <>
      <CenterModal open={open} handleClose={handleClose} style={{ width: 400, maxWidth: '100%' }}>
        <div className="">
          <div className="mb-2" style={{ fontWeight: 600, fontSize: 20 }}>Import Contact to Claim</div>
          {contacts?.map((c) => (
            <div className='pt-1 pb-1 jb-ac brelly-item-hover' style={{ cursor: 'pointer' }} key={c?.id} onClick={() => handleAdd(c)}>
              <div className="js-ac">
                {isSelected(c) && <IoCheckmarkCircleOutline className='me-2' style={{ color: "rgb(13, 114, 197)" }} />}
                {c?.firstName} {c?.lastName}
              </div>
              <div style={{  }}>{titleCase(c?.role)}</div>
            </div>
          ))}
        </div>
        <BrellyBtn className="mt-4" onClick={handleClose}>
          Done
        </BrellyBtn>
      </CenterModal>
    </>
  );
}
