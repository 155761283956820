// External Imports
import { FileUploader } from "react-drag-drop-files";

interface FileSelectorProps {
  files: File[];
  setFiles: (files: File[]) => void;
  accept?: string[];
  multiple?: boolean;
  style?: any;
}

const FileSelector = ({ files, setFiles, accept, multiple = false, style = {} }: FileSelectorProps) => {
  const handleChange = (newFiles: File[]) => {
    if (multiple) {
      if (files !== null) {
        const newFileArray = Array.from(newFiles);
        const existingFileArray = Array.isArray(files) ? files : [files];
        setFiles([...existingFileArray, ...newFileArray]);
      } else {
        setFiles(newFiles);
      }
    } else {
      setFiles(Array.isArray(newFiles) ? newFiles : [newFiles]);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: 140,
        border: "1px dashed #0D72C5",
        background: "#D7EDFF",
        borderRadius: 10,
        position: "relative",
        ...style,
      }}
      className="mt-3 jc-ac"
    >
      <FileUploader classes="drag-input" types={accept} multiple={multiple} handleChange={handleChange} />
      <div style={{ display: "flex" }}>
        {Array.isArray(files) && (
          <div
            style={{
              color: "#0D72C5",
              fontFamily: "Lato",
              fontWeight: 600,
              lineHeight: 1.2,
              fontSize: 14,
            }}
          >
            <div style={{ textDecoration: "underline" }}>Click here to add</div>
            <div>or drag and drop</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileSelector;
