// External Imports
import { format } from "date-fns";

// Internal Imports
import { ItemSpacer } from "../index";
import EmailItem from "./Email";
import EventItem from "./Event";
import CallItem from "./Call";
import FileItem from "./File";
import TaskItem from "./Task";

const TimelineItem = ({ task, last, handleTimeline }) => {
  if (task && task.timelineType && task.timelineDate) {
    const eventTypeSwitch = (ev) => {
      switch (ev?.timelineType) {
        case "FILE":
          return <FileItem ev={ev} />;

        case "EMAIL":
          return <EmailItem ev={ev} handleUpdate={handleTimeline} />;

        case "PHONE_CALL":
          return <CallItem ev={ev} handleUpdate={handleTimeline} />;

        case "EVENT":
          return <EventItem ev={ev} />;

        case "TASK":
          return <TaskItem ev={ev} />;

        default:
          console.error("Invalid timeline type:", ev?.timelineType);
      }
    };

    return (
      <>
        <div className="row">
          <div
            className=" col-2 col-lg-1 jc-ac"
            style={{ position: "relative", paddingLeft: 0, paddingRight: 0, flexDirection: "column" }}
          >
            <ItemSpacer top={true} task={task} last={last} />
            <div className="dash-table-item" style={{ color: "#64666B", textTransform: "lowercase" }}>
              {format(new Date(task.timelineDate), "hh:mm aaa")}
            </div>
            <ItemSpacer task={task} last={last} />
          </div>
          <div className="col-10 col-lg-11">{eventTypeSwitch(task)}</div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default TimelineItem;
