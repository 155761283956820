import { useApp } from "context";
import brelly from "@brelly/sdk";
import { format } from "date-fns";
import ProPic from "components/ProPic";
import { useState, useEffect } from "react";
import { AddOrgModal } from 'modals';
import { MembershipInviteItem } from 'items';

export const ProfileCompanySection = ({ accountTab }) => {
  const { setMembership, memberships, searchParams } = useApp();
  const isSelected = accountTab === "companies";
  const [createModal, setCreateModal] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const ownerModalOpen = searchParams.get("ownerwelcome") === 'true'

  const handleInvitations = async () => {
    try {
      let { data } = await brelly.users.getPendingMembershipInvites();
      setInvitations(data)
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleInvitations()
  }, [])

  return (
    <>
      <div
        style={{
          width: "100%",
          paddingTop: 22,
          display: isSelected ? "" : "none",
        }}
      >
        <div className="jb-ac" style={{ marginBottom: 24, marginTop: ownerModalOpen ? -6 : 0 }}>
          <div className="dash-box-title" style={{ color: "black" }}>
            Current Companies
          </div>
          <div className="brelly-text-btn" style={getCreateBtnStyles(ownerModalOpen)} onClick={() => setCreateModal(true)}>
            Create a Company
          </div>
        </div>
        <div>
          {memberships?.map((membership, key) => (
            <div
              key={key}
              onClick={() => setMembership(membership)}
              className="p-3 mb-1 js-ac"
              style={{
                background: "#D1EAFF",
                borderRadius: 10,
                fontWeight: 600,
              }}
            >
              <div className="me-2">
                <ProPic img={membership?.organization?.coverPhoto} />
              </div>
              <div style={{ width: "50%" }} className="js-ac">
                {membership?.organization?.name}
              </div>
              <div style={{ width: "25%" }} className="js-ac">
                {membership?.role}
              </div>
              <div style={{ width: "25%" }} className="je-ac">
                {format(new Date(membership?.createdAt), "MM/dd/yyyy")}
              </div>
            </div>
          ))}
        </div>
        {invitations?.length > 0 && (
          <h3 className="mt-3 mb-2" style={{ fontSize: 15, color: "#64666B", fontWeight: 700 }}>
            Invitations
          </h3>
        )}
        {invitations?.map((i) => <MembershipInviteItem invite={i} key={i?.id} handleInvitations={handleInvitations} />)}
      </div>
      {createModal && <AddOrgModal open={createModal} setOpen={setCreateModal} />}
    </>
  );
};

const getCreateBtnStyles = (modalOpen) => {
  if (modalOpen) {
    return {
      background: 'rgb(13, 114, 197)',
      color: 'white',
      padding: '8px 24px',
      borderRadius: 4,
      marginRight: -24
    }
  } else {
    return {}
  }
}
