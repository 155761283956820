import { Links } from './sections/Links';
import { Claim } from './sections/Claim';
import { Notes } from './sections/Notes';
import { Property } from './sections/Property';
import { Mortgage } from './sections/Mortgage';
import { Insurance } from './sections/Insurance';
import { Policyholder } from './sections/Policyholder';

const Details = () => {
  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <Links />
        <Insurance />
        <Notes />
      </div>
      <div className="col-12 col-lg-6">
        <Claim />
        <Property />
        <Mortgage />
        <Policyholder />
      </div>
    </div>
  );
};

export default Details;
