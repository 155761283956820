import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import TextArea from 'components/TextArea';
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CenterModal } from 'components/Modals';

export const AddPhaseModal = ({ open, handleClose, workflow, setSelectedWorkflow }) => {
  const { setLoading, handleUser, setError } = useApp();
  const [values, setValues] = useState({});

  useEffect(() => {
    setValues({})
  }, [open])

  const handleUpdate = (updates = {}) => {
    setValues({ ...values, ...updates })
  }

  const handleAdd = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.phases.create({
        name: values?.name,
        workflowId: workflow?.id,
        description: values?.description,
      })
      await handleUser(false);
      setSelectedWorkflow({ ...workflow, phases: [...workflow?.phases, data] })
      setLoading(false);
      handleClose()
    } catch(err) {
      setError(err?.message);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add Phase</div>
      <Input value={values?.name || ""} onChange={(v) => handleUpdate({ name: v })} label='Name' />
      <TextArea value={values?.description || ""} onChange={(v) => handleUpdate({ description: v })} label='Description' style={{ height: 80 }} />
      <BrellyBtn onClick={handleAdd} className='mt-4' style={{ width: 500 }}>Save</BrellyBtn>
    </CenterModal>
  )
}
