import { useProject } from "pages/Dash/Claims/Claim/context";
import { MultiSelect } from "components/Select";
import { CenterModal } from "components/Modals";
import { DateSelect } from "components/Select";
import { BrellyBtn } from "components/Button";
import { TrashIcon } from 'components/Icons';
import { useState, useEffect } from "react";
import Input from "components/Input";
import { v4 as uuidV4 } from 'uuid';
import brelly from "@brelly/sdk";
import { useApp } from "context";

export const EditModal = ({ open, handleClose }) => {
  const { claim, handleClaim } = useProject();
  const { setLoading, lossTypes } = useApp();
  const [claimNum, setClaimNum] = useState("");
  const [existingLossTypes, seTexistingLossTypes] = useState([]);
  const [dol, setDol] = useState(null);
  const [additional, setAdditional] = useState({ entries: [] });
  const sortedAdditional = additional?.entries?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) || [];
  const hasAdditional = sortedAdditional?.length > 0;
  const colClass = hasAdditional ? 'col-12 col-lg-6' : 'col-12';

  const addAdditional = () => {
    setAdditional({ entries: [...additional?.entries, { key: '', value: '', id: uuidV4(), createdAt: new Date() }] })
  }

  useEffect(() => {
    if (open) {
      setClaimNum(claim?.claimNumber || "")
      if (claim?.dateOfLoss) {
        setDol(new Date(claim?.dateOfLoss))
      }
      if (claim?.lossTypes?.length > 0) {
        seTexistingLossTypes(claim?.lossTypes?.map((lt) => ({ ...lt, label: lt?.type, value: lt?.id })))
      }
      if (claim?.additionalInfo) {
        setAdditional(claim?.additionalInfo)
      }
    }
    //eslint-disable-next-line
  }, [open])

  const handleSave = async () => {
    try {
      setLoading(true);
      await brelly.claims.update(claim?.id, { claimNumber: claimNum, dateOfLoss: dol, additionalInfo: additional });
      await brelly.claims.setLossTypes(claim?.id, existingLossTypes?.map((lt) => lt?.id))
      handleClaim();
      handleClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20, width: hasAdditional ? 800 : 400, maxWidth: '100%' }}>
        Edit Claim Details
      </div>
      <div className='row'>
        <div className={colClass}>
          <Input label="Claim Number" value={claimNum} onChange={setClaimNum} />
          <DateSelect
            value={dol}
            onChange={setDol}
            label="Date of Loss"
          />
          <MultiSelect
            options={lossTypes}
            label="Loss Types"
            value={existingLossTypes}
            onChange={seTexistingLossTypes}
          />
        </div>
        {sortedAdditional?.length > 0 && (
          <div className='col-6'>
            <div className='brelly-text-btn' style={{ marginTop: -23 }}>Custom</div>
            {sortedAdditional?.map((entry) => (
              <CustomField key={entry?.id} entry={entry} additional={additional} setAdditional={setAdditional} />
            ))}
            <div className='mt-3 jc-ac'>
              <div onClick={addAdditional} className='brelly-text-btn'>Add Custom</div>
            </div>
          </div>
        )}
        {sortedAdditional?.length === 0 && (
          <div className='mt-3 jc-ac'>
            <div onClick={addAdditional} className='brelly-text-btn'>Add Custom</div>
          </div>
        )}
      </div>
      <BrellyBtn className="mt-3" onClick={handleSave}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};

const CustomField = ({ entry, additional, setAdditional }) => {
  let entries = additional?.entries || [];
  const { key, value, id } = entry;

  const handleChange = (type, newValue) => {
    let others = entries?.filter((c) => c?.id !== id);
    setAdditional({ entries: [...others, { key: key, value: value, [type]: newValue, id: id }]})
  }

  const deleteEntry = () => {
    let remaining = entries?.filter((c) => c?.id !== id);
    setAdditional({ entries: remaining })
  }

  return(
    <div className='js-ac'>
      <div className='row'>
        <div className="col-5">
          <Input value={key} onChange={(nv) => handleChange('key', nv)} label='Name' />
        </div>
        <div className="col-7" style={{ paddingLeft: 0 }}>
          <Input value={value} onChange={(nv) => handleChange('value', nv)} label='Value' />
        </div>
      </div>
      <div style={{ width: 28, flexShrink: 0, height: 35, marginTop: 17, marginRight: -6 }} className='je-ac'>
        <TrashIcon size={16} onClick={deleteEntry} />
      </div>
    </div>
  )
}
