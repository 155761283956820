// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface CanopyInterface {
  consentAndConnect: (
    values: {
      insuranceCompanyId: string;
      insurerUsername: string;
      insurerPassword: string;
      insurerThirdField: string;
    },
    query?: Query
  ) => Promise<any>;
  reauthenticateConnection: (
    values: {
      connectionId: string;
      connectionJwt: string;
      insuranceCompanyId: string;
      insurerUsername: string;
      insurerPassword: string;
      insurerThirdField: string;
    },
    query?: Query
  ) => Promise<any>;
  selectMFAOption: (
    values: {
      connectionId: string;
      connectionJwt: string;
      mfaOption: string;
    },
    query?: Query
  ) => Promise<any>;
  verifyMFA: (
    values: {
      connectionId: string;
      connectionJwt: string;
      mfaValue: string;
    },
    query?: Query
  ) => Promise<any>;
  getToS: (query?: Query) => Promise<any>;
  getInsuranceCompanies: (query?: Query) => Promise<any>;
}

export const canopy = (client: Axios): CanopyInterface => ({
  consentAndConnect: async (
    {
      insuranceCompanyId,
      insurerUsername,
      insurerPassword,
      insurerThirdField,
    }: {
      insuranceCompanyId: string;
      insurerUsername: string;
      insurerPassword: string;
      insurerThirdField: string;
    },
    query?: Query
  ) => {
    const url = addUrlQuery("/canopy/connect", query);
    const res: AxiosResponse = await client.post(url, {
      insuranceCompanyId,
      insurerUsername,
      insurerPassword,
      insurerThirdField,
    });

    return {
      data: res.data,
    };
  },

  reauthenticateConnection: async (
    {
      connectionId,
      connectionJwt,
      insuranceCompanyId,
      insurerUsername,
      insurerPassword,
      insurerThirdField,
    }: {
      connectionId: string;
      connectionJwt: string;
      insuranceCompanyId: string;
      insurerUsername: string;
      insurerPassword: string;
      insurerThirdField: string;
    },
    query?: Query
  ) => {
    const url = addUrlQuery(`/canopy/connect/${connectionId}/reauthenticate`, query);
    const res: AxiosResponse = await client.post(url, {
      connectionJwt,
      insuranceCompanyId,
      insurerUsername,
      insurerPassword,
      insurerThirdField,
    });

    return {
      data: res.data,
    };
  },

  selectMFAOption: async (
    {
      connectionId,
      connectionJwt,
      mfaOption,
    }: {
      connectionId: string;
      connectionJwt: string;
      mfaOption: string;
    },
    query?: Query
  ) => {
    const url = addUrlQuery(`/canopy/connect/${connectionId}/mfa/options`, query);
    const res: AxiosResponse = await client.post(url, {
      connectionJwt,
      mfaOption,
    });

    return {
      data: res.data,
    };
  },

  verifyMFA: async (
    {
      connectionId,
      connectionJwt,
      mfaValue,
    }: {
      connectionId: string;
      connectionJwt: string;
      mfaValue: string;
    },
    query?: Query
  ) => {
    const url = addUrlQuery(`/canopy/connect/${connectionId}/mfa/verify`, query);
    const res: AxiosResponse = await client.post(url, {
      connectionJwt,
      mfaValue,
    });

    return {
      data: res.data,
    };
  },

  getToS: async (query?: Query) => {
    const url = addUrlQuery("/canopy/terms-of-service", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  getInsuranceCompanies: async (query?: Query) => {
    const url = addUrlQuery("/canopy/insurance-companies", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },
});
