// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface PropertiesInterface {
  getAll: (query?: Query) => Promise<any>;
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  getAttributes: (id: string, query?: Query) => Promise<any>;
  getInventoryItems: (id: string, query?: Query) => Promise<any>;
  getClaims: (id: string, query?: Query) => Promise<any>;
  getPolicies: (id: string, query?: Query) => Promise<any>;
  getCurrentPolicy: (id: string, query?: Query) => Promise<any>;
  uploadCoverPhoto: (id: string, file: any, query?: Query) => Promise<any>;
  getFiles: (id: string, query?: Query) => Promise<any>;
  uploadFiles: (id: string, files: any[], query?: Query) => Promise<any>;
  setFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  addFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
  removeFiles: (id: string, memberIds: string[], query?: Query) => Promise<any>;
}

export const properties = (client: Axios): PropertiesInterface => ({
  getAll: async (query?: Query) => {
    const url = addUrlQuery("/properties", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/properties", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  // *** Relationship Queries ***

  getAttributes: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/attributes`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getInventoryItems: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/inventory-items`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getClaims: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/claims`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getPolicies: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/policies`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getCurrentPolicy: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/policies`, {
      ...query,
      limit: 1,
      order: "-effectiveDate",
      filter: { expiryDate: { gte: new Date() } },
    });

    const res: AxiosResponse = await client.get(url);
    if (Array.isArray(res.data)) {
      return {
        data: res.data[0],
      };
    } else {
      return {
        data: res.data,
      };
    }
  },

  uploadCoverPhoto: async (id: string, file: any, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/cover-photo`, query);
    const formData = new FormData();
    formData.append("file", file);

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  getFiles: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/files`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  uploadFiles: async (id: string, files: any[], query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/files`, query);
    const res: AxiosResponse = await client.post(
      url,
      { files },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return {
      data: res.data,
    };
  },

  setFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/files/set`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  addFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/files/add`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },

  removeFiles: async (id: string, memberIds: string[], query?: Query) => {
    const url = addUrlQuery(`/properties/${id}/files/remove`, query);
    const res: AxiosResponse = await client.post(url, { ids: memberIds });
    return {
      data: res.data,
    };
  },
});
