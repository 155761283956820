import { format } from "date-fns";
import Calendar from "./Calender";
import { Portal } from "react-portal";
import CheckBox from "components/CheckBox";
import { BrellyBtn } from "components/Button";
import Tooltip from 'react-bootstrap/Tooltip';
import { useState, useEffect, useRef } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { BsChevronDown, BsCalendar2Week, BsClock, BsInfoCircle } from "react-icons/bs";

export const SingleSelect = ({
  value = {},
  onChange = (v) => {},
  options = [],
  style = {},
  placeholder,
  tooltip = '',
  label,
}) => {
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueKey = value?.value;
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 50;

  const isSelected = (option) => {
    return option?.value === value?.value;
  };

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect());
    }
  }, [inputRef, focus, valueKey]);

  const handleSelect = (val) => {
    setFocus(false);
    onChange(val);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  );

  return (
    <div className="" style={{ position: "relative" }}>
      {label && (
        <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className="mt-2 jb-ac">
          {label}

          {tooltip && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <button style={{ background: 'transparent' }}>
                <BsInfoCircle />
              </button>
            </OverlayTrigger>
          )}

        </div>
      )}
      <div style={{ position: "absolute", right: 8, top: 20 }} onClick={() => setFocus(true)}>
        <BsChevronDown style={{ color: "#0D72C5" }} />
      </div>
      <div
        ref={inputRef}
        onClick={() => setFocus(true)}
        className="dash-input js-ac"
        placeholder={placeholder}
        style={{ cursor: "pointer", outline: focus ? "1px solid #0D72C5" : "", ...style }}
      >
        {value?.label}
      </div>

      {focus && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={() => setFocus(false)}
          />
          <div
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 10,
              paddingLeft: 12,
              paddingRight: 12,
              zIndex: 1010,
              fontFamily: "Lato",
              left: boundingRect.x,
              top: boundingRect.bottom + 6,
              width: boundingRect.width,
            }}
            className="shadow"
          >
            <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
              {options?.length > 0 &&
                options.map((option, idx) => (
                  <div
                    key={idx}
                    onClick={() => handleSelect(option)}
                    style={{
                      width: "100%",
                      height: 38,
                      borderRadius: 10,
                      background: "white",
                      fontSize: 14,
                      cursor: "pointer",
                      marginTop: idx === 0 ? 12 : 6,
                      marginBottom: idx === options?.length - 1 ? 12 : 0,
                    }}
                    className="js-ac ps-2"
                  >
                    <CheckBox title={option.label} value={isSelected(option)} setValue={() => {}} />
                  </div>
                ))}
              {options?.length === 0 && <div className="jc-ac mb-2">No Options</div>}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export const MultiSelect = ({
  value = [],
  onChange = (v) => {},
  options = [],
  style = {},
  chevronStyle = {},
  placeholder,
  label,
  wrapperClass = "",
}) => {
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueLength = value?.length;
  const valueLabels = value?.map((v) => v?.label);
  const valueStr = valueLabels?.join(", ");
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 50;

  const isSelected = (option) => {
    const formattedValues = value?.map((v) => v.value);
    return !!formattedValues?.includes(option?.value);
  };

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect());
    }
  }, [inputRef, focus, valueLength]);

  const handleSelect = (option) => {
    if (option?.all) {
      if (value?.length === options?.length) {
        onChange([]);
      } else {
        onChange(options);
      }
    } else {
      let nonAllValues = value?.filter((v) => !v?.all && v?.label !== "All");
      let valueValues = nonAllValues?.map((v) => v?.value);

      if (valueValues?.includes(option?.value)) {
        let remaining = nonAllValues?.filter((v) => v?.value !== option?.value);
        onChange(remaining);
      } else {
        let updates = [...nonAllValues, option];
        if (updates?.length + 1 === options?.length) {
          onChange(options)
        } else {
          onChange(updates);
        }
      }
    }
  };

  return (
    <div className={wrapperClass} style={{ position: "relative", width: "100%" }}>
      {label && (
        <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className="mt-2">
          {label}
        </div>
      )}
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 7,
          paddingRight: 8,
          paddingLeft: 3,
          background: "#F5F5F5",
          ...chevronStyle,
        }}
        onClick={() => setFocus(true)}
      >
        <BsChevronDown style={{ color: "#0D72C5" }} />
      </div>
      <div
        ref={inputRef}
        onClick={() => setFocus(true)}
        className="dash-input js-ac"
        placeholder={placeholder}
        style={{
          cursor: "pointer",
          outline: focus ? "1px solid #0D72C5" : "",
          overflowX: "scroll",
          whiteSpace: "nowrap",
          minWidth: 208,
          width: "100%",
          ...style,
        }}
      >
        {valueStr?.replace("All, ", "")}
      </div>
      {focus && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={() => setFocus(false)}
          />
          <div
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 10,
              padding: 12,
              zIndex: 1010,
              fontFamily: "Lato",
              left: boundingRect.x,
              top: boundingRect.bottom + 6,
              width: boundingRect.width,
            }}
            className="shadow"
          >
            <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
              {options?.length > 0 &&
                options.map((option, key) => (
                  <div
                    key={key}
                    onClick={() => handleSelect(option)}
                    style={{
                      width: "100%",
                      height: 38,
                      borderRadius: 10,
                      background: "white",
                      fontSize: 14,
                      cursor: "pointer",
                    }}
                    className="mb-2 js-ac ps-2"
                  >
                    <CheckBox title={option.label} value={isSelected(option)} setValue={() => {}} />
                  </div>
                ))}
              {options?.length === 0 && <div className="jc-ac mb-2">No Options</div>}
            </div>
            <BrellyBtn style={{ marginTop: 12 }} onClick={() => setFocus(false)}>
              Done
            </BrellyBtn>
          </div>
        </Portal>
      )}
    </div>
  );
};

let allowedKeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
let thirtyOneDayMonths = ["01", "03", "05", "07", "08", "10", "12"];

export const DateSelect = ({
  value,
  onChange = () => {},
  options = [],
  style = {},
  placeholder,
  label,
  blur = false,
  contStyle,
}) => {
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const valueKey = value?.value;
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 50;
  const [inputValue, setInputValue] = useState("");
  const time = value ? format(new Date(value), "hh:mm") : "12:00";

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect());
    }
  }, [inputRef, focus, valueKey]);

  const handleKeyboard = (ev) => {
    let value = ev?.target?.value || "";
    let newChar = value.slice(-1);

    if (ev?.nativeEvent?.inputType === "deleteContentBackward") {
      if (inputValue?.length === 3 || inputValue?.length === 6) {
        setInputValue(inputValue?.slice(0, -2));
      } else if (inputValue?.length > 0) {
        setInputValue(inputValue?.slice(0, -1));
      }
    } else if (allowedKeys?.includes(newChar)) {
      if (value?.length === 1) {
        if (newChar === "1" || newChar === "0") {
          setInputValue(value);
        } else {
          setInputValue(`0${value}/`);
        }
      } else if (value?.length === 2) {
        if (newChar === "2" || newChar === "1" || newChar === "0") {
          setInputValue(`${value}/`);
        }
      } else if (value?.length === 4 && inputValue === "02/") {
        if (newChar === "2" || newChar === "1" || newChar === "0") {
          setInputValue(value);
        }
      } else if (value?.length === 4) {
        if (newChar === "3" || newChar === "2" || newChar === "1" || newChar === "0") {
          setInputValue(value);
        }
      } else if (value?.length === 5) {
        let previousChar = inputValue?.slice(-1);
        let month = inputValue.slice(0, 2);

        if (previousChar === "3" && month !== "02") {
          if (thirtyOneDayMonths?.includes(month)) {
            if (newChar === "1" || newChar === "0") {
              setInputValue(`${value}/`);
            }
          } else if (newChar === "0") {
            setInputValue(`${value}/`);
          }
        } else {
          setInputValue(`${value}/`);
        }
      } else if (value?.length === 7 || value?.length === 8 || value?.length === 9 || value?.length === 10) {
        setInputValue(value);
      }
    }
  };

  const checkInput = () => {
    if (inputValue?.length > 0) {
      setFocus(false);

      if (inputValue?.length === 8 || inputValue?.length === 10) {
        let timestamp = Date.parse(inputValue);

        if (isNaN(timestamp)) {
          setInputValue("");
          onChange(null);
        } else {
          onChange(new Date(`${inputValue} ${time}`));
          setInputValue("");
        }
      } else {
        setInputValue("");
        onChange(null);
      }
    }
  };

  const getDisplay = () => {
    if (inputValue) {
      let helper = "MM/DD/YYYY";
      return (
        <div>
          {inputValue}
          <span style={{ opacity: 0.5 }}>{helper?.slice(inputValue?.length)}</span>
        </div>
      );
    } else if (value) {
      return format(value, "MMM dd, yyyy");
    }
  };

  return (
    <div className="" style={{ position: "relative", ...contStyle }}>
      {label && (
        <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className="mt-2">
          {label}
        </div>
      )}
      <div style={{ position: "absolute", right: 8, bottom: 9 }} onClick={() => setFocus(true)}>
        <BsCalendar2Week style={{ color: "#0D72C5" }} />
      </div>
      <div
        ref={inputRef}
        onClick={() => setFocus(true)}
        className="dash-input js-ac"
        placeholder={placeholder}
        style={{
          position: "relative",
          color: blur ? "hsl(0, 0%, 50%)" : "",
          cursor: "pointer",
          outline: focus ? "1px solid #0D72C5" : "",
          ...style,
        }}
      >
        {getDisplay()}
        <input
          ref={inputRef2}
          onBlur={checkInput}
          style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%", opacity: 0 }}
          value={inputValue}
          onChange={handleKeyboard}
        />
      </div>

      {focus && !inputValue && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={() => setFocus(false)}
          />
          <div
            onClick={() => inputRef2?.current?.focus()}
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 10,
              padding: 16,
              zIndex: 1010,
              fontFamily: "Lato",
              left: boundingRect.x + boundingRect?.width / 2,
              top: boundingRect.bottom + 6,
              width: 232,
              transform: "translate(-50%, 0%)",
            }}
            className="shadow"
          >
            <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
              <Calendar value={value} onChange={onChange} />
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

const validHours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const validMinutes = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
  29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
  56, 57, 58, 59,
];

export const TimeSelect = ({
  value,
  onChange = () => {},
  options = [],
  style = {},
  placeholder,
  label,
  contStyle = {},
}) => {
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [isPm, setIsPm] = useState(true);
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueKey = value?.value;
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 50;

  useEffect(() => {
    if (value) {
      if (!minutes) {
        let mm = format(new Date(value), "mm");
        setMinutes(mm);
      }
      if (!hours) {
        let hh = format(new Date(value), "hh");
        setHours(hh);
      }
    }
    //eslint-disable-next-line
  }, [value]);

  const getHours = () => {
    if (hours) {
      if (isPm) {
        if (hours === "12") {
          return hours;
        } else {
          return parseInt(hours) + 12;
        }
      } else {
        if (hours?.length === 1) {
          return `0${hours}`;
        } else {
          if (hours === "12") {
            return "00";
          } else {
            return hours;
          }
        }
      }
    } else {
      return "00";
    }
  };

  const getMinutes = () => {
    if (minutes) {
      if (minutes?.length === 1) {
        return `0${minutes}`;
      } else {
        return minutes;
      }
    } else {
      return "00";
    }
  };

  const handleBlur = () => {
    if (value) {
      let hh = getHours();
      let mm = getMinutes();
      let selectedDay = format(new Date(value), "MM/dd/yyyy");
      onChange(new Date(`${selectedDay} ${hh}:${mm}`));
    } else {
      let hh = getHours();
      let mm = getMinutes();
      let today = format(new Date(), "MM/dd/yyyy");
      onChange(new Date(`${today} ${hh}:${mm}`));
    }
  };

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect());
    }
  }, [inputRef, focus, valueKey]);

  const handleHours = (inputHours) => {
    let num = parseInt(inputHours);

    if (validHours?.includes(num) || !inputHours) {
      setHours(inputHours);
    } else if (inputHours === "13") {
      setIsPm(!isPm);
      setHours("1");
    } else if (inputHours === "0") {
      setIsPm(!isPm);
      setHours("12");
    }
  };

  const handleMinutes = (inputMinutes) => {
    let num = parseInt(inputMinutes);

    if (validMinutes?.includes(num) || !inputMinutes) {
      if (inputMinutes?.length < 3) {
        setMinutes(inputMinutes);
      }
    }
  };

  const handleClose = () => {
    setFocus(false);
    handleBlur();
  };

  return (
    <div className="" style={{ position: "relative", ...contStyle }}>
      {label && (
        <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className="mt-2">
          {label}
        </div>
      )}
      <div style={{ position: "absolute", right: 8, bottom: 8 }} onClick={() => setFocus(true)}>
        <BsClock style={{ color: "#0D72C5" }} />
      </div>
      <div
        ref={inputRef}
        onClick={() => setFocus(true)}
        className="dash-input js-ac"
        placeholder={placeholder}
        style={{ cursor: "pointer", outline: focus ? "1px solid #0D72C5" : "", ...style }}
      >
        {value && format(value, "hh:mm aaa")}
      </div>

      {focus && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={handleClose}
          />
          <div
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 10,
              padding: 16,
              zIndex: 1010,
              fontFamily: "Lato",
              left: boundingRect.x + boundingRect?.width / 2,
              top: boundingRect.bottom + 6,
              width: 224,
              transform: "translate(-50%, 0%)",
            }}
            className="shadow"
          >
            <div style={{ maxHeight: maxHeight, overflowY: "auto", padding: 2 }} className="jc-as">
              <input
                value={hours}
                onChange={(e) => handleHours(e.target.value)}
                type="number"
                style={{ width: 60, height: 40, background: "white", textAlign: "center", paddingLeft: 0 }}
                className="dash-input me-2"
              />
              <input
                value={minutes}
                onChange={(e) => handleMinutes(e.target.value)}
                type="number"
                style={{ width: 60, height: 40, background: "white", textAlign: "center", paddingLeft: 0 }}
                className="dash-input me-3"
              />
              <div
                style={{
                  cursor: "pointer",
                  height: 40,
                  border: "1px solid #0D72C5",
                  borderRadius: 10,
                  width: 60,
                  fontSize: 12,
                  fontWeight: 600,
                  overflow: "hidden",
                }}
              >
                <div
                  onClick={() => setIsPm(false)}
                  style={{
                    width: "100%",
                    height: 20,
                    background: isPm ? "" : "rgb(233, 245, 255)",
                    color: isPm ? "rgb(100, 102, 107)" : "#0D72C5",
                    borderBottom: "1px solid #0D72C5",
                  }}
                  className="jc-ac"
                >
                  AM
                </div>
                <div
                  onClick={() => setIsPm(true)}
                  style={{
                    width: "100%",
                    height: 20,
                    background: isPm ? "rgb(233, 245, 255)" : "",
                    color: isPm ? "#0D72C5" : "rgb(100, 102, 107)",
                  }}
                  className="jc-ac"
                >
                  PM
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};
