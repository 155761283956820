import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const PhonePicker = ({ label, value, onChange, style = {}, wrapperStyle = {}, disabled }) => {
  return (
    <>
      {label && <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className='mt-2'>{label}</div>}
      <PhoneInput
        value={value}
        defaultCountry="US"
        onChange={onChange}
        className="dash-input"
        placeholder="Enter phone number"
        style={{ width: "100%" }}
        disabled={disabled}
      />
    </>
  );
};

export default PhonePicker;
