import { useState } from 'react';
import brelly from "@brelly/sdk";
import { useApp } from 'context';
import { format } from 'date-fns';
import { CenterModal } from 'components/Modals';
import { EditEmailModal } from 'modals';
import { AttachmentItem } from 'items';
import { ConfirmDeleteModal } from 'modals';

export const ViewEmailModal = ({ email = {}, open, handleClose, handleUpdate }) => {
  let date = email?.sentAt ? format(new Date(email?.sentAt), 'MMM dd, yyyy') : ""
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [confirmfirmDeleteModal, setConfirmfirmDeleteModal] = useState(false);
  const { setLoading, setError } = useApp();

  const handleDelete = async () => {
    try {
      setLoading(true);
      setConfirmfirmDeleteModal(false)
      await brelly.emails.delete(email?.id);
      await handleUpdate()
      handleClose()
      setLoading(false);
    } catch(err) {
      setError(err);
      setLoading(false);
    }
  }

  return(
    <>
      <CenterModal open={open} handleClose={handleClose} style={{ width: 500, maxWidth: '100%' }}>
        <div style={{ fontWeight: 600, fontSize: 20 }} className='jb-ac'>
          <div className='js-ac'>
            <span style={{ fontWeight: 600, fontSize: 20 }}>Email Details</span>
          </div>
          <div className='je-ac'>
            <div className='brelly-text-btn me-3' onClick={() => setEditTaskModal(true)} style={{ color: '#0D72C5' }}>Edit</div>
            <div className='brelly-text-btn' onClick={() => setConfirmfirmDeleteModal(true)} style={{ color: 'red' }}>Delete</div>
          </div>
        </div>
        <div className='js-ac mt-4'>
          <div style={{ width: 200 }}>Subject:</div>
          <div>
            {email?.subject}
          </div>
        </div>
        <div className='js-ac mt-2'>
          <div style={{ width: 200 }}>Date:</div>
          <div style={{ fontWeight: 500 }}>{date}</div>
        </div>
        <div className='js-ac mt-2'>
          <div style={{ width: 200 }}>From:</div>
          <div className='js-ac'>
            {email?.sender?.firstName} {email?.sender?.lastName}
          </div>
        </div>
        <div className='js-ac mt-2'>
          <div style={{ width: 200 }}>To:</div>
          <div className='js-ac'>
            {email?.reciever?.firstName} {email?.reciever?.lastName}
          </div>
        </div>
        {email?.body && (
          <div className='mt-4'>
            <div style={{ fontWeight: 600 }}>Email:</div>
            <div className='mt-1' style={{ lineHeight: 1.2 }}>
              {email?.body}
            </div>
          </div>
        )}
        <div className='mt-4'>
          {email?.files?.length > 0 && <div style={{ fontWeight: 600 }}>Attachments:</div>}
          <div className='row'>
            {email?.files?.map((tf, key) => (
              <AttachmentItem file={tf} key={key} />
            ))}
          </div>
        </div>
      </CenterModal>
      <ConfirmDeleteModal open={confirmfirmDeleteModal} handleClose={() => setConfirmfirmDeleteModal(false)} onDelete={handleDelete} />
      <EditEmailModal email={email} open={editTaskModal} handleClose={() => setEditTaskModal(false)} handleUpdate={handleUpdate} />
    </>
  )
}
