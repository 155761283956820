import { BsFiletypePdf, BsFiletypeJpg, BsFiletypeMp4, BsFiletypePng, BsFiletypePpt, BsFiletypeTxt, BsFiletypeSvg, BsFiletypeMov, BsFiletypeDoc, BsFiletypeDocx } from "react-icons/bs";

export const getFileTypeIcon = (contentType, style = {}) => {
  if (contentType?.includes("pdf")) {
    return <BsFiletypePdf style={style} />
  } else if (contentType?.includes("jpg") || contentType?.includes("jpeg")) {
    return <BsFiletypeJpg style={style} />
  } else if (contentType?.includes("mp4")) {
    return <BsFiletypeMp4 style={style} />
  } else if (contentType?.includes("png")) {
    return <BsFiletypePng style={style} />
  } else if (contentType?.includes("ppt") || contentType?.includes("pptx")) {
    return <BsFiletypePpt style={style} />
  } else if (contentType?.includes("txt")) {
    return <BsFiletypeTxt style={style} />
  } else if (contentType?.includes("svg")) {
    return <BsFiletypeSvg style={style} />
  } else if (contentType?.includes("mov") || contentType?.includes("quicktime")) {
    return <BsFiletypeMov style={style} />
  } else if (contentType?.includes("docx")) {
    return <BsFiletypeDocx style={style} />
  } else if (contentType?.includes("doc")) {
    return <BsFiletypeDoc style={style} />
  } else {
    return null;
  }
}
