import { CenterModal } from "components/Modals";
import { BrellyBtn } from "components/Button";

export const CompletedOnboardingModal = ({ open, handleClick, handleClose }) => {
  return(
    <CenterModal open={open} handleClose={handleClose} blurStyle={{ background: 'rgba(0,0,0,.5)' }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>You can start working on this claim!</div>
      <div className='mt-2'>The policyholder has onboarded info about this property and claim.  Before starting, please note:</div>
      <div style={{ color: '#0D72C5' }} className='js-as mt-2'>
        <div style={{ width: 5, height: 5, borderRadius: '50%', marginRight: 5, marginTop: 10, flexShrink: 0, background: '#0D72C5' }} />
        <div>
          <b>You should review the onboarded information carefully for accuracy and completeness.</b>
          It’s possible the policyholder or the insurer provided incorrect information.
        </div>
      </div>
      <div style={{ color: '#0D72C5' }} className='js-as mt-2'>
        <div style={{ width: 5, height: 5, borderRadius: '50%', marginRight: 5, marginTop: 10, flexShrink: 0, background: '#0D72C5' }} />
        <div>
          <b>The policyholder may not be through uploading photos, videos, and documents.</b>
          You will see them in the files tab as soon as they’re uploaded.
        </div>
      </div>
      <BrellyBtn className='mt-3' onClick={handleClick}>Got It</BrellyBtn>
    </CenterModal>
  )
}
