import { useApp } from 'context';
import { useState } from 'react';
import brelly from "@brelly/sdk";
import Input from 'components/Input';
import { AddFileModal } from 'modals';
import { AttachmentItem } from 'items';
import TextArea from 'components/TextArea';
import { BrellyBtn } from 'components/Button';
import { LiaPaperclipSolid } from 'react-icons/lia';
import { useProject } from 'pages/Dash/Claims/Claim/context';
import { DateSelect, TimeSelect, SingleSelect } from 'components/Select';

export const AddCall = ({ setAddEvent }) => {
  const { setError, setLoading, projectId, claimId, contacts } = useApp();
  const { handleTimeline, handleFiles: handleClaimFiles } = useProject();
  const [title, setTitle] = useState('');
  const [date, setDate] = useState();
  const [files, setFiles] = useState([]);
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [selectedCaller, setSelectedCaller] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState([]);
  const formattedUsers = contacts?.map((u, idx) => ({ ...u, label: `${u?.firstName} ${u?.lastName}`, value: u?.id }));
  const [desc, setDescription] = useState('');

  const handleFiles = (_files) => {
    setFiles(_files)
    setAddFilesModal(false)
  }

  const handleAdd = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.phoneCalls.create({
        projectId: projectId,
        topic: title,
        description: desc,
        claimId: claimId,
        callerId: selectedCaller?.id,
        recieverId: selectedRecipient?.id,
        occuredAt: date
      })
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id)

        if (localFiles?.length > 0) {
          await brelly.phoneCalls.uploadFiles(data?.id, localFiles)
        }

        if (remoteFiles?.length > 0) {
          await brelly.phoneCalls.addFiles(data?.id, remoteFiles)
        }
        handleClaimFiles()
      }
      handleTimeline()
      setLoading(false);
      setAddEvent(false)
    } catch(err) {
      setError(err?.message);
      setLoading(false);
    }
  }

  return(
    <div className='row'>
      <div className='col-12 col-lg-6'>
        <Input label='Primary Topic' value={title} onChange={setTitle} />
      </div>
      <div className='col-12 col-lg-6'>
        <div className='row'>
          <div className='col-7'>
            <DateSelect label='Date' value={date} onChange={setDate} />
          </div>
          <div className='col-5'>
            <TimeSelect value={date} onChange={setDate} label="Time" />
          </div>
        </div>
      </div>
      <div className='col-12 col-lg-6'>
        <SingleSelect style={{ width: '100%' }} value={selectedCaller} onChange={setSelectedCaller} options={formattedUsers} label='Caller' />
      </div>
      <div className='col-12 col-lg-6'>
        <SingleSelect style={{ width: '100%' }} value={selectedRecipient} onChange={setSelectedRecipient} options={formattedUsers} label='Recipient' />
      </div>
      <div className='col-12'>
        <TextArea style={{ height: 80 }} label='Description' value={desc} onChange={setDescription} />
      </div>
      <div className='row mt-2'>
        {files?.map((m) => (
          <AttachmentItem key={m?.id} file={m} />
        ))}
      </div>
      <div className='jb-ac mt-2 d-block d-lg-flex'>
        <div className='brelly-text-btn jc-ac' onClick={() => setAddFilesModal(true)}>
          <LiaPaperclipSolid style={{ marginBottom: 2 }} />
          Attach Files
        </div>
        <div className='je-ac d-block d-lg-flex'>
          <BrellyBtn onClick={() => setAddEvent(false)} className='me-0 me-lg-4 mb-1 mb-lg-0' style={{ background: 'transparent', boxShadow: 'none', color: '#ff0000', textShadow: 'none' }}>Cancel</BrellyBtn>
          <BrellyBtn onClick={handleAdd}>Add</BrellyBtn>
        </div>
      </div>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
      />
    </div>
  )
}
