import { useState } from 'react';
import CallItem from './Call';
import EmailItem from './Email';
import EventItem from './Event';
import FileItem from './File';
import TaskItem from './Task';
import { ItemSpacer } from '../index';
import { format } from 'date-fns';
import { ViewTaskModal, EditContactModal } from 'modals';

const TimelineItem = ({ task, last }) => {
  const [contactModal, setContactModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [emailModal, setEmailModal] = useState(false);
  const [taskModal, setTaskModal] = useState(false);

  const handleContactModal = (contact) => {
    setContactModal(true)
    setSelectedContact(contact)
  }

  const closeContactModal = () => {
    setContactModal(false)
    setSelectedContact({})
  }

  const eventTypeSwitch = (ev) => {
    if (ev?.occuredAt) {
      return <CallItem ev={ev} handleContactModal={handleContactModal} />
    } else if (ev?.sentAt) {
      return <EmailItem ev={ev} setEmailModal={setEmailModal} handleContactModal={handleContactModal} />
    } else if (ev?.status) {
      return <TaskItem ev={ev} setTaskModal={setTaskModal} />
    } else if (ev?.endDate) {
      return <EventItem ev={ev} setTaskModal={setTaskModal} />
    } else if (ev?.downloadUrl) {
      return <FileItem ev={ev} />
    } else {
      return null;
    }
  }

  return(
    <>
      <div className='row'>
        <div className=' col-2 col-lg-1 jc-ac' style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, flexDirection: 'column' }}>
          <ItemSpacer top={true} task={task} last={last} />
          <div className='dash-table-item' style={{ color: '#64666B' }}>{format(new Date(task.createdAt), "hh:mm")}</div>
          <ItemSpacer task={task} last={last} />
        </div>
        <div className='col-10 col-lg-11'>
          {eventTypeSwitch(task)}
        </div>
      </div>
    </>
  )
}

export default TimelineItem;
