import brelly from "@brelly/sdk";
import { useApp } from "context";
import Input from 'components/Input';
import { standardizeUrl } from './utils';
import { useState, useEffect } from 'react';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';
import { useProject } from "pages/Dash/Claims/Claim/context";

export const EditLinkModal = ({ currentLink, open, handleClose }) => {
  const { claim, handleClaim } = useProject();
  const { setLoading, setError } = useApp();
  const [link, setLink] = useState({})
  const existingLinks = claim?.links || [];

  const handleChange = (key, value) => {
    setLink({ ...link, [key]: value })
  }

  useEffect(() => {
    if (open) {
      setLink(currentLink)
    }
    //eslint-disable-next-line
  }, [open])

  const handleEdit = async () => {
    if (link?.name?.length > 0 && link?.url?.length > 0) {
      try {
        setLoading(true);
        let formatted = { ...link, url: standardizeUrl(link) }
        let others = existingLinks?.filter((l) => l?.id !== link?.id);
        await brelly.claims.update(claim?.id, { links: [...others, formatted] });
        handleClaim(false)
        setLoading(false);
        handleClose();
      } catch (err) {
        setError(err?.message);
        setLoading(false);
      }
    }
  };

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Link</div>
      <Input value={link?.name} onChange={(v) => handleChange('name', v)} label='Name' />
      <Input value={link?.url} onChange={(v) => handleChange('url', v)} label='URL' />
      <BrellyBtn onClick={handleEdit} className='mt-4' style={{ width: 400, maxWidth: '100%' }}>Save</BrellyBtn>
    </CenterModal>
  )
}
