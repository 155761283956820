import { ViewEventModal } from 'pages/Viewer/Modals/ViewEvent';
import { CalendarIcon } from 'components/Icons';
import { ItemSpan } from './components';
import { useState } from 'react';

const EventItem = ({ ev }) => {
  const [modal, setModal] = useState(false);

  return(
    <>
      <div className='js-ac'>
        <div style={{ width: 32, height: 32, borderRadius: '50%', background: '#DBEFFE', flexShrink: 0 }} className='jc-ac me-2'>
          <CalendarIcon />
        </div>
        <ItemSpan m='me-1' text={ev?.name} onClick={() => setModal(true)} />
      </div>
      <ViewEventModal open={modal} handleClose={() => setModal(false)} task={ev} />
    </>
  )
}

export default EventItem;
