import { useProject } from "pages/Dash/Claims/Claim/context";
import { useState, useEffect, useRef } from "react";
import { useApp } from "context";
import { CenterModal, InlineModal } from "components/Modals";
import brelly from "@brelly/sdk";
import { SingleSelect, MultiSelect } from "components/Select";
import { BrellyBtn, BrellyIconBtn } from "components/Button";
import Input from "components/Input";
import { format, addYears } from "date-fns";
import { IoChevronDownOutline } from "react-icons/io5";
import { TrashIcon } from "components/Icons";
import CheckBox from "components/CheckBox";

export const ShareClaimModal = ({ open, handleClose }) => {
  const { setLoading, projectId, setError } = useApp();
  const { policy, property, claimId } = useProject();
  const [email, setEmail] = useState("");
  const [type, setType] = useState([{ label: "All", value: 1, all: true }]);
  const [existingShares, setExistingShares] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const getExistingShares = async () => {
    try {
      let { data } = await brelly.projects.getAccessLinks(projectId);
      setExistingShares(data)
    } catch(err) {
      console.log(err);
    }
  }

  const reset = () => {
    setEmail("");
    setType([{ label: "All", value: 1 }]);
  };

  useEffect(() => {
    if (open) {
      reset();
      getExistingShares()
    }
  }, [open]);

  const addShare = async () => {
    if (email) {
      try {
        setLoading(true);
        setConfirmModal(false)
        let test = await brelly.accessLinks.create({
          email,
          allowedTabs: type?.map((t) => t?.label),
          projectId,
          propertyId: property?.id,
          policyId: policy?.id,
          claimId
        })
        getExistingShares()
        setLoading(false);
      } catch(err) {
        setLoading(false);
        setError(err?.message)
      }
    } else {
      setError("Please add an email address")
    }
  };

  return (
    <>
      <CenterModal open={open} handleClose={handleClose} style={{ width: 700 }}>
        <div style={{ fontWeight: 600, fontSize: 20 }} className='mb-3'>Share Claim Info</div>
        <Input
          value={email}
          onChange={setEmail}
          placeholder="bill.williams@abcroofers.com"
          label="Email Address"
        />
        <MultiSelect
          label="Select claim info to share"
          value={type}
          onChange={setType}
          options={shareTypes}
          style={{ width: "100%" }}
        />
        <BrellyBtn className='mt-4' onClick={() => setConfirmModal(true)}>
          Share
        </BrellyBtn>

        {existingShares?.length > 0 && (
          <div style={{ width: "100%", borderTop: "1px solid #eeeeee" }} className="mt-4">
            <div className="js-ac mt-3">
              <div style={{ width: "30%" }} className="dash-table-header">
                Email
              </div>
              <div style={{ width: "20%" }} className="dash-table-header">
                Shared
              </div>
              <div style={{ width: "20%" }} className="dash-table-header">
                Link Expiration
              </div>
              <div style={{ width: "20%" }} className="dash-table-header">
                Shared Info
              </div>
              <div style={{ width: "10%" }} className="dash-table-header">
                Active
              </div>
            </div>
            {existingShares?.map((s, key) => (
              <ShareItem share={s} key={key} getExistingShares={getExistingShares} />
            ))}
          </div>
        )}

      </CenterModal>

      <CenterModal open={confirmModal} handleClose={() => setConfirmModal(false)}>
        <h1>Attention!</h1>
        <div className='mt-3 mb-4'>The information you're about to share may contain sensitive financial and other personal information.  Brelly cannot stop the recipient of this link from sharing the link with other third parties. Proceed?</div>
        <div className='row'>
          <div className='col-6'>
            <BrellyBtn style={{ background: 'red' }} onClick={() => setConfirmModal(false)}>Cancel</BrellyBtn>
          </div>
          <div className='col-6'>
            <BrellyBtn onClick={addShare}>Share</BrellyBtn>
          </div>
        </div>
      </CenterModal>
    </>
  );
};

const ShareItem = ({ share, getExistingShares }) => {
  const { setLoading, setError } = useApp();
  const [shareTypeModal, setShareTypeModal] = useState(false);
  const shareTypeBtnRef = useRef(null);
  const allowedTabs = share?.allowedTabs?.map((t) => t)?.join(", ") || ""

  const update = async (changes) => {
    try {
      setLoading(true);
      await brelly.accessLinks.update(share?.id, changes)
      getExistingShares()
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err.message)
    }
  }

  const deleteLink = async () => {
    try {
      setLoading(true);
      await brelly.accessLinks.delete(share?.id)
      getExistingShares()
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err.message)
    }
  }

  return (
    <>
      <div className="js-ac mt-2">
        <div style={{ width: "30%", textTransform: "lowercase" }} className="dash-table-item">
          {share?.email || "NO EMAIL"}
        </div>
        <div style={{ width: "20%" }} className="dash-table-item">
          {format(new Date(share?.createdAt), 'MM/dd/yyyy')}
        </div>
        <div style={{ width: "20%" }} className="dash-table-item">
          {format(addYears(new Date(share?.createdAt), 2), 'MM/dd/yyyy')}
        </div>
        <div style={{ width: "20%", cursor: 'pointer' }} onClick={() => setShareTypeModal(true)} ref={shareTypeBtnRef} className="dash-table-item">
          <div className='truncate1'>{allowedTabs?.slice(0, 12)}{allowedTabs?.length > 12 && "..."}</div>
          <IoChevronDownOutline style={{ marginLeft: 2, marginTop: 1 }} />
        </div>
        <div style={{ width: "10%" }} className="dash-table-item js-ac">
          <MiniCheckBox value={share?.active} onChange={() => update({ active: !share?.active })} />
          <div style={{ marginLeft: 12, paddingBottom: 1 }} onClick={deleteLink}>
            <TrashIcon stroke="#ff0000" size={18} />
          </div>
        </div>
      </div>
      <UpdateShareTypeModal share={share} btnRef={shareTypeBtnRef} open={shareTypeModal} update={update} handleClose={() => setShareTypeModal(false)} />
    </>
  );
};

const UpdateShareTypeModal = ({ share, btnRef, open, handleClose, update }) => {
  const [selected, setSelected] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (open) {
      setSelected(share?.allowedTabs || [])
    }
  }, [open])

  const handleChange = (newValue) => {
    setHasChanges(true);

    if (newValue === 'All') {
      setSelected(["All"])
    } else if (selected?.includes(newValue)) {
      let remaining = selected?.filter((t) => t !== newValue && t !== 'All');
      setSelected(remaining)
    } else {
      let remaining = selected?.filter((t) => t !== 'All');
      setSelected([...remaining, newValue])
    }
  }

  const onClose = () => {
    if (hasChanges) {
      update({ allowedTabs: selected })
    }

    handleClose()
  }

  return(
    <InlineModal btnRef={btnRef} open={open} handleClose={onClose}>
      {shareTypes?.map((st, idx) => (
        <CheckBox
          title={st?.label}
          value={selected?.includes(st?.label)}
          setValue={() => handleChange(st?.label)}
          className={idx === shareTypes?.length - 1 ? "pe-3" : "mb-3 pe-3"}
        />
      ))}
    </InlineModal>
  )
}

const MiniCheckBox = ({ value, onChange = () => {} }) => {
  return (
    <div
      style={{
        width: 26,
        height: 16,
        background: value ? "#0D72C5" : "",
        borderRadius: 10,
        padding: 1,
        border: value ? "1px solid transparent" : "1px solid #0D72C5",
      }}
      className={value ? "je-ac" : "js-ac"}
      onClick={onChange}
    >
      <div
        style={{
          background: value ? "white" : "#0D72C5",
          borderRadius: "50%",
          height: 12,
          width: 12,
        }}
        className="shadow-sm"
      />
    </div>
  );
};

const shareTypes = [
  { label: "All", value: 1, all: true },
  { label: "Timeline", value: 2 },
  { label: "Claim Details", value: 3 },
  { label: "Files", value: 4 },
  { label: "Expenses", value: 5 },
  { label: "Contents Inventory", value: 6 }
];
