// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface LendersInterface {
  getAll: (query?: Query) => Promise<any>;
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
}

export const lenders = (client: Axios): LendersInterface => ({
  getAll: async (query?: Query) => {
    const url = addUrlQuery("/lenders", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/lenders/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/lenders", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },
});
