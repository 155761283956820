// Internal Imports
import { CenterModal } from "components/Modals";
import ImportFlow from "components/ImportFlow";
import { useApp } from "context";

// Brelly SDK
import brelly from "@brelly/sdk";

const contactSchema = [
  { label: "First Name", key: "firstName", type: "string", isArray: false },
  { label: "Middle Name", key: "middleName", type: "string", isArray: false },
  { label: "Last Name", key: "lastName", type: "string", isArray: false },
  { label: "Nickname", key: "nickname", type: "string", isArray: false },
  { label: "Primary Email", key: "primaryEmail", type: "string", isArray: false },
  { label: "Other Emails", key: "otherEmails", type: "string", isArray: true },
  { label: "Primary Phone Number", key: "primaryPhoneNumber", type: "string", isArray: false },
  { label: "Other Phone Numbers", key: "otherPhoneNumbers", type: "string", isArray: true },
  { label: "Role", key: "role", type: "string", isArray: false },
  { label: "Company", key: "company", type: "string", isArray: false },
  { label: "Job Title", key: "jobTitle", type: "string", isArray: false },
  { label: "Website", key: "website", type: "string", isArray: false },
  { label: "Notes", key: "notes", type: "string", isArray: false },
  { label: "Birthday", key: "birthday", type: "string", isArray: false },
  {
    label: "Are these contacts private?",
    key: "isPrivate",
    type: "boolean",
    isArray: false,
    global: true,
    value: false,
  },
];

interface ImportContactModalProps {
  open: boolean;
  handleClose: () => void;
}

export const ImportContactModal = ({ open, handleClose }: ImportContactModalProps) => {
  const { orgId, setLoading, handleContacts, membershipId, setError } = useApp();

  if (open) {
    const handleImportContacts = async (contacts: any[]) => {
      setLoading(true);
      try {
        await brelly.contacts.batchCreate(contacts, {
          organizationId: orgId,
          membershipId,
        });
        await handleContacts();
        handleClose();
      } catch (err) {
        setError("Failed to import contacts");
      }
      setLoading(false);
    };

    return (
      <>
        <CenterModal open={open} handleClose={handleClose} style={{ width: 800, maxWidth: "100%" }}>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Import Contacts</div>
          <ImportFlow modelSchema={contactSchema} importCallback={handleImportContacts} />
        </CenterModal>
      </>
    );
  } else {
    return null;
  }
};
