// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface FilesInterface {
  get: (id: string, query?: Query) => Promise<any>;
  create: (file: any, values: { [key: string]: any }, query?: Query) => Promise<any>;
  batchCreate: (files: any[], values: { [key: string]: any }[], query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  batchUpdate: (ids: string[], values: { [key: string]: any }, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  batchDelete: (ids: string[], query?: Query) => Promise<any>;
  setTags: (fileIds: string[], tagIds: string[], query?: Query) => Promise<any>;
  addTags: (fileIds: string[], tagIds: string[], query?: Query) => Promise<any>;
  removeTags: (fileIds: string[], tagIds: string[], query?: Query) => Promise<any>;
  setLossTypes: (fileIds: string[], lossTypeIds: string[], query?: Query) => Promise<any>;
  addLossTypes: (fileIds: string[], lossTypeIds: string[], query?: Query) => Promise<any>;
  removeLossTypes: (fileIds: string[], lossTypeIds: string[], query?: Query) => Promise<any>;
}

export const files = (client: Axios): FilesInterface => ({
  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/files/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (file: any, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/files", query);
    const formData = new FormData();
    formData.append("files", file);
    const cleanedValues = cleanValues(values);
    formData.append("values", cleanedValues);

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  batchCreate: async (files: any[], values: { [key: string]: any }[], query?: Query) => {
    const url = addUrlQuery("/files/batch", query);
    const formData = new FormData();
    if (Array.isArray(files)) {
      files.forEach((file) => formData.append("files", file));
    }

    if (Array.isArray(values)) {
      const cleanedValues = cleanValues(values);
      cleanedValues.forEach((value: any) => formData.append("values", value));
    }

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/files/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  batchUpdate: async (ids: string[], values: { [key: string]: any }, query?: Query) => {
    const idsStr = Array.isArray(ids) ? ids.toString() : [ids].toString();
    const url = addUrlQuery(`/files/batch/${idsStr}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/files/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  batchDelete: async (ids: string[], query?: Query) => {
    const idsStr = Array.isArray(ids) ? ids.toString() : [ids].toString();
    const url = addUrlQuery(`/files/batch/${idsStr}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  setTags: async (fileIds: string[], tagIds: string[], query?: Query) => {
    const url = addUrlQuery("/files/tags/set", query);
    const res: AxiosResponse = await client.post(url, { fileIds, tagIds });
    return {
      data: res.data,
    };
  },

  addTags: async (fileIds: string[], tagIds: string[], query?: Query) => {
    const url = addUrlQuery("/files/tags/add", query);
    const res: AxiosResponse = await client.post(url, { fileIds, tagIds });
    return {
      data: res.data,
    };
  },

  removeTags: async (fileIds: string[], tagIds: string[], query?: Query) => {
    const url = addUrlQuery("/files/tags/remove", query);
    const res: AxiosResponse = await client.post(url, { fileIds, tagIds });
    return {
      data: res.data,
    };
  },

  setLossTypes: async (fileIds: string[], lossTypeIds: string[], query?: Query) => {
    const url = addUrlQuery("/files/loss-types/set", query);
    const res: AxiosResponse = await client.post(url, { fileIds, lossTypeIds });
    return {
      data: res.data,
    };
  },

  addLossTypes: async (fileIds: string[], lossTypeIds: string[], query?: Query) => {
    const url = addUrlQuery("/files/loss-types/add", query);
    const res: AxiosResponse = await client.post(url, { fileIds, lossTypeIds });
    return {
      data: res.data,
    };
  },

  removeLossTypes: async (fileIds: string[], lossTypeIds: string[], query?: Query) => {
    const url = addUrlQuery("/files/loss-types/remove", query);
    const res: AxiosResponse = await client.post(url, { fileIds, lossTypeIds });
    return {
      data: res.data,
    };
  },
});
