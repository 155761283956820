// External Imports
import { BsFiletypeCsv } from "react-icons/bs";

// Internal Imports
import { SingleSelect, MultiSelect } from "./selects";
import GlobalInputSwitch from "./GlobalInputSwitch";
import FileSelector from "components/FileSelector";

interface FileSelectScreenProps {
  files: File[];
  setFiles: (f: File[]) => void;
}

export const FileSelectScreen = ({ files, setFiles }: FileSelectScreenProps) => {
  return (
    <>
      <FileSelector files={files} setFiles={setFiles} accept={["csv"]} multiple={false} />
      {files && files.length > 0 && (
        <div style={{ margin: 20 }}>
          <BsFiletypeCsv style={{ fontSize: 60, color: "green" }} />
          <div style={{ fontSize: 18 }}>{files[0].name}</div>
        </div>
      )}
    </>
  );
};

interface SchemaMapScreenProps {
  modelSchema: any;
  schemaMap: any;
  setSchemaMap: (schemaMap: any) => void;
  csvColumns: string[];
}

export const SchemaMapScreen = ({ schemaMap, setSchemaMap, csvColumns }: SchemaMapScreenProps) => {
  const handleMappingChange = (field: any, valueKey: string, value: any): void => {
    const newSchemaMap = schemaMap.map((map: any) =>
      map.key === field.key ? { ...map, [valueKey]: value } : map
    );

    setSchemaMap(newSchemaMap);
  };

  return (
    <div style={{ height: 400, overflow: "auto", padding: 10 }}>
      {schemaMap.map((field: any) => (
        <div key={field.key} style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: 300, padding: "10px 0px" }}>
            {field.label}
            {field.required && <b style={{ color: "red" }}> **</b>}
          </div>
          {field.global ? (
            <div style={{ width: 400 }}>
              <GlobalInputSwitch
                type={field.type}
                value={field.value}
                onChange={(value: string) => handleMappingChange(field, "value", value)}
              />
            </div>
          ) : field.isArray ? (
            <MultiSelect
              value={field.csvColumn}
              onChange={(value: string[]) => handleMappingChange(field, "csvColumn", value)}
              options={csvColumns}
              placeholder="Select columns"
              label=""
              style={{ width: 400 }}
            />
          ) : (
            <SingleSelect
              value={field.csvColumn}
              onChange={(value: string) => handleMappingChange(field, "csvColumn", value)}
              options={csvColumns}
              placeholder="Select a column"
              label=""
              style={{ width: 400 }}
            />
          )}
        </div>
      ))}
    </div>
  );
};
