import { useState, useEffect } from "react";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { useApp } from "context";
import { parseAddress } from "utils";
import { useSelectStyles2 } from "theme/selectStyles";

const LocationPicker = ({
  label = '',
  value = null,
  placeholder = '',
  setValue = () => {},
  disabled
}: any) => {
  // @ts-expect-error TS(2339): Property 'setError' does not exist on type 'unknow... Remove this comment to see the full error message
  const { setError } = useApp();
  const [inFocus, setInFocus] = useState(false);
  const { selectStyles } = useSelectStyles2(inFocus);
  const [localValue, setLocalValue] = useState(null);

  useEffect(() => {
    if (value?.brellyId) {
      setLocalValue(value);
    }
  }, [value]);

  const handleChange = async (newVal: any) => {
    if (newVal?.value) {
      let placeId = newVal?.value?.place_id;

      geocodeByPlaceId(placeId)
        .then((results) => {
          let parsedAddress = parseAddress(results, newVal);
          // @ts-expect-error TS(2774): This condition will always return true since this ... Remove this comment to see the full error message
          if (parseAddress) {
            setValue(parsedAddress);
            setLocalValue(newVal);
          } else {
            setError(
              "There was an error saving your address. Please try again"
            );
          }
        })
        .catch((error) => {
          setError("There was an error saving your address. Please try again");
        });
    } else {
      setError("There was an error saving your address. Please try again");
    }
  };

  const handleFocus = () => {
    setInFocus(true);
    setLocalValue(null);
  }

  const handleBlur = () => {
    setInFocus(false);
  }

  return (
    <>
      {label && <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className='mt-2'>{label}</div>}
      <GooglePlacesAutocomplete
        apiKey="AIzaSyBJ7nzSVGBJ0Wy5jNjusB59EKDPJQ3tghw"
        autocompletionRequest={{
          types: ["address"],
        }}
        selectProps={{
          value: localValue,
          onChange: handleChange,
          onFocus: handleFocus,
          onBlur: handleBlur,
          placeholder: placeholder || "123 Main St.",
          styles: selectStyles,
          isDisabled: disabled
        }}
      />
    </>
  );
};

export default LocationPicker;
