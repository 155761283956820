// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface LegalInterface {
  getCurrentTnCVersion: (query?: Query) => Promise<any>;
  getTnCVersions: (query?: Query) => Promise<any>;
  getCurrentTnC: (query?: Query) => Promise<any>;
  getTnC: (version: string, query?: Query) => Promise<any>;
  getCurrentPrivacyPolicyVersion: (query?: Query) => Promise<any>;
  getPrivacyPolicyVersions: (query?: Query) => Promise<any>;
  getCurrentPrivacyPolicy: (query?: Query) => Promise<any>;
  getPrivacyPolicy: (version: string, query?: Query) => Promise<any>;
}

export const legal = (client: Axios): LegalInterface => ({
  getCurrentTnCVersion: async (query?: Query) => {
    const url = addUrlQuery("/legal/terms-and-conditions/versions/current", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  getTnCVersions: async (query?: Query) => {
    const url = addUrlQuery("/legal/terms-and-conditions/versions", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  getCurrentTnC: async (query?: Query) => {
    const url = addUrlQuery("/legal/terms-and-conditions/current", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  getTnC: async (version: string, query?: Query) => {
    const url = addUrlQuery(`/legal/terms-and-conditions/${version || ""}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  getCurrentPrivacyPolicyVersion: async (query?: Query) => {
    const url = addUrlQuery("/legal/privacy-policy/versions/current", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  getPrivacyPolicyVersions: async (query?: Query) => {
    const url = addUrlQuery("/legal/privacy-policy/versions", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  getCurrentPrivacyPolicy: async (query?: Query) => {
    const url = addUrlQuery("/legal/privacy-policy/current", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  getPrivacyPolicy: async (version: string, query?: Query) => {
    const url = addUrlQuery(`/legal/privacy-policy/${version || ""}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },
});
