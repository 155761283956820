import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { useState, useEffect } from 'react';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';
import TeammemberSelector from 'components/TeammemberSelector';

export const AddTeamMemberModal = ({ open, members, handleUpdate, handleClose }) => {
  const { setLoading, setError, projectId } = useApp();
  const currentTeam = members?.map((m) => ({ label: `${m?.user?.firstName} ${m?.user?.lastName}`, value: m?.ProjectContributor?.membershipId }))
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (open) {
      setSelectedUsers(currentTeam)
    }
    //eslint-disable-next-line
  }, [open])

  const handleSave = async () => {
    try {
      setLoading(true);
      let formatted = selectedUsers?.map((u) => u?.value);
      await brelly.projects.setContributors(projectId, formatted)
      handleUpdate()
      setLoading(false);
      handleClose();
    } catch (err) {
      setError(err.message)
      setLoading(false);
    }
  };

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add a Team Member</div>
      <TeammemberSelector value={selectedUsers} onChange={setSelectedUsers} />
      <BrellyBtn className='mt-4' onClick={handleSave} style={{ width: 300 }}>Save</BrellyBtn>
    </CenterModal>
  )
}
