export const parseAddress = (geoObject: any, autoCompleteObj: any) => {
  if (geoObject?.length > 0) {
    const value = geoObject[0] || {};
    let lat = value?.geometry?.location?.lat();
    let lng = value?.geometry?.location?.lng();
    let addressArr = value?.address_components;
    let streetNumber = addressArr?.find((a: any) => a.types?.includes("street_number"))?.short_name;
    let street = addressArr?.find((a: any) => a.types?.includes("route"))?.short_name;
    let neighborhood = addressArr?.find((a: any) => a.types?.includes("neighborhood"))?.short_name;
    let city = addressArr?.find((a: any) => a.types?.includes("locality"))?.short_name;
    let parishCounty = addressArr?.find((a: any) => a.types?.includes("administrative_area_level_2"))?.short_name;
    let state = addressArr?.find((a: any) => a.types?.includes("administrative_area_level_1"))?.short_name;
    let country = addressArr?.find((a: any) => a.types?.includes("country"))?.short_name;
    let postal = addressArr?.find((a: any) => a.types?.includes("postal_code"))?.short_name;

    let fullAddress = autoCompleteObj?.label;
    let placeId = autoCompleteObj?.value?.place_id;

    return {
      lat,
      lng,
      fullAddress,
      placeId,
      street: `${streetNumber} ${street}`,
      city,
      state,
      postal,
      country,
      parishCounty,
      neighborhood,
      coords: { type: "Point", coordinates: [lng, lat] }
    }
  } else {
    return false
  }
}
