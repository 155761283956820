import { BookIcon, CircleInfoIcon } from "components/Icons";
import { useRef } from "react";
import { useIntercom } from 'react-use-intercom';
import { Transition } from 'react-transition-group';

const defaultStyle = {
  transition: `all 300ms ease-in-out`,
  transform: "translate(110%, 0%)",
  position: 'fixed', right: 0, top: 0, width: '90%', maxWidth: 500, height: '100vh', background: '#0D72C5', zIndex: 100000
}

const transitionStyles = {
  entering: { transform: "translate(0%, 0%)" },
  entered:  { transform: "translate(0%, 0%)" },
  exiting:  { transform: "translate(110%, 0%)" },
  exited:  { transform: "translate(110%, 0%)" },
};

export const MobileMenu = ({ open, setOpen }) => {
  const { show } = useIntercom();
  const nodeRef = useRef(null);

  return(
    <>
      {open && <div onClick={() => setOpen(false)} style={{ width: '100%', height: '100vh', background: 'rgba(0,0,0,.2)', left: 0, top: 0, zIndex: 10000, position: 'fixed' }} />}
      <Transition nodeRef={nodeRef} in={open} timeout={300}>
        {state => (
          <div ref={nodeRef} style={{ ...defaultStyle, ...transitionStyles[state] }} className='ps-3 pe-3 pb-3 pt-5'>
            <a
              onClick={() => setOpen(false)}
              href="https://brelly.com/claim-resources/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn menu-a"
            >
              <BookIcon
                style={{ marginRight: 12, marginTop: 5 }}
                stroke="#ffffff"
              />
              Brelly Resources
            </a>
            <div
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
              onClick={show}
            >
              <CircleInfoIcon style={{ marginRight: 12 }} stroke="#ffffff" />
              Help
            </div>
          </div>
        )}
      </Transition>
    </>
  )
}

const Menu = () => {
  const contentHeight = window.innerHeight - 90;
  const { show } = useIntercom();

  return (
    <>
      <div style={{ width: 260, height: "100vh", paddingTop: 24 }} className='d-none d-lg-block'>
        <img
          alt=""
          style={{ height: 56, marginBottom: 24, marginLeft: 26 }}
          src="/assets/logos/logoAB.png"
        />
        <div
          style={{
            height: contentHeight,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div></div>
          <div style={{ paddingBottom: 24 }}>
            <a
              href="https://brelly.com/claim-resources/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn menu-a"
            >
              <BookIcon
                style={{ marginRight: 12, marginTop: 5 }}
                stroke="#64666B"
              />
              Brelly Resources
            </a>
            <div
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                color: "#64666B",
                marginBottom: 12
              }}
              className="js-ac dash-menu-btn"
              onClick={show}
            >
              <CircleInfoIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Help
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
