import brelly from "@brelly/sdk";
import { useApp } from "context";
import Input from "components/Input";
import { getClaimDisplay } from "utils";
import TextArea from "components/TextArea";
import { useState, useEffect } from "react";
import { PhotoIcon } from "components/Icons";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { SimpleFileModal } from "modals";
import PhoneNumberPicker from "components/PhoneNumber";
import LocationPicker from "components/LocationPicker2";

export const EditAgentModal = ({
  open,
  contact,
  handleUpdate = () => {},
  handleClose,
}) => {
  if (open) {
    return (
      <Inner
        open={open}
        contact={contact}
        handleUpdate={handleUpdate}
        handleClose={handleClose}
      />
    );
  } else {
    return null;
  }
};

const Inner = ({ open, contact, handleUpdate = () => {}, handleClose }) => {
  const { setError, setLoading, handleContacts, projects } = useApp();
  const [fileModal, setFileModal] = useState(false);
  const [fName, setFName] = useState("");
  const [mName, setMName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [claims, setClaims] = useState([]);
  const [notes, setNotes] = useState("");
  const [file, setFile] = useState(null);
  const [address, setAddress] = useState({});
  const formattedProjects = contact?.projects?.map((p) => ({
    ...p,
    label: getClaimDisplay(p?.id, projects),
    value: p?.id,
  }));

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.contacts.delete(contact?.id);
      handleClose();
      await handleUpdate();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err?.message);
    }
  };

  const getProPic = () => {
    if (file?.name) {
      return URL.createObjectURL(file);
    } else if (contact?.profilePic) {
      return contact?.profilePic?.downloadUrl;
    }
  };

  const handleEdit = async () => {
    if (!fName) {
      setError("Please add a first name");
    } else if (!lName) {
      setError("Please add a last name");
    } else if (!email) {
      setError("Please add an email address");
    } else {
      try {
        setLoading(true);
        let addressData = {};

        if (address?.lat) {
          addressData = await brelly.addresses.create(address);
        } else {
          addressData = address;
        }

        await brelly.contacts.update(contact?.id, {
          firstName: fName,
          middleName: mName,
          lastName: lName,
          primaryEmail: email,
          primaryPhoneNumber: phone,
          notes: notes,
          otherEmails: [email2],
          otherPhoneNumbers: [phone2],
          homeAddressId: addressData?.id,
          isPrivate: false,
        });
        if (claims?.length > 0) {
          await brelly.contacts.setProjects(
            contact?.id,
            claims?.map((c) => c?.id)
          );
        }
        if (file?.name) {
          await brelly.contacts.uploadProfilePic(contact?.id, file);
        }
        handleContacts();
        handleUpdate();
        setLoading(false);
        handleClose();
      } catch (err) {
        setError(err?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setNotes(contact?.notes || "");
      setFName(contact?.firstName || "");
      setMName(contact?.middleName || "");
      setLName(contact?.lastName || "");
      setEmail(contact?.primaryEmail || "");
      setPhone(contact?.primaryPhoneNumber || "");

      let e2 = contact?.otherEmails[0];
      if (e2?.length > 0) {
        setEmail2(e2);
      }
      let pn2 = contact?.otherPhoneNumbers[0];
      if (pn2?.length > 0) {
        setPhone2(pn2);
      }
      if (contact?.homeAddress) {
        let addy = contact?.homeAddress;
        setAddress({
          value: { placeId: addy?.placeId },
          label: addy?.fullAddress,
          brellyId: 1,
          id: addy?.id,
        });
      }
      if (formattedProjects?.length > 0) {
        setClaims(formattedProjects);
      }
    }
    //eslint-disable-next-line
  }, [open]);

  return (
    <>
      <CenterModal
        open={open}
        handleClose={handleClose}
        style={{ width: 800, maxWidth: "100%" }}
      >
        <div className="js-ac mb-2">
          <div
            onClick={() => setFileModal(true)}
            style={{
              width: 42,
              height: 42,
              borderRadius: "50%",
              background: "rgba(13, 114, 197, 0.16)",
              border: "1px dashed #0D72C5",
            }}
            className="jc-ac me-2"
          >
            <PhotoIcon />
            {getProPic() && (
              <img
                alt=""
                src={getProPic()}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            )}
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Agent</div>
        </div>
        <div
          className="row"
          style={{ maxHeight: window.innerHeight - 280, overflowY: "auto" }}
        >
          <div className="col-12 col-lg-6">
            <Input label="First Name" value={fName} onChange={setFName} />
            <Input label="Middle Name" value={mName} onChange={setMName} />
            <Input label="Last Name" value={lName} onChange={setLName} />
            <LocationPicker
              value={address}
              setValue={setAddress}
              label="Address"
            />
            <Input label="Email (primary)" value={email} onChange={setEmail} />
            <Input
              label="Email (alternate)"
              value={email2}
              onChange={setEmail2}
            />
          </div>
          <div className="col-12 col-lg-6">
            <PhoneNumberPicker
              label="Phone Number (primary)"
              value={phone}
              onChange={setPhone}
            />
            <PhoneNumberPicker
              label="Phone Number (alternate)"
              value={phone2}
              onChange={setPhone2}
            />
            <TextArea
              label="Notes"
              value={notes}
              onChange={setNotes}
              style={{ height: 158 }}
            />
          </div>
        </div>
        <BrellyBtn className="mt-4" onClick={handleEdit}>
          Save
        </BrellyBtn>
        <BrellyBtn
          className="mt-2"
          style={{
            border: "1px solid red",
            background: "rgba(0,0,0,0)",
            color: "red",
          }}
          onClick={handleDelete}
        >
          Delete
        </BrellyBtn>
      </CenterModal>
      <SimpleFileModal
        multiple={false}
        open={fileModal}
        onSave={setFile}
        handleClose={() => setFileModal(false)}
      />
    </>
  );
};
