import { useRef, useState, useEffect } from "react";
import { Route } from "react-router-dom";
// import { Network } from "@capacitor/network";
import { IonReactRouter } from "@ionic/react-router";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { useIntercom } from 'react-use-intercom';
//Internal Imports
//Context
import AppContext from "./context";
//Pages
import Dash from "./pages/Dash";
import Login from "./pages/Login";
import ShareViewer from "pages/Viewer";
import Register from "./pages/Register";
import Onboarding from './pages/Onboarding';
import VerifySignUp from "./pages/VerifySignUp";
import ResetPassword from "./pages/ResetPassword";
import RequestPasswordCode from "./pages/RequestPasswordCode";
//Global components
import Toasts from './components/Toasts';
import Errors from "./components/Errors";
import LoadingSpinner from "./components/LoadingSpinner";
// Bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
// internal sizes
// import "./theme/variables.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";
// /* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
// brelly class
import "./index.css";

setupIonicReact();

const App = () => {
  //eslint-disable-next-line
  const [network, setNetwork] = useState(true);
  document.body.style.overflow = "hidden";
  const routerRef = useRef(null);
  const outletRef = useRef(null);
  const { boot } = useIntercom();

  useEffect(() => {
    boot()
  }, [])

  return (
    // @ts-expect-error TS(2322): Type '{ children: Element; id: string; }' is not a... Remove this comment to see the full error message
    <IonApp id="main-content">
      <IonReactRouter ref={routerRef}>
        <AppContext outletRef={outletRef}>
          {/* @ts-expect-error TS(2322): Type '{ children: Element[]; swipeGesture: boolean... Remove this comment to see the full error message */}
          <IonRouterOutlet swipeGesture={false} ref={outletRef} id="outletRef">
            <Route exact path="/" component={Dash} />
            <Route exact path="/dash" component={Dash} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/verify" component={VerifySignUp} />
            <Route exact path="/onboarding" component={Onboarding} />
            <Route exact path="/access-link/:id" component={ShareViewer} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/request-password-code" component={RequestPasswordCode} />
          </IonRouterOutlet>
          <GlobalComponents />
        </AppContext>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

const GlobalComponents = () => {
  return (
    <>
      <Errors />
      <Toasts />
      <LoadingSpinner />
    </>
  );
};
