import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import PhoneNumberPicker from "components/PhoneNumber";
import LocationPicker from "components/LocationPicker2";

export const OrgDetailsSection = ({ orgTab }) => {
  const { setLoading, selectedOrg, setError, orgId, handleUser, selectedMembership } = useApp();
  const [mailingAddress, setMailingAddress] = useState({});
  const [website, setWebsite] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [name, setName] = useState("");
  const isSelected = orgTab === "1";
  const role = selectedMembership?.role;
  const canEdit = role === 'ADMIN' || role === 'OWNER'

  useEffect(() => {
    if (isSelected) {
      setWebsite(selectedOrg?.website || "");
      setPhoneNumber(selectedOrg?.phoneNumber || "");
      setFacebook(selectedOrg?.facebook || "");
      setInstagram(selectedOrg?.instagram || "");
      setLinkedIn(selectedOrg?.linkedIn || "");
      setName(selectedOrg?.name || "");
      if (selectedOrg?.address) {
        let addy = selectedOrg?.address
        setMailingAddress({ value: {placeId: addy?.placeId}, label: addy?.fullAddress, brellyId: 1, id: addy?.id })
      }
    }
    //eslint-disable-next-line
  }, [isSelected]);

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.organizations.update(orgId, {
        website,
        phoneNumber,
        facebook,
        instagram,
        linkedIn,
        name,
      });
      handleUser(false)
      setLoading(false);
    } catch (err) {
      setError(err?.message);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        paddingTop: 22,
        display: isSelected ? "" : "none",
      }}
    >
      <div
        className="dash-box-title"
        style={{ color: "black", marginBottom: 24 }}
      >
        Company Info
      </div>

      <div className='row'>
        <div className='col12 col-lg-6'>
          <Input
            placeholder="Brelly Adjusters Inc."
            value={name}
            onChange={setName}
            label="Name"
            disabled={!canEdit}
          />
        </div>
        <div className='col12 col-lg-6'>
          <Input
            placeholder="www.brelly.com"
            value={website}
            onChange={setWebsite}
            label="Website"
            disabled={!canEdit}
          />
        </div>
        <div className='col12 col-lg-6'>
          <PhoneNumberSwitch canEdit={canEdit} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
        </div>
        <div className='col12 col-lg-6'>
          <Input
            placeholder="Facebook"
            value={facebook}
            onChange={setFacebook}
            label="Facebook"
            disabled={!canEdit}
          />
        </div>
        <div className='col12 col-lg-6'>
          <Input
            placeholder="Instagram"
            value={instagram}
            onChange={setInstagram}
            label="Instagram"
            disabled={!canEdit}
          />
        </div>
        <div className='col12 col-lg-6'>
          <Input
            placeholder="LinkedIn"
            value={linkedIn}
            onChange={setLinkedIn}
            label="LinkedIn"
            disabled={!canEdit}
          />
        </div>
      </div>

      <LocationPicker
        value={mailingAddress}
        setValue={setMailingAddress}
        placeholder="123 Magazine St. New Orleans"
        label="Business Address"
        disabled={!canEdit}
      />

      {canEdit && (
        <div className="row je-ac mt-3">
          <div className='col12 col-lg-6'>
            <BrellyBtn onClick={handleEdit} style={{ width: "100%" }}>
              Save
            </BrellyBtn>
          </div>
        </div>
      )}

    </div>
  );
};

const PhoneNumberSwitch = ({ canEdit, phoneNumber, setPhoneNumber }) => {
  if (canEdit) {
    return(
      <PhoneNumberPicker
        value={phoneNumber}
        onChange={setPhoneNumber}
        label="Phone"
      />
    )
  } else {
    return (
      <Input
        placeholder={phoneNumber}
        value={phoneNumber}
        onChange={setPhoneNumber}
        label="Phone"
        disabled={true}
      />
    )
  }
}
