import { useState } from 'react';
import { FileIcon } from 'components/Icons';
import FileModals from 'components/FileModals';

const FileItem = ({ ev }) => {
  const [modal, setModal] = useState(false);

  return(
    <div className='js-ac'>
      <div style={{ width: 32, height: 32, borderRadius: '50%', background: '#DBEFFE', flexShrink: 0 }} className='jc-ac me-2'>
        <FileIcon />
      </div>
      <div className='js-ac' style={{ flexWrap: 'wrap' }}>
        New file <div onClick={() => setModal(true)} className='ms-1 me-1' style={{ cursor: 'pointer', fontWeight: 600, color: '#0D72C5' }}>{ev?.name}</div> uploaded
      </div>
      <FileModals file={ev} open={modal} setModal={setModal} />
    </div>
  )
}

export default FileItem;
