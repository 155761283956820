import { BsCheck } from 'react-icons/bs';

export const BooleanFilter = ({ title, filterKey, filters, updateFilters = () => {}, className = '' }) => {
  let isSelected = !!filters[filterKey];

  return(
    <div onClick={() => updateFilters({ [filterKey]: !filters[filterKey] })} className={`brelly-text-btn js-ac ${className}`} style={{ color: 'rgb(100, 102, 107)', fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
      <div className='jc-ac' style={{ width: 14, height: 14, borderRadius: 4, border: isSelected ? '1px solid #0D72C5' : '1px solid rgb(100, 102, 107)', marginRight: 4, marginBottom: 1, background: isSelected ? '#0D72C5' : '' }}>
        {isSelected && <BsCheck style={{ fontSize: 12, color: 'white' }} />}
      </div>
      {title}
    </div>
  )
}

export const BooleanFilter2 = ({ title, updateFilters, filterKey, value, filters, className = '' }) => {
  let isSelected = filters[filterKey] === value;

  const handleSelect = () => {
    if (isSelected) {
      updateFilters({ [filterKey]: '' })
    } else {
      updateFilters({ [filterKey]: value })
    }
  }

  return(
    <div onClick={handleSelect} className={`brelly-text-btn js-ac ${className}`} style={{ color: 'rgb(100, 102, 107)', fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
      <div className='jc-ac' style={{ width: 14, height: 14, borderRadius: 4, border: isSelected ? '1px solid #0D72C5' : '1px solid rgb(100, 102, 107)', marginRight: 4, marginBottom: 1, background: isSelected ? '#0D72C5' : '' }}>
        {isSelected && <BsCheck style={{ fontSize: 12, color: 'white' }} />}
      </div>
      {title}
    </div>
  )
}
