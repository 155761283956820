// External Imports
import { Axios, AxiosResponse } from "axios";

// External Imports
import { Auth } from "aws-amplify";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface UsersInterface {
  get: (id: string, query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  closeAccount: (id: string, query?: Query) => Promise<any>;
  reactivateAccount: (id: string, query?: Query) => Promise<any>;
  uploadProfilePic: (id: string, file: any, query?: Query) => Promise<any>;
  uploadCoverPhoto: (id: string, file: any, query?: Query) => Promise<any>;
  getProperties: (id: string, query?: Query) => Promise<any>;
  getLastUpdatedProperty: (id: string, query?: Query) => Promise<any>;
  getClaims: (id: string, query?: Query) => Promise<any>;
  getLastUpdatedClaim: (id: string, query?: Query) => Promise<any>;
  getTags: (id: string, query?: Query) => Promise<any>;
  getOrganizations: (id: string, query?: Query) => Promise<any>;
  getMemberships: (id: string, query?: Query) => Promise<any>;
  getMembershipInvites: (id: string, query?: Query) => Promise<any>;
  getPendingMembershipInvites: (id: string, query?: Query) => Promise<any>;
}

export const users = (client: Axios): UsersInterface => ({
  get: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  update: async (id: string = "current", values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/users/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  closeAccount: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    // TODO should we do this? how would they reactivate their account? maybe on the admin side?
    await Auth.deleteUser();
    return {
      data: res.data,
    };
  },

  reactivateAccount: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/reactivate`, query);
    const res: AxiosResponse = await client.post(url);
    return {
      data: res.data,
    };
  },

  uploadProfilePic: async (id: string = "current", file: any, query?: Query) => {
    const url = addUrlQuery(`/users/${id}/profile-picture`, query);
    const formData = new FormData();
    formData.append("file", file);

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  uploadCoverPhoto: async (id: string = "current", file: any, query?: Query) => {
    const url = addUrlQuery(`users/${id}/cover-photo`, query);
    const formData = new FormData();
    formData.append("file", file);

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  // *** Relationship Queries ***

  getProperties: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/properties`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getLastUpdatedProperty: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/properties`, {
      ...query,
      limit: 1,
      order: "-updatedAt",
    });

    const res: AxiosResponse = await client.get(url);
    if (Array.isArray(res.data)) {
      return {
        data: res.data[0],
      };
    } else {
      return {
        data: res.data,
      };
    }
  },

  getClaims: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/claims`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getLastUpdatedClaim: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/claims`, {
      ...query,
      limit: 1,
      order: "-updatedAt",
    });

    const res: AxiosResponse = await client.get(url);
    if (Array.isArray(res.data)) {
      return {
        data: res.data[0],
      };
    } else {
      return {
        data: res.data,
      };
    }
  },

  getTags: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/tags`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getOrganizations: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/organizations`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getMemberships: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/memberships`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getMembershipInvites: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/membership-invites`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  getPendingMembershipInvites: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/membership-invites`, {
      ...query,
      filter: { ...(query?.filter || {}), respondedAt: null, canceledAt: null },
    });
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },
});
