import brelly from "@brelly/sdk";
import { useApp } from "context";
import Input from "components/Input";
import { AddFileModal } from 'modals';
import { AttachmentItem } from 'items';
import { inventoryCats } from 'constants';
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { currentToFloat, checkFileDupes } from "utils";
import { useProject } from "pages/Dash/Claims/Claim/context";
import { DateSelect, SingleSelect } from "components/Select";

export const EditInventoryModal = ({ item, open, handleClose }) => {
  const { setLoading, setError } = useApp();
  const { handleItems } = useProject();
  const [uploadModal, setUploadModal] = useState(false);
  const [values, setValues] = useState({});
  const [files, setFiles] = useState([]);
  const existingFiles = item?.files || [];
  const allFiles = [...existingFiles, ...files];

  useEffect(() => {
    if (open) {
      let caregory = inventoryCats?.find((it) => item?.category?.includes(it?.label));

      setValues({
        name: item?.name,
        purchaseDate: new Date(item?.purchaseDate),
        model: item?.model,
        category: caregory,
        replacementCost: item?.replacementCost,
        purchasePrice: item?.purchasePrice,
        manufacturer: item?.manufacturer,
        serialNumber: item?.serialNumber,
        location: item?.location,
        comparableLink: item?.comparableLink,
      });

      setFiles([]);
    }
    //eslint-disable-next-line
  }, [open]);

  const handleUpdate = (changes = {}) => {
    setValues({ ...values, ...changes });
  };

  const handleFiles = (_files) => {
    setFiles([...files, ..._files]);
  };

  const handleFileDelete = async (file) => {
    if (file?.notConnected || file?.file) {
      let remaining = files?.filter((f) => f?.id !== file?.id);
      setFiles(remaining);
    } else {
      try {
        setLoading(true);
        await brelly.inventoryItems.removeFiles(item?.id, [file?.id]);
        handleItems();
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }
  };

  const handleEdit = async () => {
    try {
      setLoading(true);
      let formattedValues = {
        ...values,
        replacementCost: currentToFloat(values?.replacementCost),
        purchasePrice: currentToFloat(values?.purchasePrice),
        category: values?.category?.label,
      };
      await brelly.inventoryItems.update(item?.id, formattedValues);
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id);
        let remoteNoDuplicates = checkFileDupes(remoteFiles, existingFiles);

        if (localFiles?.length > 0) {
          await brelly.inventoryItems.uploadFiles(item?.id, localFiles);
        }

        if (remoteFiles?.length > 0) {
          await brelly.inventoryItems.addFiles(item?.id, remoteNoDuplicates);
        }
      }
      await handleItems();
      setLoading(false);
      handleClose();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.inventoryItems.delete(item?.id);
      handleItems();
      setLoading(false);
      handleClose();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <>
      <CenterModal open={open} handleClose={handleClose} style={{ width: 700, maxWidth: "100%" }}>
        <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Inventory Item</div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <Input
              value={values?.name}
              onChange={(v) => handleUpdate({ name: v })}
              placeholder=""
              label="Name of Item"
            />
            <DateSelect
              value={values?.purchaseDate}
              onChange={(v) => handleUpdate({ purchaseDate: v })}
              label="Purchase Date"
            />
            <Input
              value={values?.manufacturer}
              onChange={(v) => handleUpdate({ manufacturer: v })}
              placeholder=""
              label="Manufacturer"
            />
            <Input
              value={values?.model}
              onChange={(v) => handleUpdate({ model: v })}
              placeholder=""
              label="Model"
            />
            <SingleSelect
              value={values?.category}
              onChange={(v) => handleUpdate({ category: v })}
              options={inventoryCats}
              single={true}
              label="Category"
            />
          </div>
          <div className="col-12 col-lg-6">
            <Input
              value={values?.replacementCost}
              onChange={(v) => handleUpdate({ replacementCost: v })}
              placeholder="$"
              type="number"
              label="Replacement Cost"
            />
            <Input
              value={values?.purchasePrice}
              onChange={(v) => handleUpdate({ purchasePrice: v })}
              placeholder="$"
              type="number"
              label="Purchase Cost"
            />
            <Input
              value={values?.serialNumber}
              onChange={(v) => handleUpdate({ serialNumber: v })}
              placeholder=""
              label="Serial Number"
            />
            <Input
              value={values?.location}
              onChange={(v) => handleUpdate({ location: v })}
              placeholder=""
              label="Location"
            />
            <Input
              value={values?.comparableLink}
              onChange={(v) => handleUpdate({ comparableLink: v })}
              placeholder=""
              label="Replacement Url"
              type="url"
            />
          </div>
        </div>
        <div className="row mt-2">
          {allFiles?.map((m, idx) => (
            <AttachmentItem key={`${m?.id}-${idx}`} file={m} deletable={true} handleDelete={handleFileDelete} />
          ))}
        </div>
        <BrellyBtn onClick={() => setUploadModal(true)} className="mt-3" style={{ background: "#2D3C57" }}>
          Add Pictures
        </BrellyBtn>
        <div className="mt-2 jb-ac">
          <BrellyBtn
            onClick={handleDelete}
            style={{ width: "49%", background: "transparent", border: "1px solid #ff0000", color: "#ff0000" }}
          >
            Delete
          </BrellyBtn>
          <BrellyBtn onClick={handleEdit} style={{ width: "49%" }}>
            Save
          </BrellyBtn>
        </div>
      </CenterModal>
      <AddFileModal
        onSave={handleFiles}
        open={uploadModal}
        handleClose={() => setUploadModal(false)}
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .csv, .xls, xlsx, .mov, .mp4, .ppt, .pptx, .txt, .svg"
      />
    </>
  );
};
