import { AddTask } from './Task';
import { AddCall } from './Call';
import { AddEmail } from './Email';
import { AddEvent } from './Event';
import { useState, useRef } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { InlineModal } from 'components/Modals';
import { TasksIcon, CalendarIcon, MailIcon, PhoneIcon } from 'components/Icons';

const AddTimeline = ({ setAddEvent }) => {
  const [eventTypeModal, setEventTypeModal] = useState(false);
  const [eventType, setEventType] = useState('event');
  const eventTypeBtnRef = useRef(null);

  const handleTypeChange = (newType) => {
    setEventType(newType)
    setEventTypeModal(false)
  }

  return(
    <div className='col-12 mb-3'>
      <div className='dash-box'>
        <div className='jb-ac'>
          <div className='dash-box-title'>Add New Entry</div>
          <div className='brelly-text-btn' style={{ textTransform: 'capitalize' }} onClick={() => setEventTypeModal(true)} ref={eventTypeBtnRef}>
            {eventType}
            <BsChevronDown style={{ marginLeft: 2 }} />
          </div>
        </div>
        {eventType === 'email' && <AddEmail setAddEvent={setAddEvent} />}
        {eventType === 'event' && <AddEvent setAddEvent={setAddEvent} />}
        {eventType === 'task' && <AddTask setAddEvent={setAddEvent} />}
        {eventType === 'call' && <AddCall setAddEvent={setAddEvent} />}
      </div>

      <InlineModal  btnRef={eventTypeBtnRef} open={eventTypeModal} handleClose={() => setEventTypeModal(false)}>
        <div className='dash-table-item js-ac' onClick={() => handleTypeChange('event')} style={{ fontWeight: 600, cursor: 'pointer', minWidth: 120, color: eventType === 'event' ? '#0D72C5' : '' }}>
          <CalendarIcon size={21} style={{ marginRight: 7 }} stroke={eventType === 'event' ? '#0D72C5' : '#000000'} />
          Event
        </div>
        <div className='dash-table-item' onClick={() => handleTypeChange('task')} style={{ fontWeight: 600, cursor: 'pointer', marginTop: 20, minWidth: 120, color: eventType === 'task' ? '#0D72C5' : '' }}>
          <TasksIcon style={{ marginRight: 4 }} stroke={eventType === 'task' ? '#0D72C5' : '#000000'} />
          Task
        </div>
        <div className='dash-table-item mt-3' onClick={() => handleTypeChange('call')} style={{ fontWeight: 600, cursor: 'pointer', minWidth: 120, color: eventType === 'call' ? '#0D72C5' : '' }}>
          <PhoneIcon style={{ marginRight: 4 }} stroke={eventType === 'call' ? '#0D72C5' : '#000000'} />
          Call
        </div>
        <div className='dash-table-item mt-3' onClick={() => handleTypeChange('email')} style={{ fontWeight: 600, cursor: 'pointer', minWidth: 120, color: eventType === 'email' ? '#0D72C5' : '' }}>
          <MailIcon size={21} style={{ marginRight: 7 }} stroke={eventType === 'email' ? '#0D72C5' : '#000000'} />
          Email
        </div>
      </InlineModal>
    </div>
  )
}

export default AddTimeline;
