import { useState, useEffect } from 'react';
// Internal Imports
import brelly from "@brelly/sdk";

export const useLossTypes = () => {
  const [lossTypes, setLossTypes] = useState([]);

  const handleLossTypes = async (override = false) => {
    try {
      let { data } = await brelly.options.getLossTypes();
      if (Array.isArray(data)) {
        let formatted = data?.map((p, idx) => ({ ...p, label: p?.type, value: p?.id }));
        setLossTypes(formatted)
      }
    } catch(err) {
      console.log(err);
    }
  }

  return { lossTypes, handleLossTypes }
}
