// External Imports
import { useState, useContext, createContext, useEffect } from "react";
import brelly from "@brelly/sdk";
import { useApp } from "context";

const ProjectContext = createContext();

export default function ProjectContextWrapper({ children }) {
  const { projectId, workflow, phases, setError } = useApp();
  const [media, setMedia] = useState([]);
  const [project, setProject] = useState({});
  const [expenses, setExpenses] = useState([]);
  const [payments, setPayments] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [coverages, setCoverages] = useState([]);
  const [claimTasks, setClaimTasks] = useState([]);
  const [claimEvents, setClaimEvents] = useState([]);
  const [deductibles, setDeductibles] = useState([]);
  const [personalItems, setPersonalItems] = useState([]);
  const [timeline, setTimeline] = useState([]);

  const claim = project?.claim || {};
  const policy = project?.policy || {};
  const property = project?.property || {};
  const policyholder = project?.policyholder || {};
  const claimId = claim?.id;

  const handleTimeline = async (_claimId = claim?.id) => {
    try {
      let { data } = await brelly.claims.compileTimeline(_claimId, {
        files: true,
        emails: true,
        calls: true,
        events: true,
        tasks: true,
      });
      setTimeline(data);
    } catch (err) {
      setError(err?.message);
    }
  };

  const handleItems = async (_propertyId = property?.id) => {
    try {
      let { data } = await brelly.properties.getInventoryItems(_propertyId);
      setPersonalItems(data);
    } catch (err) {}
  };

  const handleExpenses = async (_claimId = claim?.id) => {
    try {
      let { data } = await brelly.claims.getExpenses(_claimId);
      setExpenses(data);
    } catch (err) {}
  };

  const handlePayments = async (_claimId = claim?.id) => {
    try {
      let { data } = await brelly.claims.getPayments(_claimId);
      setPayments(data);
    } catch (err) {}
  };

  const handleCoverages = async (_policyId = policy?.id) => {
    try {
      let { data } = await brelly.policies.getCoverages(_policyId);
      setCoverages(data?.filter((c) => !c?.deductible));
      setDeductibles(data?.filter((c) => c?.deductible));
    } catch (err) {}
  };

  const handleFiles = async (_projectId = projectId) => {
    try {
      let { data } = await brelly.projects.getFiles(_projectId);
      setMedia(data);
    } catch (err) {}
  };

  const handleClaim = async (all = true) => {
    try {
      let { data } = await brelly.projects.get(projectId);
      setProject(data);
      if (all) {
        handleFiles(projectId);
        handleItems(data?.propertyId);
        handleTimeline(data?.claimId);
        handleExpenses(data?.claimId);
        handlePayments(data?.claimId);
        handleCoverages(data?.policyId);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleClaimTasks = async () => {
    try {
      let { data } = await brelly.projects.getTasks(projectId);
      let formatted = data?.map((t) => ({ ...t, type: "TASK" }));
      setClaimTasks(formatted);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClaimEvents = async () => {
    try {
      let { data } = await brelly.projects.getEvents(projectId);
      let formatted = data?.map((t) => ({ ...t, type: "EVENT" }));
      setClaimEvents(formatted);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClaimContacts = async () => {
    try {
      let { data } = await brelly.projects.getContacts(projectId);
      setContacts(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (projectId) {
      handleClaim();
      handleClaimTasks();
      handleClaimEvents();
      handleClaimContacts();
    }
    //eslint-disable-next-line
  }, [projectId]);

  const projectLocalState = {
    property,
    claim,
    media,
    setMedia,
    phases,
    policy,
    claimId,
    project,
    workflow,
    coverages,
    contacts,
    expenses,
    payments,
    timeline,
    claimTasks,
    claimEvents,
    deductibles,
    personalItems,
    policyholder,
    handleItems,
    handleFiles,
    handleTimeline,
    handleExpenses,
    handlePayments,
    handleCoverages,
    handleClaim,
    handleClaimTasks,
    handleClaimEvents,
    handleClaimContacts,
  };

  return <ProjectContext.Provider value={projectLocalState}>{children}</ProjectContext.Provider>;
}

export function useProject() {
  return useContext(ProjectContext);
}
