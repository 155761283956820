import { Links } from './sections/Links';
import { Claim } from './sections/Claim';
import { Notes } from './sections/Notes';
import { Property } from './sections/Property';
import { Mortgage } from './sections/Mortgage';
import { Insurance } from './sections/Insurance';
import { Policyholder } from './sections/Policyholder';

const Details = ({ project, coverages, deductibles, contacts }) => {
  const policy = project?.policy;
  const property = project?.property;
  const claim = project?.claim;

  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <Links claim={claim} />
        <Insurance policy={policy} coverages={coverages} deductibles={deductibles} />
        <Notes project={project} />
      </div>
      <div className="col-12 col-lg-6">
        <Claim claim={claim} />
        <Property property={property} />
        <Mortgage property={property} />
        <Policyholder contacts={contacts} project={project} />
      </div>
    </div>
  );
};

export default Details;
