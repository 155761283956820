import { useState } from "react";

export const Notes = ({ project }) => {
  const notes = project?.notes;
  const hasNotes = notes?.length > 0;

  return (
    <div className="dash-box mt-2 dash-box-padded">
      <div className="jb-ac">
        <div className="dash-box-title">
          Other Info
        </div>
      </div>
      {hasNotes && (
        <div
          className="mt-3 brelly-content p-3"
          style={{ borderRadius: 8, minHeight: 120, color: 'black' }}
        >
          {project?.notes}
        </div>
      )}
    </div>
  );
};
