import { useApp } from 'context';
import { useState } from 'react';
import brelly from "@brelly/sdk";
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { useProject } from '../../context';
import { BrellyBtn } from 'components/Button';
import { DateSelect } from 'components/Select';
import { AddFileModal } from 'modals';
import { AttachmentItem } from 'items';
import { LiaPaperclipSolid } from 'react-icons/lia';
import { InlineTeammemberSelector } from 'components/TeammemberSelector';

export const AddTask = ({ setAddEvent }) => {
  const { setError, setLoading, projectId, membershipId, user } = useApp();
  const { claimId, handleFiles: handleClaimFiles, handleTimeline, handleClaimTasks } = useProject();
  const [name, setName] = useState('');
  const [date, setDate] = useState(null);
  const [desc, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([{ label: `${user?.firstName} ${user?.lastName}`, value: membershipId }]);

  const handleFiles = (_files) => {
    setFiles(_files)
    setAddFilesModal(false)
  }

  const handleAdd = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.tasks.create({ name: name, description: desc, endDate: date, projectId: projectId, claimId: claimId, completedAt: date, status: 'Complete' })
      await brelly.tasks.setAssignees(data?.id, selectedUsers?.map((ass) => ass?.value));
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id)

        if (localFiles?.length > 0) {
          await brelly.tasks.uploadFiles(data?.id, localFiles)
        }

        if (remoteFiles?.length > 0) {
          await brelly.tasks.addFiles(data?.id, remoteFiles)
        }
        handleClaimFiles()
      }
      handleTimeline(claimId)
      handleClaimTasks()
      setLoading(false);
      setAddEvent(false)
    } catch(err) {
      setError(err?.message);
      setLoading(false);
    }
  }

  return(
    <div className='row'>
      <div className='col-12 col-lg-4'>
        <Input label='Title' value={name} onChange={setName} />
      </div>
      <div className='col-12 col-lg-4'>
        <InlineTeammemberSelector value={selectedUsers} onChange={setSelectedUsers} />
      </div>
      <div className='col-12 col-lg-4'>
        <DateSelect label='Date' value={date} onChange={setDate} />
      </div>
      <div className='col-12'>
        <TextArea style={{ height: 80 }} label='Description' value={desc} onChange={setDescription} />
      </div>
      <div className='row mt-2'>
        {files?.map((m) => (
          <AttachmentItem key={m?.id} file={m} />
        ))}
      </div>
      <div className='jb-ac mt-2 d-block d-lg-flex'>
        <div className='brelly-text-btn jc-ac' onClick={() => setAddFilesModal(true)}>
          <LiaPaperclipSolid style={{ marginBottom: 2 }} />
          Attach Files
        </div>
        <div className='je-ac d-block d-lg-flex'>
          <BrellyBtn onClick={() => setAddEvent(false)} className='me-0 me-lg-4 mb-1 mb-lg-0' style={{ background: 'transparent', boxShadow: 'none', color: '#ff0000', textShadow: 'none' }}>Cancel</BrellyBtn>
          <BrellyBtn onClick={handleAdd}>Add</BrellyBtn>
        </div>
      </div>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
      />
    </div>
  )
}
