import { IoChevronDownOutline } from 'react-icons/io5';
import { BooleanFilter } from 'components/FilterItems';
import { InlineModal } from 'components/Modals';
import { BsChevronDown } from 'react-icons/bs';
import { TasksIcon } from "components/Icons";
import SearchBar from "components/SearchBar";
import { useState, useRef } from "react";
import { useProject } from "./context";
import { AddTaskModal } from 'modals';
import { ClaimTaskItem } from "items";
import { useApp } from 'context';

const TasksBox = () => {
  const { user, phases, projectId } = useApp();
  const tsFilterBtn = useRef(null);
  const [filters, setFilters] = useState({
    forMe: false,
    overdue: false,
    priority: false,
    status: ['All'],
    searchVal: ''
  });
  const [tsFilterModal, setTsFilterModal] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const { claimTasks, handleClaimTasks } = useProject();
  const tasks = getFiltered(user, filters, claimTasks);
  const noPhase = tasks?.filter((t) => !t?.phaseId);
  const phaseGrouped = phases?.map((p) => ({ ...p, tasks: tasks?.filter((t) => t?.phaseId === p?.id)}))
  const sortedGroups = phaseGrouped?.sort((a, b) => a?.order - b?.order)

  const updateFilters = (changes = {}) => {
    setFilters({...filters, ...changes})
  }

  const handleStatusFilterSelect = (newStatus) => {
    let statuses = filters?.status || []

    if (newStatus === "All") {
      if (statuses?.includes("All")) {
        updateFilters({ status: [] })
      } else {
        updateFilters({ status: ["All", "Complete", "To Do", "Stand By", "Inapplicable"] })
      }
    } else {
      if (statuses?.includes(newStatus)) {
        let remaining = statuses?.filter((s) => s !== newStatus && s !== "All");
        updateFilters({ status: remaining })
      } else {
        let updates = [...statuses, newStatus];
        if (updates?.length == 4) {
          updateFilters({ status: [...updates, "All"] })
        } else {
          updateFilters({ status: updates })
        }
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mb-1">
          <div className="dash-box jb-ac">
            <div className='js-ac'>
              <div className="dash-box-title me-4">
                <TasksIcon style={{ marginRight: 8 }} stroke="#0D72C5" />
                Tasks
              </div>
              <SearchBar className='d-none d-xl-block' style={{ width: 300 }} value={filters?.searchVal} onChange={(v) => updateFilters({ searchVal: v })} />
            </div>
            <div className='je-ac'>
              <BooleanFilter title="Overdue" filterKey='overdue' filters={filters} updateFilters={updateFilters} className='me-3 d-none d-lg-flex' />
              <BooleanFilter title="Priority" filterKey='priority' filters={filters} updateFilters={updateFilters} className='me-3 d-none d-lg-flex'/>
              <BooleanFilter title="For Me" filterKey='forMe' filters={filters} updateFilters={updateFilters} className='me-3 d-none d-lg-flex' />
              <div ref={tsFilterBtn} onClick={() => setTsFilterModal(true)} className='js-ac me-3 d-none d-lg-block' style={{ cursor: 'pointer', color: 'rgb(100, 102, 107)', fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
                Status
                <BsChevronDown style={{ marginLeft: 4 }} />
              </div>
              <div className='brelly-text-btn' onClick={() => setAddTaskModal(true)}>
                Add New
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className=" d-none d-lg-flex">
            <div className="dash-table-header col-1"></div>
            <div className="dash-table-header col-6">
              Name
            </div>
            <div className="dash-table-header col-2">
              Status
            </div>
            <div className="dash-table-header col-1">
              Assignee
            </div>
            <div className="dash-table-header col-2">
              Date
            </div>
          </div>
        </div>
        <div style={{ height: window.innerHeight - 352, overflowY: 'auto', paddingBottom: 50 }}>
          <PhaseItem p={{ tasks: noPhase, name: 'No Specific Claim Phase' }} />
          {sortedGroups?.map((p) => <PhaseItem p={p} key={p?.id} />)}
        </div>
      </div>
      <InlineModal btnRef={tsFilterBtn} open={tsFilterModal} handleClose={() => setTsFilterModal(false)}>
        <StatusFilterItem title={'All'} filters={filters} callback={handleStatusFilterSelect} className="dash-table-item" />
        <StatusFilterItem title={'To Do'} filters={filters} callback={handleStatusFilterSelect} />
        <StatusFilterItem title={'Stand By'} filters={filters} callback={handleStatusFilterSelect} />
        <StatusFilterItem title={'Complete'} filters={filters} callback={handleStatusFilterSelect} />
        <StatusFilterItem title={'Inapplicable'} filters={filters} callback={handleStatusFilterSelect} />
      </InlineModal>
      <AddTaskModal open={addTaskModal} handleClose={() => setAddTaskModal(false)} handleUpdate={handleClaimTasks} inClaim={projectId} />
    </>
  );
};

const PhaseItem = ({ p }) => {
  const { handleClaimTasks, project } = useProject();
  const [open, setOpen] = useState(false);
  const height = p?.tasks?.length * 64;
  const isCurrent = p?.id === project?.currentPhaseId;

  return(
    <>
      <div key={p?.id} className='mt-2'>
        <div onClick={() => setOpen(!open)} style={{ position: 'relative', overflow: 'hidden' }} className='dash-box jb-ac'>
          {isCurrent && <div style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: 10, background: 'rgb(13, 114, 197)' }} />}
          <div style={{ color: p?.tasks?.length > 0 ? 'rgb(13, 114, 197)' : '#64666B' }}>{p?.name}</div>
          <div className='je-ac'>
            {p?.tasks?.length}
            <IoChevronDownOutline className='ms-3' style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 120ms ease' }} />
          </div>
        </div>
        <div style={{ height: open ? height : 0, overflow: 'hidden', transition: 'all 160ms linear' }}>
          {p?.tasks.map((task) => (
            <ClaimTaskItem key={task?.id} task={task} handleUpdate={handleClaimTasks} />
          ))}
        </div>
      </div>
    </>
  )
}

export default TasksBox;

const StatusFilterItem = ({ title, filters, callback, className = 'dash-table-item mt-3' }) => {
  let selected = filters?.status?.includes(title) || filters?.status?.includes("All");

  return(
    <div className={className} style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => callback(title)}>
      <div
        style={{
          width: 20,
          height: 20,
          borderRadius: 4,
          border: "1px solid #0D72C5",
          marginRight: 8,
          padding: 2,
        }}
      >
        {selected && (
          <div style={{ width: "100%", height: "100%", background: "#0D72C5", borderRadius: 3 }} />
        )}
      </div>
      {title}
    </div>
  )
}

const createSearchStr = (task) => {
  let name = task?.name
  let str = `${name}`
  return str?.toLowerCase()
}

const getFiltered = (user, filters, claimTasks) => {
  let _tasks = claimTasks

  if (filters?.overdue) {
    _tasks = _tasks?.filter((t) => new Date(t?.endDate) < new Date())
  }

  if (filters?.priority) {
    _tasks = _tasks?.filter((t) => t?.priority)
  }

  if (filters?.forMe) {
    let formatted = _tasks?.map((t) => ({
      ...t,
      cIds: t?.type === 'TASK' ? t?.assignees?.map((ta) => ta.userId) : t?.participants?.map((ta) => ta.userId)
    }));
    _tasks = formatted?.filter((c) => c.cIds?.includes(user?.id));
  }

  if (!filters?.status?.includes("All")) {
    _tasks = _tasks?.filter((t) => filters?.status?.includes(t?.status))
  }

  if (filters?.searchVal?.length > 0) {
    let formatted = _tasks?.map((t) => ({ ...t, searchVal: createSearchStr(t) }))
    _tasks = formatted?.filter((t) => t.searchVal?.includes(filters?.searchVal?.toLowerCase()))
  }

  let sorted = _tasks?.sort((a, b) => a?.endDate?.localeCompare(b?.endDate))
  return sorted
}
