// Internal Imports
// import brelly from "@brelly/sdk";
import { useApp } from "context";
import { PhotoIcon } from "components/Icons";
import { btnColors } from "./constants";
import ProPic from "components/ProPic";
import { useWindowSize } from "react-use";

export const DashPage = ({ tab, value, children, padding = true }) => {
  const { width, height } = useWindowSize();
  let selected = tab === value;
  let contentHeight = height - 80;
  let delay = selected ? 260 : 0;
  let pd = width > 991 ? "0px 32px 0px 32px" : "0px 16px 0px 16px"

  return (
    <div
      style={{
        height: contentHeight,
        width: '100%',
        padding: padding ? pd : 0,
        borderRadius: "12px 0px 0px 0px",
        position: "absolute",
        top: 80,
        left: 0,
        transform: selected ? "translate(0%, 0%)" : "translate(120%, 0%)",
        transition: `all 300ms linear ${delay}ms`,
        boxShadow: "inset -4px -4px 8px rgba(0,0,0,.038)",
      }}
      className="brelly-content"
    >
      {children}
    </div>
  );
};

export const ProfilePicture = ({ img = "", style = {}, iconSize = 24 }) => {
  if (img) {
    return <img alt="" src={img} style={{ width: 80, height: 80, borderRadius: "50%", ...style }} />;
  } else {
    return (
      <div
        style={{
          width: 80,
          height: 80,
          borderRadius: "50%",
          background: "#0D72C5",
          color: "white",
          ...style,
        }}
        className="jc-ac"
      >
        <PhotoIcon stroke="#ffffff" size={iconSize} />
      </div>
    );
  }
};

export const ProfilePictureUpload = ({ img, style = {}, iconSize = 24, handleChange = () => {}, userOverride = null }) => {
  const { user } = useApp();
  const _user = userOverride || user;

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <ProPic img={img} user={_user} style={style} iconSize={iconSize} fontSizeOverride={42} />
      <input
        style={{ width: "100%", height: "100%", opacity: 0, position: "absolute", left: 0, top: 0, zIndex: 100 }}
        type="file"
        onChange={handleChange}
        accept=".png, .jpeg, .jpg, .svg"
      />
      <div
        style={{
          position: "absolute",
          right: 6,
          bottom: 6,
          padding: 4,
          borderRadius: '50%',
          background: "#0D72C5",
        }}
      >
        <PhotoIcon size={iconSize} stroke="#fff" />
      </div>
    </div>
  );
};

export const AdjusterDisplay = ({ claim }) => {
  return (
    <div className="js-ac">
      <ProfilePicture
        style={{ width: 36, height: 36, marginRight: 8 }}
        iconSize={18}
        imageObj={{ downloadURL: claim?.proPic }}
      />
      <div>
        <h3 style={{ lineHeight: 1, marginBottom: 3 }}>
          {claim?.firstName} {claim?.lastName}
        </h3>
        <h3 style={{ lineHeight: 1, marginBottom: 0, fontSize: 13 }}>My Adjuster</h3>
      </div>
    </div>
  );
};

export const ClaimStatus = ({ claim }) => {
  return (
    <div
      style={{
        width: 130,
        height: 32,
        background: btnColors[claim?.status],
        borderRadius: 18,
        color: "white",
      }}
      className="jc-ac"
    >
      <h3 style={{ marginBottom: 0, fontWeight: 700 }}>{claim?.status}</h3>
    </div>
  );
};
