import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { format } from 'date-fns';
import { useState, useRef } from 'react';
import { ConfirmDeleteModal } from 'modals';
import { Status } from 'components/TaskStatus';
import { InlineModal } from 'components/Modals';
import { EditTaskModal, ViewTaskModal } from 'modals';
import { IoEllipsisVerticalOutline, IoFlagOutline, IoFlag } from 'react-icons/io5';

export const AllTasksItem = ({ task, handleUpdate }) => {
  const { setError, setLoading, projects } = useApp();
  const etModalBtnRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewTaskModal, setViewTaskModal] = useState(false);
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [editTaskTypeModal, setEditTaskTypeModal] = useState(false);
  const taskProject = projects?.find((p) => p?.id === task?.projectId);

  const handleInlineModal = (e) => {
    e.stopPropagation();
    setEditTaskTypeModal(true)
  }

  const handleEditModal = () => {
    setEditTaskTypeModal(false);
    setEditTaskModal(true)
  }

  const handleDeleteModal = () => {
    setEditTaskTypeModal(false);
    setDeleteModal(true)
  }

  const handlePriority = async (e) => {
    e.stopPropagation();
    try {
      await brelly.tasks.update(task?.id, { priority: !task?.priority });
      await handleUpdate();
    } catch(err) {
      setError(err.message)
    }
  }

  const getEndDate = () => {
    if (task?.endDate) {
      return format(new Date(task?.endDate), "MM/dd/yyyy")
    } else {
      return null
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.tasks.delete(task?.id)
      await handleUpdate();
      setLoading(false);
      setEditTaskTypeModal(false)
    } catch(err) {
      setLoading(false);
      setError(err.message)
    }
  }

  return(
    <>
      <div className='mb-2 brelly-content pt-3 pb-3 row dash-table-item align-items-center' onClick={() => setViewTaskModal(true)} style={{ borderRadius: 10, background: task?.type === 'event' ? "#FFD9A6" : "#DCEFFF" }}>
        {/*PRIORITY DESKTOP*/}
        <div onClick={handlePriority} className='dash-table-item jc-ac col-1 d-none d-lg-flex'>
          {task?.priority ? <IoFlag style={{ fontSize: 18 }}/> : <IoFlagOutline style={{ fontSize: 18 }}/>}
        </div>
        {/*NAME*/}
        <div className='dash-table-item col-10 col-lg-4 fw-mobile'>{task?.name}</div>
        {/*PRIORITY MOBILE*/}
        <div ref={etModalBtnRef} onClick={(e) => handleInlineModal(e)} className='je-ac col-2 d-lg-none'>
          <IoEllipsisVerticalOutline style={{ fontSize: 16, color: '#0D72C5' }} />
        </div>
        {/*ADDRESS*/}
        <div className='dash-table-item col-12 col-lg-3 mt-3 mt-lg-0'>{taskProject?.property?.address?.fullAddress || "Address Unknown"}</div>
        {/*DATE MOBILE*/}
        <div className='dash-table-item col-12 d-lg-none mt-2'>
          {getEndDate()}
        </div>
        {/*STATUS*/}
        <div className='dash-table-item jc-ac col-12 col-lg-2 mt-3 mt-lg-0'>
          <Status task={task} handleUpdate={handleUpdate} />
        </div>
        {/*DATE DESKTOP*/}
        <div className='dash-table-item col-1 d-none d-lg-flex'>
          {getEndDate()}
        </div>
        {/*ACTIONS DESKTOP*/}
        <div ref={etModalBtnRef} onClick={(e) => handleInlineModal(e)} className='je-ac col-1 d-none d-lg-flex'>
          <IoEllipsisVerticalOutline style={{ fontSize: 16, color: '#0D72C5' }} />
        </div>
      </div>
      <InlineModal btnRef={etModalBtnRef} open={editTaskTypeModal} handleClose={() => setEditTaskTypeModal(false)}>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={handleEditModal}>Edit</div>
        <div className='dash-table-item mt-2' style={{ fontWeight: 600, cursor: 'pointer', color: '#ff0000' }} onClick={handleDeleteModal}>Delete</div>
      </InlineModal>
      <ConfirmDeleteModal open={deleteModal} handleClose={() => setDeleteModal(false)} onDelete={handleDelete} />
      <EditTaskModal task={task} open={editTaskModal} handleClose={() => setEditTaskModal(false)} handleUpdate={handleUpdate} />
      <ViewTaskModal task={task} open={viewTaskModal} handleClose={() => setViewTaskModal(false)} setEditTaskModal={setEditTaskModal} handleUpdate={handleUpdate} />
    </>
  )
}
