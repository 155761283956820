import brelly from "@brelly/sdk";
import { useApp } from "context";
import { v4 as uuidV4 } from 'uuid';
import Input from "components/Input";
import { currentToFloat } from 'utils';
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CustomField } from '../../components';
import { CenterModal } from "components/Modals";
import { useProject } from "pages/Dash/Claims/Claim/context";
import MortgageCompanySelect from "components/MortgageCompanySelect";

export const EditModal = ({ mortgage, open, handleClose }) => {
  const { setLoading } = useApp();
  const { handleClaim } = useProject();
  const [loanNumber, setLoanNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [lender, setLender] = useState({})
  const [additional, setAdditional] = useState({ entries: [] });
  const sortedAdditional = additional?.entries?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) || [];
  const hasAdditional = sortedAdditional?.length > 0;

  const addAdditional = () => {
    setAdditional({ entries: [...additional?.entries, { key: '', value: '', id: uuidV4(), createdAt: new Date() }] })
  }

  useEffect(() => {
    if (open) {
      setLender(mortgage?.lender || {});
      setAmount(mortgage?.amount || "");
      setLoanNumber(mortgage?.loanNumber || "");
      if (mortgage?.additionalInfo) {
        setAdditional(mortgage?.additionalInfo)
      }
    }
    //eslint-disable-next-line
  }, [open]);

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.mortgages.update(mortgage?.id, {
        loanNumber,
        amount: currentToFloat(amount),
        lenderId: lender?.id,
        additionalInfo: additional
      });
      await handleClaim();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20, width: 400, maxWidth: '100%' }}>Edit Mortgage</div>
      <MortgageCompanySelect value={mortgage?.lender} onSelect={setLender} />
      <Input value={loanNumber} onChange={setLoanNumber} label="Loan Number" />
      <Input label="Amount" value={amount} onChange={setAmount} type='number' placeholder="$" />
      {hasAdditional && <div className='brelly-text-btn mt-3'>Custom</div>}
      {sortedAdditional?.map((entry) => (
        <CustomField key={entry?.id} entry={entry} additional={additional} setAdditional={setAdditional} />
      ))}
      <div className='mt-3 jc-ac'>
        <div onClick={addAdditional} className='brelly-text-btn'>Add Custom</div>
      </div>
      <BrellyBtn className="mt-3" onClick={handleEdit}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
