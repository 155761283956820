// External Imports
// @ts-expect-error TS(7016): Could not find a declaration file for module
import googleAnalytics from "@analytics/google-analytics";
// @ts-expect-error TS(7016): Could not find a declaration file for module
import amplitudePlugin from "@analytics/amplitude";
import AnalyticsEngine from "analytics";

export interface AnalyticsInterface {
  analyticsEngine: any;
  track: (event: string, eventData: any) => void;
  view: (title: string) => void;
  setUser: (userId: string, userData: any) => void;
  user: () => any;
  reset: () => void;
  enable: () => void;
  disable: () => void;
}
export class Analytics implements AnalyticsInterface {
  declare analyticsEngine: any;

  constructor(gaApiKey?: string, amplitudeApiKey?: string) {
    const plugins = [];

    if (gaApiKey) {
      plugins.push(
        googleAnalytics({
          measurementIds: [gaApiKey],
        })
      );
    }

    if (amplitudeApiKey) {
      plugins.push(
        amplitudePlugin({
          apiKey: amplitudeApiKey,
        })
      );
    }

    if (plugins.length > 0) {
      this.analyticsEngine = new (AnalyticsEngine as any)({
        app: "claim-manager-app",
        plugins,
      });
    } else {
      this.analyticsEngine = null;
      console.warn("No tracking tracking services enabled");
    }
  }

  track = (event: string, eventData: any): void => {
    if (this.analyticsEngine !== null) {
      this.analyticsEngine.track(event, eventData, {
        plugins: {
          amplitude: true,
          google: true,
        },
      });
    }
  };

  view = (title: string): void => {
    if (this.analyticsEngine !== null) {
      this.analyticsEngine.page({ title });
    }
  };

  setUser = (userId: string, userData: any): void => {
    if (this.analyticsEngine !== null) {
      this.analyticsEngine.identify(userId, userData, {
        plugins: {
          amplitude: true,
          google: true,
        },
      });
    }
  };

  user = (): any => {
    if (this.analyticsEngine !== null) {
      this.analyticsEngine.user();
    }
  };

  reset = (): void => {
    if (this.analyticsEngine !== null) {
      this.analyticsEngine.reset();
    }
  };

  enable = (): void => {
    if (this.analyticsEngine !== null) {
      this.analyticsEngine.plugins.enable(["amplitude", "google"]);
    }
  };

  disable = (): void => {
    if (this.analyticsEngine !== null) {
      this.analyticsEngine.plugins.disable(["amplitude", "google"]);
    }
  };
}
