import { useState, useEffect } from "react";
import brelly from "@brelly/sdk";
import { useApp } from "context";
import { format } from "date-fns";
import Input from "components/Input";
import TextArea from "components/TextArea";
import { BrellyBtn } from 'components/Button';
import { DateSelect } from "components/Select";
import { CenterModal } from "components/Modals";
import { AddFileModal } from 'modals';
import { AttachmentItem } from 'items';
import ClaimSelector from 'components/ClaimSelector';
import TeammemberSelector from 'components/TeammemberSelector';

export const AddTaskModal = ({ open, inClaim, handleClose, handleUpdate }) => {
  const { user, setLoading, membershipId, setError, projects } = useApp();
  const [desc, setDesc] = useState("");
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [due, setDue] = useState(null);
  const [name, setName] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (open) {
      setSelectedUsers([{ label: `${user?.firstName} ${user?.lastName}`, value: membershipId }])
      setDesc("")
      setDue(null)
      setName("")
      setFiles([])
    }
    //eslint-disable-next-line
  }, [open])

  const handleFiles = (_files) => {
    setFiles(_files)
    setAddFilesModal(false)
  }

  const getProject = () => {
    if (inClaim) {
      return inClaim
    } else {
      return selectedClaim[0]?.value
    }
  }

  const handleAdd = async () => {
    const projectId = getProject();

    if (!name) {
      setError("Please add a name")
    } else if (!due && !projectId) {
      setError("Please select either a claim or a due date")
    } else {
      try {
        setLoading(true);
        let { data } = await brelly.tasks.create({
          claimId: projectToClaim(projectId, projects),
          projectId: projectId,
          description: desc,
          status: "Stand By",
          endDate: due,
          creatorId: user?.id,
          name: name
        });
        await brelly.tasks.setAssignees(data?.id, selectedUsers?.map((ass) => ass?.value));
        if (files?.length > 0) {
          let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
          let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id)

          if (localFiles?.length > 0) {
            await brelly.tasks.uploadFiles(data?.id, localFiles)
          }

          if (remoteFiles?.length > 0) {
            await brelly.tasks.addFiles(data?.id, remoteFiles)
          }
        }
        handleUpdate()
        setLoading(false);
        handleClose();
      } catch (err) {
        setError(err.message)
        setLoading(false);
      }
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose} style={{ width: 700, maxWidth: '100%' }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add Task</div>
      <div className='row' style={{ maxHeight: window.innerHeight - 280, overflowY: 'auto' }}>
        <div className='col-12 col-lg-6'>
          <Input
            value={name}
            onChange={setName}
            placeholder=""
            label="Task Name"
          />
          <DateSelect value={due} onChange={setDue} label="Due Date" />
          {!inClaim && <ClaimSelector value={selectedClaim} onChange={setSelectedClaim} style={{ width: '100%' }} />}
          <TeammemberSelector value={selectedUsers} onChange={setSelectedUsers} />
        </div>
        <div className='col-12 col-lg-6'>
          <TextArea label="Description" value={desc} onChange={setDesc} />

          <div className="je-ac mt-2">
            <div className="me-3">
              <div
                className="dash-table-item"
                style={{ color: "#64666B", fontSize: 12 }}
              >
                Task Creator
              </div>
              <div className="dash-table-item" style={{ fontWeight: 600 }}>
                {user?.firstName} {user?.lastName}
              </div>
            </div>
            <div>
              <div
                className="dash-table-item"
                style={{ color: "#64666B", fontSize: 12 }}
              >
                Task Created
              </div>
              <div className="dash-table-item" style={{ fontWeight: 600 }}>
                {format(new Date(), "MMM dd, yyyy")}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-2'>
        {files?.map((m) => (
          <AttachmentItem key={m?.id} file={m} />
        ))}
      </div>
      <BrellyBtn
        onClick={() => setAddFilesModal(true)}
        className="mt-3"
        style={{ background: "#2D3C57" }}
      >
        Add Attachments
      </BrellyBtn>
      <BrellyBtn className="mt-2" onClick={handleAdd}>
        Save
      </BrellyBtn>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
      />
    </CenterModal>
  );
};

const projectToClaim = (pId, projects) => {
  let project = projects?.find((p) => p?.id === pId) || {};
  return project?.claim?.id;
}
