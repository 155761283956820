import { SingleSelect } from 'components/Select';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';
import { getUniqueArrayValues, titleCase } from 'utils';

export const ContactFilterModal = ({ open, contacts = [], handleClose = () => {}, filters = {}, updateFilters = () => {} }) => {
  const { companyOptions, shareOptions, roleOptions } = createOptions(contacts)

  return(
    <CenterModal open={open} handleClose={handleClose} style={{ width: 436, maxWidth: '100%' }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Filter Contacts</div>
      <SingleSelect label="Company" options={companyOptions} value={filters?.company} onChange={(v) => updateFilters({ company: v })} />
      <SingleSelect label="Role" options={roleOptions} value={filters?.role} onChange={(v) => updateFilters({ role: v })} />
      <SingleSelect label="Sharing Status" options={shareOptions} value={filters?.sharing} onChange={(v) => updateFilters({ sharing: v })} />
      <BrellyBtn className='mt-4' onClick={handleClose}>Done</BrellyBtn>
    </CenterModal>
  )
}

const createOptions = (contacts) => {
  let filteredCompanyOptions = contacts?.filter((c) => c?.company)
  let companyOptions = getUniqueArrayValues(filteredCompanyOptions?.map((c) => ({ label: c?.company, value: c?.company })), 'label');
  let allCompanyOptions = [{ label: 'All', value: 1 }, { label: 'Unknown', value: 2 }, ...companyOptions];

  let filteredRoleOptions = contacts?.filter((c) => c?.role)
  let roleOptions = getUniqueArrayValues(filteredRoleOptions?.map((c) => ({ label: titleCase(c?.role), value: c?.role })), 'label');
  let allRoleOptions = [{ label: 'All', value: 1 }, { label: 'Unknown', value: 2 }, ...roleOptions];

  let shareOptions = [{ label: 'All', value: 1 }, { label: 'Shared', value: 2 }, { label: 'Private', value: 3 }]

  return { companyOptions: allCompanyOptions, shareOptions, roleOptions: allRoleOptions }
}
