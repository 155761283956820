import { useState } from "react";
import { format } from 'date-fns';
import { LossTypeItem } from './Item';
import { LightningIcon } from "components/Icons";
import { DetailItem } from 'pages/Dash/Claims/Claim/Details/components';

const getDol = (claim) => {
  if (claim?.dateOfLoss) {
    return format(new Date(claim?.dateOfLoss), "MM/dd/yyyy")
  } else {
    return "Unknown"
  }
}

export const Claim = ({ claim }) => {
  const lossTypes = claim?.lossTypes || [];

  return (
    <>
      <div className="mt-2 mt-lg-0 dash-box dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 10 }}>
          <div className="dash-box-title">
            <LightningIcon style={{ marginRight: 8 }} stroke="#0D72C5" />
            Claim
          </div>
        </div>
        <DetailItem title='Claim Number' value={claim?.claimNumber} />
        <DetailItem title='Loss Date' value={getDol(claim)} />
        <div className="dash-table-item mt-3" style={{ color: "#64666B" }}>
          Loss Types
        </div>
        <div className="js-ac" style={{ flexWrap: "wrap" }}>
          {lossTypes?.map((lt, key) => (
            <LossTypeItem type={lt} key={key} />
          ))}
        </div>
      </div>
    </>
  );
};
