import { useApp } from "context";
import brelly from "@brelly/sdk";
import { useState, useRef } from "react";
import { InlineModal } from "components/Modals";
import { ConfirmDeleteModal, EditWFTaskModal } from 'modals';
import { IoArrowDownOutline, IoEllipsisVerticalOutline } from "react-icons/io5";

export const WorkflowTaskItem = ({ t, tasks, handleStage }) => {
  const { setError, setLoading } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editTaskTypeModal, setEditTaskTypeModal] = useState(false);
  const etModalBtnRef = useRef(null);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.workflowTasks.delete(t?.id);
      handleStage()
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err?.message)
    }
  }

  const handleMoveUp = async (e) => {
    e.stopPropagation();
    if (t?.order !== 0) {
      try {
        setLoading(true);
        await brelly.workflowTasks.update(t?.id, {
          order: t?.order - 1
        })
        handleStage()
        setLoading(false);
      } catch(err) {
        setError(err?.message);
        setLoading(false);
      }
    }
  }

  const handleMoveDown = async (e) => {
    e.stopPropagation();
    if (t?.order !== tasks?.length - 1) {
      try {
        setLoading(true);
        await brelly.workflowTasks.update(t?.id, {
          order: t?.order + 1
        })
        handleStage()
        setLoading(false);
      } catch(err) {
        setError(err?.message);
        setLoading(false);
      }
    }
  }

  const handleDeleteModal = (e) => {
    e.stopPropagation()
    setEditTaskTypeModal(false);
    setDeleteModal(true)
  }

  const handleEditModal = (e) => {
    e.stopPropagation()
    setEditTaskTypeModal(false);
    setEditModal(true)
  }

  const handleOptions = (e) => {
    e.stopPropagation()
    setEditTaskTypeModal(true)
  }

  return(
    <>
      <div className="jb-ac mb-1 ps-3 pe-3" onClick={() => setEditModal(true)} style={{ fontWeight: 600, background: "#DCEFFF", borderRadius: 10, width: "100%", height: 38, color: "#64666B" }}>
        {t?.name}
        <div className='je-ac'>
          <IoArrowDownOutline onClick={handleMoveUp} style={{ marginRight: 18, transform: 'rotate(180deg)' }} />
          <IoArrowDownOutline onClick={handleMoveDown} style={{ marginRight: 18 }} />
          <div className='jc-ac' ref={etModalBtnRef} onClick={handleOptions}>
            <IoEllipsisVerticalOutline />
          </div>
        </div>
      </div>
      <EditWFTaskModal task={t} open={editModal} handleClose={() => setEditModal(false)} />
      <InlineModal btnRef={etModalBtnRef} open={editTaskTypeModal} handleClose={() => setEditTaskTypeModal(false)}>
        <div className='dash-table-item mb-2' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={handleEditModal}>Edit</div>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer', color: '#ff0000' }} onClick={handleDeleteModal}>Delete</div>
      </InlineModal>
      <ConfirmDeleteModal open={deleteModal} handleClose={() => setDeleteModal(false)} onDelete={handleDelete} />
    </>
  )
}
