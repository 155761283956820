// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface PhoneCallsInterface {
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  getFiles: (id: string, query?: Query) => Promise<any>;
  uploadFiles: (id: string, files: any[], query?: Query) => Promise<any>;
  setFiles: (id: string, fileIds: string[], query?: Query) => Promise<any>;
  addFiles: (id: string, fileIds: string[], query?: Query) => Promise<any>;
  removeFiles: (id: string, fileIds: string[], query?: Query) => Promise<any>;
}

export const phoneCalls = (client: Axios): PhoneCallsInterface => ({
  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/phone-calls/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/phone-calls", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/phone-calls/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/phone-calls/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  getFiles: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/phone-calls/${id}/files`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  uploadFiles: async (id: string, files: any[], query?: Query) => {
    const url = addUrlQuery(`/phone-calls/${id}/files`, query);
    const formData = new FormData();
    if (Array.isArray(files)) {
      files.forEach((file) => formData.append("files", file));
    }

    const res: AxiosResponse = await client.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return {
      data: res.data,
    };
  },

  setFiles: async (id: string, fileIds: string[], query?: Query) => {
    const url = addUrlQuery(`/phone-calls/${id}/files/set`, query);
    const res: AxiosResponse = await client.post(url, { ids: fileIds });
    return {
      data: res.data,
    };
  },

  addFiles: async (id: string, fileIds: string[], query?: Query) => {
    const url = addUrlQuery(`/phone-calls/${id}/files/add`, query);
    const res: AxiosResponse = await client.post(url, { ids: fileIds });
    return {
      data: res.data,
    };
  },

  removeFiles: async (id: string, fileIds: string[], query?: Query) => {
    const url = addUrlQuery(`/phone-calls/${id}/files/remove`, query);
    const res: AxiosResponse = await client.post(url, { ids: fileIds });
    return {
      data: res.data,
    };
  },
});
