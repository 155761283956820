import brelly from "@brelly/sdk";
import { DashPage } from './components';
import { BsFlagFill, BsFlag } from 'react-icons/bs';
import { ClaimsIcon, ClockIcon } from 'components/Icons';
import { useApp } from 'context';
import { handleDate } from 'utils';
import { useEffect, useState, useRef } from 'react';
import { InlineModal } from 'components/Modals';
import { TasksIcon, CalendarIcon } from 'components/Icons';
import { AddTaskModal, AddEventModal, ViewEventModal, ViewTaskModal } from 'modals';
import { format } from 'date-fns';
import { useWindowSize } from 'react-use';

const HomePage = () => {
  const { tab, tasks, events, setTab, setProject, projects, handleTasks, handleEvents } = useApp();
  const attModalBtnRef = useRef(null);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [addEventModal, setAddEventModal] = useState(false);
  const [addTaskTypeModal, setAddTaskTypeModal] = useState(false);
  const sortedTasks = [...tasks, ...events]?.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
  const filteredTasks = sortedTasks?.filter((t, idx) => idx < 6);
  const isSelected = tab === 'home';

  const notArchived = projects?.filter((p) => !p.archived);
  const notPending = notArchived?.filter((p) => !p?.invite || p?.invite?.completed);
  const sortedProjects = notPending?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  const recentProjects = sortedProjects?.filter((p, idx) => idx < 6);

  const handleClaim = (claim) => {
    setTab('claims')
    setProject(claim)
  }

  useEffect(() => {
    if (isSelected) {
      handleTasks()
      handleEvents()
    }
    //eslint-disable-next-line
  }, [isSelected])

  const handleAddTask = () => {
    setAddTaskTypeModal(false)
    setAddTaskModal(true)
  }

  const handleAddEvent = () => {
    setAddTaskTypeModal(false)
    setAddEventModal(true)
  }

  return(
    <DashPage tab={tab} value='home'>
      <div className='' style={{ paddingTop: 24 }}>
        <h1 style={{ marginBottom: 0 }}>Home</h1>
        <div className='row mt-3'>
          <div className='col-12 mb-3'>
            <div className='dash-box'>
              <div className='jb-ac'>
                <div className='dash-box-title js-ac d-none d-lg-flex'>
                  <ClockIcon style={{ marginRight: 8, marginBottom: 2 }} stroke='#0D72C5' />
                  Upcoming Tasks and Events
                </div>
                <div className='dash-box-title js-ac d-lg-none'>
                  <ClockIcon style={{ marginRight: 8, marginBottom: 2 }} stroke='#0D72C5' />
                  Upcoming
                </div>
                <div ref={attModalBtnRef} className='brelly-text-btn' onClick={() => setAddTaskTypeModal(true)}>Add New</div>
              </div>
              <div className='mt-3'>
                {filteredTasks?.map((task, key) => <TaskItem task={task} key={key} />)}
              </div>
            </div>
          </div>
          <div className='col-12 mb-4'>
            <div className='dash-box'>
              <div className='jb-ac'>
                <div className='dash-box-title'>
                  <ClaimsIcon style={{ marginRight: 8 }} stroke='#0D72C5' />
                  Recent claims
                </div>
                <div className='brelly-text-btn' onClick={() => setTab('claims')}>See All</div>
              </div>
              <div className='row mt-4 d-none d-lg-flex'>
                <div className='dash-table-header col-3'>Primary Policyholder</div>
                <div className='dash-table-header col-4'>Address</div>
                <div className='dash-table-header col-3'>Claim Number</div>
                <div className='dash-table-header je-ac col-2'>Last Edited</div>
              </div>
              <div className='mt-3 mt-lg-0'>
                {recentProjects?.map((project, key) => <HomeClaimItem key={key} project={project} handleClaim={handleClaim} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTaskModal open={addTaskModal} handleClose={() => setAddTaskModal(false)} />
      <AddEventModal open={addEventModal} handleClose={() => setAddEventModal(false)} />
      <InlineModal btnRef={attModalBtnRef} open={addTaskTypeModal} handleClose={() => setAddTaskTypeModal(false)}>
        <div className='dash-table-item js-ac' onClick={handleAddTask} style={{ fontWeight: 600, cursor: 'pointer' }}>
          <TasksIcon style={{ marginTop: 2, marginRight: 1 }} stroke='#000000' />
          Create a Task
        </div>
        <div className='dash-table-item mt-3' onClick={handleAddEvent} style={{ fontWeight: 600, cursor: 'pointer' }}>
          <CalendarIcon size={22} style={{ marginRight: 4, marginBottom: 2 }} stroke='#000000' />
          Create an Event
        </div>
      </InlineModal>
    </DashPage>
  )
}

export default HomePage;

const TaskItem = ({ task }) => {
  const { setError, handleTasks, projects } = useApp();
  const [viewTaskModal, setViewTaskModal] = useState(false);
  const project = projects?.find((p) => p?.id === task?.projectId);
  const { width } = useWindowSize();

  const handlePriority = async (e) => {
    e.stopPropagation();
    try {
      await brelly.tasks.update(task?.id, { priority: !task?.priority });
      await handleTasks();
    } catch(err) {
      setError(err.message)
    }
  }

  const getModal = () => {
    if (task?.type === 'event') {
      return <ViewEventModal task={task} open={viewTaskModal} handleClose={() => setViewTaskModal(false)} handleUpdate={handleTasks} />
    } else {
      return <ViewTaskModal task={task} open={viewTaskModal} handleClose={() => setViewTaskModal(false)} handleUpdate={handleTasks} />
    }
  }

  return(
    <>
      <div style={{ cursor: 'pointer', borderRadius: 6 }} className='row pt-2 pb-2 brelly-item-hover' onClick={() => setViewTaskModal(true)}>
        <div className='dash-table-item js-ac col-1 col-lg-1' onClick={handlePriority} style={{ paddingRight: width > 991 ? '' : 0 }}>
          {task?.priority ? <BsFlagFill style={{ fontSize: 14 }} /> : <BsFlag style={{ fontSize: 14 }} />}
        </div>
        <div className='dash-table-item col-11 col-lg-3'>{task?.name}</div>
        <div className='dash-table-item col-6 d-none d-lg-flex'>{project?.property?.address?.fullAddress}</div>
        <div className='dash-table-item je-ac col-2 d-none d-lg-flex'>{format(new Date(task?.endDate), 'MM/dd/yyyy')}</div>
      </div>
      {getModal()}
    </>
  )
}

const HomeClaimItem = ({ project, handleClaim }) => {
  const { width } = useWindowSize();
  const sizeClasses = width > 991 ? 'row pt-2 pb-2 brelly-item-hover' : 'row pt-1 pb-1 brelly-content mt-2';

  let getName = () => {
    if (project?.policyholder) {
      return `${project?.policyholder?.firstName} ${project?.policyholder?.lastName}`;
    } else {
      return 'Unknown'
    }
  }

  return(
    <div className={sizeClasses} style={{ cursor: 'pointer', borderRadius: 6 }} onClick={() => handleClaim(project?.id)}>
      <div className='dash-table-item col-3 d-none d-lg-flex'>{getName()}</div>
      <div className='dash-table-item col-12 col-lg-4'>{project?.property?.address?.fullAddress || "Address Unknown"}</div>
      <div className='dash-table-item col-3 d-none d-lg-flex'>{project?.claim?.claimNumber}</div>
      <div className='dash-table-item col-2 je-ac d-none d-lg-flex'>{handleDate(new Date(project?.updatedAt))}</div>
    </div>
  )
}

const DetailItem = ({ value = '', width, className = '' }) => {
  let _value = value || "Unknown";

  return <div className={`dash-table-item ${className}`} style={{ width: width }}>{_value}</div>
}
