export const getFilteredClaims = (user, projects, filters, searchVal) => {
  let _claims = projects;

  if (!filters?.showArchived) {
    _claims = _claims?.filter((c) => !c.archived);
  }

  if (filters?.claimsType === "myclaims") {
    _claims = _claims?.filter((c) => !!c.contributors?.find((cc) => cc?.userId === user?.id)?.id)
  }

  if (filters?.carrier?.value !== 'all') {
    _claims = _claims?.filter((c) => c?.policy?.insuranceCompany?.id === filters?.carrier?.value)
  }

  if (filters?.contributor?.value !== 'all') {
    let formatted = _claims?.map((c) => ({ ...c, cIds: c?.contributors?.map((cc) => cc.userId) }));
    _claims = formatted?.filter((c) => c?.cIds?.includes(filters?.contributor?.value))
  }

  if (filters?.lossType?.value !== 'all') {
    _claims = _claims?.filter((c) => c?.claim?.perilType?.includes(filters?.lossType?.value))
  }

  if (filters?.phase?.value !== 'all') {
    _claims = _claims?.filter((c) => c?.currentPhaseId === filters?.phase?.value)
  }

  if (filters?.startDate) {
    _claims = _claims?.filter((c) => new Date(c?.claim?.dateOfLoss) > new Date(filters?.startDate))
  }

  if (filters?.endDate) {
    _claims = _claims?.filter((c) => new Date(c?.claim?.dateOfLoss) < new Date(filters?.endDate))
  }

  if (searchVal?.length > 0) {
    let _searchVal = searchVal?.toLowerCase();
    let formattedClaims = _claims?.map((c) => ({ ...c, searchStr: createSearchStr(c) }));
    _claims = formattedClaims?.filter((c) => c.searchStr?.includes(_searchVal));
  }

  return _claims
};

const createSearchStr = (project) => {
  let fName = project?.policyholder?.firstName
  let lName = project?.policyholder?.lastName
  let address = project?.property?.address?.fullAddress
  let num = project?.claim?.claimNumber
  let str = `${fName} ${lName} ${address} ${num}`
  return str?.toLowerCase()
}

export const getFilterCount = (filters) => {
  let count = 0;

  if (filters?.contributor?.value !== 'all') {
    count = count + 1
  }

  if (filters?.lossType?.value !== 'all') {
    count = count + 1
  }

  if (filters?.carrier?.value !== 'all') {
    count = count + 1
  }

  if (filters?.phase?.value !== 'all') {
    count = count + 1
  }

  if (filters?.startDate) {
    count = count + 1
  }

  if (filters?.endDate) {
    count = count + 1
  }

  return count
}

export const getInitialFilters = () => {
  return {
    showArchived: false,
    claimsType: "myclaims",
    contributor: { label: "All", value: "all" },
    lossType: { label: "All", value: "all" },
    carrier: { label: "All", value: "all" },
    phase: { label: "All", value: "all" },
  }
}
