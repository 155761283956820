import { CopyBtn } from './CopyBtn';
import { useApp } from "context";

export const LinkItem = ({ link, links }) => {
  return (
    <>
      <div className="jb-ac mt-3">
        <a
          href={`https://${link?.url}`}
          target="blank"
          className="dash-table-item"
          style={{ fontWeight: 600, textDecoration: "underline" }}
        >
          {link?.name}
        </a>
        <div className="je-ac">
          <CopyBtn url={link?.url} />
        </div>
      </div>
    </>
  );
};
