import { DashPage } from "../components";
import { useEffect } from "react";
import { useApp } from "context";
import Claims from "./Claims";
import Claim from "./Claim";

const ClaimsPage = () => {
  const { tab, projectId, handleProjects } = useApp();

  // useEffect(() => {
  //   handleProjects();
  //   //eslint-disable-next-line
  // }, [tab]);

  return (
    <DashPage tab={tab} value="claims">
      {projectId && <Claim />}
      {!projectId && <Claims />}
    </DashPage>
  );
};

export default ClaimsPage;
