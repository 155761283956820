import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import PhoneNumberPicker from "components/PhoneNumber";

export const ProfileAccountSection = ({ accountTab }) => {
  const { user, handleUser, setLoading, setError } = useApp();
  const isSelected = accountTab === "account";
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");
  const [email, setEmail] = useState(user?.email || "");
  // const [mailingAddress, setMailingAddress] = useState("");
  // const [newPassword, setNewPassword] = useState("");
  // const [currentPassword, setCurrentPassword] = useState("");
  const userId = user?.id;

  useEffect(() => {
    if (userId) {
      setFirstName(user?.firstName || "");
      setLastName(user?.lastName || "");
      setPhoneNumber(user?.phoneNumber || "");
      setEmail(user?.email || "");
      // setMailingAddress(user?.firstName || "");
    }
    //eslint-disable-next-line
  }, [userId]);

  const handleUpdate = async () => {
    try {
      setLoading(true);
      await brelly.users.update("current", {
        email: email,
        lastName: lastName,
        firstName: firstName,
        phoneNumber: phoneNumber,
      });
      await handleUser();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue updating your account, please try again");
    }
  };

  // const handlePasswordChange = async () => {
  //   if (!currentPassword) {
  //     setError("Please enter your current password");
  //   } else if (!newPassword) {
  //     setError("Please enter a new password");
  //   } else if (newPassword === currentPassword) {
  //     setError("Current password and new password cannot be the same");
  //   } else {
  //     try {
  //       setLoading(true);
  //       await brelly.auth.changePassword({
  //         oldPassword: currentPassword,
  //         newPassword: newPassword,
  //       });
  //       setLoading(false);
  //     } catch (err) {
  //       setLoading(false);
  //       setError("There was an issue updating your account, please try again");
  //     }
  //   }
  // };

  return (
    <>
      <div
        style={{
          width: "100%",
          paddingTop: 22,
          display: isSelected ? "" : "none",
        }}
      >
        <div className="dash-box-title" style={{ color: "black", marginBottom: 24 }}>
          Account
        </div>
        <div className="jb-ac">
          <div style={{ width: "49%" }}>
            <Input label="First Name" value={firstName} onChange={setFirstName} />
          </div>
          <div style={{ width: "49%" }}>
            <Input label="Last Name" value={lastName} onChange={setLastName} />
          </div>
        </div>
        <div className="jb-ac mt-2 mb-3">
          <div style={{ width: "49%" }}>
            <PhoneNumberPicker label="Phone Number" value={phoneNumber} onChange={setPhoneNumber} />
          </div>
          <div style={{ width: "49%" }}>
            <Input label="Email" value={email} onChange={setEmail} />
          </div>
        </div>

        <div className="je-ac mt-4">
          <BrellyBtn style={{ width: "49%" }} onClick={handleUpdate}>
            Save
          </BrellyBtn>
        </div>
      </div>
    </>
  );
};
