// External Imports
// import EventSource from "eventsource";
import { Axios, AxiosResponse } from "axios";
// import { Auth } from "aws-amplify";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface ChatInterface {
  getThreads: (query?: Query) => Promise<any>;
  createThread: (values: { [key: string]: string }, query?: Query) => Promise<any>;
  readThread: (threadId: string, query?: Query) => Promise<any>;
  getMesages: (threadId: string, query?: Query) => Promise<any>;
  sendMessage: (values: { [key: string]: string }, query?: Query) => Promise<any>;
  // subscribe: (onMessage: OnMessageCallback, onError: OnErrorCallback, query?: Query) => Promise<EventSource>;
}

// type OnMessageCallback = (message: { [key: string]: string }) => void;
// type OnErrorCallback = (error: any) => void;

// export const chat = (client: Axios, tokenPrefix: string): ChatInterface => ({
export const chat = (client: Axios): ChatInterface => ({
  // Threads

  getThreads: async (query?: Query) => {
    const url = addUrlQuery("/chat/threads", query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  createThread: async (values: { [key: string]: string }, query?: Query) => {
    const url = addUrlQuery("/chat/threads", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  readThread: async (threadId: string, query?: Query) => {
    const url = addUrlQuery(`/chat/threads/${threadId}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  // Messages

  getMesages: async (threadId: string, query?: Query) => {
    const url = addUrlQuery(`/chat/threads/${threadId}/messages`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
      range: res.headers["content-range"],
      count: res.headers["x-total-count"],
    };
  },

  sendMessage: async (values: { [key: string]: string }, query?: Query) => {
    const url = addUrlQuery("/chat/messages", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  // Subscriber

  // subscribe: async (
  //   onMessage: OnMessageCallback,
  //   onError: OnErrorCallback,
  //   query?: Query
  // ): Promise<EventSource> => {
  //   const url = addUrlQuery("/chat/subscribe", query);
  //   const session = await Auth.currentSession();
  //   const accessToken = session.getAccessToken();
  //   const jwtToken = accessToken.getJwtToken();

  //   const source = new EventSource(url, {
  //     withCredentials: true,
  //     headers: {
  //       Authorization: `Bearer ${tokenPrefix}:${jwtToken}`,
  //     },
  //   });

  //   source.onmessage = (event: any) => {
  //     const newMessage = JSON.parse(event.data);
  //     onMessage(newMessage);
  //   };

  //   source.onerror = (event: any) => {
  //     onError(event);
  //     console.error(`EventSource error: ${event}`);
  //     source.close();
  //   };

  //   return source;
  // },
});
