import { NumericFormat } from 'react-number-format';

const Input = ({ label = '', value = '', onChange = () => {}, placeholder = '', style = {}, labelStyle = {}, type = '', className='', disabled = false, inputRef, onFocus = () => {} }) => {
  if (type === 'number') {
    let prefix = placeholder?.includes('$') ? '$' : '';
    return(
      <div className={className}>
        <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2, ...labelStyle }} className='mt-2'>{label}</div>
        <NumericFormat ref={inputRef} onFocus={onFocus} value={value || ""} onValueChange={(values) => onChange(values?.formattedValue)} thousandSeparator="," decimalScale={2} placeholder={placeholder} prefix={prefix} className='dash-input' style={style} disabled={disabled} />
      </div>
    )
  } else {
    return(
      <div className={className}>
        <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2, ...labelStyle }} className='mt-2'>{label}</div>
        <input ref={inputRef} onFocus={onFocus} disabled={disabled} value={value || ""} onChange={(e) => onChange(e.target.value)} className='dash-input' placeholder={placeholder} type={type} style={{ ...style }} />
      </div>
    )
  }
}

export default Input;
