import { useApp } from 'context';
import { useState } from 'react';
import brelly from "@brelly/sdk";
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { BrellyBtn } from 'components/Button';
import { AddFileModal } from 'modals';
import { SingleSelect } from 'components/Select';
import { AttachmentItem } from 'items';
import { LiaPaperclipSolid } from 'react-icons/lia';
import { DateSelect, TimeSelect } from 'components/Select';
import { useProject } from 'pages/Dash/Claims/Claim/context';

export const AddEmail = ({ setAddEvent }) => {
  const { setError, setLoading, projectId, contacts } = useApp();
  const { handleTimeline, handleFiles: handleClaimFiles, claim } = useProject();
  const formattedUsers = contacts?.map((u, idx) => ({ ...u, label: `${u?.firstName} ${u?.lastName}`, value: u?.id }));
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [subject, setSubject] = useState('');
  const [sentAt, setSentAt] = useState(null);
  const [files, setFiles] = useState([]);
  const [from, setFrom] = useState('');
  const [body, setBody] = useState('');
  const [to, setTo] = useState('');

  const handleFiles = (_files) => {
    setFiles(_files)
    setAddFilesModal(false)
  }

  const handleAdd = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.emails.create({
        projectId: projectId,
        claimId: claim?.id,
        senderId: from?.value,
        subject: subject,
        recieverId: to?.value,
        sentAt: sentAt,
        body: body,
      })
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id)

        if (localFiles?.length > 0) {
          await brelly.emails.uploadFiles(data?.id, localFiles)
        }

        if (remoteFiles?.length > 0) {
          await brelly.emails.addFiles(data?.id, remoteFiles)
        }
        handleClaimFiles()
      }
      handleTimeline();
      setLoading(false);
      setAddEvent(false)
    } catch(err) {
      setError(err?.message);
      setLoading(false);
    }
  }

  return(
    <div className='row'>
      <div className='col-12 col-lg-4'>
        <SingleSelect style={{ width: '100%' }} value={from} onChange={setFrom} options={formattedUsers} label='From' />
      </div>
      <div className='col-12 col-lg-4'>
        <SingleSelect style={{ width: '100%' }} value={to} onChange={setTo} options={formattedUsers} label='To' />
      </div>
      <div className='col-12 col-lg-4'>
        <div className='row'>
          <div className='col-7'>
            <DateSelect label='Date' value={sentAt} onChange={setSentAt} />
          </div>
          <div className='col-5'>
            <TimeSelect value={sentAt} onChange={setSentAt} label="Time" />
          </div>
        </div>
      </div>
      <div className='col-12'>
        <Input label='Subject' value={subject} onChange={setSubject} />
      </div>
      <div className='col-12'>
        <TextArea style={{ height: 80 }} label='Body' value={body} onChange={setBody} />
      </div>
      <div className='row mt-2'>
        {files?.map((m) => (
          <AttachmentItem key={m?.id} file={m} />
        ))}
      </div>
      <div className='jb-ac mt-2 d-block d-lg-flex'>
        <div className='brelly-text-btn jc-ac' onClick={() => setAddFilesModal(true)}>
          <LiaPaperclipSolid style={{ marginBottom: 2 }} />
          Attach Files
        </div>
        <div className='je-ac d-block d-lg-flex'>
          <BrellyBtn onClick={() => setAddEvent(false)} className='me-0 me-lg-4 mb-1 mb-lg-0' style={{ background: 'transparent', boxShadow: 'none', color: '#ff0000', textShadow: 'none' }}>Cancel</BrellyBtn>
          <BrellyBtn onClick={handleAdd}>Add</BrellyBtn>
        </div>
      </div>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
      />
    </div>
  )
}
