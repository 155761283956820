// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface AccessLinksInterface {
  getByCode: (code: string, query?: Query) => Promise<any>;
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  makeActive: (id: string, query?: Query) => Promise<any>;
  makeInactive: (id: string, query?: Query) => Promise<any>;
}

export const accessLinks = (client: Axios): AccessLinksInterface => ({
  getByCode: async (code: string, query?: Query) => {
    const url = addUrlQuery(`/access-links/code/${code}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/access-links/${id}`, query);
    const res = await client.get(url);
    return {
      data: res.data,
    };
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/access-links", query);
    const cleanedValues = cleanValues(values);
    const res = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/access-links/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/access-links/${id}`, query);
    const res = await client.delete(url);
    return {
      data: res.data,
    };
  },

  makeActive: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/access-links/${id}`, query);
    const res = await client.put(url, { active: true });
    return {
      data: res.data,
    };
  },

  makeInactive: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/access-links/${id}`, query);
    const res = await client.put(url, { active: false });
    return {
      data: res.data,
    };
  },
});
