//External Imports
import { useState, useEffect, useRef } from "react";

//Internal Imports
import { Portal } from 'react-portal';
import Input from "components/Input";
import brelly from "@brelly/sdk";
import { useApp } from "context";

const PayeeSelect = ({ value, onSelect = () => {}, label = '' }) => {
  const { setLoading, payees, searchPayees, handlePayees } = useApp();
  const [searchVal, setSearchVal] = useState("");
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const [options, setOptions] = useState([]);
  const availableSpace = screenHeight - inputBottom - 30
  const maxHeight = Math.min(availableSpace, 400);
  const name = value?.name;

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect())
    }
    //eslint-disable-next-line
  }, [inputRef, focus])

  useEffect(() => {
    setOptions(payees);
    handlePayees()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSearchVal(name || "")
  }, [name])

  const handleResults = async (sv) => {
    try {
      setSearchVal(sv)
      let data = await searchPayees(sv);
      setOptions(data)
    } catch(err) {
      console.log(err);
    }
  }

  const createNew = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.payees.create({
        name: searchVal
      })
      onSelect(data);
      setLoading(false);
    } catch(err) {
      setLoading(false);
    }
  }

  const handleSelect = (company) => {
    onSelect(company);
    setFocus(false)
    setSearchVal(company?.name)
  }

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <Input
        autoComplete="off"
        value={searchVal}
        inputRef={inputRef}
        placeholder="Search"
        onChange={handleResults}
        label={label || "Paid To"}
        style={{ textTransform: "capitalize", width: 400 }}
        onFocus={() => setFocus(true)}
      />

      {focus && (
        <Portal>
          <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)', zIndex: 1009 }} onClick={() => setFocus(false)} />
          <div style={{ position: 'absolute', background: '#F5F5F5', borderRadius: 8, paddingLeft: 12, paddingRight: 12, zIndex: 1010, fontFamily: 'Lato', left: boundingRect.x, top: boundingRect.bottom + 6, width: boundingRect.width }} className='shadow'>
            <div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
              {options?.map((result, key) => (
                <SearchResult key={key} result={result} onSelect={handleSelect} />
              ))}
              <div className="js-ac" onClick={createNew} style={{ width: "100%", height: 54, textTransform: "capitalize", cursor: "pointer" }}>
                Create {searchVal}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default PayeeSelect;

const SearchResult = ({ onSelect, result }) => {
  return (
    <div
      className="js-ac"
      onClick={() => onSelect(result)}
      style={{ width: "100%", height: 54, textTransform: "capitalize", cursor: "pointer" }}
    >
      {result?.name}
    </div>
  );
};
