import { useState } from 'react';
import { useProject } from './context';
import SearchBar from 'components/SearchBar';
import { LiaCouchSolid } from "react-icons/lia";
import {EditInventoryModal, AddInventoryModal, InventoryReportModal, InventoryFilterModal} from 'modals';
import { formatCurrency } from "utils";
import InventoryImg from 'components/InventoryImg';

const InventoryBox = () => {
  const [filters, setFilters] = useState({
    searchVal: '',
    selectedCats: []
  })
  const { property, personalItems } = useProject()
  const [addModal, setAddModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const filteredItems = getFiltered(filters, personalItems);

  const updateFilters = (changes = {}) => {
    setFilters({...filters, ...changes})
  }

  return(
    <div className='dash-box'>
      <div className='jb-ac'>
        <div className='js-ac'>
          <div className='dash-box-title me-4 js-ac'>
            <LiaCouchSolid style={{ marginRight: 8, color: '#0D72C5' }} />
            Contents Inventory
          </div>
          <SearchBar className='ms-4 d-none d-lg-flex' style={{ width: 300 }} value={filters?.searchVal} onChange={(v) => updateFilters({ searchVal: v })} />
        </div>
        <div className='je-ac'>
          <div className='brelly-text-btn ms-4 d-none d-lg-flex' style={{ color: '#0D72C5' }} onClick={() => setReportModal(true)}>Create Report</div>
          <div className='brelly-text-btn ms-4 d-none d-lg-flex' style={{ color: '#0D72C5' }} onClick={() => setFilterModal(true)} >Filter</div>
          <div className='brelly-text-btn ms-4' style={{ color: '#0D72C5' }} onClick={() => setAddModal(true)}>Add</div>
        </div>
      </div>
      {personalItems?.length > 0 && <TableHeader />}
      {filteredItems?.map((item, key) => <InventoryItem item={item} key={key} />)}
      <AddInventoryModal property={property} open={addModal} handleClose={() => setAddModal(false)} />
      <InventoryFilterModal  open={filterModal} handleClose={() => setFilterModal(false)} filters={filters} updateFilters={updateFilters} />
      <InventoryReportModal open={reportModal} handleClose={() => setReportModal(false)} />
    </div>
  )
}

export default InventoryBox;

const TableHeader = () => {
  return(
    <div className='row mt-3 d-none d-lg-flex'>
      <div className='dash-table-header col-1'></div>
      <div className='dash-table-header col-3'>Name</div>
      <div className='dash-table-header col-2'>Replacement Cost</div>
      <div className='dash-table-header col-2'>Purchase Price</div>
      <div className='dash-table-header col-2'>Location</div>
      <div className='dash-table-header col-2'>Category</div>
    </div>
  )
}

const InventoryItem = ({ item }) => {
  const [editModal, setEditModal] = useState(false);

  return(
    <>
      <div onClick={() => setEditModal(true)} className='row mt-2 brelly-content' style={{ borderRadius: 10, height: 50 }}>
        <div className='dash-table-item jc-ac col-2 col-lg-1'>
          <InventoryImg item={item} />
        </div>
        <div className='dash-table-item col-10 col-lg-3'>{item?.name}</div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>{formatCurrency(item?.replacementCost)}</div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>{formatCurrency(item?.purchasePrice)}</div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>{item?.location}</div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>{item?.category}</div>
      </div>
      <EditInventoryModal item={item} open={editModal} handleClose={() => setEditModal(false)} />
    </>
  )
}
//<div className='dash-table-item col-2 d-none d-lg-flex'>{handleDate(item?.purchaseDate)}</div>
const createSearchStr = (task) => {
  let name = task?.name
  let str = `${name}`
  return str?.toLowerCase()
}

const getFiltered = (filters, items) => {
  let _items = items

  if (filters?.selectedCats?.length > 0) {
    let formatted = filters?.selectedCats?.map((c) => c?.label)
    _items = _items?.filter((t) => formatted?.includes(t?.category))
  }

  if (filters?.searchVal?.length > 0) {
    let formatted = _items?.map((t) => ({ ...t, searchVal: createSearchStr(t) }))
    _items = formatted?.filter((t) => t.searchVal?.includes(filters?.searchVal?.toLowerCase()))
  }

  let sorted = _items?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  return sorted
}
