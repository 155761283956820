import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import Input from "components/Input";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { useProject } from "pages/Dash/Claims/Claim/context";
import brelly from "@brelly/sdk";
import { useApp } from "context";
import { standardizeUrl } from './utils';
// import { useProject } from "pages/Dash/Claims/Claim/context";

export const AddLinkModal = ({ open, handleClose }) => {
  const { claim, handleClaim } = useProject()
  const { setLoading, setError } = useApp();
  const [links, setLinks] = useState([{ name: "", url: "", id: uuidv4() }]);
  const existingLinks = claim?.links || [];

  useEffect(() => {
    setLinks([{ name: "", url: "", id: uuidv4() }]);
  }, [open]);

  const addLink = () => {
    setLinks([...links, { name: "", url: "", id: uuidv4() }]);
  };

  const handleAdd = async () => {
    let filtered = links?.filter((c) => c?.name && c?.url);

    if (filtered?.length > 0) {
      try {
        setLoading(true);
        let formatted1 = filtered?.map((l) => ({ ...l, url: standardizeUrl(l) }))
        let formatted2 = formatted1?.map((l) => ({ name: l?.name, url: l?.url, id: l?.id }))
        await brelly.claims.update(claim?.id, { links: [...existingLinks, ...formatted2] });
        handleClaim(false)
        setLoading(false);
        handleClose();
      } catch (err) {
        let plural = filtered?.length > 1 ? "links" : "link";
        setError(
          `There was an issue adding your ${plural}, please check your connection and try again`
        );
        setLoading(false);
      }
    } else {
      handleClose();
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add Link</div>
      {links?.map((link, key) => (
        <Link key={key} link={link} links={links} setLinks={setLinks} />
      ))}
      <div className="jc-ac mt-2">
        <div
          className="dash-table-btn"
          onClick={addLink}
          style={{ color: "#0D72C5" }}
        >
          Add Another
        </div>
      </div>
      <BrellyBtn onClick={handleAdd} className="mt-4" style={{ width: 400, maxWidth: '100%' }}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};

const Link = ({ link, links = [], setLinks = () => {} }) => {
  const { name, url, id } = link;

  const handleChange = (type, newValue) => {
    let others = links?.filter((c) => c?.id !== link?.id);
    setLinks([...others, { name: name, url: url, [type]: newValue, id: id }]);
  };

  return (
    <div>
      <Input
        value={name}
        onChange={(nv) => handleChange("name", nv)}
        label="Name"
      />
      <Input
        value={url}
        onChange={(nv) => handleChange("url", nv)}
        label="URL"
      />
    </div>
  );
};
