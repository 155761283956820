import brelly from "@brelly/sdk";
import { useState, useRef, useEffect } from 'react';
import { InlineModal } from 'components/Modals';
import { IoChevronDownOutline } from 'react-icons/io5';

export const Status = ({ task, handleUpdate }) => {
  let status = task?.status;
  const [updateModal, setUpdateModal] = useState(false);
  const modalBtnRef = useRef(null);

  const updateStatus = async (status) => {
    try {
      await brelly.tasks.update(task?.id, { status: status, completedAt: null })
      await handleUpdate()
    } catch(err) {
      console.log(err);
    }
  }

  const markComplete = async () => {
    try {
      await brelly.tasks.update(task?.id, { status: 'Complete', completedAt: new Date() })
      await handleUpdate()
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (task?.id && !task?.status) {
      updateStatus("Stand By")
    }
    //eslint-disable-next-line
  }, [])

  const handleModal = (e) => {
    e.stopPropagation()
    setUpdateModal(true)
  }

  const handleChange = (e, type) => {
    e.stopPropagation()
    if (type === 'Complete') {
      markComplete()
    } else {
      updateStatus(type)
    }
    setUpdateModal(false)
  }

  return(
    <>
      <div ref={modalBtnRef} onClick={handleModal} style={{ width: '100%', paddingLeft: 12, paddingRight: 8, height: 24, cursor: 'pointer', borderRadius: 24, background: status === 'To Do' ? 'red' : status === 'Complete' ? 'green' : 'blue', color: 'white', fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }} className='jc-ac'>
        {status?.toLowerCase()}
        <IoChevronDownOutline style={{ marginLeft: 2, marginTop: 1, fontSize: 12 }} />
      </div>
      <InlineModal btnRef={modalBtnRef} open={updateModal} handleClose={() => setUpdateModal(false)}>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={(e) => handleChange(e, 'Standby')}>Standby</div>
        <div className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={(e) => handleChange(e, 'To Do')}>To Do</div>
        <div className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={(e) => handleChange(e, 'Complete')}>Complete</div>
        <div className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={(e) => handleChange(e, 'Inapplicable')}>Inapplicable</div>
      </InlineModal>
    </>
  )
}
