import { AllCalendarTaskItem, AllCalendarEventItem } from 'items';
import { TasksIcon, CalendarIcon } from 'components/Icons';
import { BooleanFilter } from 'components/FilterItems';
import { isSameDay, format, isBefore } from 'date-fns';
import { useState , useRef, useEffect } from 'react';
import { AddTaskModal, AddEventModal } from 'modals';
import { InlineModal } from 'components/Modals';
import { BsChevronDown } from 'react-icons/bs';
import SearchBar from 'components/SearchBar';
import Calendar from 'components/Calender';
import { DashPage } from './components';
import { groupTasksByDay } from 'utils';
import { useApp } from 'context';

const CalendarPage = () => {
  const { tab, handleTasks, handleEvents } = useApp();
  const isSelected = tab === 'calendar';

  useEffect(() => {
    if (isSelected) {
      handleTasks();
      handleEvents();
    }
    //eslint-disable-next-line
  }, [isSelected])

  return(
    <DashPage tab={tab} value='calendar'>
      <CalendarContent />
    </DashPage>
  )
}

export default CalendarPage;

const CalendarContent = () => {
  const [filters, setFilters] = useState({
    type: 'All',
    project: 'All',
    overdue: false,
    priority: false,
    searchVal: ''
  });
  const { user, tasks, events, handleTasks, handleEvents, projects } = useApp();
  const attModalBtnRef = useRef(null);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [addEventModal, setAddEventModal] = useState(false);
  const [addTaskTypeModal, setAddTaskTypeModal] = useState(false);
  const filtered = getFiltered(user, filters, tasks, events)
  const grouped = groupTasksByDay(filtered);
  const claimFilterBtn = useRef(null);
  const [claimFilterModal, setClaimFilterModal] = useState(false);
  const typeFilterBtn = useRef(null);
  const [typeFilterModal, setTypeFilterModal] = useState(false);

  const updateFilters = (changes = {}) => {
    setFilters({...filters, ...changes})
  }

  const handleAddTask = () => {
    setAddTaskTypeModal(false)
    setAddTaskModal(true)
  }

  const handleAddEvent = () => {
    setAddTaskTypeModal(false)
    setAddEventModal(true)
  }

  const handleTypeFilterSelect = (newType) => {
    updateFilters({ type: newType })
    setTypeFilterModal(false)
  }

  const handleClaimFilterSelect = (newValue) => {
    setClaimFilterModal(false);
    updateFilters({ project: newValue })
  }

  return(
    <div className='container' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 104px)', paddingBottom: 40, paddingTop: 24 }}>
      <div className='row'>
        <div className='col-12 col-lg-3'>
          <div className='js-ac' style={{ height: 45 }}>
            <h1 style={{ marginBottom: 0 }}>Calendar</h1>
          </div>
          <div className='dash-box jc-ac pt-3 d-none d-lg-flex' style={{ marginTop: 22 }}>
            <Calendar value={filters?.selectedDay} onChange={(v) => updateFilters({ selectedDay: v })} />
          </div>
        </div>
        <div className='col-12 col-lg-9'>
          <div className='jb-ac' style={{ marginBottom: 24 }}>
            <SearchBar style={{ background: 'white', width: 300 }} className='d-none d-lg-flex' value={filters?.searchVal} onChange={(v) => updateFilters({ searchVal: v })} />
            <div className='je-ac d-none d-lg-flex'>
              <div ref={claimFilterBtn} onClick={() => setClaimFilterModal(true)} className='js-ac me-3' style={{ cursor: 'pointer', color: 'rgb(100, 102, 107)', fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
                Claim
                <BsChevronDown style={{ marginLeft: 4 }} />
              </div>
              <div ref={typeFilterBtn} onClick={() => setTypeFilterModal(true)} className='js-ac me-3' style={{ cursor: 'pointer', color: 'rgb(100, 102, 107)', fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
                Type
                <BsChevronDown style={{ marginLeft: 2, fontSize: 12 }} />
              </div>
              <BooleanFilter title="Overdue" filterKey='overdue' filters={filters} updateFilters={updateFilters} className='me-3' />
              <BooleanFilter title="Priority" filterKey='priority' filters={filters} updateFilters={updateFilters} className='me-3'/>
              <div ref={attModalBtnRef} onClick={() => setAddTaskTypeModal(true)} className='brelly-text-btn'>
                Add New
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row' style={{ fontSize: 12 }}>
              <div className='col-1'>Priority</div>
              <div className='col-1'>Type</div>
              <div className='col-4' style={{ flexShrink: 1 }}>Name</div>
              <div className='col-2'>Status</div>
              <div className='col-3'>Claim</div>
              <div className='col-1'></div>
            </div>
          </div>

          {grouped?.map((group, key) => <DayItem group={group} key={key} />)}
        </div>
      </div>
      <AddTaskModal open={addTaskModal} handleClose={() => setAddTaskModal(false)} handleUpdate={handleTasks} />
      <AddEventModal open={addEventModal} handleClose={() => setAddEventModal(false)} handleUpdate={handleEvents} />
      <InlineModal btnRef={attModalBtnRef} open={addTaskTypeModal} handleClose={() => setAddTaskTypeModal(false)}>
        <div className='dash-table-item js-ac' onClick={handleAddTask} style={{ fontWeight: 600, cursor: 'pointer' }}>
          <TasksIcon style={{ marginTop: 2, marginRight: 1 }} stroke='#000000' />
          Create a Task
        </div>
        <div className='dash-table-item mt-3' onClick={handleAddEvent} style={{ fontWeight: 600, cursor: 'pointer' }}>
          <CalendarIcon size={22} style={{ marginRight: 4, marginBottom: 2 }} stroke='#000000' />
          Create an Event
        </div>
      </InlineModal>
      <InlineModal btnRef={claimFilterBtn} open={claimFilterModal} handleClose={() => setClaimFilterModal(false)}>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleClaimFilterSelect('All')}>All</div>
        {projects?.map((p, key) => (
          <div key={key} className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleClaimFilterSelect(p?.id)}>{p?.claim?.claimNumber}</div>
        ))}
      </InlineModal>
      <InlineModal btnRef={typeFilterBtn} open={typeFilterModal} handleClose={() => setTypeFilterModal(false)}>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleTypeFilterSelect('All')}>All</div>
        <div className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleTypeFilterSelect('task')}>Tasks</div>
        <div className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleTypeFilterSelect('event')}>Events</div>
      </InlineModal>
    </div>
  )
}

const DayItem = ({ group }) => {
  const { handleTasks, handleEvents } = useApp();
  let isToday = isSameDay(new Date(group?.day), new Date());

  const ItemSwitch = ({ task }) => {
    if (task?.type === 'event') {
      return <AllCalendarEventItem task={task} handleUpdate={handleEvents} all={true} />
    } else {
      return <AllCalendarTaskItem task={task} handleUpdate={handleTasks} all={true} />
    }
  }

  const sortInnerDay = (events) => {
    return events?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  }

  return(
    <div className='mt-3'>
      <div className='mb-1 js-ac' style={{ cursor: "pointer", color: isToday ? "#0D72C5" : '#64666B', fontWeight: isToday ? 600 : 500, fontSize: 16 }}>
        {isToday && <div style={{ width: 6, height: 6, borderRadius: '50%', background: '#0D72C5', marginRight: 5 }} />}
        {format(new Date(group?.day), "dd MMM, EEE")}
      </div>
      <div className='container'>
        {sortInnerDay(group?.tasks)?.map((task, idx) => (
          <ItemSwitch key={idx} task={task} />
        ))}
      </div>
    </div>
  )
}

const createSearchStr = (task) => {
  let name = task?.name
  let str = `${name}`
  return str?.toLowerCase()
}

const getFiltered = (user, filters, claimTasks, claimEvents) => {
  let _tasks = [...claimTasks, ...claimEvents];

  if (filters?.overdue) {
    _tasks = _tasks?.filter((t) => new Date(t?.endDate) < new Date())
  }

  if (filters?.priority) {
    _tasks = _tasks?.filter((t) => t?.priority)
  }

  if (filters?.project !== "All") {
    _tasks = _tasks?.filter((t) => t?.projectId === filters?.project)
  }

  if (filters?.type !== "All") {
    _tasks = _tasks?.filter((t) => t?.type === filters?.type)
  }

  if (filters?.searchVal?.length > 0) {
    let formatted = _tasks?.map((t) => ({ ...t, searchVal: createSearchStr(t) }))
    _tasks = formatted?.filter((t) => t.searchVal?.includes(filters?.searchVal?.toLowerCase()))
  }

  if (filters?.selectedDay) {
    _tasks = _tasks?.filter((t) => !isBefore(new Date(t?.endDate), new Date(filters?.selectedDay)))
  }

  const filteredTasks = _tasks?.filter((t) => t?.endDate)

  return filteredTasks
}
