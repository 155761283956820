import { LiaCouchSolid } from "react-icons/lia";

const InventoryImg = ({ item }) => {
  let imgs = item?.files?.filter((f) => f.contentType?.includes('image'));

  if (imgs?.length > 0) {
    let img = imgs[0]?.downloadUrl;

    return (
      <img
        alt=""
        src={img}
        style={{
          width: 36,
          height: 36,
          objectFit: "cover",
          borderRadius: 5,
        }}
      />
    );
  } else {
    return (
      <div
        style={{ width: 36, height: 36, borderRadius: 5, background: "#ffffff" }}
        className="jc-ac"
      >
        <LiaCouchSolid />
      </div>
    );
  }
};

export default InventoryImg;
