import { EditContactModal } from 'modals';
import { PersonIcon } from 'components/Icons';
import { useState } from 'react';

const ProPic = ({ img, user, size = 32, style = {}, className = '', fontSizeOverride = null }) => {
  const [modal, setModal] = useState(false);
  let fontSize = fontSizeOverride || size - 16;

  if (img) {
    return(
      <>
        <img alt='' src={img} style={{ width: size, height: size, borderRadius: '50%', objectFit: 'cover', ...style }} className={className} />
        <EditContactModal open={modal} handleClose={() => setModal(false)} contact={user} />
      </>
    )
  } else if (user?.firstName && user?.lastName) {
    let firstInitial = user?.firstName[0];
    let lastInitial = user?.lastName[0];
    let initials = `${firstInitial}${lastInitial}`

    return(
      <>
        <div style={{ width: size, height: size, borderRadius: '50%', textTransform: 'uppercase', border: '1px solid #0D72C5', background: 'rgba(13, 114, 197, 0.16)', color: '#0D72C5', fontSize: fontSize, fontWeight: 500, lineHeight: 1, ...style }} className={`jc-ac ${className}`}>{initials}</div>
        <EditContactModal open={modal} handleClose={() => setModal(false)} contact={user} />
      </>
    )
  } else if (user?.firstName) {
    let firstInitial = user?.firstName[0];

    return(
      <>
        <div style={{ width: size, height: size, borderRadius: '50%', textTransform: 'uppercase', border: '1px solid #0D72C5', background: 'rgba(13, 114, 197, 0.16)', color: '#0D72C5', fontSize: fontSize, fontWeight: 500, lineHeight: 1, ...style }} className={`jc-ac ${className}`}>{firstInitial}</div>
        <EditContactModal open={modal} handleClose={() => setModal(false)} contact={user} />
      </>
    )
  } else {
    return(
      <div style={{ width: size, height: size, borderRadius: '50%', textTransform: 'uppercase', border: '1px solid #0D72C5', background: 'rgba(13, 114, 197, 0.16)', color: '#0D72C5', ...style }} className={`jc-ac ${className}`}>
        <PersonIcon stroke="#0D72C5" size={fontSize} />
        <EditContactModal open={modal} handleClose={() => setModal(false)} contact={user} />
      </div>
    )
  }
}

export default ProPic;
