// External Imports
import { useState, useEffect } from "react";
import Papa from "papaparse";

// Internal Imports
import { FileSelectScreen, SchemaMapScreen } from "./screens";
import { BrellyBtn } from "components/Button";
import { useApp } from "context";

// Styling
import "./scrollbar.css";

const LAST_STEP = 1;

const isNotEmpty = (value: any) => value !== undefined && value !== null && value !== "";

const mapToRecord = (csvRow: any, schemaMap: any) => {
  const record: any = {};
  schemaMap.forEach((map: any) => {
    if (map.global) {
      record[map.key] = map.value;
    } else if (map.csvColumn) {
      if (map.isArray && Array.isArray(map.csvColumn)) {
        record[map.key] = [];
        map.csvColumn.forEach((column: any) => {
          if (isNotEmpty(csvRow[column])) {
            record[map.key].push(csvRow[column]);
          }
        });
      } else {
        if (isNotEmpty(csvRow[map.csvColumn])) {
          record[map.key] = csvRow[map.csvColumn];
        }
      }
    }
  });

  return record;
};

interface ImportFlowProps {
  modelSchema: any;
  importCallback: (records: any[]) => any;
}

const ImportFlow = ({ modelSchema, importCallback }: ImportFlowProps) => {
  const [csvColumns, setCsvColumns] = useState<any[]>([]);
  const [schemaMap, setSchemaMap] = useState<any>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [step, setStep] = useState<number>(0);
  const { setError } = useApp();

  useEffect(() => {
    setSchemaMap(modelSchema);
  }, [modelSchema]);

  useEffect(() => {
    if (files.length > 0) {
      Papa.parse(files[0] as any, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setCsvColumns(results.meta.fields || []);
          setCsvData(results.data);
        },
      });
    }
  }, [files]);

  const handleImport = () => {
    const missingRequiredFields = schemaMap.filter((map: any) => map.required && !map.csvColumn);
    if (missingRequiredFields.length > 0) {
      const missingFields = missingRequiredFields.map((map: any) => map.label).join(", ");
      setError(`Please select a column for ${missingFields}`);
      return;
    }

    const records = csvData.map((row: any) => mapToRecord(row, schemaMap));
    return importCallback(records);
  };

  return (
    <div className="inportflow">
      {/* Screens */}
      {step === 0 && <FileSelectScreen files={files} setFiles={setFiles} />}
      {step === 1 && (
        <SchemaMapScreen
          modelSchema={modelSchema}
          schemaMap={schemaMap}
          setSchemaMap={setSchemaMap}
          csvColumns={csvColumns}
        />
      )}

      {/* Buttons */}
      <div style={{ width: "100%" }}>
        {step > 0 && (
          <BrellyBtn className="mt-2" onClick={() => setStep(step - 1)}>
            Previous
          </BrellyBtn>
        )}

        {step < LAST_STEP && (
          <BrellyBtn
            className="mt-2"
            onClick={() => files.length > 0 && setStep(step + 1)}
            style={{ opacity: files.length > 0 ? 1 : 0.5 }}
          >
            Next
          </BrellyBtn>
        )}

        {step === LAST_STEP && (
          <BrellyBtn className="mt-2" onClick={() => handleImport()}>
            Import
          </BrellyBtn>
        )}
      </div>
    </div>
  );
};

export default ImportFlow;
