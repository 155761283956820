import { ConfirmDeleteModal, EditLinkModal } from 'modals';
import { useProject } from "pages/Dash/Claims/Claim/context";
import { TrashIcon, EditIcon } from "components/Icons";
import { CopyBtn } from './CopyBtn';
import { useState } from "react";
import brelly from "@brelly/sdk";
import { useApp } from "context";

export const LinkItem = ({ link, links }) => {
  const { setLoading, setError } = useApp();
  const { claim, handleClaim } = useProject();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editLinkModal, setEditLinkModal] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      let remaining = links?.filter((l) => l?.id !== link?.id);
      await brelly.claims.update(claim?.id, { links: remaining });
      handleClaim()
      setLoading(false);
    } catch(err) {
      setError("There was an issue deleting this link, please check your connection and try again")
      setLoading(false);
    }
  }

  return (
    <>
      <div className="jb-ac mt-3">
        <a
          href={`https://${link?.url}`}
          target="blank"
          className="dash-table-item"
          style={{ fontWeight: 600, textDecoration: "underline" }}
        >
          {link?.name}
        </a>
        <div className="je-ac">
          <CopyBtn url={link?.url} />
          <div className="me-3" onClick={() => setEditLinkModal(true)}>
            <EditIcon size="18" stroke="#0D72C5" />
          </div>
          <div onClick={() => setDeleteModal(true)}>
            <TrashIcon size="18" stroke="#ff0000" />
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        open={deleteModal}
        onDelete={handleDelete}
        handleClose={() => setDeleteModal(false)}
      />
      <EditLinkModal
        currentLink={link}
        open={editLinkModal}
        handleClose={() => setEditLinkModal(false)}
      />
    </>
  );
};
