import { DetailItem } from 'pages/Dash/Claims/Claim/Details/components';
import { useProject } from "pages/Dash/Claims/Claim/context";
import { HomeIcon } from "components/Icons";
import { AddMortgageCompany } from './Add';
import { EditModal } from './EditModal';
import Toggle from 'components/Toggle';
import brelly from "@brelly/sdk";
import { useApp } from "context";
import { useState } from "react";

export const Mortgage = () => {
  const { setError } = useApp();
  const { property, handleClaim } = useProject();
  const mortgages = property?.mortgages || [];
  const [addModal, setAddModal] = useState(false);
  const ownership = property?.ownedOutright === null ? 'Unknown' : ''
console.log(mortgages);
  const handleOutright = async () => {
    try {
      await brelly.properties.update(property?.id, { ownedOutright: !property?.ownedOutright })
      handleClaim(false)
    } catch(err) {
      setError(err?.message)
    }
  }

  return (
    <div className="dash-box mt-2 dash-box-padded">
      <div className="jb-ac" style={{ marginBottom: 14 }}>
        <div className="dash-box-title">
          <HomeIcon
            style={{ marginRight: 8, marginBottom: 2 }}
            stroke="#0D72C5"
          />
          Mortgages
        </div>
        <div className='je-ac'>
          <div
            className="brelly-text-btn ms-3"
            onClick={() => setAddModal(true)}
          >
            Add
          </div>
        </div>
      </div>
      <div className="jb-ac mt-3 mb-1">
        <div className="dash-table-item" style={{ color: "#64666B", paddingRight: 0 }}>
          Mortgage on Property
        </div>
        <div className="dash-table-item" style={{ fontWeight: 600, paddingRight: 0 }}>
          <div className='me-2'>{ownership}</div>
          <Toggle value={property?.ownedOutright} onChange={handleOutright} />
        </div>
      </div>
      {mortgages?.map((mortgage) => (
        <MortgageItem mortgage={mortgage} key={mortgage?.id} />
      ))}
      <AddMortgageCompany open={addModal} handleClose={() => setAddModal(false)} property={property} />
    </div>
  );
};

const MortgageItem = ({ mortgage }) => {
  const [editModal, setEditModal] = useState(false);
  const additional = mortgage?.additionalInfo?.entries || [];

  return(
    <>
      <div className="brelly-content ps-3 pe-3 pt-1 pb-3 mt-3" style={{ borderRadius: 8 }}>
        <div className='jb-ac mt-3'>
          <div className="dash-table-item" style={{ fontWeight: 600, fontSize: 16 }}>{mortgage?.mortgageLoanNumber || "Mortgage"}</div>
          <div
            className="brelly-text-btn"
            onClick={() => setEditModal(true)}
            style={{ color: "#0D72C5" }}
          >
            Edit
          </div>
        </div>
        <DetailItem title="Loan Number" value={mortgage?.loanNumber} />
        <DetailItem title="Lendor" value={mortgage?.lender?.name} />
        <DetailItem title="Amount" value={mortgage?.amount} />
        {additional?.map((ad) => (
          <DetailItem key={ad?.id} title={ad?.key} value={ad?.value} />
        ))}
      </div>
      <EditModal
        mortgage={mortgage}
        open={editModal}
        handleClose={() => setEditModal(false)}
      />
    </>
  )
}
