import { useApp } from "context";
import brelly from "@brelly/sdk";
import { BsCheck, BsX } from "react-icons/bs";

export const MembershipInviteItem = ({ invite, handleInvitations }) => {
  const { setLoading, setError, handleUser } = useApp();

  const handleAccept = async () => {
    try {
      setLoading(true);
      await brelly.membershipInvites.accept(invite?.id, invite?.code);
      await handleUser();
      handleInvitations();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue accepting this membership, please try again");
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      await brelly.membershipInvites.reject(invite?.id, invite?.code);
      await handleUser();
      handleInvitations();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue rejecting this membership, please try again");
    }
  };

  return (
    <div className="p-3 mb-1 jb-ac brelly-content" style={{ borderRadius: 10, fontWeight: 600 }}>
      <div className="js-ac">
        <img
          alt=""
          src="/assets/logos/symbolAB.png"
          style={{
            width: 32,
            height: 32,
            borderRadius: "50%",
            objectFit: "contain",
            marginRight: 12,
            background: "white",
          }}
          className="shadow-sm"
        />
        <div>
          {invite?.organization?.name}
        </div>
      </div>
      <div className="je-ac" style={{ color: "white", fontSize: 24 }}>
        <div
          onClick={handleAccept}
          style={{
            width: 32,
            height: 32,
            background: "#0D72C5",
            borderRadius: "50%",
            marginRight: 12,
          }}
          className="jc-ac"
        >
          <BsCheck />
        </div>
        <div
          onClick={handleReject}
          style={{
            width: 32,
            height: 32,
            background: "#ff0000",
            borderRadius: "50%",
          }}
          className="jc-ac"
        >
          <BsX />
        </div>
      </div>
    </div>
  );
};
