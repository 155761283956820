import brelly from "@brelly/sdk";
import { useApp } from "context";
import Input from "components/Input";
import { SimpleFileModal } from "modals";
import TextArea from "components/TextArea";
import { useState, useEffect } from "react";
import { PhotoIcon } from "components/Icons";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import PhoneNumberPicker from "components/PhoneNumber";
import LocationPicker from "components/LocationPicker2";

export const AddAgentModal = ({
  open,
  handleClose,
  handleUpdate = () => {},
}) => {
  if (open) {
    return (
      <Inner
        open={open}
        handleClose={handleClose}
        handleUpdate={handleUpdate}
      />
    );
  } else {
    return null;
  }
};

const Inner = ({ open, handleClose, handleUpdate = () => {} }) => {
  const {
    orgId,
    setLoading,
    handleContacts,
    membershipId,
    setError,
    projectId,
  } = useApp();
  const [fileModal, setFileModal] = useState(false);
  const [fName, setFName] = useState("");
  const [mName, setMName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [claims, setClaims] = useState([]);
  const [notes, setNotes] = useState("");
  const [file, setFile] = useState({});
  const [address, setAddress] = useState({});

  useEffect(() => {
    if (open && projectId) {
      setClaims([{ id: projectId }]);
    }
    //eslint-disable-next-line
  }, [open]);

  const handleAdd = async () => {
    if (!fName) {
      setError("Please add a first name");
    } else if (!lName) {
      setError("Please add a last name");
    } else if (!email) {
      setError("Please add an email address");
    } else {
      try {
        setLoading(true);
        let addressData = {};

        if (address?.lat) {
          addressData = await brelly.addresses.create(address);
        }

        let { data } = await brelly.contacts.create({
          membershipId: membershipId,
          organizationId: orgId,
          firstName: fName,
          middleName: mName,
          lastName: lName,
          role: "INSURANCE AGENT",
          primaryEmail: email,
          otherEmails: [email2],
          primaryPhoneNumber: phone,
          otherPhoneNumbers: [phone2],
          notes: notes,
          homeAddressId: addressData?.id,
          isPrivate: false,
        });
        if (claims?.length > 0) {
          await brelly.contacts.setProjects(
            data?.id,
            claims?.map((c) => c?.id)
          );
        }
        if (file?.name) {
          await brelly.contacts.uploadProfilePic(data?.id, file);
        }
        await handleContacts();
        handleUpdate();
        setLoading(false);
        handleClose();
      } catch (err) {
        setError(err?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <CenterModal
        open={open}
        handleClose={handleClose}
        style={{ width: 800, maxWidth: "100%" }}
      >
        <div className="js-ac mb-2">
          <div
            onClick={() => setFileModal(true)}
            style={{
              width: 42,
              height: 42,
              borderRadius: "50%",
              background: "rgba(13, 114, 197, 0.16)",
              border: "1px dashed #0D72C5",
              position: "relative",
            }}
            className="jc-ac me-2"
          >
            {!file?.name && <PhotoIcon />}
            {file?.name && (
              <img
                alt=""
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                }}
                src={URL.createObjectURL(file)}
              />
            )}
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Add Contact</div>
        </div>
        <div
          className="row"
          style={{ maxHeight: window.innerHeight - 280, overflowY: "auto" }}
        >
          <div className="col-12 col-lg-6">
            <Input
              label="First Name"
              value={fName}
              onChange={setFName}
              placeholder="Bill"
            />
            <Input
              label="Middle Name"
              value={mName}
              onChange={setMName}
              placeholder="Theodore"
            />
            <Input
              label="Last Name"
              value={lName}
              onChange={setLName}
              placeholder="Walton"
            />
            <LocationPicker
              value={address}
              setValue={setAddress}
              label="Address"
            />
            <Input
              label="Email (primary)"
              value={email}
              onChange={setEmail}
              placeholder="bwalton1@ucla.edu"
            />
            <Input
              label="Email (alternate)"
              value={email2}
              onChange={setEmail2}
              placeholder="bill@trailblazers.com"
            />
          </div>
          <div className="col-12 col-lg-6">
            <PhoneNumberPicker
              label="Phone Number (primary)"
              value={phone}
              onChange={setPhone}
            />
            <PhoneNumberPicker
              label="Phone Number (alternate)"
              value={phone2}
              onChange={setPhone2}
            />
            <TextArea
              label="Notes"
              value={notes}
              onChange={setNotes}
              style={{ height: 158, marginBottom: 0 }}
            />
          </div>
        </div>
        <BrellyBtn className="mt-4" onClick={handleAdd}>
          Save
        </BrellyBtn>
      </CenterModal>
      <SimpleFileModal
        multiple={false}
        open={fileModal}
        onSave={setFile}
        handleClose={() => setFileModal(false)}
      />
    </>
  );
};
