import { useApp } from "context";
import brelly from "@brelly/sdk";
import { useState, useRef } from "react";
import { ConfirmDeleteModal } from 'modals';
import { InlineModal } from "components/Modals";
import { IoEllipsisVerticalOutline, IoArrowDownOutline } from "react-icons/io5";

export const WorkflowStageItem = ({ stage, stages, canEdit, setSelectedStage }) => {
  const { setLoading, handleUser, setError } = useApp();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editTaskTypeModal, setEditTaskTypeModal] = useState(false);
  const etModalBtnRef = useRef(null);

  const handleMoveUp = async (e) => {
    e.stopPropagation()
    if (stage?.order !== 0) {
      try {
        setLoading(true);
        await brelly.phases.update(stage?.id, {
          order: stage?.order - 1
        })
        handleUser(false);
        setLoading(false);
      } catch(err) {
        setError(err?.message);
        setLoading(false);
      }
    }
  }

  const handleMoveDown = async (e) => {
    e.stopPropagation()
    if (stage?.order !== stages?.length - 1) {
      try {
        setLoading(true);
        await brelly.phases.update(stage?.id, {
          order: stage?.order + 1
        })
        handleUser(false);
        setLoading(false);
      } catch(err) {
        setError(err?.message);
        setLoading(false);
      }
    }
  }

  const handleDelete = async (e) => {
    e.stopPropagation()
    try {
      setLoading(true);
      await brelly.phases.delete(stage?.id);
      handleUser(false);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err?.message)
    }
  }

  const handleDeleteModal = (e) => {
    e.stopPropagation()
    setEditTaskTypeModal(false);
    setDeleteModal(true)
  }

  const handleDeleteModalClose = (e) => {
    e.stopPropagation()
    setDeleteModal(false)
  }

  const handleEditModal = (e) => {
    e.stopPropagation()
    setEditTaskTypeModal(false);
    setSelectedStage(stage)
  }

  const handleOptions = (e) => {
    e.stopPropagation()
    setEditTaskTypeModal(true)
  }

  return (
    <div
      onClick={() => setSelectedStage(stage)}
      style={{ width: "100%", height: 44, background: "#F6F5F5", borderRadius: 10  }}
      className="jb-ac ps-3 pe-3 mb-1"
    >
      <div style={{ color: "#0D72C5", fontWeight: 600, userSelect: "none" }}>{stage?.name}</div>
      <div className='je-ac' style={{ color: "#64666B" }}>
        {canEdit && (
          <>
            <IoArrowDownOutline onClick={handleMoveUp} style={{ marginRight: 18, transform: 'rotate(180deg)' }} />
            <IoArrowDownOutline onClick={handleMoveDown} style={{ marginRight: 18 }} />
            <div className='jc-ac' ref={etModalBtnRef} onClick={handleOptions}>
              <IoEllipsisVerticalOutline />
            </div>
            <InlineModal btnRef={etModalBtnRef} open={editTaskTypeModal} handleClose={() => setEditTaskTypeModal(false)}>
              <div className='dash-table-item mb-2' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={handleEditModal}>Edit</div>
              <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer', color: '#ff0000' }} onClick={handleDeleteModal}>Delete</div>
            </InlineModal>
            <ConfirmDeleteModal open={deleteModal} handleClose={handleDeleteModalClose} onDelete={handleDelete} />
          </>
        )}
      </div>
    </div>
  );
};
