// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface ReportsInterface {
  get: (id: string, query?: Query) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  batchDelete: (ids: string[], query?: Query) => Promise<any>;
  // attachToClaim: (id: string, query?: Query) => Promise<any>;
  // detachFromClaim: (id: string, query?: Query) => Promise<any>;
  createPropertyDamageReport: (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      propertyAttributeIds?: string[];
      tagIds?: string[];
    },
    query?: Query
  ) => Promise<any>;
  createInventoryDamageReport: (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      inventoryItemIds?: string[];
      tagIds?: string[];
    },
    query?: Query
  ) => Promise<any>;
  createInventoryDamageCSV: (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
    },
    query?: Query
  ) => Promise<any>;
  createClaimExpenseReport: (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      coverageIds?: string[];
    },
    query?: Query
  ) => Promise<any>;
  createClaimExpenseCSV: (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      coverageIds?: string[];
    },
    query?: Query
  ) => Promise<any>;
  createClaimPaymentReport: (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      coverageIds?: string[];
    },
    query?: Query
  ) => Promise<any>;
  createClaimPaymentCSV: (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      coverageIds?: string[];
    },
    query?: Query
  ) => Promise<any>;
  createCustomReport: (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      fileIds?: string[];
    },
    query?: Query
  ) => Promise<any>;
}

export const reports = (client: Axios): ReportsInterface => ({
  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/reports/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/reports/${id}`, query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.put(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/reports/${id}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  batchDelete: async (ids: string[], query?: Query) => {
    const idsStr = Array.isArray(ids) ? ids.toString() : [ids].toString();
    const url = addUrlQuery(`/reports/${idsStr}`, query);
    const res: AxiosResponse = await client.delete(url);
    return {
      data: res.data,
    };
  },

  // attachToClaim: async (id: string, query?: Query) => {
  //   const url = addUrlQuery(`/reports/${id}/attach`, query);
  //   const res: AxiosResponse = await client.put(url);
  //   return {
  //     data: res.data,
  //   };
  // },

  // detachFromClaim: async (id: string, query?: Query) => {
  //   const url = addUrlQuery(`/reports/${id}/detach`, query);
  //   const res: AxiosResponse = await client.put(url);
  //   return {
  //     data: res.data,
  //   };
  // },

  createPropertyDamageReport: async (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      propertyAttributeIds?: string[];
      tagIds?: string[];
    },
    query?: Query
  ) => {
    const url = addUrlQuery("reports/property-damages", query);
    const cleanedOptions = cleanValues(options);
    const res: AxiosResponse = await client.post(url, cleanedOptions);
    return {
      data: res.data,
    };
  },

  createInventoryDamageReport: async (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      inventoryItemIds?: string[];
      tagIds?: string[];
    },
    query?: Query
  ) => {
    const url = addUrlQuery("reports/inventory-damages", query);
    const cleanedOptions = cleanValues(options);
    const res: AxiosResponse = await client.post(url, cleanedOptions);
    return {
      data: res.data,
    };
  },

  createInventoryDamageCSV: async (
    options: { projectId?: string; claimId?: string; name?: string },
    query?: Query
  ) => {
    const url = addUrlQuery("/reports/inventory-damages", query);
    const cleanedOptions = cleanValues(options);
    const res: AxiosResponse = await client.post(url, cleanedOptions);
    return {
      data: res.data,
    };
  },

  createClaimExpenseReport: async (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      coverageIds?: string[];
    },
    query?: Query
  ) => {
    const url = addUrlQuery("reports/claim-expenses", query);
    const cleanedOptions = cleanValues(options);
    const res: AxiosResponse = await client.post(url, cleanedOptions);
    return {
      data: res.data,
    };
  },

  createClaimExpenseCSV: async (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      coverageIds?: string[];
    },
    query?: Query
  ) => {
    const url = addUrlQuery("/reports/claim-expenses", query);
    const cleanedOptions = cleanValues(options);
    const res: AxiosResponse = await client.post(url, cleanedOptions);
    return {
      data: res.data,
    };
  },

  createClaimPaymentReport: async (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      coverageIds?: string[];
    },
    query?: Query
  ) => {
    const url = addUrlQuery("reports/claim-payments", query);
    const cleanedOptions = cleanValues(options);
    const res: AxiosResponse = await client.post(url, cleanedOptions);
    return {
      data: res.data,
    };
  },

  createClaimPaymentCSV: async (
    options: {
      projectId?: string;
      claimId?: string;
      name?: string;
      startDate?: Date;
      endDate?: Date;
      coverageIds?: string[];
    },
    query?: Query
  ) => {
    const url = addUrlQuery("/reports/claim-payments", query);
    const cleanedOptions = cleanValues(options);
    const res: AxiosResponse = await client.post(url, cleanedOptions);
    return {
      data: res.data,
    };
  },

  createCustomReport: async (
    options: { projectId?: string; claimId?: string; name?: string; fileIds?: string[] },
    query?: Query
  ) => {
    const url = addUrlQuery("/reports/custom", query);
    const cleanedOptions = cleanValues(options);
    const res: AxiosResponse = await client.post(url, cleanedOptions);
    return {
      data: res.data,
    };
  },
});
