import { useProject } from "pages/Dash/Claims/Claim/context";
import { useApp } from "context";
import { useState, useEffect } from "react";
import brelly from "@brelly/sdk";
import { v4 as uuidV4 } from 'uuid';
import { CenterModal } from "components/Modals";
import TextArea from "components/TextArea";
import { BrellyBtn } from "components/Button";
import { CustomField } from '../../components';

export const EditModal = ({ project, open, handleClose }) => {
  const { setLoading, setError } = useApp();
  const { handleClaim } = useProject();
  const [notes, setNotes] = useState("");
  const [additional, setAdditional] = useState({ entries: [] });
  const sortedAdditional = additional?.entries?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) || [];

  const addAdditional = () => {
    setAdditional({ entries: [...additional?.entries, { key: '', value: '', id: uuidV4(), createdAt: new Date() }] })
  }

  useEffect(() => {
    if (open) {
      setNotes(project?.notes || '');
      if (project?.additionalInfo) {
        setAdditional(project?.additionalInfo)
      }
    }
    //eslint-disable-next-line
  }, [open])

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.projects.update(project?.id, {
        notes: notes,
        additionalInfo: additional
      });
      await handleClaim(false);
      setLoading(false);
      handleClose();
    } catch (err) {
      setError(err.message)
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose} style={{ width: 600 }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Notes</div>
      <TextArea
        label="Notes"
        value={notes}
        onChange={setNotes}
        style={{ height: 260, marginBottom: 0 }}
      />
      {sortedAdditional?.map((entry) => (
        <CustomField key={entry?.id} entry={entry} additional={additional} setAdditional={setAdditional} />
      ))}
      <div className='mt-3 jc-ac'>
        <div onClick={addAdditional} className='brelly-text-btn'>Add Custom</div>
      </div>
      <BrellyBtn className="mt-3" onClick={handleEdit} style={{ width: '100%' }}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
