import { useApp } from "context";
import brelly from "@brelly/sdk";
import { useState } from "react";
import Input from "components/Input";
import TextArea from 'components/TextArea';
import { BrellyBtn } from "components/Button";
import { CenterModal } from 'components/Modals';

export const AddWorkflowModal = ({ open, handleClose }) => {
  const { setLoading, setError, orgId, handleUser } = useApp();
  const [values, setValues] = useState({});

  const handleUpdate = (changes = {}) => {
    setValues({...values, ...changes})
  }

  const handleAdd = async () => {
    try {
      setLoading(true);
      await brelly.workflows.create({ ...values, organizationId: orgId })
      await handleUser();
      setLoading(false);
      handleClose()
      setValues({})
    } catch(err) {
      setError(err.message)
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose} style={{ width: 500, maxWidth: '100%' }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Create New Workflow</div>
      <Input value={values?.name} onChange={(v) => handleUpdate({ name: v })} label='Name' />
      <TextArea style={{ height: 150 }} value={values?.description} onChange={(v) => handleUpdate({ description: v })} label='Description' />
      <BrellyBtn onClick={handleAdd} className='mt-3'>Save</BrellyBtn>
    </CenterModal>
  )
}
