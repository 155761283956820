import { Oval } from 'react-loading-icons'
import { Portal } from 'react-portal';
import { useApp } from "context";

const LoadingSpinner = () => {
  const { load } = useApp();

  if (load) {
    return(
      <Portal>
        <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.35)', zIndex: 1001000 }} className='jc-ac'>
          <Oval stroke="#0D72C5" fill="#0D72C5" strokeWidth={6} />
        </div>
      </Portal>
    )
  } else {
    return null;
  }
};

export default LoadingSpinner;
