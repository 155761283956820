// External Imports
import { Amplify, Auth } from "aws-amplify";
import axios, { Axios } from "axios";

// Error Classes
import { UserError, UnauthorizedError, ForbiddenError, NotFoundError, ServerError } from "./errors";

// Core SDK Modules
import { Analytics, AnalyticsInterface } from "./modules/analytics";
import { auth, AuthInterface } from "./modules/auth";

// Claim Manager SDK Modules
import { properties, PropertiesInterface } from "./modules/properties";
import { propertyAttributes, PropertyAttributesInterface } from "./modules/propertyAttributes";
import { canopy, CanopyInterface } from "./modules/canopy";
import { lossItems, LossItemsInterface } from "./modules/lossItems";
import { claimPayments, ClaimPaymentsInterface } from "./modules/claimPayments";
import { claims, ClaimsInterface } from "./modules/claims";
import { coverages, CoveragesInterface } from "./modules/coverages";
import { emails, EmailsInterface } from "./modules/emails";
import { claimExpenses, ClaimExpensesInterface } from "./modules/claimExpenses";
import { files, FilesInterface } from "./modules/files";
import { insuranceCompanies, InsuranceCompaniesInterface } from "./modules/insuranceCompanies";
import { legal, LegalInterface } from "./modules/legal";
import { lenders, LendersInterface } from "./modules/lenders";
import { mortgages, MortgagesInterface } from "./modules/mortgages";
import { payees, PayeesInterface } from "./modules/payees";
import { inventoryItems, InventoryItemsInterface } from "./modules/inventoryItems";
import { phoneCalls, PhoneCallsInterface } from "./modules/phoneCalls";
import { policies, PoliciesInterface } from "./modules/policies";
import { reports, ReportsInterface } from "./modules/reports";
import { users, UsersInterface } from "./modules/users";

// Pro SDK Modules
import { accessLinks, AccessLinksInterface } from "./modules/accessLinks";
import { contacts, ContactsInterface } from "./modules/contacts";
import { events, EventsInterface } from "./modules/events";
import { membershipInvites, MembershipInvitesInterface } from "./modules/membershipInvites";
import { memberships, MembershipsInterface } from "./modules/memberships";
import { organizations, OrganizationsInterface } from "./modules/organizations";
import { projectInvites, ProjectInvitesInterface } from "./modules/projectInvites";
import { projects, ProjectsInterface } from "./modules/projects";
import { phases, PhasesInterface } from "./modules/phases";
import { subscriptions, SubscriptionsInterface } from "./modules/subscriptions";
import { tasks, TasksInterface } from "./modules/tasks";
import { workflows, WorkflowsInterface } from "./modules/workflows";
import { workflowTasks, WorkflowTasksInterface } from "./modules/workflowTasks";

// Other SDK Modules
import { chat, ChatInterface } from "./modules/chat";
import { addresses, AddressesInterface } from "./modules/addresses";
import { options, OptionsInterface } from "./modules/options";

class BrellySDK {
  declare analytics: AnalyticsInterface;
  declare auth: AuthInterface;

  declare properties: PropertiesInterface;
  declare accessLinks: AccessLinksInterface;
  declare propertyAttributes: PropertyAttributesInterface;
  declare canopy: CanopyInterface;
  declare lossItems: LossItemsInterface;
  declare claimPayments: ClaimPaymentsInterface;
  declare claims: ClaimsInterface;
  declare coverages: CoveragesInterface;
  declare emails: EmailsInterface;
  declare claimExpenses: ClaimExpensesInterface;
  declare files: FilesInterface;
  declare insuranceCompanies: InsuranceCompaniesInterface;
  declare legal: LegalInterface;
  declare inventoryItems: InventoryItemsInterface;
  declare phoneCalls: PhoneCallsInterface;
  declare lenders: LendersInterface;
  declare mortgages: MortgagesInterface;
  declare payees: PayeesInterface;
  declare policies: PoliciesInterface;
  declare reports: ReportsInterface;
  declare users: UsersInterface;

  declare contacts: ContactsInterface;
  declare events: EventsInterface;
  declare membershipInvites: MembershipInvitesInterface;
  declare memberships: MembershipsInterface;
  declare organizations: OrganizationsInterface;
  // // declare professionals: ProfessionalsInterface;
  declare projectInvites: ProjectInvitesInterface;
  declare projects: ProjectsInterface;
  declare phases: PhasesInterface;
  declare subscriptions: SubscriptionsInterface;
  declare tasks: TasksInterface;
  declare workflows: WorkflowsInterface;
  declare workflowTasks: WorkflowTasksInterface;

  declare chat: ChatInterface;
  declare addresses: AddressesInterface;
  declare options: OptionsInterface;

  declare handleUnauthenticated?: () => void;
  declare userType: "policyholder" | "professional";
  declare tokenPrefix: "ph" | "pro";
  declare cognitoUserPoolId: string;
  declare cognitoClientId: string;
  declare cognitoRegion: string;
  declare apiVersion: string;
  declare apiBaseUrl: string;
  declare amplitudeApiKey?: string;
  declare gaApiKey?: string;
  declare client: Axios;

  constructor() {}

  initialize = (config: {
    handleUnauthenticated?: () => void;
    userType: "policyholder" | "professional";
    cognitoUserPoolId: string;
    cognitoClientId: string;
    cognitoRegion: string;
    apiVersion: string;
    apiBaseUrl: string;
    amplitudeApiKey?: string;
    gaApiKey?: string;
  }) => {
    if (!config) {
      throw new SyntaxError("Missing or invalid SDK config");
    }

    if (!config.handleUnauthenticated) {
      throw new SyntaxError(
        `Missing or invalid handleUnauthenticated param. Expected a function, but recieved ${typeof config.handleUnauthenticated}`
      );
    }

    if (!["policyholder", "professional"].includes(config.userType)) {
      throw new SyntaxError(
        `Missing or invalid userType param. Expected one of 'policyholder' or 'professional', but recieved ${config.userType}`
      );
    }

    if (!config.cognitoUserPoolId) {
      throw new SyntaxError(
        `Missing or invalid cognitoUserPoolId param. Expected a string, but recieved ${typeof config.cognitoUserPoolId}`
      );
    }

    if (!config.cognitoClientId) {
      throw new SyntaxError(
        `Missing or invalid cognitoClientId param. Expected a string, but recieved ${typeof config.cognitoClientId}`
      );
    }

    if (!config.cognitoRegion) {
      throw new SyntaxError(
        `Missing or invalid cognitoRegion param. Expected a string, but recieved ${typeof config.cognitoRegion}`
      );
    }

    if (!config.apiVersion) {
      throw new SyntaxError(
        `Missing or invalid apiVersion param. Expected a string, but recieved ${typeof config.apiVersion}`
      );
    }

    if (!config.apiBaseUrl) {
      throw new SyntaxError(
        `Missing or invalid apiBaseUrl param. Expected a string, but recieved ${typeof config.apiBaseUrl}`
      );
    }

    // class configs
    this.handleUnauthenticated = config.handleUnauthenticated;
    this.userType = config.userType;
    this.tokenPrefix = this.userType === "professional" ? "pro" : "ph";
    this.cognitoUserPoolId = config.cognitoUserPoolId;
    this.cognitoClientId = config.cognitoClientId;
    this.cognitoRegion = config.cognitoRegion;
    this.apiVersion = config.apiVersion;
    this.apiBaseUrl = config.apiBaseUrl;
    this.amplitudeApiKey = config.amplitudeApiKey;
    this.gaApiKey = config.gaApiKey;

    Amplify.configure({
      Auth: {
        region: this.cognitoRegion,
        userPoolId: this.cognitoUserPoolId,
        userPoolWebClientId: this.cognitoClientId,
        signUpVerificationMethod: "code",
        authenticationFlowType: "USER_SRP_AUTH",
      },
    });

    // build axios api client
    this.buildAxiosClient();

    // build api module classes
    this.buildSdkModules();
  };

  // build axios api client
  buildAxiosClient = () => {
    this.client = axios.create({
      withCredentials: true,
      baseURL: `${this.apiBaseUrl}/v${this.apiVersion}`,
    });

    this.client.interceptors.request.use(
      (config: any) =>
        Auth.currentSession()
          .then((session: any) => {
            const accessToken = session.getAccessToken();
            const jwtToken = accessToken.getJwtToken();
            // const jwtToken = session.accessToken.jwtToken;
            config.headers["Authorization"] = `Bearer ${this.tokenPrefix}:${jwtToken}`;
            return config;
          })
          .catch(() => {
            return config;
          }),
      (error) => Promise.reject(error)
    );

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response?.status;
        const message = error.response?.data?.message;

        if (status === 401) {
          if (typeof this.handleUnauthenticated === "function") {
            this.handleUnauthenticated();
          }
          throw new UnauthorizedError(status, message || "Please login in");
        } else if (status === 403) {
          throw new ForbiddenError(status, message || "You do not have permission to do this");
        } else if (status === 404) {
          throw new NotFoundError(status, message || "Oh oh.. We can't find what you are looking for");
        } else if (status < 500) {
          throw new UserError(status, message || "Something doesn't look right.. Please try again");
        } else {
          throw new ServerError(status, message || "Oh oh.. Something went wrong");
        }
      }
    );
  };

  // build sdk modules ** should be overwriten in the classes extended from this one **
  buildSdkModules = () => {
    // base modules
    this.analytics = new Analytics(this.gaApiKey, this.amplitudeApiKey);
    this.auth = auth(this.client, this.cognitoClientId, this.handleUnauthenticated);

    // claim manager modules
    this.properties = properties(this.client);
    this.accessLinks = accessLinks(this.client);
    this.propertyAttributes = propertyAttributes(this.client);
    this.canopy = canopy(this.client);
    this.lossItems = lossItems(this.client);
    this.claimPayments = claimPayments(this.client);
    this.claims = claims(this.client);
    this.coverages = coverages(this.client);
    this.emails = emails(this.client);
    this.claimExpenses = claimExpenses(this.client);
    this.files = files(this.client);
    this.insuranceCompanies = insuranceCompanies(this.client);
    this.inventoryItems = inventoryItems(this.client);
    this.phoneCalls = phoneCalls(this.client);
    this.lenders = lenders(this.client);
    this.mortgages = mortgages(this.client);
    this.legal = legal(this.client);
    this.payees = payees(this.client);
    this.policies = policies(this.client);
    this.reports = reports(this.client);
    this.users = users(this.client);

    // pro modules
    this.contacts = contacts(this.client);
    this.events = events(this.client);
    this.membershipInvites = membershipInvites(this.client);
    this.memberships = memberships(this.client);
    this.organizations = organizations(this.client);
    this.projectInvites = projectInvites(this.client);
    this.projects = projects(this.client);
    this.phases = phases(this.client);
    this.subscriptions = subscriptions(this.client);
    this.tasks = tasks(this.client);
    this.workflows = workflows(this.client);
    this.workflowTasks = workflowTasks(this.client);

    // other modules
    // this.chat = chat(this.client, this.tokenPrefix);
    this.chat = chat(this.client);
    this.addresses = addresses(this.client);
    this.options = options(this.client);
  };
}

export default BrellySDK;
