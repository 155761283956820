import { useApp } from 'context';
import { AllTasksItem } from 'items';
import { AddTaskModal } from 'modals';
import { DashPage } from './components';
import { BsChevronDown } from 'react-icons/bs';
import { InlineModal } from 'components/Modals';
import { useState, useRef, useEffect } from 'react';
import { BooleanFilter, BooleanFilter2 } from 'components/FilterItems';

const TasksPage = () => {
  const { tab, user, tasks, projects, handleTasks } = useApp();
  const [filters, setFilters] = useState({
    forMe: false,
    status: 'All',
    overdue: false,
    project: 'All',
    priority: false,
  });
  const isSelected = tab === "tasks"
  const claimFilterBtn = useRef(null);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const filteredTasks = getFiltered(user, filters, tasks)
  const sortedTasks = filteredTasks?.sort((a, b) => new Date(a.endDate) - new Date(b.endDate))
  const [claimFilterModal, setClaimFilterModal] = useState(false);

  const updateFilters = (changes = {}) => {
    setFilters({...filters, ...changes})
  }

  useEffect(() => {
    if (isSelected) {
      handleTasks();
    }
    //eslint-disable-next-line
  }, [isSelected])

  const handleClaimFilterSelect = (newValue) => {
    setClaimFilterModal(false);
    updateFilters({ project: newValue })
  }

  return(
    <DashPage tab={tab} value='tasks'>
      <div className='' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 120px)', paddingTop: 24 }}>
        <div className='jb-ac'>
          <h1 style={{ marginBottom: 0 }}>My Tasks</h1>
          <div className='je-ac d-none d-lg-flex'>
            <div ref={claimFilterBtn} onClick={() => setClaimFilterModal(true)} className='js-ac me-3' style={{ cursor: 'pointer', color: 'rgb(100, 102, 107)', fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
              Claim
              <BsChevronDown style={{ marginLeft: 4 }} />
            </div>
            <BooleanFilter title="Overdue" filterKey='overdue' filters={filters} updateFilters={updateFilters} className="me-3" />
            <BooleanFilter title="Priority" filterKey='priority' filters={filters} updateFilters={updateFilters} className="me-3" />
            <BooleanFilter2 title="To Do" filterKey='status' value='todo' filters={filters} updateFilters={updateFilters} className="me-3" />
            <BooleanFilter2 title="Stand By" filterKey='status' value='standby' filters={filters} updateFilters={updateFilters} className="me-3" />
            <BooleanFilter2 title="Complete" filterKey='status' value='complete' filters={filters} updateFilters={updateFilters} className="me-3" />
            <BooleanFilter2 title="Inapplicable" filterKey='status' value='inapplicable' filters={filters} updateFilters={updateFilters} className="me-3" />
            <div onClick={() => setAddTaskModal(true)} className='brelly-text-btn'>Add New</div>
          </div>
        </div>
        <div className='container mt-3'>
          <div className="row d-none d-lg-flex">
            <div className="dash-table-header col-1 jc-ac">
            </div>
            <div className="dash-table-header col-4">
              Name
            </div>
            <div className="dash-table-header col-3">
              Claim
            </div>
            <div className="dash-table-header col-2">
              Status
            </div>
            <div className="dash-table-header col-2">Due</div>
          </div>
          {sortedTasks?.map((task, idx) => (
            <AllTasksItem key={idx} task={task} handleUpdate={handleTasks} />
          ))}
        </div>
      </div>
      <AddTaskModal open={addTaskModal} handleClose={() => setAddTaskModal(false)} handleUpdate={handleTasks} />
      <InlineModal btnRef={claimFilterBtn} open={claimFilterModal} handleClose={() => setClaimFilterModal(false)}>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleClaimFilterSelect('All')}>All</div>
        {projects?.map((p, key) => (
          <div key={key} className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleClaimFilterSelect(p?.id)}>{p?.claim?.claimNumber}</div>
        ))}
      </InlineModal>
    </DashPage>
  )
}

export default TasksPage;

const getFiltered = (user, filters, claimTasks = []) => {
  let _tasks = claimTasks

  if (filters?.overdue) {
    _tasks = _tasks?.filter((t) => new Date(t?.endDate) < new Date())
  }

  if (filters?.priority) {
    _tasks = _tasks?.filter((t) => t?.priority)
  }

  if (filters?.status === "todo") {
    _tasks = _tasks?.filter((t) => t?.status === "To Do")
  }

  if (filters?.status === "standby") {
    _tasks = _tasks?.filter((t) => t?.status === "Standby")
  }

  if (filters?.status === "complete") {
    _tasks = _tasks?.filter((t) => t?.status === "Complete")
  }

  if (filters?.status === "inapplicable") {
    _tasks = _tasks?.filter((t) => t?.status === "Inapplicable")
  }

  if (filters?.project !== "All") {
    _tasks = _tasks?.filter((t) => t?.projectId === filters?.project)
  }

  return _tasks
}
