import { AddTeamMemberModal } from 'modals';
import { BsX, BsHouseDoor } from "react-icons/bs";
import { ShareClaimModal } from 'modals';
import { ConfirmDeleteModal } from 'modals';
import { CenterModal, InlineModal } from "components/Modals";
import { LiaPlusSolid } from "react-icons/lia";
import ProPic from "components/ProPic";
import { BrellyBtn, BrellyIconBtn } from "components/Button";
import { format } from "date-fns";
import {
  IoShareSocialOutline,
  IoChevronBackOutline,
  IoChevronDownOutline,
  IoEllipsisVerticalOutline,
} from "react-icons/io5";
import { useApp } from "context";
import brelly from "@brelly/sdk";
import { useProject } from "./context";
import { useState, useEffect, useRef } from "react";

const ClaimDashHeader = () => {
  const { project, claim, property, handleClaim, phases } = useProject();
  const { setError, setLoading, setTab, projectId, handleUser } = useApp();
  const [teamMemberModal, setTeamMemberModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const modalBtnRef = useRef(null);
  const statusModalBtnRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModal = () => {
    setActionsModal(false);
    setDeleteModal(true);
  };

  const handleArchived = async () => {
    if (projectId) {
      try {
        setLoading(true);
        await brelly.projects.update(projectId, { archived: !project?.archived });
        await handleClaim(false);
        handleUser()
        setActionsModal(false);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err?.message);
      }
    }
  };

  const handlePhase = async (newPhaseId) => {
    if (projectId) {
      try {
        await brelly.projects.update(projectId, { currentPhaseId: newPhaseId });
        await handleClaim(false);
        setStatusModal(false);
      } catch (err) {
        setError(err?.message);
      }
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.projects.delete(projectId);
      setLoading(false);
      setDeleteModal(false);
      setTab("claims");
    } catch (err) {
      setLoading(false);
      setError(err.message)
    }
  };

  const claimNumber = claim?.claimNumber ? claim.claimNumber : "Unknown";
  const dateOfLoss = claim?.dateOfLoss
    ? format(new Date(claim?.dateOfLoss), "MMM dd, yyyy")
    : "Unknown";

  let getName = () => {
    if (project?.policyholder) {
      return `${project?.policyholder?.firstName} ${project?.policyholder?.lastName}`;
    } else {
      return "Unknown";
    }
  };

  const handleCoverPhoto = async (ev) => {
    let file = ev.target.files[0];

    if (file) {
      try {
        setLoading(true);
        await brelly.claims.uploadCoverPhoto(claim?.id, file);
        await handleClaim(false);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <div className="jb-ac">
      <div className="js-ac" style={{ fontWeight: 600, fontSize: 20, marginLeft: -10 }}>
        <IoChevronBackOutline style={{ marginRight: 5 }} onClick={() => setTab("claims")} className='d-none d-lg-block' />
        <div style={{ position: "relative" }} className='d-none d-lg-block'>
          <Img claim={claim} />
          <input
            type="file"
            accept="image/*"
            onChange={handleCoverPhoto}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
            }}
          />
        </div>
        <div>
          <div style={{ maxWidth: '100%' }} className='truncate1'>{property?.address?.fullAddress || "ADDRESS"}</div>
          <div style={{ fontSize: 14, fontWeight: 400 }} className='d-none d-lg-block'>
            <div className="mt-1 truncate1">
              <span>Primary Policyholder:</span>
              <span className="ms-1" style={{ fontWeight: 600 }}>
                {getName()}
              </span>
            </div>
            <div className="truncate1">
              <span>Claim Number:</span>
              <span className="ms-1" style={{ fontWeight: 600 }}>
                {claimNumber}
              </span>
            </div>
            <div className="truncate1">
              <span>Loss Date:</span>
              <span className="ms-1" style={{ fontWeight: 600 }}>
                {dateOfLoss}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='d-none d-lg-block' style={{ flexShrink: 0 }}>
        <div className="je-ac">
          <BrellyBtn
            onClick={() => setShareModal(true)}
            style={{
              color: "rgb(13, 114, 197)",
              background: "transparent",
              border: "1px solid rgb(13, 114, 197)",
              paddingLeft: 4,
              paddingRight: 4,
              minWidth: 110,
            }}
          >
            <IoShareSocialOutline
              style={{
                fontSize: 24,
                color: "rgb(13, 114, 197)",
                marginRight: 5,
              }}
            />
            Share
          </BrellyBtn>
          <BrellyBtn style={{ flexShrink: 0 }} onClick={() => setStatusModal(!statusModal)} btnRef={statusModalBtnRef} className="ms-3">
            <div className="jc-ac">
              <div>{phases?.find((p) => p?.id === project?.currentPhaseId)?.name || "PHASE"}</div>
              <IoChevronDownOutline style={{ color: "white", marginLeft: 2 }} />
            </div>
          </BrellyBtn>
          <BrellyIconBtn className="ms-3" btnRef={modalBtnRef} onClick={() => setActionsModal(true)}>
            <IoEllipsisVerticalOutline style={{ fontSize: 24, color: "#0D72C5" }} />
          </BrellyIconBtn>
        </div>
        <div>
          <div className="je-ac mt-2">
            {project?.contributors?.map((owner, key) => (
              <div key={key} style={{ position: "relative", marginLeft: 5 }}>
                <ProPic user={owner?.user} img={owner?.user?.profilePic?.downloadUrl} />
              </div>
            ))}
            <div
              onClick={() => setTeamMemberModal(true)}
              style={{
                width: 32,
                height: 32,
                borderRadius: "50%",
                textTransform: "uppercase",
                border: "1px solid #0D72C5",
                background: "rgba(13, 114, 197, 0.16)",
                color: "#0D72C5",
                marginLeft: 5,
              }}
              className="jc-ac"
            >
              <LiaPlusSolid style={{ fontSize: 20 }} />
            </div>
          </div>
        </div>
      </div>
      <InlineModal btnRef={statusModalBtnRef} open={statusModal} handleClose={() => setStatusModal(false)}>
        {phases?.map((phase, key) => (
          <BrellyBtn
            style={{ fontSize: 14, padding: "4px 18px" }}
            key={key}
            onClick={() => handlePhase(phase?.id)}
            className="mb-1"
          >
            {phase?.name}
          </BrellyBtn>
        ))}
      </InlineModal>
      <InlineModal btnRef={modalBtnRef} open={actionsModal} handleClose={() => setActionsModal(false)}>
        <div
          onClick={handleArchived}
          className="dash-table-item js-ac"
          style={{ fontWeight: 600, cursor: "pointer" }}
        >
          {project?.archived ? "Unarchive" : "Archive"}
        </div>
        <div
          className="dash-table-item js-ac mt-3"
          onClick={handleDeleteModal}
          style={{ fontWeight: 600, cursor: "pointer", color: "red" }}
        >
          Delete
        </div>
      </InlineModal>
      <ConfirmDeleteModal
        open={deleteModal}
        onDelete={handleDelete}
        handleClose={() => setDeleteModal(false)}
      />
      <ShareClaimModal open={shareModal} handleClose={() => setShareModal(false)} />
      <AddTeamMemberModal
        open={teamMemberModal}
        handleUpdate={handleClaim}
        members={project.contributors}
        handleClose={() => setTeamMemberModal(false)}
      />
    </div>
  );
};

export default ClaimDashHeader;

const Img = ({ claim }) => {
  if (claim?.coverPhoto?.downloadUrl) {
    return (
      <img
        alt=""
        style={{
          width: 95,
          height: 95,
          marginRight: 12,
          borderRadius: 10,
          objectFit: "cover",
          transition: "all 200ms linear",
        }}
        src={claim?.coverPhoto?.downloadUrl}
      />
    );
  } else {
    return (
      <div
        style={{ width: 95, height: 95, marginRight: 12, borderRadius: 10, background: "#F5F5F5" }}
        className="jc-ac"
      >
        <BsHouseDoor />
      </div>
    );
  }
};
