import Input from 'components/Input';
import { getClaimDisplay } from 'utils';
import TextArea from 'components/TextArea';
import { useState, useEffect } from 'react';
import { BrellyBtn } from 'components/Button';
import { DateSelect } from 'components/Select';
import { CenterModal } from 'components/Modals';
import { AddFileModal } from 'modals';
import { AttachmentItem } from 'items';
import ClaimSelector from 'components/ClaimSelector';
import { ConfirmDeleteModal } from 'modals';
import TeammemberSelector from 'components/TeammemberSelector';

import brelly from "@brelly/sdk";
import { useApp } from 'context';

export const EditEventModal = ({ task, inClaim, open, handleClose, handleUpdate }) => {
  const [confirmfirmDeleteModal, setConfirmfirmDeleteModal] = useState(false);
  const { setLoading, setError, projects } = useApp();
  const [desc, setDesc] = useState('');
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [due, setDue] = useState(new Date());
  const [name, setName] = useState('');
  const [files, setFiles] = useState([]);
  const existingFiles = task?.files || []
  const allFiles = [...existingFiles, ...files];

  const handleFiles = (_files) => {
    setFiles([...files, ..._files])
  }

  const handleFileDelete = async (file) => {
    if (file?.notConnected || file?.file) {
      let remaining = files?.filter((f) => f?.id !== file?.id);
      setFiles(remaining)
    } else {
      try {
        setLoading(true);
        await brelly.events.removeFiles(task?.id, [file?.id])
        handleUpdate()
        setLoading(false);
      } catch(err) {
        setError(err.message);
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (open) {
      setDesc(task?.description)
      if (task?.endDate) {
          setDue(new Date(task?.endDate))
      }
      setName(task?.name)
      let formattedUsers = task?.participants?.map((ass) => ({ label: `${ass?.user?.firstName} ${ass?.user?.lastName}`, value: ass?.id }));
      setSelectedUsers(formattedUsers);
      setSelectedClaim([{ label: getClaimDisplay(task?.projectId, projects), value: task?.project?.id }])
    }
    //eslint-disable-next-line
  }, [open])

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.events.update(task?.id, { name: name, description: desc, endDate: due, projectId: selectedClaim[0]?.value })
      await brelly.events.setParticipants(task?.id, selectedUsers?.map((ass) => ass?.value))
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id);
        let remoteNoDuplicates = checkFileDupes(remoteFiles, existingFiles)

        if (localFiles?.length > 0) {
          await brelly.events.uploadFiles(task?.id, localFiles)
        }

        if (remoteNoDuplicates?.length > 0) {
          await brelly.events.addFiles(task?.id, remoteNoDuplicates)
        }
      }
      await handleUpdate()
      setLoading(false);
      handleClose()
    } catch(err) {
      setError(err?.message);
      setLoading(false);
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true);
      setConfirmfirmDeleteModal(false)
      await brelly.events.delete(task?.id);
      await handleUpdate()
      handleClose()
      setLoading(false);
    } catch(err) {
      setError(err);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Event</div>
      <div className='js-as'>
        <div style={{ width: 300, marginRight: 8 }}>
          <Input value={name} onChange={setName} placeholder='' label='Task Name' />
          <DateSelect value={due} onChange={setDue} label='Due Date' />
          {!inClaim && <ClaimSelector value={selectedClaim} onChange={setSelectedClaim} />}
          <TeammemberSelector value={selectedUsers} onChange={setSelectedUsers} />
        </div>
        <div style={{ width: 300, marginLeft: 8 }}>
          <TextArea label='Description' value={desc} onChange={setDesc} />
        </div>
      </div>
      <div className='row mt-2'>
        {allFiles?.map((m, idx) => (
          <AttachmentItem key={`${m?.id}-${idx}`} file={m} deletable={true} handleDelete={handleFileDelete} />
        ))}
      </div>
      <BrellyBtn onClick={() => setAddFilesModal(true)} className='mt-3' style={{ background: '#2D3C57' }}>Add Attachments</BrellyBtn>
      <div className='row mt-2'>
        <div className='col-6'>
          <BrellyBtn style={{ border: '1px solid red', color: 'red', background: 'transparent' }} onClick={() => setConfirmfirmDeleteModal(true)}>Delete</BrellyBtn>
        </div>
        <div className='col-6'>
          <BrellyBtn  onClick={handleEdit}>Save</BrellyBtn>
        </div>
      </div>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .csv, .xls, xlsx, .mov, .mp4, .ppt, .pptx, .txt, .svg"
      />
      <ConfirmDeleteModal open={confirmfirmDeleteModal} handleClose={() => setConfirmfirmDeleteModal(false)} onDelete={handleDelete} />
    </CenterModal>
  )
}

const checkFileDupes = (toAttach, existing) => {
  let existingIds = existing?.map((eimg) => eimg?.id);
  return toAttach?.filter((nimg) => !existingIds?.includes(nimg))
}
