//External Imports
import { useState } from "react";
import { useHistory } from "react-router";

// Internal Imports
import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import {BrellyBtn} from "components/Button";
import AuthPage from 'components/AuthImage';

const RequestPasswordCode = () => {
  const history = useHistory();
  // @ts-expect-error TS(2339): Property 'setError' does not exist on type 'unknow... Remove this comment to see the full error message
  const { setError, setLoading } = useApp();
  const [email, setEmail] = useState("");

  const handleResetEmail = async () => {
    // make sure email is not blank
    if (email.length < 1) {
      setError("Email cannot be blank!");
    } else {
      setLoading(true);
      try {
        const lowerEmail = email.toLowerCase();
        await brelly.auth.sendPasswordResetCode(lowerEmail);
        localStorage.setItem("reset-password-email", lowerEmail);
        history.push("/reset-password");
        setLoading(false);
      } catch (err) {
        setLoading(false);
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        setError(err.message);
      }
    }
  };

  return (
    <AuthPage title='Reset Password'>
      <Input label='Email' value={email} onChange={setEmail} />
      <BrellyBtn className='mt-3' onClick={handleResetEmail}>
        Next
      </BrellyBtn>
      <div className="jc-ac mt-3">
        <div className="brelly-text-btn" onClick={() => history.push("/login")}>
          Back to Login
        </div>
      </div>
    </AuthPage>
  );
};

export default RequestPasswordCode;
