import brelly from "@brelly/sdk";
import { useApp } from 'context';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { useState, useEffect } from 'react';
import { BrellyBtn } from 'components/Button';
import { DateSelect } from 'components/Select';
import { CenterModal } from 'components/Modals';
import { AddFileModal } from 'modals';
import { SingleSelect } from 'components/Select';
import { AttachmentItem } from 'items';
import { ConfirmDeleteModal } from 'modals';

export const EditCallModal = ({ call, inClaim, open, handleClose, handleUpdate }) => {
  const [confirmfirmDeleteModal, setConfirmfirmDeleteModal] = useState(false);
  const { setLoading, setError, contacts } = useApp();
  const [desc, setDesc] = useState('');
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [selectedCaller, setSelectedCaller] = useState({});
  const [selectedRecipient, setSelectedRecipient] = useState({});
  const [occuredAt, setOccuredAt] = useState(null);
  const [topic, setTopic] = useState('');
  const [files, setFiles] = useState([]);
  const existingFiles = call?.files || []
  const allFiles = [...existingFiles, ...files];
  const formattedUsers = contacts?.map((u, idx) => ({ ...u, label: `${u?.firstName} ${u?.lastName}`, value: u?.id }));

  const handleFiles = (_files) => {
    setFiles([...files, ..._files])
  }

  useEffect(() => {
    if (open) {
      setSelectedRecipient(formattedUsers?.find((u) => u?.id === call?.recieverId) || {})
      setSelectedCaller(formattedUsers?.find((u) => u?.id === call?.callerId) || {})
      setDesc(call?.description)
      setTopic(call?.topic)
      if (call?.occuredAt) {
          setOccuredAt(new Date(call?.occuredAt))
      }
    }
    //eslint-disable-next-line
  }, [open])

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.phoneCalls.update(call?.id, { topic: topic, description: desc, occuredAt: occuredAt, callerId: selectedCaller?.id, recieverId: selectedRecipient?.id })
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id);
        let remoteNoDuplicates = checkFileDupes(remoteFiles, existingFiles)

        if (localFiles?.length > 0) {
          await brelly.phoneCalls.uploadFiles(call?.id, localFiles)
        }

        if (remoteNoDuplicates?.length > 0) {
          await brelly.phoneCalls.addFiles(call?.id, remoteNoDuplicates)
        }
      }
      await handleUpdate()
      setLoading(false);
      handleClose()
    } catch(err) {
      setError(err?.message)
      setLoading(false);
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true);
      setConfirmfirmDeleteModal(false)
      await brelly.phoneCalls.delete(call?.id);
      await handleUpdate()
      handleClose()
      setLoading(false);
    } catch(err) {
      setError(err);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose} style={{ width: 700, maxWidth: '100%' }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Call</div>
      <div className='row' style={{ maxHeight: window.innerHeight - 280, overflowY: 'auto' }}>
        <div className='col-12 col-lg-6'>
          <Input value={topic} onChange={setTopic} placeholder='' label='Primary Topic' />
          <DateSelect value={occuredAt} onChange={setOccuredAt} label='Date' />
          <SingleSelect style={{ width: '100%' }} value={selectedCaller} onChange={setSelectedCaller} options={formattedUsers} label='Caller' />
          <SingleSelect style={{ width: '100%' }} value={selectedRecipient} onChange={setSelectedRecipient} options={formattedUsers} label='Recipient' />
        </div>
        <div className='col-12 col-lg-6'>
          <TextArea label='Description' value={desc} onChange={setDesc} style={{ height: 218 }} />
        </div>
      </div>
      {allFiles?.length > 0 && (
        <div className='row mt-2'>
          {allFiles?.map((f, key) => (
            <AttachmentItem key={key} file={f} />
          ))}
        </div>
      )}
      <BrellyBtn onClick={() => setAddFilesModal(true)} className='mt-3' style={{ background: '#2D3C57' }}>Add Attachments</BrellyBtn>
      <div className='row mt-2'>
        <div className='col-6'>
          <BrellyBtn style={{ border: '1px solid red', color: 'red', background: 'transparent' }} onClick={() => setConfirmfirmDeleteModal(true)}>Delete</BrellyBtn>
        </div>
        <div className='col-6'>
          <BrellyBtn  onClick={handleEdit}>Save</BrellyBtn>
        </div>
      </div>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .csv, .xls, xlsx, .mov, .mp4, .ppt, .pptx, .txt, .svg"
      />
      <ConfirmDeleteModal open={confirmfirmDeleteModal} handleClose={() => setConfirmfirmDeleteModal(false)} onDelete={handleDelete} />
    </CenterModal>
  )
}

const checkFileDupes = (toAttach, existing) => {
  let existingIds = existing?.map((eimg) => eimg?.id);
  return toAttach?.filter((nimg) => !existingIds?.includes(nimg))
}
