import { Portal } from "react-portal";
import { BsX } from "react-icons/bs";

const FileModals = ({ open, file, setModal }) => {
  let contentType = file?.contentType;

  if (open) {
    if (contentType?.includes("image")) {
      return <ImgModal img={file} setModal={setModal} />
    } else if (contentType?.includes("video")) {
      return <VideoModal vid={file} setModal={setModal} />
    } else {
      return null;
    }
  } else {
    return null
  }
}

export default FileModals;

const ImgModal = ({ img, setModal }) => {
  return(
    <Portal>
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          background: "black",
          zIndex: 1000000
        }}
        className="jc-ac"
      >
        <div
          style={{ position: "fixed", right: 16, top: 16, color: "white" }}
          onClick={() => setModal(false)}
        >
          <BsX style={{ fontSize: 24 }} />
        </div>
        <img
          src={img.downloadUrl}
          alt=""
          style={{ height: "100%", objectFit: "contain", maxWidth: "100%" }}
        />
      </div>
    </Portal>
  )
}

const VideoModal = ({ vid, setModal }) => {
  return(
    <Portal>
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          background: "black",
          zIndex: 1000000
        }}
        className="jc-ac"
      >
        <div
          style={{ position: "fixed", right: 6, top: 6, color: "white", padding: 10, zIndex: 1000 }}
          onClick={() => setModal(false)}
        >
          <BsX style={{ fontSize: 24 }} />
        </div>
        <video
          controls
          style={{
            height: '100%',
            objectFit: "contain",
            maxWidth: "100%"
          }}
        >
          <source  src={vid?.downloadUrl} type="video/mp4" />
        </video>
      </div>
    </Portal>
  )
}
