import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { BsFlag, BsFlagFill } from 'react-icons/bs';

export const TaskPriority = ({ task, handleUpdate = () => {}, style = {}, className = "", iconStyle = {} }) => {
  const { setError } = useApp();

  const handlePriority = async (e) => {
    e.stopPropagation();

    if (task?.id) {
      try {
        await brelly.tasks.update(task?.id, { priority: !task?.priority });
        await handleUpdate();
      } catch(err) {
        setError(err.message)
      }
    }
  }

  if (task?.priority) {
    return(
      <div onClick={handlePriority} className={`jc-ac ${className}`} style={{ width: '100%', ...style }}>
        <BsFlagFill style={{ fontSize: 20, ...iconStyle }} />
      </div>
    )
  } else {
    return(
      <div onClick={handlePriority} className={`jc-ac ${className}`} style={{ width: '100%', ...style }}>
        <BsFlag style={{ fontSize: 20, ...iconStyle }} />
      </div>
    )
  }
}

export const EventPriority = ({ task, handleUpdate = () => {} }) => {
  const { setError } = useApp();

  const handlePriority = async (e) => {
    e.stopPropagation();
    try {
      await brelly.events.update(task?.id, { priority: !task?.priority });
      await handleUpdate();
    } catch(err) {
      setError(err.message)
    }
  }

  if (task?.priority) {
    return(
      <div className="jc-ac" style={{ width: '100%' }}>
        <BsFlagFill style={{ fontSize: 20 }} />
      </div>
    )
  } else {
    return(
      <div className="jc-ac" style={{ width: '100%' }}>
        <BsFlag style={{ fontSize: 20 }} />
      </div>
    )
  }
}
