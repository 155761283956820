import brelly from "@brelly/sdk";
import { useApp } from 'context';
import { useState } from 'react';
import { format } from 'date-fns';
import ProPic from 'components/ProPic';
import { EditEventModal } from './Edit';
import { CenterModal } from 'components/Modals';
import { AttachmentItem } from 'items';
import { ConfirmDeleteModal } from 'modals';

export const ViewEventModal = ({ task = {}, open, handleClose, handleUpdate }) => {
  const [confirmfirmDeleteModal, setConfirmfirmDeleteModal] = useState(false);
  const { setLoading, setError } = useApp();
  let startDate = task?.startDate ? format(new Date(task?.startDate), 'MMM dd, yyyy') : ""
  let endDate = task?.endDate ? format(new Date(task?.endDate), 'MMM dd, yyyy') : ""
  let startTime = task?.startDate ? format(new Date(task?.startDate), 'h:mm aaa') : ""
  let endTime = task?.endDate ? format(new Date(task?.endDate), 'h:mm aaa') : ""
  const [editTaskModal, setEditTaskModal] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      setConfirmfirmDeleteModal(false)
      await brelly.events.delete(task?.id);
      await handleUpdate()
      handleClose()
      setLoading(false);
    } catch(err) {
      setError(err);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ width: 450 }}>
        <span style={{ fontSize: 12 }}>Event Details</span>
        <div style={{ fontWeight: 600, fontSize: 20 }} className='jb-ac mb-4'>
          {task?.name}
          <div className='je-ac'>
            <div className='brelly-text-btn me-3' onClick={() => setEditTaskModal(true)} style={{ color: '#0D72C5' }}>Edit</div>
            <div className='brelly-text-btn' onClick={() => setConfirmfirmDeleteModal(true)} style={{ color: 'red' }}>Delete</div>
          </div>
        </div>
        {startDate && (
          <div className='js-ac mb-2'>
            <div style={{ width: 200 }}>Start</div>
            <div className='js-ac'>
              <div style={{ fontWeight: 500 }} className='me-3'>{startDate}</div>
              <div style={{ fontWeight: 500 }}>{startTime}</div>
            </div>
          </div>
        )}
        {endDate && (
          <div className='js-ac mb-2'>
            <div style={{ width: 200 }}>End</div>
            <div className='js-ac'>
              <div style={{ fontWeight: 500 }} className='me-3'>{endDate}</div>
              <div style={{ fontWeight: 500 }}>{endTime}</div>
            </div>
          </div>
        )}
        {task?.participants?.length > 0 && (
          <div className='js-ac mb-2'>
            <div style={{ width: 200 }}>Participants:</div>
            <div className='js-ac'>
              {task?.participants?.map((user, key) => (
                <ProPic user={user?.user} key={key} size={24} className='me-1' style={{ fontSize: 14 }} />
              ))}
            </div>
          </div>
        )}
        {task?.description && (
          <div className='mt-4'>
            <div style={{ fontWeight: 600 }}>Description:</div>
            <div className='mt-1' style={{ lineHeight: 1.2 }}>
              {task?.description}
            </div>
          </div>
        )}

        <Creator task={task} />

        <div className='js-ac mt-2'>
          <div style={{ width: 200 }}>Created At:</div>
          <div>
            <div className='truncate1' style={{ fontWeight: 500 }}>{format(new Date(task?.createdAt), 'MM/dd/yyyy - hh:mm aaa')}</div>
          </div>
        </div>

        <div className='mt-4'>
          {task?.files?.length > 0 && <div style={{ fontWeight: 600 }}>Attachments:</div>}
          <div className='row'>
            {task?.files?.map((tf, key) => (
              <AttachmentItem file={tf} key={key} />
            ))}
          </div>
        </div>
      </div>
      <EditEventModal task={task} open={editTaskModal} handleClose={() => setEditTaskModal(false)} handleUpdate={handleUpdate} />
      <ConfirmDeleteModal open={confirmfirmDeleteModal} handleClose={() => setConfirmfirmDeleteModal(false)} onDelete={handleDelete} />
    </CenterModal>
  )
}

const Creator = ({ task }) => {
  if (task?.creator) {
    return(
      <div className='js-ac mt-2'>
        <div style={{ width: 200 }}>Creator:</div>
        <div>
          <div className='truncate1 js-ac' style={{ fontWeight: 500 }}>
            <ProPic user={task?.creator} size={24} className='me-1' style={{ fontSize: 14 }} />
            {task?.creator?.firstName} {task?.creator?.lastName}
          </div>
        </div>
      </div>
    )
  } else if (task?.phaseId) {
    return (
      <div className='js-ac mt-2'>
        <div style={{ width: 200 }}>Creator:</div>
        <div>
          <div className='truncate1 js-ac' style={{ fontWeight: 500 }}>
            Workflow
          </div>
        </div>
      </div>
    )
  } else {
    return;
  }
}
