import { useState } from "react";

import { useProject } from "pages/Dash/Claims/Claim/context";
import { AddAgentModal, EditAgentModal } from "modals";
import {
  DetailItem,
  EmailDetailItem,
  PhoneDetailItem,
} from "pages/Dash/Claims/Claim/Details/components";

const Agents = () => {
  const { contacts } = useProject();
  const [addModal, setAddModal] = useState(false);

  const insurerAgentContacts = contacts?.filter(
    (c) => c.role === "INSURANCE AGENT"
  );

  return (
    <>
      <div className="dash-box-title mt-3 jb-ac">
        Insurance Agents
        <div
          className="brelly-text-btn"
          onClick={() => setAddModal(true)}
          style={{ color: "#0D72C5" }}
        >
          Add
        </div>
      </div>
      {insurerAgentContacts?.map((c) => (
        <AgentItem c={c} key={c?.id} />
      ))}
      <AddAgentModal open={addModal} handleClose={() => setAddModal(false)} />
    </>
  );
};

export default Agents;

const AgentItem = ({ c }) => {
  const [editModal, setEditModal] = useState(false);
  return (
    <>
      <div
        className="mt-3 brelly-content ps-3 pe-3 pt-2 pb-3"
        style={{ borderRadius: 8 }}
      >
        <div className="jb-ac" style={{ width: "100%", marginBottom: 14 }}>
          <div className="dash-box-title">
            {c?.firstName} {c?.lastName}
          </div>
          <div
            className="brelly-text-btn"
            onClick={() => setEditModal(true)}
            style={{ color: "#0D72C5" }}
          >
            Edit
          </div>
        </div>
        <PhoneDetailItem title="Phone" value={c?.primaryPhoneNumber} />
        <EmailDetailItem title="Email" value={c?.primaryEmail} />
        <DetailItem title="Address" value={""} />
      </div>
      <EditAgentModal
        contact={c}
        open={editModal}
        handleClose={() => setEditModal(false)}
      />
    </>
  );
};
