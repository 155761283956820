import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import { WorkflowTaskItem } from 'items';
import TextArea from 'components/TextArea';
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CenterModal } from 'components/Modals';
import ColorPicker from 'react-best-gradient-color-picker';

export const EditPhaseModal = ({ open, handleClose, phase, stageDetails, handleStage }) => {
  const { setLoading, handleUser, setError } = useApp();
  const [colorModal, setColorModal] = useState(false);
  const [values, setValues] = useState({});

  const handleUpdate = (updates = {}) => {
    setValues({ ...values, ...updates })
  }

  useEffect(() => {
    if (open) {
      setValues(phase)
    }
    //eslint-disable-next-line
  }, [open])

  const handleAdd = async () => {
    try {
      setLoading(true);
      await brelly.phases.update(phase?.id, {
        name: values?.name,
        color: values?.color,
        description: values?.description,
      })
      await handleUser(false);
      handleStage()
      setLoading(false);
      handleClose()
    } catch(err) {
      setError(err?.message);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Phase {phase?.name}</div>
      <Input value={values?.name || ""} onChange={(v) => handleUpdate({ name: v })} label='Name' />
      <TextArea value={values?.description || ""} onChange={(v) => handleUpdate({ description: v })} label='Description' style={{ height: 80 }} />
      <div className='jb-ac mt-2 mb-3 ps-1' style={{ }}>
        Color
        <div style={{ width: 28, height: 28, borderRadius: '50%', background: values?.color || 'blue' }} />
      </div>
      {stageDetails?.workflowTasks?.map((t) => <WorkflowTaskItem t={t} key={t?.id} tasks={stageDetails?.workflowTasks} handleStage={handleStage} />)}
      <BrellyBtn onClick={handleAdd} className='mt-4' style={{ width: 500 }}>Save</BrellyBtn>
      <CenterModal open={colorModal} handleClose={() => setColorModal(false)}>
        <ColorPicker value={values?.color} onChange={(v) => handleUpdate({ color: v })} />
      </CenterModal>
    </CenterModal>
  )
}
