import { useState, useEffect, useRef } from 'react';
import { SearchIcon, CloseIcon } from 'components/Icons';
import { BrellyBtn } from 'components/Button';
import CheckBox from 'components/CheckBox';
import { Portal } from 'react-portal';
import { BsX } from 'react-icons/bs';

const SearchBar = ({ value = '', onChange = () => {}, placeholder = 'Search', style = {}, labelStyle = {}, label = '', className = "", contStyle = {} }) => {
  return(
    <div className={className} style={contStyle}>
      {label && <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2, ...labelStyle }} className='mt-2'>{label}</div>}
      <div className='' style={{ position: 'relative', width: '100%' }}>
        <div style={{ position: 'absolute', left: 8, top: 6 }}>
          <SearchIcon size='20' />
        </div>
        <input value={value} onChange={(e) => onChange(e.target.value)} className='dash-input' placeholder={placeholder} style={{ paddingLeft: 35, ...style }} />
      </div>
    </div>
  )
}

export default SearchBar;

export const DropdownSearchBar = ({ value = [], onChange = () => {}, options = [], style = {}, labelStyle = {}, placeholder='Search', label = '', single }) => {
  const [searchVal, setSearchVal] = useState("");
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueLength = value?.length
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;

  const isSelected = (option) => {
    const formattedValues = value?.map((v) => v.value);
    return !!formattedValues?.includes(option?.value)
  }

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect())
    }
  }, [inputRef, focus, valueLength])

  const filteredOptions = () => {
    if (searchVal?.length === 0) {
      return options;
    } else {
      return options?.filter((option) => option.label?.includes(searchVal))
    }
  }

  const handleOptionsClose = () => {
    setSearchVal("");
    setFocus(false)
  }

  const handleChange = (val) => {
    let vals = value?.map((tag) => tag.value);

    if (single) {
      onChange([val])
    } else {
      if (vals?.includes(val?.value)) {
        let remaining = value?.filter((tag) => tag?.value !== val?.value);
        onChange(remaining)
      } else {
        onChange([...value, val])
      }
    }
  }

  const handleSelect = (val) => {
    if (single) {
      handleOptionsClose()
    }
    handleChange(val)
  }

  return(
    <>
      {label && <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2, ...labelStyle }} className='mt-2'>{label}</div>}
      <div className='' style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', left: 8, top: 6 }}>
          <SearchIcon size='20' />
        </div>
        <input ref={inputRef} value={searchVal} onChange={(e) => setSearchVal(e.target.value)} onFocus={() => setFocus(true)} className='dash-input' placeholder={placeholder} style={{ paddingLeft: 35, width: 300, ...style }} />

        {valueLength > 0 && (
          <div style={{ marginTop: 6, marginBottom: 12 }}>
            {value?.map((v, key) => (
              <div key={key} style={{ fontFamily: 'Lato', fontWeight: 500, fontSize: 12 }} className='jb-ac mt-1'>
                <div style={{ width: 'calc(100%, - 40px)', textTransform: 'capitalize' }}>{v?.label}</div>
                <div style={{ width: 30 }} onClick={() => handleSelect(v)} className='je-ac'>
                  <CloseIcon stroke='#ff0000' size={16} />
                </div>
              </div>
            ))}
          </div>
        )}
        {focus && (
          <Portal>
            <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)', zIndex: 1009 }} onClick={handleOptionsClose} />
            <div style={{ position: 'absolute', background: '#F5F5F5', borderRadius: 10, padding: 12, zIndex: 1010, fontFamily: 'Lato', left: boundingRect.x, top: boundingRect.bottom + 6, width: boundingRect.width }} className='shadow'>
              <div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
                {filteredOptions()?.length > 0 && (
                  filteredOptions().map((option, key) => (
                    <div key={key} onClick={() => handleSelect(option)} style={{ width: '100%', height: 38, borderRadius: 10, background: 'white', fontSize: 14, cursor: 'pointer' }} className='mb-2 js-ac ps-2'>
                      <CheckBox title={option.label} value={isSelected(option)} setValue={() => {}} />
                    </div>
                  ))
                )}
                {options?.length === 0 && (
                  <div className='jc-ac mb-2'>No Options</div>
                )}
              </div>
              <BrellyBtn style={{ marginTop: 12 }} onClick={handleOptionsClose}>Done</BrellyBtn>
            </div>
          </Portal>
        )}
      </div>
    </>
  )
}

export const SearchSelect = ({ value = [], onChange = () => {}, options = [], style = {}, labelStyle = {}, label = '', placeholder='Search', single }) => {
  const [searchVal, setSearchVal] = useState("");
  const [boundingRect, setBoundingRect] = useState({});
  const [valueVoundingRect, setValueBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueLength = value?.length;
  const valueRef = useRef(null);
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;

  const isSelected = (option) => {
    const formattedValues = value?.map((v) => v.value);
    return !!formattedValues?.includes(option?.value)
  }

  useEffect(() => {
    if (valueRef?.current) {
      setValueBoundingRect(valueRef.current?.getBoundingClientRect())
    }
  }, [valueLength])

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect())
    }
  }, [inputRef, focus, valueLength])

  const filteredOptions = () => {
    if (searchVal?.length === 0) {
      return options;
    } else {
      return options?.filter((option) => option.label?.includes(searchVal))
    }
  }

  const handleOptionsClose = () => {
    setSearchVal("");
    setFocus(false)
  }

  const handleSelect = (val) => {
    if (single) {
      handleOptionsClose()
    }
    onChange(val)
  }

  return(
    <>
      {label && <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2, ...labelStyle }} className='mt-2'>{label}</div>}
      <div className='' style={{ position: 'relative', width: '100%' }}>
        <div style={{ position: 'absolute', left: 8, top: 6 }}>
          <SearchIcon size='20' />
        </div>
        <input ref={inputRef} value={searchVal} onChange={(e) => setSearchVal(e.target.value)} onFocus={() => setFocus(true)} className='dash-input' placeholder={placeholder} style={{ width: 300, ...style, paddingLeft: 35 + valueVoundingRect?.width }} />
        <div ref={valueRef} className='js-ac' style={{ position: 'absolute', left: 36, top: 5 }}>
          {value?.map((v, key) => (
            <div style={{ height: 28, paddingLeft: 8, fontSize: 12, paddingRight: 4, background: 'white', borderRadius: 10, marginRight: 5, textTransform: 'capitalize' }} className='jc-ac shadow-sm'>
              {v?.label}
              <div onClick={() => handleSelect(v)} style={{ paddingTop: 2 }} className='jc-ac'>
                <BsX style={{ fontSize: 16 }} />
              </div>
            </div>
          ))}
        </div>
        {focus && (
          <Portal>
            <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)' }} onClick={handleOptionsClose} />
            <div style={{ position: 'absolute', background: '#F5F5F5', borderRadius: 14, padding: 16, zIndex: 1001, fontFamily: 'Lato', left: boundingRect.x, top: boundingRect.bottom + 6, width: boundingRect.width }} className='shadow'>
              <div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
                {filteredOptions()?.length > 0 && (
                  filteredOptions().map((option, key) => (
                    <div key={key} onClick={() => handleSelect(option)} style={{ width: '100%', height: 38, borderRadius: 10, background: 'white', fontSize: 14, cursor: 'pointer' }} className='mb-2 js-ac ps-2'>
                      <CheckBox title={option.label} value={isSelected(option)} setValue={() => {}} />
                    </div>
                  ))
                )}
                {options?.length === 0 && (
                  <div className='jc-ac mb-2'>No Options</div>
                )}
              </div>
              <BrellyBtn style={{ marginTop: 16 }} onClick={handleOptionsClose}>Done</BrellyBtn>
            </div>
          </Portal>
        )}
      </div>
    </>
  )
}
