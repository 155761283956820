import { useApp } from "context";
import brelly from "@brelly/sdk";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DashPage, ProfilePictureUpload } from "./components";
import { ProfileAccountSection, ProfileCompanySection } from 'sections';

const ProfilePage = () => {
  const history = useHistory()
  const { user, tab, setError, setLoading, handleUser, profileTab, setProfileTab, searchParams } = useApp();
  const scrollArea = window.innerHeight - 262;
  const isSelected = tab === "profile";

  useEffect(() => {
    if (isSelected && !searchParams.get("profileTab")) {
      searchParams.set("profileTab", "account");
      searchParams.delete("project");
      history.replace({ search: searchParams.toString() });
    }
    //eslint-disable-next-line
  }, [isSelected])

  const handleProfilePic = async (ev) => {
    let file = ev.target.files[0];

    try {
      setLoading(true);
      await brelly.users.uploadProfilePic(user?.id, file);
      handleUser();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err?.message);
    }
  };

  return (
    <DashPage tab={tab} value="profile" padding={false}>
      <div
        style={{
          width: "100%",
          height: 180,
          background: "linear-gradient(90deg, #0D72C5 0%, #239CFF 100%)",
          borderRadius: "12px 0px 0px 0px",
          paddingLeft: 64,
        }}
        className="js-ac"
      >
        <h1 style={{ color: "#ffffff" }}>My Profile</h1>
      </div>
      <div style={{ paddingLeft: 64, paddingRight: 42 }}>
        <div
          style={{
            width: "100%",
            padding: "22px 0px",
            background: "#ffffff",
            marginTop: -40,
            borderRadius: 18,
            boxShadow: "3px 3px 8px rgba(0,0,0,.03)",
            cursor: "pointer",
            height: scrollArea,
          }}
          className="js-ac"
        >
          <div className="container" style={{ height: "100%" }}>
            <div className="row" style={{ height: "100%" }}>
              <div
                className="col-3 js-ac flex-col"
                style={{
                  height: "100%",
                  borderRight: "1px solid rgba(0,0,0,.06)",
                  padding: 24,
                }}
              >
                <ProfilePictureUpload img={user?.profilePic?.downloadUrl} handleChange={handleProfilePic} style={{ width: 140, height: 140 }} />
                <h2 style={{ marginTop: 16 }}>
                  {user?.firstName} {user?.lastName}
                </h2>
                <AccountTabBtn
                  value="account"
                  label="Account"
                  accountTab={profileTab}
                  setAccountTab={setProfileTab}
                />
                <AccountTabBtn
                  value="companies"
                  label="Companies"
                  accountTab={profileTab}
                  setAccountTab={setProfileTab}
                />
              </div>
              <div className="col-9" style={{ height: "100%", paddingLeft: 32, paddingRight: 32 }}>
                {isSelected && <ProfileAccountSection accountTab={profileTab} />}
                {isSelected && <ProfileCompanySection accountTab={profileTab} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashPage>
  );
};

export default ProfilePage;

const AccountTabBtn = ({ value, label, accountTab, setAccountTab }) => {
  let isSelected = value === accountTab;

  return (
    <h3
      onClick={() => setAccountTab(value)}
      style={{
        marginTop: 24,
        fontSize: 15,
        color: isSelected ? "#0D72C5" : "#64666B",
        fontWeight: 700,
        textAlign: "left",
        width: "100%",
      }}
    >
      {label}
    </h3>
  );
};

//<LocationPicker value={mailingAddress} setValue={setMailingAddress} label="Mailing Address" />
// <div className="jb-ac mt-4">
//   <div style={{ width: "49%" }}>
//     <Input label="Current Password" value={currentPassword} onChange={setCurrentPassword} />
//   </div>
//   <div style={{ width: "49%" }}>
//     <Input label="New Password" value={newPassword} onChange={setNewPassword} />
//   </div>
// </div>
// <div className="je-ac mt-4">
//   <BrellyBtn style={{ width: "49%" }} onClick={handlePasswordChange}>
//     Update
//   </BrellyBtn>
// </div>
