export const getClaimDisplay = (project, projects) => {
  let fullProject = {};

  if (project?.id) {
    fullProject = project
  } else {
    fullProject = projects?.find((c) => c?.id === project);
  }

  let ph = fullProject?.policyholder
  let property = fullProject?.property || {};
  let address = property?.address || {}
  let addy = address?.street || address?.fullAddress;
  let claimNumber = fullProject?.claim?.claimNumber;
  let string = ""

  if (ph?.lastName) {
    string = ph?.lastName
  }

  if (addy) {
    string = `${string}, ` + addy
  }

  if (claimNumber) {
    string = `${string}, ` + claimNumber
  }

  return string
}
