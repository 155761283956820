// External Imports
import { useState, useEffect } from "react";
import { AiOutlineLink } from "react-icons/ai";

// Internal Imports
import { useProject } from "pages/Dash/Claims/Claim/context";
import { MultiSelect } from "components/Select";
import { CenterModal } from "components/Modals";
import { DateSelect } from "components/Select";
import { BrellyBtn } from "components/Button";
import Input from "components/Input";
import { useApp } from "context";

// Brelly SDK
import brelly from "@brelly/sdk";

const getFilteredIds = (selectedCats, items) => {
  let _items = items;

  if (selectedCats?.length > 0) {
    let formatted = selectedCats?.map((c) => c?.label);
    _items = _items?.filter((t) => formatted?.includes(t?.category));
  }

  return _items.map((i) => i.id);
};

export const ExpenseReportModal = ({ open, handleClose }) => {
  if (open) {
    return <Inner open={open} handleClose={handleClose} />
  } else {
    return null;
  }

}

const Inner = ({ open, handleClose }) => {
  const { setLoading, projectId, claimId, setError, addToast } = useApp();
  const [selectedCoverages, setSelectedCoverages] = useState([]);
  const [name, setName] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const { coverages, media, setMedia } = useProject();
  const formattedCoverages = coverages?.map((c) => ({ ...c, label: c?.name, value: c?.id }))
  const coverageOptions = [{ label: 'All', value: 'all', all: true }, ...formattedCoverages];

  useEffect(() => {
    if (open) {
      setSelectedCoverages(coverageOptions);
    }

  }, [open])

  const handleCreate = async () => {
    if (projectId && claimId) {
      try {
        setLoading(true);

        // Get filtered coverages
        const coverageIds =
          selectedCoverages.length > 0 ? getFilteredIds(selectedCoverages, coverages) : undefined;

        // Generate expense report
        const report = await brelly.reports.createClaimExpenseReport({
          coverageIds,
          projectId,
          claimId,
          name,
          from,
          to,
        });

        // Add report to file state
        setMedia([report, ...media]);

        // Add toast with link to report and info about saving to claim files
        addToast(
          <div>
            Your{" "}
            <a
              href={report.downloadUrl}
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
              target="_blank"
            >
              report <AiOutlineLink style={{ fontSize: 18 }} />
            </a>{" "}
            was created and saved to your claim files!{" "}
          </div>
        );

        // Update state
        setLoading(false);
        handleClose();
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    } else {
      setError("No claim selected");
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20, width: 400, maxWidth: '100%' }}>Create Report</div>
      <Input value={name} onChange={setName} placeholder="Expense Report" label="Report Name" />
      <div style={{ fontSize: 13, paddingLeft: 4, fontFamily: "Lato" }} className="mt-2">
        Select Coverage
      </div>
      <MultiSelect value={selectedCoverages} onChange={setSelectedCoverages} options={coverageOptions} />
      <DateSelect value={from} onChange={setFrom} label="From" />
      <DateSelect value={to} onChange={setTo} label="To" />
      <BrellyBtn onClick={handleCreate} className="mt-4">
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
