import {inventoryCats} from 'constants';
import { DropdownSearchBar } from 'components/SearchBar';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';

export const InventoryFilterModal = ({ open, handleClose, filters, updateFilters }) => {
  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Filter Inventory Items</div>
      <DropdownSearchBar value={filters?.selectedCats} onChange={(v) => updateFilters({ selectedCats: v })} options={inventoryCats} label='Category' />
      <BrellyBtn className='mt-4' onClick={handleClose}>Filter</BrellyBtn>
    </CenterModal>
  )
}
