import { IoCheckmarkOutline } from "react-icons/io5";

const CheckBox = ({ title, value, setValue = () => {}, className = "" }: any) => {
  return (
    <div
      onClick={(e) => setValue(!value, e)}
      className={`js-as ${className}`}
      style={{
        fontFamily: 'Lato',
        cursor: "pointer",
        lineHeight: 1.2,
        fontSize: 16,
        fontWeight: 500,
      }}
    >
      <div
        style={{
          width: 16,
          height: 16,
          border: value ? "" : "1px solid #0D72C5",
          background: value ? "#0D72C5" : "",
          borderRadius: 4,
          marginRight: 6,
          flexShrink: 0,
          marginTop: 2,
        }}
        className="jc-ac"
      >
        {value && <IoCheckmarkOutline style={{ color: "white", fontSize: 16 }} />}
      </div>
      <div className="truncate1">{title}</div>
    </div>
  );
};

export default CheckBox;

export const CheckBox2 = ({ title, value, setValue = () => {}, className = "" }: any) => {
  return (
    <div
      onClick={(e) => setValue(!value, e)}
      className={`js-as ${className}`}
      style={{
        fontFamily: 'Lato',
        cursor: "pointer",
        lineHeight: 1.2,
        fontSize: 16,
        fontWeight: 500,
      }}
    >
      <div
        style={{
          width: 16,
          height: 16,
          border: value ? "" : "1px solid #0D72C5",
          background: value ? "#0D72C5" : "",
          borderRadius: 4,
          marginRight: 6,
          flexShrink: 0,
          marginTop: 2,
        }}
        className="jc-ac"
      >
        {value && <IoCheckmarkOutline style={{ color: "white", fontSize: 16 }} />}
      </div>
      <div>{title}</div>
    </div>
  );
};
