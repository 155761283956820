import { useApp } from 'context';
import { useProject } from './context';
import { groupTasksByDay } from 'utils';
import { useState, useRef } from 'react';
import Calendar from 'components/Calender';
import { isSameDay, format } from 'date-fns';
import SearchBar from 'components/SearchBar';
import { BsChevronDown } from 'react-icons/bs';
import { TimelineIcon } from 'components/Icons';
import { InlineModal } from 'components/Modals';
import { AddTaskModal, AddEventModal } from 'modals';
import { BooleanFilter } from 'components/FilterItems';
import { TasksIcon, CalendarIcon } from 'components/Icons';
import { ClaimCalendarTaskItem, ClaimCalendarEventItem } from 'items';

const CalendarBox = () => {
  const [filters, setFilters] = useState({
    forMe: false,
    overdue: false,
    priority: false,
    type: "All",
    searchVal: ''
  });
  const { user, projectId } = useApp();
  const [sortTimelineModal, setSortTimelineModal] = useState(false);
  const [addTaskTypeModal, setAddTaskTypeModal] = useState(false);
  const [timelineSort, setTimelineSort] = useState('soonest');
  const [addEventModal, setAddEventModal] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const sortModalBtnRef = useRef(null);
  const attModalBtnRef = useRef(null);
  const typeFilterBtn = useRef(null);
  const dateFilterBtn = useRef(null);
  const [typeFilterModal, setTypeFilterModal] = useState(false);
  const [dateFilterModal, setDateFilterModal] = useState(false);
  const { claimTasks, claimEvents, handleClaimTasks, handleClaimEvents } = useProject();
  const filtered = getFiltered(user, filters, claimTasks, claimEvents)
  const grouped = groupTasksByDay(filtered);

  const updateFilters = (changes = {}) => {
    setFilters({...filters, ...changes})
  }

  const handleAddTask = () => {
    setAddTaskTypeModal(false)
    setAddTaskModal(true)
  }

  const handleAddEvent = () => {
    setAddTaskTypeModal(false)
    setAddEventModal(true)
  }

  const handleSort = (sortType) => {
    setTimelineSort(sortType)
    setSortTimelineModal(false)
  }

  const handleTypeFilterSelect = (newType) => {
    updateFilters({ type: newType })
    setTypeFilterModal(false)
  }

  return(
    <>
      <div className='row'>
        <div className='col-12 mb-1'>
          <div className='dash-box jb-ac'>
            <div className='jb-ac'>
              <div className='dash-box-title me-4'>
                <TimelineIcon style={{ marginRight: 6 }} stroke='#0D72C5' />
                Calendar
              </div>
              <SearchBar className='d-none d-lg-block' style={{ width: 300 }} value={filters?.searchVal} onChange={(v) => updateFilters({ searchVal: v })} />
            </div>
            <div className='je-ac'>
              <BooleanFilter title="Overdue" filterKey='overdue' filters={filters} updateFilters={updateFilters} className='me-3 d-none d-lg-flex' />
              <BooleanFilter title="Priority" filterKey='priority' filters={filters} updateFilters={updateFilters} className='me-3 d-none d-lg-flex' />
              <BooleanFilter title="For Me" filterKey='forMe' filters={filters} updateFilters={updateFilters} className='me-3 d-none d-lg-flex' />
              <div ref={typeFilterBtn} onClick={() => setTypeFilterModal(true)} className='js-ac me-3 d-none d-lg-block' style={{ cursor: 'pointer', color: 'rgb(100, 102, 107)', fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
                Type
                <BsChevronDown style={{ marginLeft: 2, fontSize: 12 }} />
              </div>
              <div ref={dateFilterBtn} onClick={() => setDateFilterModal(true)} className='js-ac me-3 d-none d-lg-block' style={{ cursor: 'pointer', color: 'rgb(100, 102, 107)', fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
                Date
                <BsChevronDown style={{ marginLeft: 2, fontSize: 12 }} />
              </div>
              <div ref={attModalBtnRef} className='brelly-text-btn' onClick={() => setAddTaskTypeModal(true)}>
                Add New
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row ps-2 pe-2'>
            <div className='dash-table-header col-1 jc-ac'>Priority</div>
            <div className='dash-table-header col-1'>Type</div>
            <div className='dash-table-header col-5'>Name</div>
            <div className='dash-table-header col-2'>Status</div>
            <div className='dash-table-header col-2 je-ac'>Assignees</div>
            <div className='dash-table-header col-1'></div>
          </div>
        </div>

        <div className='col-12'>
          {grouped?.map((group, key) => <DayItem group={group} key={key} />)}
        </div>
      </div>
      <AddTaskModal open={addTaskModal} handleClose={() => setAddTaskModal(false)} handleUpdate={handleClaimTasks} inClaim={projectId} />
      <AddEventModal open={addEventModal} handleClose={() => setAddEventModal(false)} handleUpdate={handleClaimEvents} inClaim={projectId} />
      <InlineModal btnRef={attModalBtnRef} open={addTaskTypeModal} handleClose={() => setAddTaskTypeModal(false)}>
        <div className='dash-table-item js-ac' onClick={handleAddTask} style={{ fontWeight: 600, cursor: 'pointer' }}>
          <TasksIcon style={{ marginTop: 2, marginRight: 1 }} stroke='#000000' />
          Create a Task
        </div>
        <div className='dash-table-item mt-3' onClick={handleAddEvent} style={{ fontWeight: 600, cursor: 'pointer' }}>
          <CalendarIcon size={22} style={{ marginRight: 4, marginBottom: 2 }} stroke='#000000' />
          Create an Event
        </div>
      </InlineModal>
      <InlineModal btnRef={sortModalBtnRef} open={sortTimelineModal} handleClose={() => setSortTimelineModal(false)}>
        <div className='dash-table-item js-ac' onClick={() => handleSort('soonest')} style={{ fontWeight: 600, cursor: 'pointer', color: timelineSort === 'soonest' ? '#0D72C5' : '' }}>
          Soonest First
        </div>
        <div className='dash-table-item mt-3' onClick={() => handleSort('latest')} style={{ fontWeight: 600, cursor: 'pointer', color: timelineSort === 'latest' ? '#0D72C5' : '' }}>
          Latest First
        </div>
      </InlineModal>
      <InlineModal btnRef={dateFilterBtn} open={dateFilterModal} handleClose={() => setDateFilterModal(false)}>
        <Calendar value={filters?.date || new Date()} onChange={(v) => updateFilters({ date: v })} onReset={() => updateFilters({ date: null })} />
      </InlineModal>
      <InlineModal btnRef={typeFilterBtn} open={typeFilterModal} handleClose={() => setTypeFilterModal(false)}>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleTypeFilterSelect('All')}>All</div>
        <div className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleTypeFilterSelect('TASK')}>Tasks</div>
        <div className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={() => handleTypeFilterSelect('EVENT')}>Events</div>
      </InlineModal>
    </>
  )
}

const DayItem = ({ group }) => {
  const { projectId } = useApp();
  const { handleClaimEvents, handleClaimTasks } = useProject();
  let isToday = isSameDay(new Date(group?.day), new Date());

  const ItemSwitch = ({ task }) => {
    if (task?.type === 'EVENT') {
      return <ClaimCalendarEventItem task={task} inClaim={projectId} handleUpdate={handleClaimEvents} />
    } else {
      return <ClaimCalendarTaskItem task={task} inClaim={projectId} handleUpdate={handleClaimTasks} />
    }
  }

  const sortInnerDay = (events) => {
    return events?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  }

  return(
    <div className='mt-3'>
      <div className='mb-1 js-ac' style={{ cursor: "pointer", color: isToday ? "#0D72C5" : '#64666B', fontWeight: isToday ? 600 : 500, fontSize: 16 }}>
        {isToday && <div style={{ width: 6, height: 6, borderRadius: '50%', background: '#0D72C5', marginRight: 5 }} />}
        {format(new Date(group?.day), "dd MMM, EEE")}
      </div>
      <div className='container'>
        {sortInnerDay(group?.tasks)?.map((task, idx) => (
          <ItemSwitch key={idx} task={task} />
        ))}
      </div>
    </div>
  )
}

export default CalendarBox;

const createSearchStr = (task) => {
  let name = task?.name
  let str = `${name}`
  return str?.toLowerCase()
}

const getFiltered = (user, filters, claimTasks, claimEvents) => {
  let _tasks = [...claimTasks, ...claimEvents];

  if (filters?.overdue) {
    _tasks = _tasks?.filter((t) => new Date(t?.endDate) < new Date())
  }

  if (filters?.priority) {
    _tasks = _tasks?.filter((t) => t?.priority)
  }

  if (filters?.forMe) {
    let formatted = _tasks?.map((t) => ({
      ...t,
      cIds: t?.type === 'TASK' ? t?.assignees?.map((ta) => ta.userId) : t?.participants?.map((ta) => ta.userId)
    }));
    _tasks = formatted?.filter((c) => c.cIds?.includes(user?.id));
  }

  if (filters?.type !== "All") {
    _tasks = _tasks?.filter((t) => t?.type === filters?.type)
  }

  if (filters?.date) {
    _tasks = _tasks?.filter((t) => isSameDay(new Date(t?.endDate), new Date()))
  }

  if (filters?.searchVal?.length > 0) {
    let formatted = _tasks?.map((t) => ({ ...t, searchVal: createSearchStr(t) }))
    _tasks = formatted?.filter((t) => t.searchVal?.includes(filters?.searchVal?.toLowerCase()))
  }

  const filteredTasks = _tasks?.filter((t) => t?.endDate)

  return filteredTasks
}
