// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface MembershipInvitesInterface {
  getByCode: (code: string, query?: Query) => Promise<any>;
  accept: (id: string, code: string, query?: Query) => Promise<any>;
  reject: (id: string, code: string, query?: Query) => Promise<any>;
  complete: (id: string, query?: Query) => Promise<any>;
  get: (id: string, query?: Query) => Promise<any>;
  send: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  resend: (id: string, query?: Query) => Promise<any>;
  cancel: (id: string, query?: Query) => Promise<any>;
}

export const membershipInvites = (client: Axios): MembershipInvitesInterface => ({
  // ** Inviter Routes **

  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/membership-invites/${id}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  send: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/membership-invites", query);
    const cleanedValues = cleanValues(values);
    const res: AxiosResponse = await client.post(url, cleanedValues);
    return {
      data: res.data,
    };
  },

  resend: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/membership-invites/${id}/resend`, query);
    const res: AxiosResponse = await client.post(url);
    return {
      data: res.data,
    };
  },

  cancel: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/membership-invites/${id}/cancel`, query);
    const res: AxiosResponse = await client.put(url);
    return {
      data: res.data,
    };
  },

  // ** Invitee Routes **

  getByCode: async (code: string, query?: Query) => {
    const url = addUrlQuery(`/membership-invites/code/${code}`, query);
    const res: AxiosResponse = await client.get(url);
    return {
      data: res.data,
    };
  },

  accept: async (id: string, code: string, query?: Query) => {
    const url = addUrlQuery(`/membership-invites/${id}/accept?code=${code}`, query);
    const res: AxiosResponse = await client.put(url);
    return {
      data: res.data,
    };
  },

  reject: async (id: string, code: string, query?: Query) => {
    const url = addUrlQuery(`/membership-invites/${id}/reject?code=${code}`, query);
    const res: AxiosResponse = await client.put(url);
    return {
      data: res.data,
    };
  },

  complete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/membership-invites/${id}/complete`, query);
    const res: AxiosResponse = await client.put(url);
    return {
      data: res.data,
    };
  },
});
