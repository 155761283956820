import { IoEllipsisVerticalOutline, IoCloudDownloadOutline } from 'react-icons/io5';
import { SimpleFileModal, ConfirmDeleteModal } from 'modals';
import { InlineModal } from 'components/Modals';
import { BsFiletypePdf } from 'react-icons/bs';
import { DocsIcon2 } from 'components/Icons';
import { useState, useRef } from 'react';
import { useProject } from './context';
import { useApp } from 'context';
import brelly from "@brelly/sdk";

const DocsBox = () => {
  const { setLoading } = useApp();
  const { documents, reports, claim } = useProject();
  const [addModal, setAddModal] = useState(false);
  const [type, setType] = useState('docs');
  const toShow = type === 'docs' ? documents : reports;

  const handleNewDocs = async (newDocs) => {
    if (newDocs?.length > 0) {
      try {
        setLoading(true);
        await brelly.claims.uploadDocuments(claim?.id, newDocs);
        setLoading(false);
      } catch(err) {
        setLoading(false);
        console.log(err);
      }
    }
  }

  return(
    <div className='dash-box'>
      <div className='jb-ac'>
        <div className='dash-box-title' style={{ width: '40%'}}>
          <DocsIcon2 style={{ marginRight: 12 }} stroke='#0D72C5' />
          Claim Documents
        </div>
        <div className='jc-ac'>
          <div className='brelly-text-btn me-3' onClick={() => setType('docs')} style={{ color: type === 'docs' ? '#0D72C5' : 'rgb(100, 102, 107)' }}>Docs</div>
          <div className='brelly-text-btn' onClick={() => setType('reports')} style={{ color: type === 'reports' ? '#0D72C5' : 'rgb(100, 102, 107)' }}>Reports</div>
        </div>
        <div style={{ width: '40%'}} className='je-ac'>
          <div className='brelly-text-btn' onClick={() => setAddModal(true)} style={{ color: '#0D72C5' }}>Add New</div>
        </div>
      </div>
      {toShow?.length > 0 && <TableHeader />}
      {toShow?.map((doc, key) => <DocItem doc={doc} key={key} />)}
      <SimpleFileModal open={addModal} onSave={handleNewDocs} handleClose={() => setAddModal(false)} accept='.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf' />
    </div>
  )
}

export default DocsBox;

const TableHeader = () => {
  return(
    <div className='jb-ac mt-3'>
      <div className='dash-table-header' style={{ width: "8%" }}></div>
      <div className='dash-table-header' style={{ width: "20%" }}>Document name</div>
      <div className='dash-table-header' style={{ width: "16%" }}>Date created</div>
      <div className='dash-table-header' style={{ width: "16%" }}>Date uploaded</div>
      <div className='dash-table-header' style={{ width: "16%" }}>Uploaded by</div>
      <div className='dash-table-header' style={{ width: "16%" }}>Shared with PH</div>
      <div className='dash-table-header' style={{ width: "8%" }}>Actions</div>
    </div>
  )
}

const DocItem = ({ doc = {} }) => {
  const [actionsModal, setActionsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const modalBtnRef = useRef(null);

  const handleConfirmDelete = () => {
    setDeleteModal(true);
    setActionsModal(false);
  }

  return(
    <>
      <div className='jb-ac mt-2 brelly-content' style={{ borderRadius: 10, height: 50, cursor: 'pointer' }}>
        <div className='dash-table-item jc-ac' style={{ width: "8%" }}>
          <BsFiletypePdf style={{ fontSize: 24 }} />
        </div>
        <div className='dash-table-item' style={{ width: "20%" }}>{doc?.name}</div>
        <div className='dash-table-item' style={{ width: "16%" }}>{doc?.created}</div>
        <div className='dash-table-item' style={{ width: "16%" }}>{doc?.uploaded}</div>
        <div className='dash-table-item' style={{ width: "16%" }}>{doc?.uploadedBy}</div>
        <div className='dash-table-item' style={{ width: "16%" }}>{doc?.shared ? 'Yes' : "No"}</div>
        <div className='dash-table-item' style={{ width: "8%", color: '#0D72C5' }}>
          <div className='js-ac'>
            <div className='me-3'>
              <IoCloudDownloadOutline />
            </div>
            <div ref={modalBtnRef} onClick={() => setActionsModal(true)}>
              <IoEllipsisVerticalOutline />
            </div>
          </div>
        </div>
      </div>
      <InlineModal btnRef={modalBtnRef} open={actionsModal} handleClose={() => setActionsModal(false)}>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }}>Share with PH</div>
        <div className='dash-table-item mt-3' style={{ fontWeight: 600, cursor: 'pointer', color: '#ff0000' }} onClick={handleConfirmDelete}>Delete</div>
      </InlineModal>
      <ConfirmDeleteModal open={deleteModal} handleClose={() => setDeleteModal(false)} />
    </>
  )
}

// export const docs = [
//   { name: 'Insurance Report', created: format(subDays(new Date(), 1), 'MMM dd, yyyy'), uploaded: format(new Date(), 'MMM dd, yyyy'), uploadedBy: 'Trevor Johnson', shared: true, url: '' },
//   { name: 'Insurance Report 1', created: format(subDays(new Date(), 4), 'MMM dd, yyyy'), uploaded: format(subDays(new Date(), 3), 'MMM dd, yyyy'), uploadedBy: 'Jacquelyn Broussard', shared: true, url: '' },
//   { name: 'Insurance Report 2', created: format(subDays(new Date(), 6), 'MMM dd, yyyy'), uploaded: format(subDays(new Date(), 4), 'MMM dd, yyyy'), uploadedBy: 'You', shared: false, url: '' },
// ]
//
// const reports = [
//   { name: 'Expense Report 3', created: format(subDays(new Date(), 1), 'MMM dd, yyyy'), uploaded: format(new Date(), 'MMM dd, yyyy'), uploadedBy: 'Trevor Johnson', shared: true, url: '' },
//   { name: 'Expense Report 2', created: format(subDays(new Date(), 4), 'MMM dd, yyyy'), uploaded: format(subDays(new Date(), 3), 'MMM dd, yyyy'), uploadedBy: 'Jacquelyn Broussard', shared: true, url: '' },
//   { name: 'Inventory Report 1', created: format(subDays(new Date(), 6), 'MMM dd, yyyy'), uploaded: format(subDays(new Date(), 4), 'MMM dd, yyyy'), uploadedBy: 'You', shared: false, url: '' },
//   { name: 'Expense Report 1', created: format(subDays(new Date(), 8), 'MMM dd, yyyy'), uploaded: format(subDays(new Date(), 8), 'MMM dd, yyyy'), uploadedBy: 'You', shared: true, url: '' },
// ]
