import { useApp } from 'context';
import brelly from "@brelly/sdk";
import ProPic from "components/ProPic";
import { useState, useRef } from 'react';
import { InlineModal } from 'components/Modals';
import { BsFlag, BsFlagFill } from 'react-icons/bs';
import { IoEllipsisVerticalOutline } from 'react-icons/io5';
import { EditEventModal, ViewEventModal, ConfirmDeleteModal } from 'modals';

export const ClaimCalendarEventItem = ({ task, inClaim, handleUpdate, all }) => {
  const { setLoading, setError } = useApp();
  const etModalBtnRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewTaskModal, setViewTaskModal] = useState(false);
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [editTaskTypeModal, setEditTaskTypeModal] = useState(false);

  const handleInlineModal = (e) => {
    e.stopPropagation();
    setEditTaskTypeModal(true)
  }

  const handleEditModal = () => {
    setEditTaskTypeModal(false);
    setEditTaskModal(true)
  }

  const handleDeleteModal = () => {
    setEditTaskTypeModal(false);
    setDeleteModal(true)
  }

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.events.delete(task?.id)
      handleUpdate()
      setLoading(false);
      setEditTaskTypeModal(false)
    } catch(err) {
      setError(err.message)
      setLoading(false);
    }
  }

  const handlePriority = async (e) => {
    e.stopPropagation();
    try {
      await brelly.events.update(task?.id, { priority: !task?.priority });
      handleUpdate()
    } catch(err) {
      setError(err.message)
    }
  }

  return(
    <>
      <div className='mb-2 brelly-content pt-3 pb-3 row dash-table-item align-items-center' onClick={() => setViewTaskModal(true)} style={{ borderRadius: 10, background: "#FFD9A6" }}>
        <div className='jc-ac col-2 col-lg-1' onClick={handlePriority}>
          {task?.priority ? <BsFlagFill style={{ fontSize: 20 }} /> : <BsFlag style={{ fontSize: 20 }} />}
        </div>

        <div className='col-1 d-none d-lg-flex'>Event</div>

        <div className='col-8 col-lg-7 fw-mobile' style={{ flexShrink: 1 }}>{task?.name}</div>

        <div className='col-2 je-ac d-lg-none' ref={etModalBtnRef} onClick={(e) => handleInlineModal(e)}>
          <IoEllipsisVerticalOutline style={{ fontSize: 16, color: '#000000' }} />
        </div>

        <div className='col-12 d-lg-none mt-3'>Type: Event</div>

        <div className='col-12 col-lg-2 mt-2 mt-lg-0 je-ac'>
          {task?.participants?.length > 0 && <div className='me-2 d-lg-none'>Participants:</div>}
          {task?.participants?.map((ass, key) => (
            <ProPic key={key} user={ass?.user} img={ass?.user?.profilePic?.downloadUrl} size={18} className="ms-1" style={{ fontSize: 9 }} />
          ))}
        </div>

        <div className='col-1 je-ac d-none d-lg-flex' ref={etModalBtnRef} onClick={(e) => handleInlineModal(e)}>
          <IoEllipsisVerticalOutline style={{ fontSize: 16, color: '#000000' }} />
        </div>
      </div>
      <InlineModal btnRef={etModalBtnRef} open={editTaskTypeModal} handleClose={() => setEditTaskTypeModal(false)}>
        <div className='dash-table-item' style={{ fontWeight: 600, cursor: 'pointer' }} onClick={handleEditModal}>Edit</div>
        <div className='dash-table-item mt-2' style={{ fontWeight: 600, cursor: 'pointer', color: '#ff0000' }} onClick={handleDeleteModal}>Delete</div>
      </InlineModal>
      <ConfirmDeleteModal open={deleteModal} handleClose={() => setDeleteModal(false)} onDelete={handleDelete} />
      <EditEventModal task={task} open={editTaskModal} handleClose={() => setEditTaskModal(false)} handleUpdate={handleUpdate} inClaim={inClaim} />
      <ViewEventModal task={task} open={viewTaskModal} handleClose={() => setViewTaskModal(false)} setEditTaskModal={setEditTaskModal} handleUpdate={handleUpdate} />
    </>
  )
}
