import { useProject } from "pages/Dash/Claims/Claim/context";
import { useApp } from "context";
import { useState, useEffect } from "react";
import brelly from "@brelly/sdk";
import { CenterModal } from "components/Modals";
import Input from "components/Input";
import { BrellyBtn } from "components/Button";
import { DateSelect, SingleSelect } from "components/Select";
import CompanySelect from "components/CompanySelect";
import { v4 as uuidV4 } from "uuid";
import { TrashIcon } from "components/Icons";

export const EditModal = ({ policy, open, handleClose }) => {
  const { setLoading } = useApp();
  const { handleClaim } = useProject();
  const [effective, setEffective] = useState(null);
  const [expiration, setExpiration] = useState(null);
  const [insurer, setInsurer] = useState("");
  const [policyNum, setPolicyNum] = useState("");
  const [policyType, setPolicyType] = useState({});
  const [additional, setAdditional] = useState({ entries: [] });
  const filteredAdditional = additional?.entries?.filter((e) => !e.hide);
  const sortedAdditional =
    filteredAdditional?.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    ) || [];
  const hasAdditional = sortedAdditional?.length > 0;
  const colClass = hasAdditional ? "col-12 col-lg-6" : "col-12";

  useEffect(() => {
    if (open) {
      if (policy?.insuranceCompany) {
        setInsurer(policy?.insuranceCompany);
      }
      if (policy?.effectiveDate) {
        setEffective(new Date(policy?.effectiveDate));
      }
      if (policy?.expiryDate) {
        setExpiration(new Date(policy?.expiryDate));
      }
      if (policy?.type) {
        let selectedType =
          policyTypeOptions?.find((pt) => pt?.label === policy?.type) || {};
        setPolicyType(selectedType);
      }
      if (policy?.additionalInfo) {
        setAdditional(policy?.additionalInfo);
      }
      setPolicyNum(policy?.policyNumber || "");
    }
    //eslint-disable-next-line
  }, [open]);

  const addAdditional = () => {
    setAdditional({
      entries: [
        ...additional?.entries,
        { key: "", value: "", id: uuidV4(), createdAt: new Date() },
      ],
    });
  };

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.policies.update(policy?.id, {
        effectiveDate: effective,
        expiryDate: expiration,
        policyNumber: policyNum,
        insuranceCompanyId: insurer?.id,
        type: policyType?.label,
        additionalInfo: additional,
      });
      await handleClaim(false);
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const insurancePhone = additional?.entries?.find(
    (e) => e?.key === "INSURANCE PHONE"
  ) || { value: "", key: "INSURANCE PHONE", id: uuidV4(), hide: true };

  const handleInsurancePhone = (newValue) => {
    let others = additional?.entries?.filter(
      (c) => c?.id !== insurancePhone?.id
    );
    setAdditional({
      entries: [...others, { ...insurancePhone, value: newValue }],
    });
  };

  const insuranceEmail = additional?.entries?.find(
    (e) => e?.key === "INSURANCE EMAIL"
  ) || { value: "", key: "INSURANCE EMAIL", id: uuidV4(), hide: true };

  const handleInsuranceEmail = (newValue) => {
    let others = additional?.entries?.filter(
      (c) => c?.id !== insuranceEmail?.id
    );
    setAdditional({
      entries: [...others, { ...insuranceEmail, value: newValue }],
    });
  };

  const insuranceAddress = additional?.entries?.find(
    (e) => e?.key === "INSURANCE ADDRESS"
  ) || { value: "", key: "INSURANCE ADDRESS", id: uuidV4(), hide: true };

  const handleInsuranceAddress = (newValue) => {
    let others = additional?.entries?.filter(
      (c) => c?.id !== insuranceAddress?.id
    );
    setAdditional({
      entries: [...others, { ...insuranceAddress, value: newValue }],
    });
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div
        style={{
          fontWeight: 600,
          fontSize: 20,
          width: hasAdditional ? 800 : 400,
          maxWidth: "100%",
        }}
      >
        Edit Policy Info
      </div>
      <div className="row">
        <div className={colClass}>
          <CompanySelect
            value={insurer}
            onSelect={setInsurer}
            style={{ width: "100%" }}
          />
          <Input
            value={policyNum}
            label="Policy Number"
            onChange={setPolicyNum}
          />
          <SingleSelect
            value={policyType}
            onChange={setPolicyType}
            label="Policy Type"
            options={policyTypeOptions}
          />
          <DateSelect
            value={effective}
            onChange={setEffective}
            label="Effective Date"
          />
          <DateSelect
            value={expiration}
            onChange={setExpiration}
            label="Expiration Date"
          />

          <Input
            value={insurancePhone?.value}
            label="Insurer Phone"
            onChange={handleInsurancePhone}
          />
          <Input
            value={insuranceEmail?.value}
            label="Insurer Email"
            onChange={handleInsuranceEmail}
          />
          <Input
            value={insuranceAddress?.value}
            label="Insurer Address"
            onChange={handleInsuranceAddress}
          />
        </div>
        {sortedAdditional?.length > 0 && (
          <div className="col-6">
            <div className="brelly-text-btn" style={{ marginTop: -23 }}>
              Custom
            </div>
            {sortedAdditional?.map((entry) => (
              <CustomField
                key={entry?.id}
                entry={entry}
                additional={additional}
                setAdditional={setAdditional}
              />
            ))}
            <div className="mt-3 jc-ac">
              <div onClick={addAdditional} className="brelly-text-btn">
                Add Custom
              </div>
            </div>
          </div>
        )}
        {sortedAdditional?.length === 0 && (
          <div className="mt-3 jc-ac">
            <div onClick={addAdditional} className="brelly-text-btn">
              Add Custom
            </div>
          </div>
        )}
      </div>
      <BrellyBtn
        className="mt-3"
        onClick={handleEdit}
        style={{ width: "100%" }}
      >
        Save
      </BrellyBtn>
    </CenterModal>
  );
};

const CustomField = ({ entry, additional, setAdditional }) => {
  let entries = additional?.entries || [];
  const { key, value, id } = entry;

  const handleChange = (type, newValue) => {
    let others = entries?.filter((c) => c?.id !== id);
    setAdditional({
      entries: [
        ...others,
        { key: key, value: value, [type]: newValue, id: id },
      ],
    });
  };

  const deleteEntry = () => {
    let remaining = entries?.filter((c) => c?.id !== id);
    setAdditional({ entries: remaining });
  };

  return (
    <div className="js-ac">
      <div className="row">
        <div className="col-5">
          <Input
            value={key}
            onChange={(nv) => handleChange("key", nv)}
            label="Name"
          />
        </div>
        <div className="col-7" style={{ paddingLeft: 0 }}>
          <Input
            value={value}
            onChange={(nv) => handleChange("value", nv)}
            label="Value"
          />
        </div>
      </div>
      <div
        style={{
          width: 28,
          flexShrink: 0,
          height: 35,
          marginTop: 17,
          marginRight: -6,
        }}
        className="je-ac"
      >
        <TrashIcon size={16} onClick={deleteEntry} />
      </div>
    </div>
  );
};

const policyTypeOptions = [
  { label: "Homeowners", value: 1 },
  { label: "Business Owner", value: 2 },
  { label: "Flood", value: 3 },
  { label: "Commercial Property/Fire", value: 4 },
];
