import { groupArrayItems } from "utils";
import { DateSelect } from "components/Select";
import { InlineModal } from "components/Modals";
import { isAfter, isBefore, format, getHours, getMinutes } from "date-fns";

export const DateFilterModal = ({
  filters,
  dateFilterBtnRef,
  dateFilterModal,
  setDateFilterModal,
  handeFilterUpdate,
}) => {
  return (
    <InlineModal
      btnRef={dateFilterBtnRef}
      open={dateFilterModal}
      handleClose={() => setDateFilterModal(false)}
    >
      <DateSelect
        label="Start Date"
        style={{ minWidth: 240 }}
        value={filters?.startDate}
        onChange={(v) => handeFilterUpdate({ startDate: v })}
      />
      <DateSelect
        label="End Date"
        style={{ minWidth: 240 }}
        value={filters?.endDate}
        onChange={(v) => handeFilterUpdate({ endDate: v })}
      />
    </InlineModal>
  );
};

export const TypeFilterModal = ({
  filters,
  typeFilterBtnRef,
  typeFilterModal,
  setTypeFilterModal,
  handeFilterUpdate,
}) => {
  let existing = filters?.type || [];

  const handleClick = (type) => {
    if (existing?.includes(type)) {
      let remaining = existing?.filter((t) => t !== type);
      handeFilterUpdate({ type: remaining });
    } else {
      handeFilterUpdate({ type: [...existing, type] });
    }
  };

  return (
    <InlineModal
      btnRef={typeFilterBtnRef}
      open={typeFilterModal}
      handleClose={() => setTypeFilterModal(false)}
    >
      <div
        className={`dash-table-item js-ac mb-2`}
        onClick={() => handeFilterUpdate({ type: [] })}
        style={{ fontWeight: 600, cursor: "pointer", minWidth: 120 }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 4,
            border: "1px solid #0D72C5",
            marginRight: 8,
            padding: 2,
          }}
        >
          {filters?.type?.length === 0 && (
            <div style={{ width: "100%", height: "100%", background: "#0D72C5", borderRadius: 3 }} />
          )}
        </div>
        All
      </div>
      <div
        className={`dash-table-item js-ac mb-2`}
        onClick={() => handleClick("Event")}
        style={{ fontWeight: 600, cursor: "pointer", minWidth: 120 }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 4,
            border: "1px solid #0D72C5",
            marginRight: 8,
            padding: 2,
          }}
        >
          {filters?.type?.includes("Event") && (
            <div style={{ width: "100%", height: "100%", background: "#0D72C5", borderRadius: 3 }} />
          )}
        </div>
        Event
      </div>
      <div
        className={`dash-table-item js-ac mb-2`}
        onClick={() => handleClick("Email")}
        style={{ fontWeight: 600, cursor: "pointer", minWidth: 120 }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 4,
            border: "1px solid #0D72C5",
            marginRight: 8,
            padding: 2,
          }}
        >
          {filters?.type?.includes("Email") && (
            <div style={{ width: "100%", height: "100%", background: "#0D72C5", borderRadius: 3 }} />
          )}
        </div>
        Email
      </div>
      <div
        className={`dash-table-item js-ac mb-2`}
        onClick={() => handleClick("Call")}
        style={{ fontWeight: 600, cursor: "pointer", minWidth: 120 }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 4,
            border: "1px solid #0D72C5",
            marginRight: 8,
            padding: 2,
          }}
        >
          {filters?.type?.includes("Call") && (
            <div style={{ width: "100%", height: "100%", background: "#0D72C5", borderRadius: 3 }} />
          )}
        </div>
        Call
      </div>
      <div
        className={`dash-table-item js-ac mb-2`}
        onClick={() => handleClick("Task")}
        style={{ fontWeight: 600, cursor: "pointer", minWidth: 120 }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 4,
            border: "1px solid #0D72C5",
            marginRight: 8,
            padding: 2,
          }}
        >
          {filters?.type?.includes("Task") && (
            <div style={{ width: "100%", height: "100%", background: "#0D72C5", borderRadius: 3 }} />
          )}
        </div>
        Task
      </div>
      <div
        className={`dash-table-item js-ac`}
        onClick={() => handleClick("Doc")}
        style={{ fontWeight: 600, cursor: "pointer", minWidth: 120 }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 4,
            border: "1px solid #0D72C5",
            marginRight: 8,
            padding: 2,
          }}
        >
          {filters?.type?.includes("Doc") && (
            <div style={{ width: "100%", height: "100%", background: "#0D72C5", borderRadius: 3 }} />
          )}
        </div>
        Document
      </div>
    </InlineModal>
  );
};

const createSearchStr = (task) => {
  let name = task?.name || "";
  let body = task?.body || "";
  let topic = task?.topic || "";
  let subject = task?.subject || "";
  let description = task?.description || "";
  let senderFirst = task?.sender?.firstName;
  let senderLast = task?.sender?.lastName;
  let callerFirst = task?.caller?.firstName;
  let callerLast = task?.caller?.lastName;
  let recieverFirst = task?.reciever?.firstName;
  let recieverLast = task?.reciever?.lastName;
  let str = `${name} ${body} ${topic} ${subject} ${description} ${callerFirst} ${callerLast} ${recieverFirst} ${recieverLast} ${senderFirst} ${senderLast}`;
  return str?.toLowerCase();
};

export const getDisplayDate = (ev) => {
  if (ev?.startDate) {
    return ev?.startDate;
  } else if (ev?.sentAt) {
    return ev?.sentAt;
  } else if (ev?.occuredAt) {
    return ev?.occuredAt;
  } else if (ev?.completedAt) {
    return ev?.completedAt;
  } else if (ev?.endDate) {
    return ev?.endDate;
  } else {
    return ev?.createdAt;
  }
};

const sortEvents = (events, latest) => {
  const createTime = (t) => {
    let hours = getHours(new Date(t?.timelineDate));
    let minutes = getMinutes(new Date(t?.timelineDate));
    let time = `${hours}.${minutes}`;
    return parseFloat(time);
  };

  if (latest) {
    return events?.sort((a, b) => createTime(a) - createTime(b));
  } else {
    return events?.sort((a, b) => createTime(b) - createTime(a));
  }
};

export const getFiltered = (filters, timeline) => {
  let _timeline = timeline;

  if (filters?.startDate) {
    _timeline = _timeline?.filter((t) => isAfter(new Date(t?.timelineDate), new Date(filters?.startDate)));
  }

  if (filters?.endDate) {
    _timeline = _timeline?.filter((t) => isBefore(new Date(t?.timelineDate), new Date(filters?.endDate)));
  }

  if (filters?.type?.length > 0) {
    if (!filters?.type?.includes("Event")) {
      _timeline = _timeline?.filter((t) => !t?.participants);
    }

    if (!filters?.type?.includes("Email")) {
      _timeline = _timeline?.filter((t) => !t?.sentAt);
    }

    if (!filters?.type?.includes("Call")) {
      _timeline = _timeline?.filter((t) => !t?.occuredAt);
    }

    if (!filters?.type?.includes("Task")) {
      _timeline = _timeline?.filter((t) => !t?.completedAt);
    }

    if (!filters?.type?.includes("Doc")) {
      _timeline = _timeline?.filter((t) => !t?.downloadUrl);
    }
  }

  if (filters?.searchVal?.length > 0) {
    let formatted = _timeline?.map((t) => ({ ...t, searchStr: createSearchStr(t) }));
    _timeline = formatted?.filter((t) => t?.searchStr?.includes(filters?.searchVal?.toLowerCase()));
  }

  const formattedTimeline = _timeline?.map((t) => ({
    ...t,
    date: format(new Date(t?.timelineDate), "MM/dd/yyyy"),
  }));
  let groupedEvents = groupArrayItems(formattedTimeline, "date");

  if (filters.sort === "latest") {
    groupedEvents = groupedEvents
      ?.sort((a, b) => new Date(a.type) - new Date(b.type))
      .map((ge) => ({ ...ge, values: sortEvents(ge?.values, true) }));
  } else {
    groupedEvents = groupedEvents
      ?.sort((a, b) => new Date(b.type) - new Date(a.type))
      .map((ge) => ({ ...ge, values: sortEvents(ge?.values) }));
  }

  return groupedEvents;
};
