import { useState } from "react";
import { format } from "date-fns";
import { EditModal } from "./EditModal";
import { useProject } from "pages/Dash/Claims/Claim/context";
import {
  DetailItem,
  EmailDetailItem,
  PhoneDetailItem,
} from "pages/Dash/Claims/Claim/Details/components";

export const Details = () => {
  const { policy } = useProject();
  const [editInfoModal, setEditInfoModal] = useState(false);
  const insuranceCompany = policy?.insuranceCompany || {};
  const effectiveDate = policy?.effectiveDate
    ? format(new Date(policy?.effectiveDate), "MM/dd/yyyy")
    : "Unknown";
  const expiryDate = policy?.expiryDate
    ? format(new Date(policy?.expiryDate), "MM/dd/yyyy")
    : "Unknown";
  const policyNumber = policy?.policyNumber ? policy.policyNumber : "Unknown";
  const additional = policy?.additionalInfo?.entries || [];
  const filteredAdditional = additional?.filter((e) => !e.hide);

  const insurancePhone = additional?.find(
    (e) => e?.key === "INSURANCE PHONE"
  ) || { value: "" };
  const insuranceEmail = additional?.find(
    (e) => e?.key === "INSURANCE EMAIL"
  ) || { value: "" };
  const insuranceAddress = additional?.find(
    (e) => e?.key === "INSURANCE ADDRESS"
  ) || { value: "" };

  return (
    <>
      <div
        className="mt-3 brelly-content ps-3 pe-3 pt-2 pb-3"
        style={{ borderRadius: 8 }}
      >
        <div className="jb-ac" style={{ width: "100%", marginBottom: 14 }}>
          <div className="dash-box-title">Information</div>
          <div
            className="brelly-text-btn"
            onClick={() => setEditInfoModal(true)}
            style={{ color: "#0D72C5" }}
          >
            Edit
          </div>
        </div>
        <DetailItem
          title="Insurer Name"
          value={insuranceCompany?.name || "Unknown"}
        />
        <DetailItem title="Policy Number" value={policyNumber} />
        <DetailItem title="Policy Type" value={policy?.type} />
        <DetailItem title="Effective Date" value={effectiveDate} />
        <DetailItem title="Expiration Date" value={expiryDate} />
        <PhoneDetailItem title="Insurer Phone" value={insurancePhone?.value} />
        <EmailDetailItem title="Insurer Email" value={insuranceEmail?.value} />
        <DetailItem title="Insurer Address" value={insuranceAddress?.value} />
        {filteredAdditional?.map((ad) => (
          <DetailItem key={ad?.id} title={ad?.key} value={ad?.value} />
        ))}
      </div>

      <EditModal
        policy={policy}
        open={editInfoModal}
        handleClose={() => setEditInfoModal(false)}
      />
    </>
  );
};
