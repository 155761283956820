import brelly from "@brelly/sdk";
import { useApp } from 'context';
import Input from 'components/Input';
import { contactRoles } from 'constants';
import TextArea from 'components/TextArea';
import { useState, useEffect } from 'react';
import { PhotoIcon } from 'components/Icons';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';
import { SimpleFileModal } from 'modals';
import PhoneNumberPicker from 'components/PhoneNumber';
import LocationPicker from "components/LocationPicker2";
import { SingleSelect, MultiSelect } from 'components/Select';

export const ViewContactModal = ({ open, contact, handleClose }) => {
  if (open) {
    return <Inner open={open} contact={contact} handleClose={handleClose} />
  } else {
    return null;
  }
}

const Inner = ({ open, contact, handleClose }) => {
  const fakeOnChange = () => {};

  const getProPic = () => {
    if (contact?.profilePic) {
      return contact?.profilePic?.downloadUrl
    }
  }

  return(
    <>
      <CenterModal open={open} handleClose={handleClose} style={{ width: 800, maxWidth: '100%' }}>
        <div className="js-ac mb-2">
          <div style={{ width: 42, height: 42, borderRadius: '50%', background: 'rgba(13, 114, 197, 0.16)', border: '1px dashed #0D72C5' }} className='jc-ac me-2'>
            <PhotoIcon />
            {getProPic() && (
              <img alt='' src={getProPic()} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            )}
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Contact</div>
        </div>
        <div className='row' style={{ maxHeight: window.innerHeight - 280, overflowY: 'auto' }}>
          <div className='col-12 col-lg-6'>
            <Input label='First Name' value={contact?.firstName} onChange={fakeOnChange} disabled={true} />
            <Input label='Middle Name' value={contact?.middleName} onChange={fakeOnChange} disabled={true} />
            <Input label='Last Name' value={contact?.lastName} onChange={fakeOnChange} disabled={true} />
            <Input value={contact?.homeAddress?.fullAddress} setValue={fakeOnChange} label="Address" disabled={true} />
            <Input label='Company' value={contact?.company} onChange={fakeOnChange} disabled={true} />
            <Input label='Role' value={contact?.role} onChange={fakeOnChange} disabled={true} />
            <Input label='Title' value={contact?.jobTitle} onChange={fakeOnChange} disabled={true} />
          </div>
          <div className='col-12 col-lg-6'>
            <Input label='Website' value={contact?.website} onChange={fakeOnChange} placeholder='www.trailblazers.com' disabled={true} />
            <Input label='Email (primary)' value={contact?.primaryEmail} onChange={fakeOnChange} disabled={true} />
            <Input label='Phone Number' value={contact?.primaryPhoneNumber} onChange={fakeOnChange} disabled={true} />
            <TextArea label='Notes' value={contact?.notes} onChange={fakeOnChange} style={{ height: 216 }} disabled={true} />
          </div>
        </div>
        <BrellyBtn className='mt-4' onClick={handleClose}>Done</BrellyBtn>
      </CenterModal>
    </>
  )
}
