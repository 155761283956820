import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import TextArea from 'components/TextArea';
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CenterModal } from 'components/Modals';

export const EditWorkflowModal = ({ workflow, open, handleClose, setSelectedWorkflow }) => {
  const { setLoading, setError, handleUser } = useApp();
  const [values, setValues] = useState({});

  useEffect(() => {
    if (open) {
      setValues({
        name: workflow?.name,
        description: workflow?.description
      })
    }
    //eslint-disable-next-line
  }, [open])

  const handleUpdate = (changes = {}) => {
    setValues({...values, ...changes})
  }

  const handleAdd = async () => {
    try {
      setLoading(true);
      await brelly.workflows.update(workflow?.id, values);
      await handleUser();
      setSelectedWorkflow({ ...workflow, ...values })
      setLoading(false);
      handleClose()
    } catch(err) {
      setError(err.message)
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose} style={{ width: 500, maxWidth: '100%' }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit {workflow?.name || "Workflow"}</div>
      <Input value={values?.name} onChange={(v) => handleUpdate({ name: v })} label='Name' />
      <TextArea style={{ height: 150 }} value={values?.description} onChange={(v) => handleUpdate({ description: v })} label='Description' />
      <BrellyBtn onClick={handleAdd} className='mt-3'>Save</BrellyBtn>
    </CenterModal>
  )
}
