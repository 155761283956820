//External Imports
import { useState, useEffect, useRef } from "react";

//Internal Imports
import { Portal } from "react-portal";
import Input from "components/Input";
import brelly from "@brelly/sdk";
import ProPic from "components/ProPic";
import { useApp } from "context";

const MortgageCompanySelect = ({ value, onSelect = () => {}, maxHeight = 380 }) => {
  const { setLoading } = useApp();
  const [searchVal, setSearchVal] = useState("");
  const [companies, setCompanies] = useState([]);
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueKey = value?.value;
  // const screenHeight = window?.innerHeight;
  // const inputBottom = boundingRect?.bottom || 0;

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect());
    }
  }, [inputRef, focus, valueKey]);

  const handleCompanies = async () => {
    try {
      let { data } = await brelly.lenders.getAll();
      setCompanies(data);
    } catch (err) {}
  };

  useEffect(() => {
    handleCompanies();

    if (value?.name) {
      setSearchVal(value?.name);
    }
    //eslint-disable-next-line
  }, []);

  const handleResults = () => {
    if (searchVal?.length > 0) {
      // return companies
      return companies?.filter((c) => c?.name?.toLowerCase().includes(searchVal?.toLowerCase()));
    } else {
      return companies;
    }
  };

  const handleSelect = (company) => {
    onSelect(company);
    setFocus(false);
    setSearchVal(company?.name);
  };

  const createNew = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.lenders.create({
        name: searchVal,
      });
      handleSelect(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const showCreate = searchVal?.length > 2;

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div
        style={{
          fontSize: 12,
          paddingLeft: 4,
          lineHeight: 1,
          marginBottom: 2,
        }}
        className="mt-2"
      >
        Mortgage Company
      </div>
      <Input
        inputRef={inputRef}
        autoComplete="off"
        value={searchVal}
        placeholder="Search"
        onChange={setSearchVal}
        label=""
        style={{ textTransform: "capitalize" }}
        onFocus={() => setFocus(true)}
      />

      {focus && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={() => setFocus(false)}
          />
          <div
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 8,
              paddingLeft: 12,
              paddingRight: 12,
              zIndex: 1010,
              fontFamily: 'Lato',
              left: boundingRect.x,
              top: boundingRect.bottom + 6,
              width: boundingRect.width,
            }}
            className="shadow"
          >
            <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
              {handleResults()?.map((result, key) => (
                <SearchResult key={key} result={result} onSelect={handleSelect} />
              ))}
              {showCreate && (
                <div
                  className="js-ac"
                  onClick={createNew}
                  style={{ width: "100%", height: 54, textTransform: "capitalize", cursor: "pointer" }}
                >
                  <ProPic user={{ firstName: searchVal }} style={{ marginRight: 10 }} />
                  Create {searchVal}
                </div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default MortgageCompanySelect;

const SearchResult = ({ onSelect, result }) => {
  return (
    <div
      className="js-ac"
      onClick={() => onSelect(result)}
      style={{
        width: "100%",
        height: 54,
        textTransform: "capitalize",
        cursor: "pointer",
      }}
    >
      {result?.branding?.logo && (
        <img
          alt=""
          src={result?.branding?.logo}
          style={{
            width: 32,
            height: 32,
            objectFit: "cover",
            marginRight: 10,
            borderRadius: "50%",
          }}
        />
      )}
      {!result?.branding?.logo && <ProPic user={{ firstName: result?.name }} style={{ marginRight: 10 }} />}
      {result?.name}
    </div>
  );
};
