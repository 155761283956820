import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import TextArea from "components/TextArea";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import PhoneNumberPicker from "components/PhoneNumber";
import LocationPicker from "components/LocationPicker2";

export const AddOrgModal = ({ open, setOpen }) => {
  const { user, setLoading, setError, handleUser, setMembership } = useApp();
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState("");
  const [addy, setAddy] = useState({});
  const [fb, setFb] = useState('');
  const [insta, setInsta] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (open) {
      setName("")
      setWebsite("")
      setPhoneNumber("")
      setDescription("")
      setAddy("")
      setFb("")
      setInsta("")
      setLinkedin("")
    }
  }, [open])

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    if (name?.length > 0) {
      try {
        setLoading(true);
        let newAddy = {};

        if (addy?.lat) {
          newAddy = await brelly.addresses.create(addy);
        }

        let { data } = await brelly.organizations.create({
          name: name,
          website: website,
          ownerId: user?.id,
          phoneNumber: phoneNumber,
          description: description,
          facebook: fb,
          instagram: insta,
          linkedIn: linkedin,
          addressId: newAddy?.id
        });
        let userData = await handleUser();
        let newMembership = userData?.memberships?.find((m) => m?.organizationId === data?.id);
        setMembership(newMembership)
        setLoading(false);
        handleClose();
        history.push(`/dash?tab=home&walkthrough=${name}&mid=${newMembership?.id}`)
      } catch (err) {
        setError(err?.message);
        setLoading(false);
      }
    } else {
      setError("Please add a name");
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose} style={{ width: 800, maxWidth: '100%' }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Create Company</div>
      <div className='row' style={{ maxHeight: window.innerHeight - 280, overflowY: 'auto' }}>
        <div className='col-12 col-lg-6'>
          <Input label="Name" value={name} onChange={setName} placeholder="Claim Pros Inc" />
          <Input label="Website" value={website} onChange={setWebsite} placeholder="www.claims.com" />
          <PhoneNumberPicker label="Phone Number" value={phoneNumber} onChange={setPhoneNumber} placeholder="504 555-9090" />
        </div>
        <div className='col-12 col-lg-6'>
          <Input label="Facebook" value={fb} onChange={setFb} placeholder="claim-pros" />
          <Input label="Instagram" value={insta} onChange={setInsta} placeholder="@claim-pros" />
          <Input label="LinkedIn" value={linkedin} onChange={setLinkedin} placeholder="claim-pros" />
        </div>
        <div className='col-12'>
          <LocationPicker label="Address" value={addy} setValue={setAddy} placeholder="5 St. Charles Ave. New Orleans, LA" />
          <TextArea
            label="Description"
            value={description}
            onChange={setDescription}
            style={{ height: 80 }}
            placeholder="Claim Pros enables policyholders and professionals to seamlessly collaborate for fast and effective claims."
          />
        </div>
      </div>
      <BrellyBtn style={{ width: '100%' }} className="mt-4" onClick={handleCreate}>
        Create
      </BrellyBtn>
    </CenterModal>
  );
};
