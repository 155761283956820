import { useState } from "react";
import { LinkItem } from './Item';
import { LinksIcon } from "components/Icons";
import { EmptyState } from 'pages/Viewer/Details/components';

export const Links = ({ claim }) => {
  const links = claim?.links || [];

  return (
    <>
      <div className="dash-box dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 10 }}>
          <div className="dash-box-title">
            <LinksIcon style={{ marginRight: 8 }} stroke="#0D72C5" />
            Important Links
          </div>
        </div>
        {links?.map((link, key) => (
          <LinkItem key={key} link={link} links={links} />
        ))}
        <EmptyState type="Links" arr={links} />
      </div>
    </>
  );
};
