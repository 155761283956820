import { useState } from "react";
import { EditModal } from './EditModal';
import { useProject } from "pages/Dash/Claims/Claim/context";
import { DetailItem } from 'pages/Dash/Claims/Claim/Details/components';

export const Notes = () => {
  const { project } = useProject();
  const [editModal, setEditModal] = useState(false);
  const additional = project?.additionalInfo?.entries || [];
  const notes = project?.notes;
  const hasNotes = notes?.length > 0;

  return (
    <div className="dash-box mt-2 dash-box-padded">
      <div className="jb-ac">
        <div className="dash-box-title">
          Other Info
        </div>
        <div
          className="brelly-text-btn"
          onClick={() => setEditModal(true)}
          style={{ color: "#0D72C5" }}
        >
          {hasNotes ? 'Edit' : "Add"}
        </div>
      </div>
      {hasNotes && (
        <div
          className="mt-3 brelly-content p-3"
          style={{ borderRadius: 8, minHeight: 120, color: 'black' }}
        >
          {project?.notes}
        </div>
      )}
      {additional?.map((ad) => (
        <DetailItem key={ad?.id} title={ad?.key} value={ad?.value} />
      ))}
      <EditModal
        project={project}
        open={editModal}
        handleClose={() => setEditModal(false)}
      />
    </div>
  );
};
