import { DetailItem, MapDetailItem } from 'pages/Dash/Claims/Claim/Details/components';
import { useProject } from "pages/Dash/Claims/Claim/context";
import { formatCurrency, formatNumber } from "utils";
import { HomeIcon } from "components/Icons";
import { EditModal } from './EditModal';
// import { useApp } from "context";
import { useState } from "react";

export const Property = () => {
  const { property } = useProject();
  const additional = property?.additionalInfo?.entries || [];
  const [editPropertyModal, setEditPropertyModal] = useState(false);

  return (
    <>
      <div className="dash-box mt-2 dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 14 }}>
          <div className="dash-box-title">
            <HomeIcon
              style={{ marginRight: 8, marginBottom: 2 }}
              stroke="#0D72C5"
            />
            Property
          </div>
          <div
            className="brelly-text-btn"
            onClick={() => setEditPropertyModal(true)}
            style={{ color: "#0D72C5" }}
          >
            Edit
          </div>
        </div>
        <DetailItem
          title="Replacement Cost (total value)"
          value={formatCurrency(property?.replacementCost)}
        />
        <DetailItem title="Year Built" value={property?.yearBuilt} />
        <DetailItem
          title="Year Last Renovated"
          value={property?.lastRenovationDate}
        />
        <DetailItem title="Square Footage" value={formatNumber(property?.sqft)} />
        {additional?.map((ad) => (
          <DetailItem key={ad?.id} title={ad?.key} value={ad?.value} />
        ))}
        <MapDetailItem title="Address" value={property?.address} />
      </div>
      <EditModal
        property={property}
        open={editPropertyModal}
        handleClose={() => setEditPropertyModal(false)}
      />
    </>
  );
};
